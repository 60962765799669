<div class="d-flex flex-row align-items-center justify-content-between mb-4">
  <div class="fw-bold text-primary font-weight-medium m-0">Show product comments</div>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" (change)="handleCommentsChange($event)" [checked]="merchant.show_product_comments">
  </div>
</div>

<div class="d-flex flex-row align-items-center justify-content-between">
  <div class="fw-bold text-primary font-weight-medium m-0">Show product ratings</div>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" (change)="handleRatingsChange($event)" [checked]="!!merchant.show_product_ratings">
  </div>
</div>
