<section class="panel padding-regular" *ngIf="where == 'loan'">
  <h3 class="section-title">
    Accounts <small>Enjoy the benefit of easy transfer, stay safe.</small>
  </h3>
  <div class="flex mt-5">
    <div class="cards fill-w" *ngIf="!loadingCards">
      <div class="card light has-overlay" *ngIf="account.LAST_FOUR_DIGITS">
        <div class="top">
          <div class="name">{{ account.bank_name }}</div>
        </div>
        <div class="middle">
          <div class="number">{{ account.LAST_FOUR_DIGITS }}</div>
        </div>
        <div class="bottom">
          <div class="items flex justify-between">
            <div class="item">
              <span class="label">{{ account.CARD_NAME }}</span>
              <span class="value"></span>
            </div>
          </div>
        </div>
        <div class="overlay">
          <app-button label="Manage accounts" class="small outline ml-2" (click)="open.emit('account')">
          </app-button>
        </div>
      </div>
      <div class="card card-light flex-1 flex-center" *ngIf="!account.LAST_FOUR_DIGITS">
        <div (click)="openModal('accounts')" class="icon-text medium cursor-pointer" style="width: 50%;padding: 10px 0px;">
          <div class="icon color-red"><i class="fas fa-ban"></i></div>
          <div class="text w-60" style="color:#fff">No Account Added</div>
        </div>
      </div>

       
    </div>
    <div class="cards fill-w" *ngIf="loadingCards">
      <div class="card light skeleton fit-content"></div>
      <div class="card light skeleton fit-content"></div>
    </div>
  </div>
</section>
<section class="panel padding-regular"  *ngIf="where == 'customer'">
  <h3 class="section-title">
    Accounts & Cards <small>Enjoy the benefit of easy transfer, stay safe.</small>
  </h3>
  <div class="flex mt-5">
    <div class="cards fill-w" *ngIf="!loadingCards"> 
      <div class="card card-light flex-1 flex-center">
        <div (click)="open.emit('account')" class="icon-text medium cursor-pointer" style="width: 50%;padding: 10px 0px;">
          <div class="icon color-red"><i class="fas fa-ban"></i></div>
          <div class="text w-60" style="color:#fff">View Accounts</div>
        </div>
      </div>
 
      <div class="card flex-1 flex-center">
        <div (click)="open.emit('card')" class="icon-text medium cursor-pointer" style="width: 50%;padding: 10px 0px;">
          <div class="icon color-red"><i class="fas fa-ban"></i></div>
          <div class="text w-60 color-white">View Cards</div>
        </div>
      </div>
    </div>
    <div class="cards fill-w" *ngIf="loadingCards">
      <div class="card light skeleton fit-content"></div>
      <div class="card light skeleton fit-content"></div>
    </div>
  </div>
</section>