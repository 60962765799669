import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-loanactions',
  templateUrl: './loanactions.component.html',
  styleUrls: ['./loanactions.component.css']
})
export class LoanactionsComponent implements OnInit {
  @Input() requestId;
  @Input() loan;
  @Input() embedModal = false;
  @Output() openModalChild = new EventEmitter();
  more = false;
  test_mode = JSON.parse(localStorage.getItem('test_mode'));

  constructor(
    public dataService: DataService,
    public authService: AuthenticationService,
  ) { }

  ngOnInit() {
  }

  grantRight(right) {
    return this.authService.userHasRights(right);
  }

  setDefaultRepaymentMode(loan, repayment_mode) {
    this.dataService.onOpenLoanChildModal.emit({ 'location': 'set_default_repayment_mode', data: this.loan, repayment_mode: repayment_mode });
  }

  acceptContract() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'accept_contract_mod', data: this.loan });
    }
  }

  sendBVN() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'send_bvn_mod', data: this.loan });
    }
  }

  requestCard() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('39')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_card_mod', data: this.loan });
    }
  }

  sendContract() {
    if (this.test_mode == '0') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'send_contract_mod', data: this.loan });
    } else if (!this.grantRight('47')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }

  requestToGetPaid() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_to_get_paid', data: this.loan });
    }
  }

  requestOthers() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('40')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_others_mod', data: this.loan });
    }
  }

  confirmAccount() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('44')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'confirm_account_mod', data: this.loan });
    }
  }

  requestGuarantors() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('41')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_guarantor_mod', data: this.loan });
    }
  }

  sendEmailToCustomer() {
    this.dataService.sendMailToCustomer.emit({ data: this.loan.customer });
  }

  assignLoanOfficer() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('45')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onChangeLoanOfficer.emit({ loan: this.loan });
    }
  }

  deleteRequest() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('46')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'delete_request_mod', data: this.loan });
    }
  }
}
