import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { AppConfig } from '../app.config';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../_services/storage.service';
import { UserService } from '../_services';

@Component({
  selector: 'app-twofa',
  templateUrl: './twofa.component.html',
  styleUrls: ['./twofa.component.css']
})
export class TwofaComponent implements OnInit {

  model: any = {};
  loading = false;
  error = '';
  currentUser: any;
  logged_in_user: any;

  constructor(
    public router: Router,
    public authenticationService: AuthenticationService,
    public storageService: StorageService,
    private toastr: ToastrService,
    private _user: UserService
  ) {
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }

  showError(msg) {
    this.toastr.error(msg);
  }

  ngOnInit() {
    // reset login status
    this.currentUser = this.storageService.read<any>('currentUser');
    this.logged_in_user = this.storageService.read<any>('logged_in_user');
    if (this.logged_in_user.current_state != "1") {
          if (this.authenticationService.canViewModule('1,2,4,5,1026')) {
            this.router.navigate(['/applications/dashboard']);
          }else
          if (this.authenticationService.canViewModule('3')) {
            this.router.navigate(['/operations/queue']);
          }else{
            this.router.navigate(['/login']);
          }
    }
  }
  login() {
    this.loading = true;
    this.authenticationService.confirmOTP(this.currentUser.token, this.model.password)
      .subscribe(result => {
        if (result.status === true) {
          if (this.authenticationService.canViewModule('1,2,4,5,1026')) {
            this.router.navigate(['/applications/dashboard']);
          } else if (this.authenticationService.canViewModule('3')) {
            this.router.navigate(['/operations/queue']);
          } else {
            this.router.navigate(['/login']);
          }
          setTimeout(() => {
            if (!this._user.kycDone) {
              this._user.showOnboarding();
            }
          }, 2000);
        } else {
          this.showError('Unable to confirm otp');
          this.loading = false;
        }
      }, err => {
        this.loading = false;
      });
  }
}
