<div class="wrapper">
  <div class="row">
    <div class="col-md-6 left">
      <div class="content">
        <div class="flex justify-center logos">
          <img src="/assets/images/cc-logo-md.png" alt="creditclan's logo"/>
          <span>+</span>
          <img src="/assets/images/kuda-logo-white.svg" alt="kuda bank logo" class="ml-4"/>
        </div>
        <img src="/assets/images/kuda-illustration.svg" alt="kuda illustration" class="illustration"/>
      </div>
    </div>
    <div class="col-md-6 right">
      <div *ngIf="view === 'form'" class="content">
        <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
          <div class="mb-5">
            <h3 class="m-0 font-bold">Hello, Kuda Bank</h3>
            <p class="mt-2">Kindly reset your password.</p>
          </div>
          <div class="form-group mt-3">
            <label>Password</label>
            <input
              #password="ngModel" autocomplete="false" [(ngModel)]="model.password" [disabled]="loading"
              class="form-control custom" name="password" placeholder="Password" required type="password"
            />
          </div>
          <div class="form-group">
            <label>Confirm password</label>
            <input
              #confirmPassword="ngModel" autocomplete="false" [(ngModel)]="model.confirmPassword" [disabled]="loading"
              class="form-control custom" name="confirmPassword" placeholder="Confirm password" required type="password"
            />
          </div>
          <div class="mt-4">
            <app-button [disabled]="loading" type="submit" label="Reset password" [loading]="loading"></app-button>
          </div>
        </form>
      </div>
      <div *ngIf="view === 'success'" class="content">
        <div class="d-flex flex-column align-items-center justify-content-center text-center my-auto">
          <i class="fa fa-check-circle text-success" style="font-size: 8rem"></i>
          <h4 class="mt-5 mb-0 font-bold">Password reset successful</h4>
          <p class="mt-2">Click the button below to login to your dashboard</p>
          <a routerLink="/login" class="mt-4 btn btn-outline-secondary">Login</a>
        </div>
      </div>
    </div>
  </div>
</div>
