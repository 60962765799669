<!-- <div class="overpanel-scroll">
    <div class="calendar___popout" *ngIf="calendar_from!='7'">
      <h3 class="title">Bulk Payments</h3>
      <div class="calendar__popout-table padding-regular">
  
      </div>
    </div>
  </div>
   -->
<div class="title flex justify-between">
  <span>Bulk Repayment<br><small>Batch by payment date and push once</small></span>

  <div class="flex">
    <div class="input-group">
      <input type="search" name="search_string" [(ngModel)]="param.search_string" class="form-control"
        style="width: 200px;" placeholder="Search..">
      <span class="input-group-btn">
        <button class="btn btn-outline-light" (click)="searchDueLoans()" type="button">Go</button>
      </span>
    </div>
    <a [routerLink]="['/operations/bulk_payment']" class="btn btn-outline-light btn-sm ml-2">Upload batch</a>
  </div>
</div>
<!-- <section class="overpanel-scroll">
        <div class="panel mb-1">
            <div class="panel-body flex align-start">
          
              <div class="section-poster flx-2" style="max-width: none">
                <app-timer [endDate]='endDate'></app-timer>
              </div>
          
              <div class="flex-2 ml-3" style="margin-left:100px !important">
                <div class="label-value-list mb-2">
                  <h4 class="section-title mb-5">Features</h4>
                  <div class="item">
                    
                    <span class="value pl_0 ">Multiple payments can be done at once</span> 
                  </div>
                  <div class="separator"></div>
                  <div class="item">
                    
                    <span class="value pl_0">Bulk approvals on payment for faster operations</span> 
                  </div>
                   
                   
          
                </div> 
              </div>
            </div>
          </div>
    </section> -->
<section infinite-scroll (scrolled)="onScrollDown()" *ngIf="!is_done" class="overpanel-scroll padding-regular"
  slimScroll width="auto" [infiniteScrollDistance]="1" [scrollWindow]="false" [infiniteScrollThrottle]="300"
  [infiniteScrollUpDistance]="2">
  <div class="mt-2" *ngIf="send_payments_to_queue == '1'">
    <div class="alert alert-warning alert-sm mb-4">Payments are queued for final approval</div>
  </div>

  <div *ngIf="!adding"
    class="panel padding-regular-medium padding-regular-x flex justify-between align-center mt-2 mb-4">
    <app-button label="Back" (click)="adding = true" class="small outline" icon="fa fa-arrow-left">
    </app-button>
    <div class="flex align-center">
      <input type="date" name="payment_date" id="payment_date" [(ngModel)]="payment_date" class="form-control mr-1"
        style="width: 200px">
      <!-- <app-button *ngIf="adding" label="Finish" [loading]="loading"
        [disabled]="loading || payment_date=='' || added_requests.length == 0" (click)="adding=!adding"
        class="small hide">
      </app-button> -->
      <app-button label="Submit" [loading]="loading"
        [disabled]="loading || payment_date=='' || added_requests.length == 0" (click)="postDuePayments()"
        class="small green mr-1">
      </app-button>
    </div>
  </div>

  <div *ngIf="!adding">
    <div class="contract-table">
      <table class="table medium">
        <thead>
          <tr>
            <th>Name</th>
            <th>Principal</th>
            <th>Repaid</th>
            <th>Balance</th>
            <th>Due</th>
            <th>Amount Paid</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let due of added_requests; let i=index">
            <td class="flex align-center">
              <div (click)="openSidemenuForDueLoan(due)" class="image-avatar smaller mr-2"><img
                  src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{due?.FILE_NAME | imagenotfound}}">
              </div>
              {{due.Name}}
            </td>
            <td>{{due.Principal | number: ".2-2"}}</td>
            <td>{{due.Paid | number: ".2-2"}}</td>
            <td>{{due.Balance | number: ".2-2"}}</td>
            <td>{{due.Due | number: ".2-2"}}</td>
            <td>{{due.AMOUNT_PAID | number: ".2-2"}}</td>
            <td>
              <app-button *ngIf="due.checked" class="small outline red" icon="fa fa-minus" (click)="removeRecord(due,i)"
                label="Remove">
              </app-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="contract-table" *ngIf="adding">
    <div class="added-popup" *ngIf="added_requests.length">
      <div class="alert alert-sm flex align-center justify-between">
        {{ added_requests.length }} payments added
        <label class="ml-2">
          <app-button [disabled]="!added_requests.length" *ngIf="adding" (click)="adding = false" class="small"
            label="View all"></app-button>
          <app-button *ngIf="!adding" label="Add More Requests" (click)="adding = true" class="small"></app-button>
        </label>
      </div>
    </div>
    <!-- <section infinite-scroll (scrolled)="onScrollDown()" class="item-area no-margin" slimScroll width="auto"
      [infiniteScrollDistance]="0"> -->
    <table class="table medium">
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Principal</th>
          <th>Repaid</th>
          <th>Balance</th>
          <th>Due</th>
          <th>Amount Paid</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let due of repayments; let i=index">
          <td><input type="checkbox" name="due{{due?.REQUEST_ID}}" [(ngModel)]="due.checked"></td>
          <td class="flex align-center">
            <div (click)="openSidemenuForDueLoan(due)" class="image-avatar smaller mr-2"><img
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{due?.FILE_NAME | imagenotfound}}">
            </div>
            {{due.Name}}
          </td>
          <td>{{due.Principal | number: ".2-2"}}</td>
          <td>{{due.Paid | number: ".2-2"}}</td>
          <td>{{due.Balance | number: ".2-2"}}</td>
          <td>{{due.Due | number: ".2-2"}}</td>
          <td><input type="number" min="0" [max]="due.Due" name="AMOUNT_PAID{{due?.REQUEST_ID}}"
              [(ngModel)]="due.AMOUNT_PAID"></td>
          <td>
            <app-button *ngIf="!due.checked" class="small outline" icon="fa fa-plus" (click)="addRecord(due,i)"
              label="Add"></app-button>
            <app-button *ngIf="due.checked" class="small outline red" icon="fa fa-minus" (click)="removeRecord(due,i)"
              label="Remove"></app-button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- </section> -->

    <div class="mt-4 text-bold text-center mx-auto" *ngIf="loading">Loading...</div>
  </div>
</section>

<section *ngIf="is_done" class="overpanel-scroll" style="padding : 2rem 2rem !important">
  <section class="fill-h fill-v">
    <div class="h-group center mb-4 padded empty-state">
      <div class="icon"><i class="fa fa-check-o"></i></div>
      <h2 class="title">Request Successful</h2>
      <p class="subtitle">Your request has been posted successfully</p>

    </div>
  </section>
</section>