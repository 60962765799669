<div class="title flex justify-between">
    <span>Bulk Repayment<br><small>Upload your payments and push once</small></span>
</div>

<section class="overpanel-scroll padding-regular" *ngIf="!finalpay">
    <div *ngIf="send_payments_to_queue == '1'">
        <div class="alert alert-info alert-sm mb-4">Payments are queued for final approval</div>
    </div>
    <div class="row">
        <div class="col-md-12 sharp_form">
            <div class="text-center">
                <div class="form-group flex-center">
                    <input #file style="display: none" (change)="onFilesAdded($event)" type="file"
                        accept=".png,.jpg,.jpeg,.txt,.doc,.docx,.xls,.xlsx">
                    <button class="upload-btn" [disabled]="uploadingfile||loading" (click)="openUploadDialog()"><i
                            class="fa fa-plus mr-2"></i> Add Files
                        <i class="fa fa-spinner fa-spin" *ngIf="uploadingfile"></i>
                    </button>
                </div>
                <!-- <div class="form-group">
                    <label class="control-label normal">
                        Select all payment and click upload documents button below to upload them
                    </label>
                </div> -->
                <div class="form-group" *ngIf="photoHasBeenUploaded">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let d of filesUploaded">{{d}}</li>
                    </ul>
                </div>

                <div class="mt-4">
                    <app-button *ngIf="photoHasBeenUploaded" class="small" (click)="uploadTheFile()" [loading]="loading" label="Upload Documents">
                    </app-button>
                    <app-button [disabled]="loading" (click)="downloadActiveLoans()" class="small outline ml-1"
                        label="Download Active Loans" icon="fa fa-download">
                    </app-button>
                </div>
            </div>

            <section class="panel" style="margin-top:15px" *ngIf="result?.data">
                <header class="panel-heading">Uploaded Data
                    <span class="actions pull-right">
                        <button [disabled]="loading" (click)="uploadPayments()" class="btn btn-white btn-xs"><i
                                class="fa fa-download"></i> Upload Payment
                            <i *ngIf="loading" class="fa fa-spinner fa-spin"></i></button>
                        <button [disabled]="loading" (click)="clearRecords()" class="btn btn-white btn-xs"><i
                                class="fa fa-download"></i> Clear Records <i *ngIf="loading"
                                class="fa fa-spinner fa-spin"></i></button>
                    </span>
                </header>
                <table class="table table-striped">
                    <thead>
                        <th>Customer Name</th>
                        <th>Loan Id</th>
                        <th>Principal</th>
                        <th>Due</th>
                        <th>Amount Paid</th>
                        <th>Payment Date</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let loan of result?.data">
                            <td>{{loan?.CustomerName}}</td>
                            <td>{{loan?.LoanId}}</td>
                            <td>{{loan?.Principal}}</td>
                            <td>{{loan?.Due}}</td>
                            <td>{{loan?.AmountPaid}}</td>
                            <td>{{loan?.PaymentDate}}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
    </div>
</section>
<section class="overpanel-scroll padding-regular" *ngIf="finalpay">
    
    <div class="row">
        <div class="col-md-12 sharp_form">
            <div class="text-center">
                <div class="form-group flex-center">
                    
                    <button class="upload-btn"><i
                            class="fa fa-check mr-2"></i>   
                    </button>
                </div> 
            </div> 
        </div>
    </div>
</section>