<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Loan Arrears Aging Report</h3>

      <div class="panel">
        <div class="panel-heading">Loans that are overdue between 1 and 30 days</div>
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <tr>
                <td colspan="7"><select (change)="getRecordsnew()" name="loan_product_id" [(ngModel)]="loan_product_id">
                    <option value=""></option>
                    <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                  </select>
                </td>
              </tr>
            </thead>
            <thead>
              <th>Date</th>
              <th>Customer</th> 
              <th>Loan Id</th>
              <th>Days In Arrears</th>
              <th>Overdue Principal</th>
              <th>Overdue Interest</th>
              <th>Overdue Balance</th>
              <th>Total Principal Outstanding</th>
              <th>Product</th>
            </thead>

            <tbody *ngFor="let record of data">
              
              <tr>
                <td>{{record.disbursement_date}}</td>
                <td>{{record.legal_name}}</td>
                <td>{{record.loan_id}}</td>
                <td>{{record.pastdue}}</td>
                <td>{{record.principal_net_due|number:'.2-2'}}</td>
                <td>{{record.interest_net_due|number:'.2-2'}}</td>
                <td>{{record.total_net_due|number:'.2-2'}}</td>
                <td>{{record.principal_balance|number:'.2-2'}}</td>
                <td>{{record.loan_title}}</td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <td colspan="7">
                  <app-button class="small outline mr-1" (click)="getRecords()" label="Next"></app-button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="panel">
        <div class="panel-heading">Loans that are overdue between 31 and 60 days</div>
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <tr>
                <td colspan="7"><select (change)="getRecordsnews()" name="loan_product_id" [(ngModel)]="loan_product_id">
                    <option value=""></option>
                    <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                  </select>
                </td>
              </tr>
            </thead>
            <thead>
              <th>Date</th>
              <th>Customer</th> 
              <th>Loan Id</th>
              <th>Days In Arrears</th>
              <th>Overdue Principal</th>
              <th>Overdue Interest</th>
              <th>Overdue Balance</th>
              <th>Total Principal Outstanding</th>
              <th>Product</th>
            </thead>

            <tbody *ngFor="let record of data_">
              
              <tr>
                <td>{{record.disbursement_date}}</td>
                <td>{{record.legal_name}}</td>
                <td>{{record.loan_id}}</td>
                <td>{{record.pastdue}}</td>
                <td>{{record.principal_net_due|number:'.2-2'}}</td>
                <td>{{record.interest_net_due|number:'.2-2'}}</td>
                <td>{{record.total_net_due|number:'.2-2'}}</td>
                <td>{{record.principal_balance|number:'.2-2'}}</td>
                <td>{{record.loan_title}}</td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <td colspan="7">
                  <app-button class="small outline mr-1" (click)="getRecordss()" label="Next"></app-button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="panel">
        <div class="panel-heading">Loans that are overdue between 61 and 90 days</div>
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <tr>
                <td colspan="7"><select (change)="getRecordsnewss()" name="loan_product_id" [(ngModel)]="loan_product_id">
                    <option value=""></option>
                    <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                  </select>
                </td>
              </tr>
            </thead>
            <thead>
              <th>Date</th>
              <th>Customer</th> 
              <th>Loan Id</th>
              <th>Days In Arrears</th>
              <th>Overdue Principal</th>
              <th>Overdue Interest</th>
              <th>Overdue Balance</th>
              <th>Total Principal Outstanding</th>
              <th>Product</th>
            </thead>

            <tbody *ngFor="let record of data__">
              
              <tr>
                <td>{{record.disbursement_date}}</td>
                <td>{{record.legal_name}}</td>
                <td>{{record.loan_id}}</td>
                <td>{{record.pastdue}}</td>
                <td>{{record.principal_net_due|number:'.2-2'}}</td>
                <td>{{record.interest_net_due|number:'.2-2'}}</td>
                <td>{{record.total_net_due|number:'.2-2'}}</td>
                <td>{{record.principal_balance|number:'.2-2'}}</td>
                <td>{{record.loan_title}}</td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <td colspan="7">
                  <app-button class="small outline mr-1" (click)="getRecordsss()" label="Next"></app-button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="panel">
        <div class="panel-heading">Loans that are overdue between 91 and 180 days</div>
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <tr>
                <td colspan="7"><select (change)="getRecordsnewsss()" name="loan_product_id" [(ngModel)]="loan_product_id">
                    <option value=""></option>
                    <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                  </select>
                </td>
              </tr>
            </thead>
            <thead>
              <th>Date</th>
              <th>Customer</th> 
              <th>Loan Id</th>
              <th>Days In Arrears</th>
              <th>Overdue Principal</th>
              <th>Overdue Interest</th>
              <th>Overdue Balance</th>
              <th>Total Principal Outstanding</th>
              <th>Product</th>
            </thead>

            <tbody *ngFor="let record of data___">
              
              <tr>
                <td>{{record.disbursement_date}}</td>
                <td>{{record.legal_name}}</td>
                <td>{{record.loan_id}}</td>
                <td>{{record.pastdue}}</td>
                <td>{{record.principal_net_due|number:'.2-2'}}</td>
                <td>{{record.interest_net_due|number:'.2-2'}}</td>
                <td>{{record.total_net_due|number:'.2-2'}}</td>
                <td>{{record.principal_balance|number:'.2-2'}}</td>
                <td>{{record.loan_title}}</td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <td colspan="7">
                  <app-button class="small outline mr-1" (click)="getRecordssss()" label="Next"></app-button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="panel">
        <div class="panel-heading">Loans that are overdue above 180</div>
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <tr>
                <td colspan="7"><select (change)="getRecordsnewssss()" name="loan_product_id" [(ngModel)]="loan_product_id">
                    <option value=""></option>
                    <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                  </select>
                </td>
              </tr>
            </thead>
            <thead>
              <th>Date</th>
              <th>Customer</th> 
              <th>Loan Id</th>
              <th>Days In Arrears</th>
              <th>Overdue Principal</th>
              <th>Overdue Interest</th>
              <th>Overdue Balance</th>
              <th>Total Principal Outstanding</th>
              <th>Product</th>
            </thead>

            <tbody *ngFor="let record of data____">
              
              <tr>
                <td>{{record.disbursement_date}}</td>
                <td>{{record.legal_name}}</td>
                <td>{{record.loan_id}}</td>
                <td>{{record.pastdue}}</td>
                <td>{{record.principal_net_due|number:'.2-2'}}</td>
                <td>{{record.interest_net_due|number:'.2-2'}}</td>
                <td>{{record.total_net_due|number:'.2-2'}}</td>
                <td>{{record.principal_balance|number:'.2-2'}}</td>
                <td>{{record.loan_title}}</td> 
              </tr>
            </tbody>

            <thead>
              <tr>
                <td colspan="7">
                  <app-button class="small outline mr-1" (click)="getRecordsssss()" label="Next"></app-button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>