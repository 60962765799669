import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LoansService, StorageService, DataService, AnalyticsService, AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-ippisdetails',
  templateUrl: './ippisdetails.component.html',
  styleUrls: ['./ippisdetails.component.css']
})
export class IppisdetailsComponent implements OnInit {
  
  @Input() loan :any;
  @Output() openMore = new EventEmitter();
  payslip:any;
  payslip_:any;
  currentUser: any;
  loading = false;
  constructor(
    public loansService: LoansService,
    public analyticsService: AnalyticsService,
    public storageService: StorageService,
    private dataService: DataService,
    public authService: AuthenticationService,
    private router: Router,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getPayslipDetails();
  }
  getPayslipDetails(){
    this.loading = true
    this.payslip = this.payslip_;
    this.loansService.getPayslipDetails(this.currentUser.token, this.loan.request_id)
    .subscribe(result => {
      this.payslip = result;
      this.loading = false;
    })
  }
}
