import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { validateConfig } from '@angular/router/src/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(public http: Http) {
  }

  getWebsiteContent(company_id: any): Observable<any> {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getWebsiteInformation',
    {company_id: company_id}).map((response: Response) => response.json());
  }

  postLogo(uploadData): Observable<any> {
    return this.http.post('https://emailmodule.creditclan.com/index.php/mailservices/updateLogo', 
    uploadData)
      .map((response: Response) =>  response.json());
  }


  postBgImage(uploadBgImage): Observable<any> {
    return this.http.post('https://emailmodule.creditclan.com/index.php/websitesetting/updateBgImg',
    uploadBgImage ).map((response) => response.json());
  }

  postCallToAction(token: any, company_id: any, data: any): Observable<any> {
    return this.http.
    post('https://emailmodule.creditclan.com/index.php/websitesetting/addCallToAction',
    {token: token, company_id: company_id, call_to_action: data })
      .map((response: Response) => response.json());
  }

  postCodeSection(token: any, company_id: any, data: any): Observable<any> {
    return this.http
    .post('https://emailmodule.creditclan.com/index.php/websitesetting/updateCodeSections', {
        token: token,
        company_id: company_id,
        data: data, 
      }).map((response: Response) => response.json());
  }

  postContactSection(uploadFooter): Observable<any> {
    //console.log(uploadFooter);
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/updateFooterSection',
    uploadFooter ).map((response: Response) => response.json());
  }
}
