import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LoansService, StorageService, AuthenticationService, DataService } from '../../_services/index';

@Component({
  selector: 'app-portfolio-sideview',
  templateUrl: './portfolio-sideview.component.html',
  styleUrls: ['./portfolio-sideview.component.css']
})
export class PortfolioSideviewComponent implements OnInit {
  @Input() requestId;
  @Input() loan;
  @Input() repayment;
  @Input() autoLoad = false;
  @Output() close = new EventEmitter();
  @Output() openModal = new EventEmitter();
  @Output() doingDisbursement = new EventEmitter();
  @Output() approveThisLoan = new EventEmitter();
  @Output() rejectThisLoan = new EventEmitter();
  currentUser: any;
  customer: any;
  is_individual: any;
  loading: boolean;
  more = '';
  child = '';
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  loanportfolio: any;
  subview = 'schedule'
  constructor(
    public loansService: LoansService,
    public storageService: StorageService,
    private dataService: DataService,
    public authService: AuthenticationService,
    private router: Router,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.changePortfolioView.subscribe(res => {
      this.subview = res.view;

    });
    this.dataService.sendstatementtocustomer.subscribe(res => {
      this.sendLoanStatement(res);

    });
    this.dataService.modifyLoanContract.subscribe(res => {
      this.modifyLoanContract(res)

    });
    this.dataService.modifyLoanSchedule.subscribe(res => {
      this.modifyLoanSchedule(res)

    });
  }

  view = 'repayment';
  details = false;

  ngOnInit() {

    document.body.style.overflow = "hidden";
    this.getLoan();
  }

  calculateMonthlyRepayment(principal, duration, rate) {
    if (!principal) return 0;
    return (((rate / 100) * principal) + principal) / duration;
  }
  ngOnDestroy() {
    document.body.style.overflow = "initial";
  }

  ngOnChanges() {
    this.child = '';
    this.getCustomer(this.requestId);
    if (this.loan) this.getLoan();
    if (this.autoLoad) this.getLoan();
  }

  setChild(child, event) {
    this[event.key] = event.value;
    this.child = child;
  }

  setView(view) {
    this.view = view;
  }

  toggleDetails() {
    this.details = !this.details;
  }

  closeView() {
    this.close.emit();
  }

  getLoan() {
    this.loading = true;
    this.loansService.getLoanPortfolio(this.currentUser.token, this.requestId)
      .subscribe(data => {
        this.loading = false;
        this.loanportfolio = data.loan;
        this.loan = data.loan;
        console.log({ data });
      });
  }
  openActionsForRolloverRequest(event){
    this.dataService.openRollOverActionModal.emit({ loan: this.loan.loan, actions_panel: 'rollover', request_id: event.request_id });
  }
  getCustomer(people_id) {
    this.loading = true;
    this.loansService.getLoanContractCustomerFromLoan(this.currentUser.token, people_id)
      .subscribe(data => {
        this.customer = data.data;
        this.is_individual = data.is_individual;
        this.loading = false;
      });
  }

  grantRight(right) {
    return this.authService.userHasRights(right);
  }

  requestCard() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('39')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_card_mod', data: this.loan });
    }
  }

  requestGuarantors() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('41')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_guarantor_mod', data: this.loan });
    }
  }

  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }

  registerPayment() {
    this.dataService.onStartNewRepaymentFlow.emit({ parentRouteId: this.requestId });
  }

  breakLoan() {
    this.dataService.onBreakingLoan.emit({ request_id: this.requestId, loan_viewed_details: {} })
  }
  modifyLoanContract(event){
    this.child='editcontract';
  }
  sendLoanStatement(event){
     this.dataService.sendstatementtocustomer.emit({loan:this.loan})
  }
  modifyLoanSchedule(event){
    this.loading = true;
    this.loansService.modifyloanschedule(this.currentUser.token, this.requestId)
      .subscribe(data => {
        this.loading = false;
        this.dataService.previewScheduleDetails.emit({ request_id: this.requestId, schedule: data.repayments, canEditSchedule: true, loan_status: 3 });
      });
  }
  openModal_(section) {
    if (section === 'setup_cheques') {
      if (!this.grantRight('27')) {
        section = 'no_right';
      }
    }
    if (section === 'setup_cards') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'account_debit') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'remita_inflight') {
      if (!this.grantRight('81')) {
        section = 'no_right';
      }
    }
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.requestId, loan: this.loan });
  }

  openActionsForPortfolioRequest() {
    this.dataService.openRollOverActionModal.emit({ loan: this.loan, actions_panel: 'portfolio', request_id: this.requestId });
  }

  changeDefaultRepayment() {
    if (!this.grantRight('54')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.dataService.onChangeDefaultPayment.emit({ loan: this.loan });
  }

  makePayment(event) {
    this.dataService.onStartNewRepaymentFlow.emit({ parentRouteId: this.loan.REQUEST_ID });
  }

  cancelToUp(request_id) {
    this.dataService.onOpenLoanChildModal.emit({ 'location': 'cancel_top_up_mod', data: request_id });
  }

  cancelRollover(request_id){
    this.dataService.onOpenLoanChildModal.emit({ 'location': 'cancel_roll_over_mod', data: request_id });
  }
  sendEmailToCustomer(request) { 
    this.dataService.sendMailToCustomer.emit({ customer_id: request.people_id, is_multiple: false });
  }
  sendSMSToCustomer(request) { 
    this.dataService.sendSMSToCustomer.emit({ customer_id: request.people_id, is_multiple: false });
  }
}
