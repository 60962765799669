import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() label = '';
  @Input() class: string;
  @Input() type = 'button';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() icon = '';
  @Input() iconOnly = false;

  @Input() buttonclass: string;

  constructor() {
  }

  ngOnInit() {
  }

}
