import { Component, Input, OnInit } from '@angular/core';
import { LoansService, StorageService } from 'src/app/_services';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit {
  currentUser: any;
  collections = [];
  loading = false;
  @Input() request_id = '';
  constructor(
    private loanService: LoansService,
    public storageService: StorageService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getCollections();
  }

  getCollections() {
    this.loading = true;
      this.loanService.getPaymentCollectionsForRequest(this.currentUser.token, this.request_id).subscribe(res => {
        this.loading = false;
        this.collections = res.repayments;
      }, err => {
        this.loading = false;
      })
    
  }
}
