<div class="wrapper">
    <div class="row">
        <div class="col-md-6 left">
            <div class="content">
                <div class="flex justify-center logos">
                    <img src="/assets/images/cc-logo-md.png" alt="creditclan's logo" />
                    <!-- <span>+</span>
                    <img src="/assets/images/kuda-logo-white.svg" alt="kuda bank logo" class="ml-4" /> -->
                </div>
                <img src="/assets/images/analytics.svg" alt="kuda illustration" class="illustration" />
            </div>
        </div>
        <div class="col-md-6 right">

            <div class="content">
                <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input #email="ngModel" autocomplete="false" [(ngModel)]="model.email" [disabled]="loading"
                            class="form-control custom" name="email" placeholder="Email" required type="email"
                            autofocus />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input #password="ngModel" autocomplete="false" [(ngModel)]="model.password"
                            [disabled]="loading" class="form-control custom" name="password" placeholder="Password"
                            required type="password" />
                    </div>
                    <!-- <div class="checkbox">
                        <label>
              <input type="checkbox"> Keep me logged in
            </label>
                    </div> -->
                    <div class="mt-4">
                        <app-button [disabled]="loading" type="submit" label="Sign in" [loading]="loading"></app-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>