<button (click)="done.emit()" class="icon-button close-button">
  <i class="fa fa-times"></i>
</button>

<div class="row" style="height: 600px; overflow: hidden">
  <div class="col-md-6 h-100">
    <img
      src="https://images.pexels.com/photos/3762927/pexels-photo-3762927.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
      class="w-100 h-100" alt="" style="object-fit: cover"
    />
  </div>
  <div class="col-md-6 h-100 position-relative" style="overflow-y: auto;">
    <div *ngIf="step === 1" style="padding: 3rem 3rem 3rem 2rem">
      <h2 class="m-0 font-weight-bold">Welcome to partners dashboard</h2>
      <p class="mt-3">
        We are excited to welcome you to the biggest BNPL distribution accross Africa. We are looking at the consumer
        credit space very differently and our partnership for funding and embedded finance makes it a win-win for
        everyone
      </p>
      <button (click)="step = step + 1" class="btn btn-primary mt-5">Got it</button>
      <img
        src="assets/images/baloons.jpg"
        alt="" style="position: absolute; bottom: 0; right: 20px; width: 300px"
      />
    </div>
    <div *ngIf="step === 2" style="padding: 3rem 3rem 3rem 2rem">
      <div class="d-flex">
        <button
          (click)="step = step - 1" class="btn btn-outline-secondary rounded-full"
          style="width: 35px; height: 35px"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h2 class="m-0 font-weight-bold ml-2">Our ecosystems</h2>
      </div>
      <div class="mt-5 w-100">
        <div class="panel padding-regular inline-block" style="width: 400px">
          <h3 class="title font-weight-bold m-0">Merchants</h3>
          <p class="mt-3">
            Fund thousands of merchants and millions of consumers across Africa.
          </p>
          <br><br><br>
          <div class="mt-5 text-right">
            <i class="fa fa-shopping-cart text-danger" style="font-size: 8rem"></i>
          </div>
        </div>
        <div class="panel padding-regular inline-block" style="width: 400px">
          <h3 class="title font-weight-bold m-0">Schools</h3>
          <p class="mt-3">
            Fund parents, teachers and school owners in our School credit ecosystem
          </p>
          <br><br><br>
          <div class="mt-5 text-right">
            <i class="fas fa-school text-success" style="font-size: 8rem"></i>
          </div>
        </div>
        <div class="panel padding-regular inline-block" style="width: 400px">
          <h3 class="title font-weight-bold m-0">Rent</h3>
          <p class="mt-3">
            Africa needs our rent now pay later initiative, thousands of agents and tenants are waiting.
          </p>
          <br><br><br>
          <div class="mt-5 text-right">
            <i class="fa fa-building text-info" style="font-size: 8rem"></i>
          </div>
        </div>
        <div class="panel padding-regular inline-block" style="width: 400px">
          <h3 class="title font-weight-bold m-0">Restaurants</h3>
          <p class="mt-3">
            We are excited to welcome you to the biggest BNPL distribution accross Africa. We are looking at the
          </p>
          <br><br><br>
          <div class="mt-5 text-right">
            <i class="fas fa-utensils text-warning" style="font-size: 8rem"></i>
          </div>
        </div>
        <div class="panel padding-regular inline-block" style="width: 400px">
          <h3 class="title font-weight-bold m-0">Healthcare</h3>
          <p class="mt-3">
            With connections to several HMOs, we are connecting patients with hundreds of hospitals.
          </p>
          <br><br><br>
          <div class="mt-5 text-right">
            <i class="fa fa-hospital-o text-danger" style="font-size: 8rem"></i>
          </div>
        </div>
      </div>
      <div
        style="position: sticky; bottom: 0; left: 0; width: 100%; padding: 2rem 0; background: linear-gradient(to bottom, white, white)"
      >
        <button (click)="step = step + 1" class="btn btn-primary mt-5">Got it</button>
      </div>
    </div>
    <div *ngIf="step === 3" style="padding: 3rem 3rem 3rem 2rem">
      <div class="d-flex">
        <button
          (click)="step = step - 1" class="btn btn-outline-secondary rounded-full"
          style="width: 35px; height: 35px"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h2 class="m-0 font-weight-bold ml-2">How you operate</h2>
      </div>
      <div class="mt-5">
        <div class="panel" style="padding: 1rem 1.3rem">
          <h5 class="title m-0">1. Review</h5>
          <p class="m-0 mt-1">Assess your requests for suitability</p>
        </div>
        <div class="panel mt-2" style="padding: 1rem 1.3rem">
          <h5 class="title m-0">2. Queue</h5>
          <p class="m-0 mt-1">Push requests for funding (OTP required here)</p>
        </div>
        <div class="panel mt-2" style="padding: 1rem 1.3rem">
          <h5 class="title m-0">3. Fund</h5>
          <p class="m-0 mt-1">Download disbursement excel sheet and fund</p>
        </div>
      </div>
      <button (click)="step = step + 1" class="btn btn-primary mt-5">Got it</button>
    </div>
    <div *ngIf="step === 4" style="padding: 3rem 3rem 3rem 2rem">
      <div class="d-flex">
        <button
          (click)="step = step - 1" class="btn btn-outline-secondary rounded-full"
          style="width: 35px; height: 35px"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h2 class="m-0 font-weight-bold ml-2">2FA & Security</h2>
      </div>
      <p class="mt-3">
        Kindly enter phone number to receive OTP for verification.
      </p>
      <div class="form-group mt-5">
        <label>Phone number</label>
        <div class="d-flex align-items-center">
          <input [(ngModel)]="phone" class="form-control" [readOnly]="!changingPhone" [disabled]="sendOtpLoading"/>
          <button (click)="changingPhone = true" *ngIf="!changingPhone" class="btn btn-outline-secondary btn-sm ml-2">
            <i class="fa fa-pencil"></i>
            <span class="ml-2 inline-block">Change</span>
          </button>
        </div>
      </div>
      <button (click)="handleChangePhone()" [disabled]="sendOtpLoading" class="btn btn-primary mt-5">
        Get OTP
      </button>
      <img
        src="/assets/images/insurance.png"
        alt="" style="position: absolute; bottom: 30px; right: 30px; width: 200px"
      />
    </div>
    <div *ngIf="step === 5" style="padding: 3rem 3rem 3rem 2rem">
      <div class="d-flex">
        <button
          (click)="step = step - 1" class="btn btn-outline-secondary rounded-full"
          style="width: 35px; height: 35px"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h2 class="m-0 font-weight-bold ml-2">2FA & Security</h2>
      </div>
      <ul class="mt-4" style="margin-left: 16px">
        <li style="list-style: disc">Save the number +2349053535333 on Whatsapp as Creditclan</li>
        <li style="list-style: disc">Chat the keyword OTP</li>
        <li style="list-style: disc">A 6 digit keyword is created</li>
      </ul>
      <div class="form-group form-group-lg mt-5">
        <label>OTP</label>
        <input [(ngModel)]="otp" class="form-control" [disabled]="confirmOtpLoading"/>
      </div>
      <button (click)="handleConfirmOtp()" [disabled]="confirmOtpLoading" class="btn btn-primary mt-5">
        Verify OTP
      </button>
      <img
        src="/assets/images/insurance.png"
        alt="" style="position: absolute; bottom: 30px; right: 30px; width: 200px"
      />
    </div>
    <div *ngIf="step === 6" style="padding: 3rem 3rem 3rem 2rem">
      <h2 class="m-0 font-weight-bold">Finally KYC</h2>
      <form class="mt-5" [formGroup]="kycForm" (ngSubmit)="handleKycSubmit()">
        <div class="form-group form-group-lg">
          <label for="reg_no">Registration number</label>
          <input type="text" class="form-control" id="reg_no" formControlName="reg_no"/>
        </div>
        <div class="form-group form-group-lg">
          <label for="address">Full corporate address</label>
          <textarea class="form-control" id="address" formControlName="address"></textarea>
        </div>
        <div class="form-group form-group-lg">
          <label for="business_type">Business type</label>
          <select class="form-control" id="business_type" formControlName="business_type">
            <option value="commercial-bank">Commercial bank</option>
            <option value="microfinance-bank">Microfinance bank</option>
            <option value="other">Other financial institutions</option>
            <option value="private-lender">Private lender</option>
            <option value="state-licenced-lender">State licenced lender</option>
          </select>
        </div>
        <div class="form-group">
          <label for="exampleInputFile">Upload corporate affairs commission</label>
          <input type="file" id="exampleInputFile" (change)="handleFileChange($event)">
        </div>
        <button type="submit" [disabled]="submitKycLoading" class="btn btn-primary mt-5">
          Got it
        </button>
      </form>
    </div>
    <div *ngIf="step === 7" class="d-flex flex-column justify-content-center align-items-center my-auto text-center">
      <br><br><br><br>
      <i class="fa fa-check-circle font-weight-bold text-success" style="font-size: 8rem"></i>
      <h5 class="title m-0 font-weight-bold mt-5">Congratulations</h5>
      <p class="mt-2" style="max-width: 300px">
        You have successfully onboarded and entered into a 3 days test mode. For further support, send email to
        <a href="mailto:partners@creditclan.com">partners@creditclan.com</a>
      </p>
      <button (click)="done.emit()" class="btn btn-primary mt-5">Goto dashboard</button>
    </div>
  </div>
</div>
