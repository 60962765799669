
  <div class="row">
    <div class="col-md-12">
      
     <h3 *ngIf="ecosystem=='2'">Parents</h3>
     <h3 *ngIf="ecosystem=='5'">Schools</h3>
     <h3 *ngIf="ecosystem=='4'">Teachers</h3>
     <h3 *ngIf="ecosystem=='3'">Tenants</h3>
     <h3 *ngIf="ecosystem=='4'">Landlords</h3>
     <h3 *ngIf="ecosystem=='3'">Merchants</h3>
     <h3 *ngIf="ecosystem=='1'">Customers</h3>
     <h3 *ngIf="ecosystem=='12'">Patients</h3>
     <h3 *ngIf="ecosystem=='11'">Hospitals</h3>
     <h3 *ngIf="ecosystem=='13'">Rent</h3>
     <h3 *ngIf="ecosystem=='10'">Eat</h3>
     <!-- <h3>{{band_details?.data?.min_amount}} - {{band_details?.data?.max_amount}} </h3> -->
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-3">
      <div>
        <label class="form-group">
          <input  (change)="loadoffers()"  type="radio" value="1" name="band_type"  [(ngModel)]="band_type">
          <span class="control-label normal">Just There</span>
        </label>
      </div>
    </div>
    <div class="col-md-3">
      <div>
        <label class="form-group">
          <input  (change)="loadoffers()"  type="radio" value="2" name="band_type"  [(ngModel)]="band_type">
          <span class="control-label normal">Good Enough</span>
        </label>
      </div>
    </div>
    <div class="col-md-3">
      <div>
        <label class="form-group">
          <input  (change)="loadoffers()"  type="radio" value="3" name="band_type"  [(ngModel)]="band_type">
          <span class="control-label normal">Fantastic</span>
        </label>
      </div>
    </div>
  </div> -->
  <div class="row">
    <div class="col-md-12">
      <table class="table table-stripped">
        <thead>
          <tr>
            <th>Category</th>
            <th>Upfront %</th>
            <th>Max Tenor</th>
            <th>Rate</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Just There(0-40)</td>
            <td><input type="text" size="4" name="upfront_percentage" [(ngModel)]="offer_just_there.upfront_percentage"></td>
            <td><input type="text" size="4" name="maximum_tenor" [(ngModel)]="offer_just_there.maximum_tenor"></td>
            <td><input type="text" size="4" name="interest" [(ngModel)]="offer_just_there.interest"></td>
            <td><button class="btn btn-xs" (click)="saveoffer(offer_just_there,1)">Save</button></td>

          </tr>
          <tr>
            <td>Good Enough(41-70)</td>
            <td><input type="text" size="4" name="upfront_percentage" [(ngModel)]="offer_good_enough.upfront_percentage"></td>
            <td><input type="text" size="4" name="maximum_tenor" [(ngModel)]="offer_good_enough.maximum_tenor"></td>
            <td><input type="text" size="4" name="interest" [(ngModel)]="offer_good_enough.interest"></td>
            <td><button class="btn btn-xs" (click)="saveoffer(offer_good_enough,2)">Save</button></td>

          </tr>
          <tr>
            <td>Fantastic(70-100)</td> 
            <td><input type="text" size="4" name="upfront_percentage" [(ngModel)]="offer_fantastic.upfront_percentage"></td>
            <td><input type="text" size="4" name="maximum_tenor" [(ngModel)]="offer_fantastic.maximum_tenor"></td>
            <td><input type="text" size="4" name="interest" [(ngModel)]="offer_fantastic.interest"></td>
            <td><button class="btn btn-xs" (click)="saveoffer(offer_fantastic,3)">Save</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

