import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'; 
import { DataService, LoansService, StorageService } from 'src/app/_services';

@Component({
  selector: 'app-accountandcards-card',
  templateUrl: './accountandcards-card.component.html',
  styleUrls: ['./accountandcards-card.component.css']
})
export class AccountandcardsCardComponent implements OnInit {

  @Input() reqid;
  @Input() pid;
  @Input() from_loan = true;
  @Output() open = new EventEmitter();
  @Input() where = 'loan';
  currentUser: any;
  loading = false;
  loadingCards = false;
  card: any = {};
  account: any = {};
  @Input('loan') loan:any;
  constructor(
    public loansService: LoansService,
    public storageService: StorageService,
    public dataService: DataService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    if (this.from_loan) this.getAccountAndCard(this.reqid);
    else this.getCustomerAccountAndCard(this.pid);
  }

  getAccountAndCard(people_id) {
    this.loadingCards = true;
    this.loansService.getConnectedLoanCardsAndAccounts(this.currentUser.token, people_id).subscribe(({ cards, accounts }) => {
      this.card = cards[0] || {};
      this.account = accounts[0] || {};
      this.loadingCards = false;
    })
  }

  getCustomerAccountAndCard(people_id) {
    this.loadingCards = true;
    this.loansService.getCustomerAccountAndCard(this.currentUser.token, people_id).subscribe(({ cards, accounts }) => {
      this.card = cards[0] || {};
      this.account = accounts[0] || {};
      this.loadingCards = false;
    })
  }
  openModal(section) {
    // if (this.authService.canViewModule('1,2,3,1026')) {

    // }else{

    // }
    // if (section === 'bvn') {
    //   if (!this.grantRight('80')) {
    //     section = 'no_right';
    //   }
    // }
    // if (section === 'cards') {
    //   if (!this.grantRight('35')) {
    //     section = 'no_right';
    //   }
    // }
    // if (section === 'accounts') {
    //   if (!this.grantRight('35')) {
    //     section = 'no_right';
    //   }
    // }
    // if (section === 'attachments') {
    //   if (!this.grantRight('28')) {
    //     section = 'no_right';
    //   }
    // }
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.loan.request_id, loan: this.loan });
  }
}
