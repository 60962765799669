import { ElementRef, ViewEncapsulation, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { OnChanges, SimpleChanges, SimpleChange, Component, OnInit, OnDestroy, EventEmitter, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UserService, DataService, OperationsService } from '../../_services/index';
import { CustomerService, AuthenticationService, StorageService, LoansService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr'; 
@Component({
  selector: 'app-loanportfolioaction',
  templateUrl: './loanportfolioaction.component.html',
  styleUrls: ['./loanportfolioaction.component.css']
})
export class LoanportfolioactionComponent implements OnInit {
  @Input('request_id') request_id: any;
  @Input() buttonOnly = false;
  loading = false;
  loan: any;
  currentUser: any;
  data: any;
  @Output() doingDisbursement = new EventEmitter();
  constructor(private toastr: ToastrService, private _elementRef: ElementRef,
    private authService: AuthenticationService,
    public operationsService: OperationsService, private DataService: DataService,
    public router: Router, public route: ActivatedRoute, public loansService: LoansService, public storageService: StorageService) {
    // if (!this.authService.canViewModule('1,2,3,1026')) {
    //   this.router.navigate(['../unauthorized']);
    // }
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  ngOnInit() {
    this.getLoanStatement();
  }
  getLoanStatement() {
    this.loading = true;
    this.loansService.getRepaymentDetails_(this.currentUser.token, this.request_id)
      .subscribe(loans => {
        this.loading = false;
        this.data = loans;
      });
  }
  doPayDue() {
    this.doingDisbursement.emit({})
  }
}
