import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { ApiService } from '../../services/api.service';
// import { AuthService } from '../../services/auth.service';
// import { GeneralService } from '../../services/general.service';
// import { MerchantProfileDetails } from '../../models/merchantProfile';

@Component({
  selector: 'app-settings-homepage-sections',
  templateUrl: './settings-homepage-sections.component.html',
  styleUrls: ['./settings-homepage-sections.component.css']
})
export class SettingsHomepageSectionsComponent implements OnInit, OnChanges {
  @Input() merchant: any;

  loading: boolean;
  sections = [];

  constructor(
    // private _api: ApiService,
    // private _auth: AuthService,
    // private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const merchant: any = changes.merchant.currentValue || {};
    const { sections } = merchant;
    const defaultSections = [
      { name: 'top_deals', enabled: true, columns: 2, required: false },
      { name: 'best_sellers', enabled: true, columns: 2, required: false },
      { name: 'new_arrivals', enabled: true, columns: 2, required: false },
    ];
    this.sections = sections || defaultSections;
    this.sections = defaultSections.map(s => {
      const found = this.sections.find(_s => _s.name === s.name);
      return found || s;
    });
  }

  incrementColumns(name: string) {
    this.sections = this.sections.map(section => {
      if (section.name === name) {
        return {
          ...section, columns: Math.min(section.columns + 1, 5)
        };
      }
      return section;
    });
  }

  decrementColumns(name: string) {
    this.sections = this.sections.map(section => {
      if (section.name === name) {
        return {
          ...section, columns: Math.max(section.columns - 1, 2)
        };
      }
      return section;
    });
  }

  async saveSections() {
    // this.loading = true;
    // const res = await this._api.updateOnlineStore({ sections: this.sections }).toPromise<any>();
    // this._general.notify('success', res.message || 'Saved');
    // this._auth.updateMerchant({ sections: this.sections });
    // this.loading = false;
  }
}
