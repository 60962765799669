import { Component, OnInit } from '@angular/core';
import { DataService, OperationsService, StorageService } from '../_services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-loanarrearsageing',
  templateUrl: './loanarrearsageing.component.html',
  styleUrls: ['./loanarrearsageing.component.css']
})
export class LoanarrearsageingComponent implements OnInit {

  currentUser:any;
  loading = false;
  data:any;
  data_:any;
  data__:any;
  data___:any;
  data____:any;
  start = '0';
  start_ = '0';
  start__ = '0';
  start___ = '0';
  start____ = '0';
  loan_product_id = '';
  products:any;
  loadingRouteConfig = false;
  constructor(
    public toastr: ToastrService,
    public router: Router,
    private dataService: DataService, private operationsService: OperationsService, private storageService: StorageService) { 
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.loadingRoute.subscribe(res => {
      this.loadingRouteConfig = res.loadingRouteConfig;
    });
  }

  ngOnInit() {
    this.getRecords();
    this.getRecordss();
    this.getRecordsss();
    this.getRecordssss();
    this.getRecordsssss();
  }

  getRecords(){
    this.loading = true;
    this.operationsService.getLoanArrearsAgeing(this.currentUser.token, this.start, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start;
        this.products = result.products;
      });
  }
  getRecordss(){
    this.loading = true;
    this.operationsService.getLoanArrearsAgeings(this.currentUser.token, this.start_, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data_ = result.data;
        this.start_ = result.start; 
      });
  }
  getRecordsss(){
    this.loading = true;
    this.operationsService.getLoanArrearsAgeingss(this.currentUser.token, this.start__, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data__ = result.data;
        this.start__ = result.start; 
      });
  }
  getRecordssss(){
    this.loading = true;
    this.operationsService.getLoanArrearsAgeingsss(this.currentUser.token, this.start___, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data___ = result.data;
        this.start___ = result.start; 
      });
  }
  getRecordsssss(){
    this.loading = true;
    this.operationsService.getLoanArrearsAgeingssss(this.currentUser.token, this.start____, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data____ = result.data;
        this.start____ = result.start; 
      });
  }
  getRecords_(){
    this.start = '0';
    this.loading = true;
    this.operationsService.getLoanReport(this.currentUser.token, this.start, this.loan_product_id,'','')
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start; 
      });
  }
  getRecordsnew(){
    this.start = '0';
    this.loading = true;
    this.operationsService.getLoanArrearsAgeing(this.currentUser.token, this.start, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start; 
      });
  }
  getRecordsnews(){
    this.start = '0';
    this.loading = true;
    this.operationsService.getLoanArrearsAgeings(this.currentUser.token, this.start, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start; 
      });
  }
  getRecordsnewss(){
    this.start = '0';
    this.loading = true;
    this.operationsService.getLoanArrearsAgeingss(this.currentUser.token, this.start, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start; 
      });
  }
  getRecordsnewsss(){
    this.start = '0';
    this.loading = true;
    this.operationsService.getLoanArrearsAgeingsss(this.currentUser.token, this.start, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start; 
      });
  }
  getRecordsnewssss(){
    this.start = '0';
    this.loading = true;
    this.operationsService.getLoanArrearsAgeingssss(this.currentUser.token, this.start, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start; 
      });
  }
  getTotal(key, schedule) {
    if (schedule === undefined || schedule === null) { } else {
      let total = 0;
      if (key === 'request_principal') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.request_principal); }, 0);
      }
      if (key === 'principal_paid') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.principal_paid); }, 0);
      }
      if (key === 'principal_balance') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.principal_balance); }, 0);
      }
      if (key === 'principal_due') {
        total = schedule.reduce(function (cnt, o) { if(o.principal_due){return cnt + parseInt(o.principal_due);} }, 0);
      }
      if (key === 'principal_arrears') {
        total = schedule.reduce(function (cnt, o) { if(o.principal_arrears){return cnt + parseInt(o.principal_arrears);} }, 0);
      }
      
      return total;
    }
    //

  }
}
