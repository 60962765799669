<div class="overlay_ app_overlay animated fadeIn">

  <!-- <div class="col-md-12">
      <a class="dismiss cancel_treat_ close" role="button" (click)="closeOverlay()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </a>
  </div> -->
  <div class="overpanel-content confirmation animated zoomIn" role="document"
    style="margin-top:100px;text-align:left;width:400px; padding-top:0px;padding-left:0px;padding-right:0px; overflow:hidden; border-radius: 5px;">
    <div class="cc-dialog-body-section">
      <div layout="column" layout-align="space-around center" class="layout-align-space-around-center layout-column">
        <div layout="column" layout-align="center center" class="layout-align-center-center layout-column"
          style="visibility: visible;">
          <div class="layout-align-space-around-center layout-row color-red mb-5" layout="row"
            layout-align="space-around center">
            <i class="fa fa-ban" style="font-size:160px !important"></i>
          </div>
          <div class="c5e-create-project-async-ready-label layout-align-space-around-center layout-row" layout="row"
            layout-align="space-around center">
            <p>You are not allowed to view this page</p>
          </div>
          <a class="btn btn-outline-danger" routerLink="/applications/dashboard">Go back</a>
        </div>
      </div>
    </div>
  </div>
</div>