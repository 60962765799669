<div class="side-view animated slideInRight">
  <button class="close" (click)="closeView()"><i class="fa fa-times"></i></button>
  <div class="side-view-wrap">
      <div class="header" *ngIf="!loan">
          <div class="user">
              <div class="image large skeleton fit-content">
                  <img class="image-avatar" src="" />
              </div>
              <div class="content skeleton-children">
                  <div class="title mb-1">Lorem ipsum dolor sit lofoe.</div>
                  <div class="subtitle mb-1">Lorem ipsum </div>
                  <div>Lorem,.</div>
              </div>
          </div>
      </div>

      <div class="header" *ngIf="loan">
          <div class="user">
              <div class="image large cursor-pointer" (click)="child = 'profile-picture'">
                  <img class="{{ loan | requeststatus }}" [ngClass]="{'image-avatar': true}"
                      src="{{loan?.customer?.profile_pic | imagenotfound}}" />
              </div>
              <div class="content flex-1">
                  <div class="title">{{ loan?.customer.legal_name }}</div>
                  <div class="subtitle">
                      N{{ loan.request_principal | number:'.2-2' }} | {{ loan.request_tenor }}
                      {{ loan.loan_duration }}
                  </div>
                  <div class="loan-action-buttons mt-2">
                      <app-loanaction (approveThisLoan)="approveThisLoan.emit($event)"
                          (rejectThisLoan)="rejectThisLoan.emit($event)"
                          (doingDisbursement)="doingDisbursement($event)" [request_id]="requestId">
                      </app-loanaction>
                  </div>
              </div>
              <div class="flex flex-row justify-around" style="align-self: flex-start">
                  <app-button class="small outline mr-1" (click)="setChild('liste')" icon="fa fa-list"
                      label="Send Message"></app-button>
                  <app-button class="small outline" (click)="child = 'schedule'"
                      *ngIf="grantRight('22') && loan?.loan_status > 0" icon="fa fa-list" label="View Schedule">
                  </app-button>
                  <button *ngIf="loan?.has_collateral && loan?.loan_status > 0"
                      class="btn btn-outline-info ml-2 bounce-animation" (click)="child = 'collateral'">
                      <i class="fas fa-car color-teal"></i>
                  </button>
              </div>
          </div>
      </div>

      <div class="nav" *ngIf="loan?.loan_status > 0">
          <div class="nav-item" (click)="setView('application')" [ngClass]="{'active': view === 'application'}">
              Application
          </div>
          <div class="nav-item" (click)="setView('timeline')" [ngClass]="{'active': view === 'timeline'}">
              Timeline
          </div>
          <div class="nav-item" (click)="setView('links')" [ngClass]="{'active': view === 'links'}">
              Analysis
          </div>
          <div class="dropdown">
              <div class="dropdown-toggle nav-item" [ngClass]="{'active': view === 'more'}" id="dropdownMenu1"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">{{ more }}
                  <span class="caret ml-2"></span>
              </div>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                  <li><a (click)="setMore('Collateral')">Collateral</a></li>
                  <li><a (click)="setMore('Recommendation')">Recommendation</a></li>
                  <li><a (click)="setMore('Offer Letter')">Offer Letter</a></li>
                  <li><a (click)="setMore('Checklist')">Checklist</a></li>
                  <li><a (click)="setMore('Recollection')">Recollection</a></li>
                  <li><a (click)="setMore('Loan Action')">Loan Action</a></li>
                  <li><a (click)="setMore('Account Officer')">Account Officer</a></li>
                  <li><a (click)="setMore('Guarantor')">Guarantor</a></li>
                  <!-- <li><a (click)="setMore('ippis')">IPPIS</a></li> -->
                  <li *ngIf="loan && loan?.repayment_mode=='6'"><a (click)="setMore('More')">More</a></li>
              </ul>
          </div>
      </div>
      <div class="nav" *ngIf="loan?.loan_status < 0">
          <div class="nav-item" (click)="setView('application')" [ngClass]="{'active': view === 'application'}">
              Application
          </div>
          <div class="nav-item" (click)="setView('timeline')" [ngClass]="{'active': view === 'timeline'}">
              Timeline
          </div>
          <div class="nav-item" (click)="child='eligibility'" [ngClass]="{'active': view === 'links'}">
              Eligibility
          </div>

      </div>

      <div class="content skeleton-children fit-content" *ngIf="loading">
          <div class="fill-w mb-2">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatem, possimus suscipit illum
              consectetur quibusdam magnam provident tempora ducimus expedita nostrum non aliquid quod earum eos
              dignissimos, recusandae unde sed soluta! recusandae unde sed
              soluta!
          </div>
          <div class="fill-w mb-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut
              nulla. Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab?
              Quidem adipisci facilis porro doloremque at
              quos pariatur, illo magnam in minima!
          </div>
          <div class="fill-w mb-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut
              nulla. Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab?
              Quidem adipisci facilis porro doloremque at
              quos pariatur, illo magnam in minima!
          </div>
      </div>

      

      <!-- Child Modals -->
      <div class="overlay" *ngIf="child">
          <button class="closer" (click)="child = ''"><i class="fa fa-times"></i></button>
          <div class="child overflow-visible">
              

              <app-applicationseligibility [band_id]="band_id" [view_type]="view_type" [band_type]="band_type" [ecosystem]="ecosystem" [runstatus]="runstatus" *ngIf="child === 'eligibility'"
                  (analyseCustomerComplete)="showEligCrit($event)" [requestId]="requestId" [embedModal]="true"
                  (openProfileAnalysisModal)="setChild('profileanalysis')">
              </app-applicationseligibility>

              <div class="panel overflow-visible" *ngIf="child === 'eligibilitycriteria'">
                  <app-eligibilitycriteria [loan]="loan" (doEligibility)="doEligibility($event)"
                      (showBankStatement)="showBankStatement($event)" (showCreditBureau)="showCreditBureau($event)"
                      [eligibilitycriteria]="eligibilitycriteria" (openNIN)="child = 'nin'" (closed)="closeSMS()"
                      [parentRouteId]="requestId" [where]='2'></app-eligibilitycriteria>
              </div>

              
              <!-- <div class="panel padding-regular" *ngIf="child === 'runanalytics'">
                  <app-runselectedanalyticsmodal (closeSelectedAnalyticsModal)="overlayModalSection=''" [loan]="loan"
                      [parentRouteId]="requestId" [token]="currentUser.token"
                      [analytics_data_status]="analytics_data_status">
                  </app-runselectedanalyticsmodal>
              </div> -->

              <div class="panel padding-regular" *ngIf="child === 'profileanalysis'">
                  <app-profileanalysismodal *ngIf="type=='analysis'" (updatebandtype)="updatebandtype($event)" [band_id]="band_id" [ecosystem]="ecosystem"  (closeSelectedAnalyticsModal)="overlayModalSection=''">
                  </app-profileanalysismodal>
                  
                  <app-eligibilityofferstable *ngIf="type=='offers'" [band_id]="band_id" [ecosystem]="ecosystem"  (closeSelectedAnalyticsModal)="overlayModalSection=''">
                </app-eligibilityofferstable>

              </div>

              <div class="panel"  *ngIf="child === 'profileanalysis' && type=='analysis'">
                <div class="alert padding-regular bg-off-white">
                  <div class="texts no-padding">
                    <h4 class="title">Settings</h4>
                    <p class="subtitle">Adjust your eligibility criteria</p>
                  </div>
                  <div class="action">
                    <app-button label="Open" (click)="view_type='lender_leaves';child='eligibility';" buttonclass="small outline">
                    </app-button>
                  </div>
                </div>
              </div>
              <div class="panel"  *ngIf="child === 'profileanalysis' && type=='analysis'">
                <div class="alert padding-regular bg-off-white">
                  <div class="texts no-padding">
                    <h4 class="title">Settings</h4>
                    <p class="subtitle">Compulsory Data</p>
                  </div>
                  <div class="action">
                    <app-button label="Open" (click)="view_type='compulsory_data';child='eligibility';" buttonclass="small outline">
                    </app-button>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
