<!-- <section class="panel no-padding"> -->
<agm-map [latitude]="lat" [longitude]="lng" [zoom]="13" [fitBounds]="latlngBounds">
  <!-- <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker> -->
  <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng" [iconUrl]="m.iconUrl">

    <agm-info-window>
      <strong>{{m.address}}</strong>
    </agm-info-window>

  </agm-marker>
</agm-map>
<!-- </section> -->