import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// import { MerchantProfileDetails } from 'src/app/models/merchantProfile';
// import { AuthService } from 'src/app/services/auth.service';
// import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-online-store',
  templateUrl: './online-store.component.html',
  styleUrls: ['./online-store.component.css']
})
export class OnlineStoreComponent implements OnInit, OnDestroy {
  merchant: any = {};
  subscriptions: Subscription[] = [];
  modal = '';

  constructor(
    // private _auth: AuthService,
    // public _general: GeneralService,
  ) { }

  ngOnInit(): void {
    // this.subscriptions.push(
    //   this._auth.$merchant.subscribe({
    //     next: (merchant) => {
    //       this.merchant = merchant;
    //     }
    //   })
    // );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
