<div class="panel box decSection edged_box" *ngIf="!loading">
  <div class="panel box decSection edged_box">
    
    <div class="panel-body flex">
      <div class="flex-1">
        <h3 class="section-title mb-3">
          Workflow Level
        </h3>
        <!-- <p>Currently at {{ loan?.workflowlevel?.title }}</p> -->
        <!-- <p>
        {{ loan?.workflowlevel?.number_of_approvals }} /
        {{ loan?.workflowlevel?.minimum_no_approvals }} Approved
      </p> -->
        <p>Amount Limit : {{ loan?.workflowlevel?.amount_limit }}</p>
        <p>Minimum Approval : {{ loan?.workflowlevel?.minimum_no_approvals }}</p>
      </div>
      <div class="flex-1">
        <div style="margin-bottom: 10px; border-bottom: 1px solid #ccc; padding-bottom: 13px">
          <div class="image-avatar-list small">
            <div class="image-avatar ring" *ngFor="let emp of loan?.workflowlevel?.executors">
              <img title="{{ emp?.LEGAL_NAME }}"
                src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg" />
            </div>

            <div class="circle small flex-center grey border" *ngIf="loan?.workflowlevel?.has_more_staff">
              {{ loan?.workflowlevel?.remaining_staff }}+
            </div>
          </div>
        </div>
        <div class="color-teal" style="margin-bottom: 10px; border-bottom: 1px solid #ccc; padding: 0 0 10px">
          {{ loan?.workflowlevel?.how_long && loan?.workflowlevel?.how_long > 24 ? floor(loan?.workflowlevel?.how_long / 24) + ' days gone' : loan?.workflowlevel?.how_long + ' hours gone' }}
        </div>
        <div class="color-teal" style="margin-bottom: 10px; border-bottom: 1px solid #ccc; padding: 0 0 10px">
          <!-- <span>Amount Limit : </span><span>{{ loan?.workflowlevel?.amount_limit }}</span> -->
          <span>Currently at {{ loan?.workflowlevel?.title }}</span>
        </div>
        <div class="color-teal" style="margin-bottom: 10px; border-bottom: 1px solid #ccc; padding: 0 0 10px">
          <!-- <span>Minimum Approval : </span><span>{{ loan?.workflowlevel?.minimum_no_approvals }}</span> -->
          {{ loan?.workflowlevel?.number_of_approvals }} / {{ loan?.workflowlevel?.minimum_no_approvals }} Approved
        </div>
        <div>
          <app-button (click)="openWorkflow()" class="small mr-1" label="View Workflow"></app-button>
          <app-button (click)="restartWorkflow()" class="small" label="Restart Workflow"></app-button>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="panel box decSection edged_box" *ngIf="loading">
  <div class="panel-body flex">
    <div class="flex-1">
      <h3 class="section-title mb-3 skeleton">
        Workflow Level
      </h3>
      <p class="skeleton">Currently at {{loan?.workflowlevel?.title}}</p>
      <p class="skeleton">{{loan?.workflowlevel?.number_of_approvals}} / {{loan?.workflowlevel?.minimum_no_approvals}}
        Approved</p>
    </div>
    <div class="flex-1">
      <div style="margin-bottom: 10px;">
        <div class="image-avatar-list small">
          <div class="image-avatar ring skeleton">
            <img title="{{emp?.LEGAL_NAME}}"
              src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
          </div>

          <!-- <div class="circle small flex-center grey border" *ngIf="loan?.workflowlevel?.has_more_staff">
            {{loan?.workflowlevel?.remaining_staff}}+</div> -->
        </div>
      </div>
      <div class="skeleton mb-1" style="margin-bottom: 10px; border-bottom: 1px solid #ccc; padding: 0 0 10px">
        <span>Amount Limit : </span><span>{{loan?.workflowlevel?.amount_limit}}</span>
      </div>
      <div class="skeleton mb-1" style="margin-bottom: 10px; border-bottom: 1px solid #ccc; padding: 0 0 10px">
        <span>Minimum Approval : </span><span>{{loan?.workflowlevel?.minimum_no_approvals}}</span>
      </div>
      <!-- <div>
        <app-button (click)="openWorkflow()" class="small outline" label="View Workflow"></app-button>
      </div> -->
    </div>
  </div>
</div>