<div class="panel padding-regular">
  <div class="flex">
    <div class="flex-1 flex flex-center">
      <div class="section-poster">
        <div class="image flex-center">
          <img src="assets/images/fingerprint.svg" alt="">
        </div>
      </div>
    </div>
    <div class="flex-2">
      <div class="section-title mb-4">BVN Information</div>
      <ul class="decList bigger">
        <li class="bvn__modal-text">
          <span>BVN</span>
          <span *ngIf="!loading">{{bvn_details?.bvn}}</span>
          <span *ngIf="loading" class="skeleton">Sample Test</span>
        </li>
        <li class="bvn__modal-text">
          <span>Name</span>
          <span *ngIf="!loading">{{bvn_details?.name}}</span>
          <span *ngIf="loading" class="skeleton">Sample Test</span>
        </li>
        <li class="bvn__modal-text">
          <span>Phone Number</span>
          <span *ngIf="!loading">{{bvn_details?.phone}}</span>
          <span *ngIf="loading" class="skeleton">Sample Test</span>
        </li>
        <li class="bvn__modal-text">
          <span>Date of Birth</span>
          <span *ngIf="!loading">{{bvn_details?.date_of_birth}}</span>
          <span *ngIf="loading" class="skeleton">Sample Test</span>
        </li>
        <li class="bvn__modal-text">
          <span>BVN Validation Date</span>
          <span *ngIf="!loading">{{bvn_details?.bvn_validation_date | date}}</span>
          <span *ngIf="loading" class="skeleton">Sample Test</span>
        </li>
      </ul>
      <app-button class="small outline teal mt-2" label="More" (click)="more.emit('bvn')"></app-button>
    </div>
  </div>
</div>