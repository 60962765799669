import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { MerchantProfileDetails } from '../../models/merchantProfile';
// import { ApiService } from '../../services/api.service';
// import { AuthService } from '../../services/auth.service';
// import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-settings-item-details-template',
  templateUrl: './settings-item-details-template.component.html',
  styleUrls: ['./settings-item-details-template.component.css']
})
export class SettingsItemDetailsTemplateComponent implements OnInit, OnChanges {

  @Input() merchant: any;
  loading = false;
  details_template = 1;
  templates = [
    { id: 1, name: 'Boxed details page' },
    { id: 2, name: 'Long details page' },
  ];

  constructor(
    // private _api: ApiService,
    // private _auth: AuthService,
    // private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const merchant: any = changes.merchant.currentValue;
    const { details_template } = merchant;
    this.details_template = +details_template;
  }

  async saveTemplate() {
    // this.loading = true;
    // const res = await this._api.updateOnlineStore({ details_template: this.details_template }).toPromise<any>();
    // this._general.notify('success', res.message || 'Saved');
    // this._auth.updateMerchant({ details_template: this.details_template });
    // this.loading = false;
  }
}
