import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface Invites {
  status: boolean;
  message: string;
  data?: Array<object>
}

@Injectable({
  providedIn: 'root'
})
export class InvitesService {

  constructor(private http: HttpClient) { }

  getInvites(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/getUserInvites', data, httpOptions);
  }
  getAllInvites(data: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/getAllInvites', {token: data}, httpOptions);
  }
}
