import { Component, OnInit, OnDestroy, EventEmitter, ViewContainerRef, ViewEncapsulation, Output, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionsserviceService, UserService, CustomerService, AuthenticationService, StorageService, LoansService } from '../../_services/index';

@Component({
  selector: 'app-loanschedulemodal',
  templateUrl: './loanschedulemodal.component.html',
  styleUrls: ['./loanschedulemodal.component.css']
})
export class LoanschedulemodalComponent implements OnInit {
  @Input('record') record: any;
  @Input('OPEN_SCHEDULE') OPEN_SCHEDULE = false;
  @Input('from_where') from_where = '1';
  @Input('schedule') schedule: any;
  @Input('parentRouteId') parentRouteId: number;
  @Input('where') where = '1';
  @Input('loan') loan = '1';
  request_id_: any;
  public sub: any;
  public loading = false;
  public currentUser: any;
  public status = false;
  @Input('loan_status')  loan_status='';
  @Output() closePopUp = new EventEmitter();
  @Output() modifyContract = new EventEmitter();
  @Input('canEditSchedule') canEditSchedule = false;
  constructor(
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getAllSchedule_();
  }
  saveNewRepayment() {
    this.loading = true;
    this.loansService.saveNewRepayment(this.currentUser.token, this.parentRouteId, this.schedule)
      .subscribe(analysis => {
        this.loading = false;
        this.getAllSchedule();

      });
  }
  getAllSchedule() {
    this.sub = this.route.url.subscribe(params => {
      //console.log(params);
      // this.parentRouteId = +params['id'];
      // this.loansService.getLoanSchedule(this.currentUser.token, this.parentRouteId)
      //   .subscribe(repayments => {
      //     this.status = repayments.status;
      //     if (repayments.status) {
      //       this.schedule = repayments.data;
      //       this.canEditSchedule = repayments.can_modify_contract;
      //       this.loan_status = repayments.loan_status;
      //     } else {

      //     }

      //   });
    });
  }
  getAllSchedule_() { 
    this.loading = true;
    this.loansService.getLoanSchedule(this.currentUser.token, this.record.request_id)
    .subscribe(repayments => {
      this.loading = false;
      this.status = repayments.status;
      if (repayments.status) {
        this.schedule = repayments.data;
        this.canEditSchedule = repayments.can_modify_contract;
        this.loan_status = repayments.loan_status;
      } else {

      }

    });
  }
  getTotal(key, schedule) {
    if (schedule === undefined) { } else {
      let total = 0;
      if (key === 'INITIAL_PRINCIPAL') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.INITIAL_PRINCIPAL); }, 0);
      }
      if (key === 'PRINCIPAL_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.PRINCIPAL_REPAYMENT); }, 0);
      }
      if (key === 'INTEREST_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.INTEREST_REPAYMENT); }, 0);
      }
      if (key === 'FEES') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.FEES) + parseInt(o.VAT_ON_FEES); }, 0);
      }
      if (key === 'TERM_REPAYMENT') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.TERM_REPAYMENT); }, 0);
      }
      if (key === 'HOW_MUCH_PAID') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.FEES) + parseInt(o.HOW_MUCH_PAID); }, 0);
      }
      if (key === 'HOW_MUCH_REMAINING') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.HOW_MUCH_REMAINING); }, 0);
      }
      if (key === 'TOTAL_FINES_SO_FAR') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseFloat(o.TOTAL_FINES_SO_FAR); }, 0);
      }
      return total;
    }
    //

  }
  closeTheLoanFormPopUp() {
    this.closePopUp.emit();
  }
  modifyTheContract() {
    this.modifyContract.emit();
  }
}
