<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Loan Comparison</h3>

      <div class="panel">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-stripped">

                <thead>
                  <th>Products</th>
                  <th>Requests</th>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <select (change)="getRecords()" name="loan_product_id" [(ngModel)]="loan_product_id">
                        <option value=""></option>
                        <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                      </select>
                    </td> 
                    <td>
                      <select (change)="getRecordsSchedules()" name="loan_id" [(ngModel)]="loan_id">
                        <option value=""></option>
                        <option *ngFor="let p of data" value="{{p.loan_id}}">{{p.loan_id}}-{{p.legal_name}}-{{p.request_principal}}</option>
                      </select>
                    </td> 
                  </tr> 

                </tbody>


              </table> 
            </div> 
          </div>
          <div class="row" *ngIf="datas">
            <div class="col-md-6">
              <table class="table table-stripped">

                <thead>
                  <th>Loan ID</th>
                  <th>Customer</th>
                  <th>Disbursement Date</th>
                  <th>Principal</th>
                  <th>Duration</th>
                  <th>Rate</th>
                  <th>Total Interest</th>
                  <th>Total Repayment</th>
                </thead>

                <tbody>

                  <tr>
                    <td>{{datas.old_details.LOAN_ID}}</td>
                    <td>{{datas.old_details.LEGAL_NAME}}</td>
                    <td>{{datas.old_details.DISBURSEMENT_DATE}}</td>
                    <td>{{datas.old_details.REQUEST_PRINCIPAL}}</td>
                    <td>{{datas.old_details.REQUEST_TENOR}} {{datas.old_details.LOAN_DURATION}}</td>
                    <td>{{datas.old_details.REQUEST_RATE}}</td>
                    <td>{{datas.old_details.TOTAL_INTERESTS}}</td>
                    <td>{{datas.old_details.TOTAL_REPAYMENT}}</td>
                  </tr>

                </tbody>


              </table>
              <table class="table table-stripped">

                <thead>
                  <th>Repayment Date</th>
                  <th>Principal</th>
                  <th>Interest</th>
                  <th>Term Repayment</th>
                </thead>

                <tbody>

                  <tr *ngFor="let schedule of datas?.old_schedule">
                    <td>{{schedule.REPAYMENT_DATE}}</td>
                    <td>{{schedule.PRINCIPAL_REPAYMENT|number:'.2-2'}}</td>
                    <td>{{schedule.INTEREST_REPAYMENT|number:'.2-2'}}</td>
                    <td>{{schedule.TERM_REPAYMENT|number:'.2-2'}}</td>

                  </tr>

                </tbody>


              </table>
            </div>
            <div class="col-md-6">
              <table class="table table-stripped">

                <thead>
                  <th>Loan ID</th>
                  <th>Customer</th>
                  <th>Disbursement Date</th>
                  <th>Principal</th>
                  <th>Duration</th>
                  <th>Rate</th>
                  <th>Total Interest</th>
                  <th>Total Repayment</th>
                </thead>

                <tbody>

                  <tr>
                    <td>{{datas.new_details.loan_id}}</td>
                  <td>{{datas.new_details.customer}}</td>
                  <td>{{datas.new_details.disbursement_date}}</td>
                  <td>{{datas.new_details.principal}}</td>
                  <td>{{datas.new_details.duration}}</td>
                  <td>{{datas.new_details.rate}}</td>
                  <td>{{datas.new_details.total_interest|number:'.2-2'}}</td>
                  <td>{{datas.new_details.total_repayment|number:'.2-2'}}</td>
                  
                  </tr>

                </tbody>


              </table>
              <table class="table table-stripped">

                <thead>
                  <th>Repayment Date</th>
                  <th>Principal</th>
                  <th>Interest</th>
                  <th>Term Repayment</th>
                </thead>

                <tbody>

                  <tr *ngFor="let schedule of datas?.new_schedules">
                    <td>{{schedule.repayment_date}}</td>
                    <td>{{schedule.principal_repayment|number:'.2-2'}}</td>
                    <td>{{schedule.interest_repayment|number:'.2-2'}}</td>
                    <td>{{schedule.term_repayment|number:'.2-2'}}</td>

                  </tr>

                </tbody>


              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>