import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-applicationschecklist',
  templateUrl: './applicationschecklist.component.html',
  styleUrls: ['./applicationschecklist.component.css']
})
export class ApplicationschecklistComponent implements OnInit {
  @Input('parentRouteId') parentRouteId: number;
  @Input('sub') sub: any;
  @Input('sub_summary') sub_summary: any;
  @Input('where') where = '1';
  @Input('loan') loan: any;
  @Input('loading') loading: any;
  @Output() saveApplicationChecklist = new EventEmitter();
  is_individual = true;
  constructor() { }

  ngOnInit() {
  }
  saveProductChecklists(event) {

    this.saveApplicationChecklist.emit(event);

  }
}
