import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, AuthenticationService, StorageService, LoansService } from '../_services/index';
import { DataService, OptionsserviceService, UserService } from '../_services/index';
import { MatDialog } from '@angular/material'; 
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-loanstatement',
  templateUrl: './loanstatement.component.html',
  styleUrls: ['./loanstatement.component.css']
})
export class LoanstatementComponent implements OnInit {
  canViewLinks = true;
  @Input('parentRouteId') parentRouteId: number;
  @Input('where') where = '2';
  @Input() showHeader = true;
  @Output() modifyLoanContract = new EventEmitter();
  @Output() sendLoanStatement = new EventEmitter();
  type_of_entry = '-1';
  currentUser: any;
  public sub: any;
  loan: any;
  transactions = [];
  statment_type = "2";
  ttype = '1';
  public repayment_mode = { 1: 'Remita Inflight', 2: 'Cards', 3: 'Direct Debit Mandate', 4: 'Others', 5: 'Cheques' };
  enable_agents_collection = false;
  data:any;
  constructor(public dialog: MatDialog,
    public authService: AuthenticationService,
    public DataService: DataService,
    public optionsService: OptionsserviceService,
    public router: Router, public route: ActivatedRoute,
    public loansService: LoansService,
    public customerService: CustomerService,
    public userService: UserService,
    public storageService: StorageService
  ) {
    // if (!this.authService.canViewModule('1,3,1026')) {
    //   this.canViewLinks = false;
    // }
    this.currentUser = this.storageService.read<any>('currentUser');
    this.DataService.paymentHasBeenProcessedFinally.subscribe(res => {
      // this.getStatement();

    });

  }

  ngOnInit() {
    this.getStatement();
  }
  modifyContract(){
    this.DataService.modifyLoanContract.emit();
  }
  sendStatement(){ 
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_statement_mod', data: this.loan });
  }
  modifySchedule(){
    this.DataService.modifyLoanSchedule.emit();
  }
  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  eir_statement() {
    this.statment_type = "1";
    this.getStatement();
  }
  creditStatement() {
    if (!this.grantRight('60')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onCreditLoanStatement.emit({ loan: this.loan, ttype: 2 });
  }
  suspendFines(){ 
    if (!this.grantRight('60')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onSuspendFines.emit({ loan: this.loan, ttype: 2 });
  }
  debitStatement() {
    if (!this.grantRight('61')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onCreditLoanStatement.emit({ loan: this.loan, ttype: 1 });
  }
  makeLoanPayment() {

    this.DataService.onMakePaymentFromStatement.emit(this.parentRouteId)
  }
  breakLoan() {
    this.DataService.onBreakingLoan.emit(this.parentRouteId)
  }
  initiateRollback(trans) {
    this.DataService.onRollbackPaymentFromStatement.emit({ 'location': 'initiate_roll_back', data: { loan: this.loan, trans: trans } });
  }
  assignCollectionAgent(){
    this.DataService.assignCollectionAgent.emit({ loan: this.loan.loan });
  }
  stopAutoDebit() {
    if (!this.grantRight('59')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'stop_auto_debit', data: this.loan });
  }
  startAutoDebit() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'start_auto_debit', data: this.loan });
  }
  sendAccountStatement() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_account_statement', data: this.loan });
  }
  requestForCard() {
    if (!this.grantRight('39')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_new_card', data: this.loan });
  }
  sendEmailReminder() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_email_reminder', data: this.loan });
  }
  sendSMSReminder() {
    this.DataService.onOpenLoanChildModal.emit({ 'location': 'send_sms_reminder', data: this.loan });
  }
  getStatement() {
    if (this.where == '1') {
      this.sub = this.route.parent.params.subscribe(params => {

        this.parentRouteId = this.parentRouteId || +params["id"];
        this.loansService.getStatement(this.currentUser.token, this.parentRouteId, this.statment_type, this.type_of_entry)

          .subscribe(loan => {
            this.loan = loan;
            this.transactions = loan.chart_account_transactions;
            this.enable_agents_collection = loan.enable_agents_collection;
          });
      });
    } else if(this.where == '6'){
      this.sub = this.route.params.subscribe(params => {
        const reid = this.parentRouteId || +params['id'];
        this.loansService.getInvStatement(this.currentUser.token, this.parentRouteId, 1)
      .subscribe(loan => {
        this.loan = loan;
        this.transactions = loan.chart_account_transactions;
        this.enable_agents_collection = loan.enable_agents_collection;
      });

      });
      
    } else{
      this.sub = this.route.params.subscribe(params => {
        const reid = this.parentRouteId || +params['id'];
        this.loansService.getStatement(this.currentUser.token, reid, this.statment_type, this.type_of_entry)

          .subscribe(loan => {
            this.loan = loan;
            this.transactions = loan.chart_account_transactions;
            this.enable_agents_collection = loan.enable_agents_collection;
          });

      });

    }
  }
  initiateStopRemitaCollection() {
    this.DataService.initStopRemita.emit({ loan: this.loan.loan });
  }
  initiateLoanBuyBack() {
    this.DataService.initLoanBuyBack.emit({ loan: this.loan.loan });
  }
  calculateTotal(chart_account_transactions) {
    let bal = parseFloat(chart_account_transactions[0]["DEBIT"]);
    bal = bal - parseFloat(chart_account_transactions[0]["CREDIT"]);
    chart_account_transactions.map(function (element) {
      element["BALANCE"] = bal
      bal = bal + parseFloat(element["DEBIT"]);
      bal = bal - parseFloat(element["CREDIT"]);
      //this.transactions.push(element)
    });
    this.transactions = chart_account_transactions
  }
  open_schedule(request_id) {
    this.router.navigate(['../repayments/' + request_id]);
  }
  open_payment(request_id) {
    this.router.navigate(['../payments/' + request_id]);
  }
  open_statement(request_id) {
    this.statment_type = '1';
    this.getStatement();
  }
  open_contract(request_id) {
    this.router.navigate(['../loan', request_id]);
  }
  break_loan() {
    this.DataService.onBreakLoan.emit();
  }
  changeDefaultRepayment() {
    if (!this.grantRight('54')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onChangeDefaultPayment.emit({ loan: this.loan });
  }
  initiateSendRemitaLoanNotification() {
    this.DataService.remitaLoanNotification.emit({ loan: this.loan });
  }
  changePortfolioView(view) {
    if (view === 'statement') {
      if (!this.grantRight('51')) {
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }
    }
    if (view === 'schedule') {
      if (!this.grantRight('22')) {
        this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      }
    }
    this.DataService.changePortfolioView.emit({ view: view });
  }
  makePay() {
    if (!this.grantRight('52')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onStartNewRepaymentFlow.emit({ parentRouteId: this.parentRouteId });
  }
  breakPay() {
    if (!this.grantRight('53')) {
      this.DataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    }
    this.DataService.onBreakingLoan.emit({ request_id: this.parentRouteId, loan_view: this.loan.loan })

  }
}
