<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Interest Accrual Report</h3>

      <div class="panel">
        <div class="panel-body">
          <form #filterCustomerForm="ngForm">
            <div class="row">
                <div class="col-xs-6 col-md-3">
                    <div class="form-group">
                        <small><label>Start Date</label></small>
                        <input type="date" name="date_start" [(ngModel)]="filter.date_start" id="date_start"
                            class="form-control">
                    </div>
                </div>

                <div class="col-xs-6 col-md-3">
                    <div class="form-group">
                        <small><label>End Date</label></small>
                        <input type="date" name="date_end" [(ngModel)]="filter.date_end" id="date_end"
                            class="form-control">
                    </div>
                </div>

            </div>

        </form>
        <app-button (click)="filter.start='0';filter.is_export='0';filterCustomer();" class="small mt-2"
            [disabled]="loading" label="Submit" [loading]="loading">
        </app-button>
        <app-button *ngIf="rows.length > 0" (click)="filter.start='0';filter.is_export='1';filterCustomer();" class="small mt-2" style="margin-left: 10px;"
            [disabled]="loading" label="Download" [loading]="loading">
        </app-button>
          <table class="table table-stripped">
            <thead>
              <tr>
                <th>
                  Total Accrued Interest
                </th>
                <th>
                  {{total|number:'.2-2'}}
                </th>
              </tr>

            </thead> 
            <thead>
              <tr>
                  <ng-container *ngFor="let column of objectKeys(columns)">
                      <th *ngIf="columns[column]">{{column}}</th>
                  </ng-container>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let row of rows">
                  <ng-container *ngFor="let column of objectKeys(columns)">
                      <td *ngIf="columns[column]">{{row[column]}}</td>
                  </ng-container>
              </tr>
          </tbody>
 
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>