import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { Merchant } from '../models/merchant';
// import { MerchantItems } from '../models/items';
// import { AuthResponse } from '../models/auth';
// import { MerchantDashboardData } from 'src/app/models/dashboard';
// import { CreditBureauData } from 'src/app/models/creditBureauData';
// import { IConfirmMBSPayload, IInitializeMBSPayload, ILoanStages } from 'src/app/models/api';
// import { AuthService } from './auth.service';

interface ConfirmBankDetails {
  data: { account_name: string; account_number: string; bank_id: number };
  status: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private generalApiUrl = 'https://sellbackend.creditclan.com/merchantclan/public/index.php/api/';

  constructor(
    private http: HttpClient,
    // private _auth: AuthService
  ) {
  }

  generateHttpOptions(): HttpHeaders {
    return new HttpHeaders({
      // Authorization: `Bearer ${ this._auth.token }`
    });
  }

  generateKeyForBankStatementAnalysis(key: string): HttpHeaders {
    return new HttpHeaders({
      'x-api-key': `${ key }`
    });
  }

  // Confirms the account details entered by merchant
  getAccountDetails(obj: {
    account_number: string; bank_code: string;
  }): Observable<ConfirmBankDetails> {
    const url = 'https://mobile.creditclan.com/webapi/v1/account/resolve';
    const httpHeaders = new HttpHeaders({
      'x-api-key': 'WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228'
    });
    return this.http.post<any>(url, obj, { headers: httpHeaders });
  }

  // updates the bankInfo done by a merchant
  updateBankInfo(form: {
    bank_id: string; account_name: string; account_number: string; password: string;
  }): Observable<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }profilebank`, form, {
      headers: this.generateHttpOptions()
    });
  }

  // updates the profile Data
  updateProfileData(form: Partial<{
    phone: string; name: string; address: string; business_type: number; whatsapp_no: string
  }>): Observable<any> {
    return this.http.post<any>(`${ this.generalApiUrl }profile`, form, {
      headers: this.generateHttpOptions()
    });
  }

  updateProfilePicture(picture: FormData): Observable<{ message: string; status: boolean }> {
    return this.http.post<any>(`${ this.generalApiUrl }profilepicture`, picture, {
      headers: this.generateHttpOptions()
    });
  }

  fetchASingleSchedule(id: string): Promise<{
    data: any[]; amount_left: number; amount_paid: number; total_repayment_amount: number; status: boolean;
  }> {
    return this.http.post<any>(`${ this.generalApiUrl }request/${ id }/schedule`, id, {
      headers: this.generateHttpOptions()
    }).toPromise();
  }

  salesByMerchant(): Observable<any> {
    return this.http.get(`${ this.generalApiUrl }sales`, { headers: this.generateHttpOptions() });
  }

  fetchCompletionDetails(): Promise<{ score: number; status: boolean }> {
    return this.http.get<any>(`${ this.generalApiUrl }profilecomplete`, {
      headers: this.generateHttpOptions()
    }).toPromise();
  }

  activatePopularProducts(): Observable<any> {
    return this.http.patch(`${ this.generalApiUrl }popular/activate`, {}, { headers: this.generateHttpOptions() });
  }

  deactivatePopularProducts(): Observable<any> {
    return this.http.patch(`${ this.generalApiUrl }popular/deactivate`, {}, { headers: this.generateHttpOptions() });
  }

  updateOnlineStore(data) {
    return this.http.post(`${ this.generalApiUrl }onlinestore`, data, { headers: this.generateHttpOptions() });
  }

  updateProductReviewSettings(data) {
    return this.http.patch(`${ this.generalApiUrl }reviews`, data, { headers: this.generateHttpOptions() });
  }

  addBanner(data) {
    return this.http.post(`${ this.generalApiUrl }banner`, data, { headers: this.generateHttpOptions() });
  }

  deleteBanner(id) {
    return this.http.delete(`${ this.generalApiUrl }banner/${ id }/delete`, { headers: this.generateHttpOptions() });
  }
}
