import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yeargenerator'
})
export class YeargeneratorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let res = [];
    for (let i = 1970; i <= value; i++) {
        res.push(i);
      }
      return res;
  }

}
