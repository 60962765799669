import { Component, Input, OnInit } from '@angular/core';
// import { MerchantProfileDetails } from '../../models/merchantProfile';
// import { ApiService } from '../../services/api.service';
// import { AuthService } from '../../services/auth.service';
// import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'app-settings-collection-banner',
  templateUrl: './settings-collection-banner.component.html',
  styleUrls: ['./settings-collection-banner.component.css']
})
export class SettingsCollectionBannerComponent implements OnInit {

  @Input() merchant: any;
  loading = false;

  constructor(
    // private _api: ApiService,
    // private _auth: AuthService,
    // private _general: GeneralService
  ) {
  }

  ngOnInit(): void {
  }

  handleChange(e) {
    this.save(e.target.checked);
  }

  async save(collection_banner: boolean) {
    // this.loading = true;
    // const res = await this._api.updateOnlineStore({ collection_banner }).toPromise<any>();
    // this._general.notify('success', res.message || 'Saved');
    // this._auth.updateMerchant({ collection_banner });
    // this.loading = false;
  }
}
