<!-- <section class="panel" style="margin-bottom: 10px" *ngIf="where == '1'">
  <header class="panel-heading">
    <span class="tools pull-right">
      <ul class="list-inline">
        <li>
          <a [routerLink]="['../contract']">Summary</a>
        </li>
        <li>
          <a [routerLink]="['../repayments']">Schedule</a>
        </li>
        <li>
          <a [routerLink]="['../more']">More</a>
        </li>
      </ul>
    </span>
    <span>Request Timeline</span>
  </header>
</section> -->
<section class="panel padding-regular timeline-panel">
 
  <div class="flex justify-between align-center mb-5">
    <h3 class="section-title">Timeline of events</h3>
     
  </div>

  
 




  <div class="timeline mt-5">
    <article class="timeline-item" *ngFor="let item of timeline; let idx = index" [ngClass]="{ alt: idx % 2 > 0 }">
      <div class="timeline-desk" *ngIf="idx % 2 > 0">
        <div class="panel">
          <div class="panel-body">
            <span class="arrow"></span>
            <span class="timeline-icon red"></span>
            <span class="timeline-date">{{
              item?.date_added | amDateFormat: "h:mm a"
            }}</span>
            <h1 class="color-orange default-text-size">
              {{ item?.date_added | amDateFormat: "D" }}
              {{ item?.date_added | amDateFormat: "MMMM" }} |
              {{ item?.date_added | amDateFormat: "dddd" }}
            </h1>
            <p>
              {{ item?.LEGAL_NAME }} |
              <span [innerHTML]="item?.TIMELINE_ACTIVITY"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="timeline-desk" *ngIf="idx % 2 == 0">
        <div class="panel">
          <div class="panel-body">
            <span class="arrow-alt"></span>
            <span class="timeline-icon green"></span>
            <span class="timeline-date">{{
              item?.date_added | amDateFormat: "h:mm a"
            }}</span>
            <h1 class="color-teal default-text-size">
              {{ item?.date_added | amDateFormat: "D" }}
              {{ item?.date_added | amDateFormat: "MMMM" }} |
              {{ item?.date_added | amAdd: 1:"hours" | amDateFormat: "dddd" }}
            </h1>
            <p>
              {{ item?.legal_name }} |
              <span [innerHTML]="item?.comments"></span>
            </p>
          </div>
        </div>
      </div>
    </article>
  </div>
</section>