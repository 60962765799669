// import { NewaccountComponent } from './newaccount/newaccount.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
// import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './_guards/auth.guard';
import { LoggedinGuard } from './_guards/loggedin.guard';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {
  AnalyticsService,
  ApprovalsService,
  AuthenticationService,
  CustomerService,
  DataService,
  InvestmentService,
  KycService,
  LoansService,
  MarketService,
  OperationsService,
  OptionsserviceService,
  StorageService,
  UserService
} from './_services/index';
import { CustomersService } from './_services/customers.service';
import { HttpHelperService } from './_services/http-helper.service';
import { GmapsserviceService } from './_services/gmapsservice.service';
import { ChatService } from './_services/chat.service';
import { LoggingService } from './_services/logging.service';
import { HelperService } from './_services/helper.service';
import { ConstantsService } from './_services/constants.service';
import { NotificationService } from './_services/notification.service';
import { InvitesService } from './_services/invites.service';
import { ToastrModule } from 'ngx-toastr';
import { ChartsModule } from 'ng2-charts';
import { PendingDisbursementComponent } from './pending-disbursement/pending-disbursement.component';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { CarouselComponent } from './carousel/carousel.component';
import { LoadingComponent } from './loading/loading.component';
import { ImageComponent } from '../app/image/image.component';
import { PageNotFoundComponentComponent } from '../app/page-not-found-component/page-not-found-component.component';
import { RepaymentComponent } from '../app/repayment/repayment.component';
import { RepaymentsComponent } from './repayments/repayments.component';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { ForgotComponent } from './forgot/forgot.component';
import { Ng2TrackScrollModule } from 'ng2-track-scroll';
import { MobileRestrictionComponent } from './mobile-restriction/mobile-restriction.component';
import { LazyloadDirective } from './_directives/lazyload.directive';
import { BackDirective } from './_directives/back.directive';
import { SharedcustomerinfoonlyComponent } from './sharedcustomerinfoonly/sharedcustomerinfoonly.component';
import { KudaloginComponent } from './kudalogin/kudalogin.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TwofaComponent } from './twofa/twofa.component';
import { PrincipaloutstandingComponent } from './principaloutstanding/principaloutstanding.component';
import { LoanreportComponent } from './loanreport/loanreport.component';
import { LoancomparisonComponent } from './loancomparison/loancomparison.component';
import { LoanarrearsageingComponent } from './loanarrearsageing/loanarrearsageing.component'; 
import { AccrualsComponent } from './accruals/accruals.component';
import { PartnercollectionsComponent } from './partnercollections/partnercollections.component';
import { ClosedpartnerlloansComponent } from './closedpartnerlloans/closedpartnerlloans.component';

@NgModule({
  declarations: [
    CarouselComponent, RepaymentsComponent,
    AppComponent, ImageComponent, LoadingComponent, PageNotFoundComponent,
    LoginComponent, PageNotFoundComponentComponent,
    SignupComponent,
    RepaymentComponent,
    PageNotFoundComponent,
    PendingDisbursementComponent,
    ForgotComponent,
    MobileRestrictionComponent,
    LazyloadDirective,
    BackDirective,
    SharedcustomerinfoonlyComponent,
    KudaloginComponent,
    ResetPasswordComponent,
    TwofaComponent,
    PrincipaloutstandingComponent,
    LoanreportComponent,
    LoancomparisonComponent,
    LoanarrearsageingComponent, 
    AccrualsComponent,
    PartnercollectionsComponent,
    ClosedpartnerlloansComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    ToastrModule.forRoot(),
    HttpModule,
    ChartsModule,
    IonRangeSliderModule,
    SlimLoadingBarModule.forRoot(),
    Ng2TrackScrollModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    LoggedinGuard,
    AuthenticationService,
    GmapsserviceService,
    DataService,
    UserService,
    StorageService,
    CustomerService,
    InvestmentService,
    LoansService,
    OptionsserviceService,
    OperationsService,
    HttpHelperService,
    HelperService,
    ConstantsService,
    CustomersService,
    ChatService,
    LoggingService,
    KycService,
    MarketService,
    ApprovalsService,
    AnalyticsService,
    NotificationService,
    InvitesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
