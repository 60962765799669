import { Component, OnInit } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { DataService, OperationsService, UserService } from '../app/_services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  loadingRouteConfig: boolean;
  showMobile = false;
  modal = '';

  constructor(
    private router: Router,
    private dataService: DataService,
    private operationsService: OperationsService,
    private _user: UserService
  ) {
    const { width } = window.screen;
    this.showMobile = width < 800;
    if (width <= 1024 && width > 800) {
      document.body.classList.add('zoom-out');
    }
  }

  async ngOnInit() {
    this._user.onboard$.subscribe({
      next: () => {
        this.modal = 'onboard';
      }
    });
    const token = localStorage.getItem('token');
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.dataService.loadingRoute.emit({ loadingRouteConfig: true });
      } else if (event instanceof RouteConfigLoadEnd) {
        this.dataService.loadingRoute.emit({ loadingRouteConfig: false });
      }
    });

    if (!this.operationsService.rolerights.lender_roles && token) {
      await this.operationsService.getRoleRights(token).toPromise();
    }
  }
}
