<h3 class="title">Loan Schedule</h3>
<section class="overpanel-scroll padding-regular">
  <div *ngIf="!loading">
    <div class="contract-table"> 
      <table class="table medium">
        <thead>
          <tr>
            <th>Date</th> 
            <th>Principal</th>
            <th>Interest</th> 
            <th>Repayment</th> 
            <th>Paid</th> 
            <th>Balance</th> 
          </tr>
        </thead>
        
        <tbody>
          <tr *ngFor="let item of schedule">
            <td>{{item.REPAYMENT_DATE}}</td> 
            <td>{{item.PRINCIPAL_REPAYMENT | number:'.2-2'}}</td>
            <td>{{item.INTEREST_REPAYMENT | number:'.2-2'}}</td> 
            <td>{{item.TERM_REPAYMENT | number:'.2-2'}}</td> <td>{{item.HOW_MUCH_PAID | number:'.2-2'}}</td> 
            <td>{{item.HOW_MUCH_REMAINING | number:'.2-2'}}</td> 
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td> 
            <td>{{getTotal('PRINCIPAL_REPAYMENT', schedule) | number:'.2-2'}}</td>
            <td>{{getTotal('INTEREST_REPAYMENT', schedule) | number:'.2-2'}}</td> 
            <td>{{getTotal('TERM_REPAYMENT', schedule) | number:'.2-2'}}</td> 
            <td>{{getTotal('HOW_MUCH_PAID', schedule) | number:'.2-2'}}</td> 
            <td>{{getTotal('HOW_MUCH_REMAINING', schedule) | number:'.2-2'}}</td> 
          </tr>
        </tfoot>
      </table>
    </div> 
  </div> 
</section>
