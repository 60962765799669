<div class="wrapper">
  <div class="row">
    <div class="col-md-6 left">
      <div class="content">
          <div class="flex justify-center logos">
              <img src="/assets/images/cc-logo-md.png" alt="creditclan's logo" />
              <!-- <span>+</span>
              <img src="/assets/images/kuda-logo-white.svg" alt="kuda bank logo" class="ml-4" /> -->
          </div>
          <img src="/assets/images/analytics.svg" alt="kuda illustration" class="illustration" />
      </div>
  </div>
    <div class="col-md-6 right">

      <div class="content">
        <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
          <div class="form-group">
            <label for="exampleInputPassword1">Enter OTP</label>
            <input
              #password="ngModel" autocomplete="false" [(ngModel)]="model.password" [disabled]="loading"
              class="form-control custom" name="password" placeholder="Password" required type="password"
            />
            <p class="mt-2">
              To get OTP, save the number +2349053535333 on Whatsapp as Creditclan & chat the keyword OTP
            </p>
          </div>

          <div class="mt-4">
            <app-button [disabled]="loading" type="submit" label="Confirm OTP" [loading]="loading"></app-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
