import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OperationsService, StorageService } from '../../_services';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.css']
})
export class OnboardComponent implements OnInit {
  @Output() done = new EventEmitter();
  step = 1;
  phone = '';
  otp = '';
  changingPhone = true;
  sendOtpLoading = false;
  confirmOtpLoading = false;
  submitKycLoading = false;
  kycForm: FormGroup;
  file = null;

  constructor(
    private storageService: StorageService,
    private operationsService: OperationsService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    const user = this.storageService.read<any>('logged_in_user');
    if (user.PHONE) {
      this.phone = user.PHONE;
      this.changingPhone = false;
    }
    this.kycForm = this.fb.group({
      reg_no: ['', Validators.required],
      address: ['', Validators.required],
      business_type: ['', Validators.required],
    });
  }

  ngOnInit() {
  }

  handleChangePhone() {
    if (!this.changingPhone) {
      this.step++;
      return;
    }
    this.sendOtpLoading = true;
    const token = localStorage.getItem('token');
    this.operationsService.changePhone({ phone: this.phone, token }).subscribe(res => {
      console.log({ res });
      if (!res.status) {
        this.toastr.error(res.message || 'An error occurred, please try again');
      } else {
        this.step++;
      }
      this.sendOtpLoading = false;
    }, err => {
      this.sendOtpLoading = false;
      this.toastr.error(err.message || 'An error occurred, please try again');
    });
  }

  handleConfirmOtp() {
    this.confirmOtpLoading = true;
    const token = localStorage.getItem('token');
    this.operationsService.confirmOtp({ token, otp: this.otp }).subscribe(
      res => {
        if (!res.status) {
          this.toastr.error(res.message || 'An error occurred, please try again');
        } else {
          this.step++;
        }
        this.confirmOtpLoading = false;
      },
      (err) => {
        this.confirmOtpLoading = false;
        this.toastr.error(err.message || 'An error occurred, please try again');
      }
    );
  }

  handleKycSubmit() {
    if (!this.kycForm.valid) {
      return this.toastr.error('Please fill in all fields correctly');
    }
    if (!this.file) {
      return this.toastr.error('Please upload your CAC document');
    }
    const values = this.kycForm.value;
    this.kycForm.disable();
    this.submitKycLoading = true;
    const token = localStorage.getItem('token');
    const fd = new FormData();
    Object.keys(values).forEach(key => fd.append(key, values[key]));
    fd.append('cac', this.file);
    fd.append('token', token);
    this.operationsService.updatePartnerInfo(fd).subscribe(res => {
      if (!res.status) {
        this.toastr.error(res.message || 'An error occurred, please try again');
      } else {
        this.step++;
        localStorage.setItem('kyc_done', String(true));
      }
      this.kycForm.enable();
      this.submitKycLoading = false;
    }, err => {
      this.kycForm.enable();
      this.submitKycLoading = false;
      this.toastr.error(err.message || 'An error occurred, please try again');
    });
  }

  handleFileChange(e) {
    const [file] = e.target.files;
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 2) {
      return this.toastr.error('File cannot be more than 2 mb');
    }
    this.file = file;
  }
}
