<section class="panel contract_area hideable_area" style="margin-bottom: 10px" *ngIf="where=='1'">
  <header class="panel-heading">
    <span class="tools pull-right">
      <ul class="list-inline">
        <li><a [routerLink]="['../contract']">Summary</a></li>
        <li><a [routerLink]="['../checklist']">Checklist</a></li>
        <li *ngIf="analysis?.LOAN_STATUS<=2"><a [routerLink]="['../repayments']">Schedule</a></li>
        <li><a [routerLink]="['../more']">More</a></li>
      </ul>
    </span>
    <span>Checklist</span>
  </header>
</section>
<section class="panel padding-regular">
  <h3 class="section-title mb-4">Checklist of Actions</h3>
  <div class="decList bigger">
    <ul>
      <li>
        <span>BVN Added</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.is_bvn_done ? 'Yes':'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Bank Account Added</span>
        <span *ngIf="!loading">{{analysis?.checklist?.is_bank_account_provider ? 'Yes':'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Card Added</span><span *ngIf="!loading">{{analysis?.checklist?.is_card_provider ? 'Yes':'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li *ngIf="loan?.referal_code == '13'">
        <span>Upfront Paid</span><span *ngIf="!loading">{{analysis?.checklist?.is_card_provider ? 'Yes':'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li *ngIf="loan?.referal_code == '13'">
        <span>Landlord Visited</span><span *ngIf="!loading">{{analysis?.checklist?.is_card_provider ? 'Yes':'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Contract Sent</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.is_contract_sent ? 'Yes':'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Contract Status</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.is_contract_accepted ? 'Accepted':'Not Accepted'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Documents Requested</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.document_requested ? 'Yes':'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li *ngIf="analysis?.checklist?.document_requested">
        <span>Last Date Sent</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.last_date_sent}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Direct Debit Request</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.direct_debit_requested ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li *ngIf="analysis?.checklist?.direct_debit_requested">
        <span>Direct Debit Active</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.direct_debit_active ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Home Address Verification</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.home_address ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li *ngIf="analysis?.checklist?.home_address">
        <span>Home Address Verification Status</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.home_addres_active ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Office Address Verification</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.office_address ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li *ngIf="analysis?.checklist?.office_address">
        <span>Office Address Verification Status</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.office_address_active ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>CRC search carried out</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.crc ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>First Central search carried out</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.xds ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li>
        <span>Eligibility Checked</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.eligibility ? 'Yes' : 'No'}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
      <li *ngIf="analysis?.checklist?.eligibility">
        <span>Eligibility Score</span>
        <span *ngIf="!loading"> {{analysis?.checklist?.elibility_score}}</span>
        <span class="skeleton" *ngIf="loading"> Sample Test Text</span>
      </li>
    </ul>
  </div>
</section>