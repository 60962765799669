<!-- Attachment -->
<div class="panel padding-regular">
  <div class="section-title text-right mb-4">Attachments
    {{ !attachments.length && !photos.length && !loadingAttachments ? '(No Attachment)' : '' }}</div>
  <div class="attachments" *ngIf="(!attachments.length && !photos.length) && !loadingAttachments">
    <div class="attachment-item"></div>
    <div class="attachment-item"></div>
    <div class="attachment-item"></div>
    <div class="attachment-item flex flex-center cursor-pointer" (click)="openModal.emit('attachment')">
      View<br />more
    </div>
  </div>
  <div class="attachments" *ngIf="(attachments.length > 0 || photos.length > 0) && !loadingAttachments">
    <a *ngFor="let attach of attachments" class="attachment-item flex flex-column flex-center" target="_blank"
      href="{{attach?.FILE_NAME}}">
      <i class="fa fa-file-alt default-text-size"></i>
      <small class="ellipsize padding-regular-small fill-w text-capitalize"
        [title]="attach?.COMMENTS">{{ attach?.COMMENTS }}</small>
    </a>
    <a *ngFor="let photo of photos" class="attachment-item" target="_blank"
      href="{{photo?.FILE_NAME}}">
      <img src="{{photo?.FILE_NAME}}">
    </a>
    <!-- <a class="attachment-item flex flex-center cursor-pointer" (click)="openModal.emit('attachment')">
      View<br />more
    </a> -->
  </div>
  <!-- <div class="attachments" *ngIf="loadingAttachments">
    <a class="attachment-item skeleton fit-content"></a>
    <a class="attachment-item skeleton fit-content"></a>
    <a class="attachment-item skeleton fit-content"></a>
    <a class="attachment-item flex flex-center cursor-pointer" (click)="openModal.emit('attachment')">
      View<br />more
    </a>
  </div> -->
</div>