
<div *ngIf="from_where != 'product'">
  <div class="panel">
    <div class="panel-body">
      <div class="mt-3">
        <h5 class="section-title mb-5">Product Checkeclist</h5>
        <div class="h-group center mt-4 empty-state mt-5" *ngIf="!product_checklist.length">
          <div class="icon"><i class="fa fa-meh-o"></i></div>
          <h2 class="title no-float">No data to show</h2>
        </div>

        <div>
         
           
          <form>
            <div *ngFor="let checklist of product_checklist; let i=index">
              <label class="form-group d-flex justify-content-between"> 
                <span class="control-label normal">{{checklist.display}}</span>
                <span *ngIf="checklist.value">
                  <i class="fa fa-check color-green"></i>
                </span>
                <span *ngIf="!checklist.value">
                  <i class="fa fa-question-mark color-black"></i>
                </span>
              </label>
            </div>
          </form>
        </div>


      </div>
    </div>
  </div>
</div>