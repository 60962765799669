<div class="side-view animated slideInRight">
  <button class="close" (click)="closeView()"><i class="fa fa-times"></i></button>
  <div class="side-view-wrap">
    <div class="header" *ngIf="!loan">
      <div class="user">
        <div class="image large skeleton fit-content">
          <img class="image-avatar" src="assets/img/avatar-mini.png" />
        </div>
        <div class="content skeleton-children">
          <div class="title mb-1">Lorem ipsum dolor sit lofoe.</div>
          <div class="subtitle mb-1">Lorem ipsum </div>
          <div>Lorem,.</div>
        </div>
      </div>
    </div>
    <div class="header" *ngIf="loan">
      <div class="user">
        <div class="image large">
          <img class="image-avatar" onerror="this.src='assets/img/avatar-mini.png';" src="{{loan.customer.profile_pic | imagenotfound}}" />
        </div>
        <div class="content flex-1">
          <div class="title">{{ loan.customer.legal_name }}</div>
          <div class="subtitle">N{{ loan.request_principal }} | {{ loan.request_tenor }} {{ loan.loan_duration }}</div>
          <div class="progress mt-2 mb-1" style="height: 4px; width:25%" [ngClass]="{'hidden': loading}">
            <div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100"
              [ngStyle]="{'width': request?.PERCENT_PAID+'%'}"
              [ngClass]="{'progress-bar-primary': request?.PERCENT_PAID >= 100, 'progress-bar-danger': request?.PERCENT_PAID < 100}">
            </div>
            <!-- <span class="sr-only">{{request?.PERCENT_PAID}}% Complete</span> -->
          </div>
          <div class="loan-action-buttons mt-2">
            <app-loanportfolioaction (doingDisbursement)="child = 'repaymentflow'" [request_id]="requestId"
              *ngIf="loanportfolio?.show_portfolio" [buttonOnly]="true">
            </app-loanportfolioaction>
          </div>
        </div>
        <div class="flex flex-row justify-around" style="align-self: flex-start">
          <!-- <button class="btn btn-outline-secondary btn-xs" (click)="child = 'edit'"><i class="fa fa-edit"></i></button> -->
          <!-- <app-button class="small outline mr-1" (click)="more = 'liste'" icon="fa fa-list" label="Send Message">
          </app-button>
          <button class="btn btn-outline-secondary btn-xs ml-2" (click)="child = 'statement'">
            <i class="fa fa-book mr-1"></i> Statement</button>
          <button class="btn btn-outline-secondary btn-xs ml-2" (click)="child = 'schedule'">
            <i class="fa fa-list mr-1"></i> Schedule</button> -->
        </div>
      </div>
    </div>

    <div class="nav">
      <div (click)="setView('repayment')" [ngClass]="{'active': view === 'repayment'}">Repayment</div>
      <div (click)="setView('application')" [ngClass]="{'active': view === 'application'}">Application</div>
      <div (click)="setView('timeline')" [ngClass]="{'active': view === 'timeline'}">Comments</div>
      <!-- <div (click)="setView('links')" [ngClass]="{'active': view === 'links'}">More options</div> -->
    </div>

    <div class="content skeleton-children fit-content" *ngIf="!loan || !loanportfolio">
      <div class="fill-w mb-2">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatem, possimus suscipit illum
        consectetur quibusdam magnam provident tempora ducimus expedita nostrum non aliquid quod earum eos dignissimos,
        recusandae unde sed soluta! recusandae unde sed soluta!
      </div>
      <div class="fill-w mb-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut nulla.
        Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab? Quidem adipisci
        facilis porro doloremque at quos pariatur, illo magnam in minima!
      </div>
      <div class="fill-w mb-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate cum officiis molestias, quaerat ut nulla.
        Asperiores reprehenderit rerum quaerat cum eaque aspernatur ipsum modi dolorum, dolores quos ab? Quidem adipisci
        facilis porro doloremque at quos pariatur, illo magnam in minima!
      </div>
    </div>

    <div class="content" *ngIf="loan && loanportfolio && view === 'repayment'">
      <div class="panel padding-regular">
        <div class="padding-regular-y text-center">
          <div class="section-title default-text-size">Total repayment made</div>
          <div class="progress mt-2 mb-1" style="height: 4px; width: 60%; margin: auto;">
            <div class="progress-bar progress-bar-striped progress-bar-primary active" role="progressbar"
              aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': 100 - loanportfolio.percent_remaining + '%'}"
              [ngClass]="{'progress-bar-primary': (100 - loanportfolio.percent_remaining) >= 100, 'progress-bar-danger':
              (100 - loanportfolio.percent_remaining) < 100}">
            </div>
            <span class="sr-only">{{100 - loanportfolio.percent_remaining}}% Complete</span>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 flex-center border-right">
            <div class="h-group text-center">
              <div class="title">N{{ (loanportfolio.paid) | number: '.2-2' }}</div>
              <div>Paid</div>
            </div>
          </div>
          <div class="flex-1 flex-center">
            <div class="h-group text-center">
              <div class="title">N{{ loanportfolio.balance | number:'.2-2' }}</div>
              <div>Balance</div>
            </div>
          </div>
        </div>
        <!-- <div class="panel-overlay flex-center">
          <app-loanportfolioaction (doingDisbursement)="makePayment($event)" [request_id]="requestId"
            *ngIf="loanportfolio?.show_portfolio" [buttonOnly]="true">
          </app-loanportfolioaction>
        </div> -->
      </div>
      <!-- <div>
        
        <div class="panel box edged_box mt-2 mb-2 border-green">
          <a class="alert padding-regular bg-off-white">
            <div class="texts no-padding">
              <h4 class="title color-green">Preview the contract</h4>
              <p class="subtitle">View a copy of this contract in a new tab</p>
            </div>
            <div class="action" *ngIf="!loan?.show_old">
              <a class="btn btn-outline-success btn-sm"
                href="https://creditclanapis.creditclan.com/api/v2/services/downloadkudacontract?token={{loan?.request_id}}"
                target="_blank">Download Offer Letter</a>
            </div>
             
          </a>
        </div>

      </div> -->
      <!-- <div class="panel padding-regular" *ngIf="loan?.loan_status > 3">
        <div class="h-group center flex-center flex-column">
          <div class="section-poster">
            <div class="image flex-center">
              <img src="assets/images/closed.svg" class="fill-w">
            </div>
          </div>
          <p class="subtitle mt-2">Loan has been closed</p>
          <div class="text-center mt-4 width_1_2">
            <app-button class="small outline" label="View Letter of Indebtedness" (click)="child = 'indebtedness'">
            </app-button>
          </div>
        </div>
      </div> -->
      
      <!-- <section class="panel mb-1" *ngIf="loan?.has_roll_over && loan?.loan_status <='3'">
        <div class="alert padding-regular bg-off-white">
          <div class="icon color-orange">
            <i class="fas fa-excalamation-circle"></i>
          </div>
          <div class="texts">
            <h3 class="title">Rollover request</h3>
            <p class="subtitle">Review the top up request</p>
          </div>
          <div class="action">
            <app-button class="small outline mr-1" (click)="reviewRollover()" label="Open"></app-button>
            <app-button class="small outline mr-1" (click)="cancelRollover(loan?.top_up_request_id)" label="Cancel">
            </app-button>
          </div>
        </div>
      </section> -->
      
      <!-- <app-loancontractsection (showActionsModal)="openActionsForRolloverRequest($event)" (editOtherAction)="child = 'edit'"
        [is_top_up]="loan?.loan?.is_top_up" *ngIf="loan?.has_top_up && loan?.loan?.loan_status <=3" [loan]="loan?.loan"
        [request_id]="loan?.top_up_request_id" [currentUser]="currentUser" [loading]="loading">
      </app-loancontractsection> -->


      

       
      

      <!-- <app-loanstatement *ngIf="subview == 'statement'" [where]="2" [parentRouteId]="requestId" [showHeader]="true">
      </app-loanstatement> -->

      <app-statementschedule *ngIf="subview == 'schedule'" [where]="1" [parentRouteId]="requestId" [showHeader]="true"
        [embedModal]="true" (openCardDebit)="setChild('carddebit', $event)"
        (openAccountDebit)="setChild('accountdebit', $event)" (openClearingFines)="setChild('clearingfines', $event)"
        (openMakeLoanPayment)="setChild('makeloanpayment', $event)" (openStopRemita)="setChild('stopremita', $event)">
      </app-statementschedule>
      <app-collections [request_id]="requestId"></app-collections>
    </div>
    <div class="content" *ngIf="loan && view === 'application'">
      <div  *ngIf="loan.status < 3" class="panel padding-regular flex">
        <div class="flex-1 flex-center border-right">
          <div class="h-group text-center">
            <div class="title">N{{ loan.request_principal }}</div>
            <div>Monthly Repayment</div>
          </div>
        </div>
        <div class="flex-1 flex-center">
          <div class="h-group text-center">
            <div class="title">{{ loan.request_rate }}%</div>
            <div>{{ loan.interest_duration }}</div>
          </div>
        </div>
        <div class="flex-1 flex-center border-left">
          <div class="icon-text medium cursor-pointer" (click)="toggleDetails()">
            <div class="icon color-blue"><i class="fas fa-ellipsis-h"></i></div>
            <div class="text">More</div>
          </div>
        </div>
      </div>
      <app-ippisdetails *ngIf="loan && loan?.repayment_mode=='6'" [loan]="loan">
      </app-ippisdetails>
      <!-- <app-remitainfodetails [customer]="customer" *ngIf="loan && loan?.use_remita==1">
      </app-remitainfodetails> -->
      <app-customer-info *ngIf="!loading" [embeded]="true" [loan]="loan" [customer]="customer" [is_individual]="is_individual"
        (refresh)="getCustomer()" [reqid]="requestId" [pid]="loan.people_id" [from_loan]="true" [show_header]="false">
      </app-customer-info>
    </div>
    <div class="content" *ngIf="loan && view === 'timeline'">
      <app-applicationstimeline [loan]="loan" [view_from]="'drawer'" [requestId]="requestId" ></app-applicationstimeline>
    </div>
    <div class="content" *ngIf="loan && view === 'links'">
      <div class="grid three mt-4">
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'analytics'">
          <div class="icon color-blue round-icon"><i class="fas fa-building"></i></div>
          <div class="text">Analytics</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'avs'">
          <div class="icon color-blue round-icon"><i class="fas fa-credit-card"></i></div>
          <div class="text">Adress VS</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'creditbureau'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Credit bureau</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'collateral'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Collateral</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'recommendation'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Recommendation</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'eligibility'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Eligibility</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'actions'">
          <div class="icon color-blue round-icon"><i class="fas fa-external-link-square-alt"></i></div>
          <div class="text">Take action</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="child = 'checklist'" *ngIf="grantRight('21')">
          <div class="icon color-blue round-icon"><i class="fas fa-tasks"></i></div>
          <div class="text">Checklist</div>
        </div>
        <div class="icon-text medium cursor-pointer mb-4" (click)="more = 'list'">
          <div class="icon color-blue round-icon"><i class="fas fa-ellipsis-h"></i></div>
          <div class="text">More</div>
        </div>
      </div>
    </div>

    <div class="overlay" *ngIf="details">
      <button class="closer" (click)="toggleDetails()"><i class="fa fa-times"></i></button>
      <div class="dialog panel padding-regular">
        <ul class="decList bigger">
          <li><span>Reason</span><span>{{ loan.loan_purpose }}</span></li>
          <li><span>Interest rate</span><span>{{ loan.request_rate }}%</span></li>
          <li><span>Total fees</span><span>{{ loan.total_fees_and_charges }}</span></li>
          <li><span>1st repayment</span><span>{{ loan.repayment_started_when }}</span></li>
          <li><span>Net disbursement</span><span>{{ loan.net_disbursement }}</span></li>
          <li><span>Officer</span><span><img class="image-avatar" src="assets/images/user.png" /></span></li>
        </ul>
        <app-button class="outline small orange block mb-1" label="Edit request"></app-button>
        <app-button class="outline small block" label="Send offer letter"></app-button>
      </div>
    </div>

    <div class="overlay" *ngIf="child">
      <button class="closer" (click)="child = ''"><i class="fa fa-times"></i></button>
      <div class="child overflow-visible">
        <!-- <app-applicationsindebtedness [requestId]="requestId" *ngIf="child === 'indebtedness'">
        </app-applicationsindebtedness>

        <div class="panel padding-regular" *ngIf="child === 'repaymentflow'">
          <app-repaymentflow [request_id]="requestId" [token]="currentUser.token" [fromModal]="false">
          </app-repaymentflow>
        </div> -->

        <!-- <div class="panel padding-regular" *ngIf="child === 'stopremita'">
          <app-stopremita [repayment]="repayment" [loan]="loan" (modalClosed)="child = ''"></app-stopremita>
        </div> -->

        <!-- <div class="panel padding-regular" *ngIf="child === 'changepayment'">
          <app-changepayment [loan]="loan" [currentUser]='currentUser'></app-changepayment>
        </div> -->
<!-- 
        <div class="panel padding-regular" *ngIf="child === 'cheque'">
          <app-requestheader [loan]="loan" [where]=''></app-requestheader>
          <h5 class="title">Loan cheque</h5>
          <app-loancheque [parentRouteId]="requestId" [where]='2' [scroll]="false"></app-loancheque>
        </div> -->

        <!-- <div class="panel padding-regular" *ngIf="child === 'accountdebit'">
          <app-accountdebit [sending_instruction]="false" [token]="currentUser.token" [parentRouteId]="requestId"
            [repayment]="repayment" [loan]="loan"></app-accountdebit>
        </div> -->

        <div class="panel padding-regular" *ngIf="child === 'nibbsdebit'">
          <app-nibbsdebit [sending_instruction]="false" [token]="currentUser.token" [parentRouteId]="requestId"
            [repayment]="repayment" [loan]="loan"></app-nibbsdebit>
        </div>

        <!-- <div class="panel padding-regular" *ngIf="child === 'remita'">
          <app-remitainflight [token]="currentUser.token" [parentRouteId]="requestId" [loan]="loan" [scroll]="false">
          </app-remitainflight>
        </div> -->

        <!-- <div class="panel padding-regular" *ngIf="child === 'carddebit'">
          <app-carddebitsetup [chargingCard]='chargingCard' [token]="currentUser.token" [parentRouteId]="requestId"
            [loan]="loan"></app-carddebitsetup>
        </div> -->

        <!-- <div class="panel padding-regular" *ngIf="child === 'breakloan'">
          <app-breakingloan (breakLoanHasBeenSent)="breakloanSent($event)" [token]="currentUser.token"
            [parentRouteId]="requestId" [loan]="loan"></app-breakingloan>
        </div>

        <div class="panel padding-regular" *ngIf="child === 'payment'">
          <app-repaymentflow [request_id]="requestId" [token]="currentUser.token" [fromModal]="false">
          </app-repaymentflow>
        </div> -->

        <!-- <app-analyticshome [requestId]="requestId" *ngIf="child === 'analytics'" [showHeader]="false">
        </app-analyticshome> -->

        <app-locationdetails [customer_id]='loan.people_id' *ngIf="child === 'avs'" from="drawer">
        </app-locationdetails>

        <!-- <app-creditregistry [from_where]='1' [request_id]="requestId" *ngIf="child === 'creditbureau'">
        </app-creditregistry> -->

        <div class="panel padding-regular" *ngIf="child === 'checklist'">
          <app-applicationschecklist [loan]='loan' [where]="1" [parentRouteId]="requestId">
          </app-applicationschecklist>
        </div>

        <app-loanstatement *ngIf="child == 'statement'" (sendLoanStatement)="sendLoanStatement($event)" (modifyLoanContract)="modifyLoanContract($event)" [where]="2" [parentRouteId]="requestId" [showHeader]="false">
        </app-loanstatement>

        <app-statementschedule *ngIf="child === 'schedule'" [where]="1" [parentRouteId]="requestId" [showHeader]="false"
          [embedModal]="true" (openCardDebit)="setChild('carddebit', $event)"
          (openAccountDebit)="setChild('accountdebit', $event)" (openClearingFines)="setChild('clearingfines', $event)"
          (openMakeLoanPayment)="setChild('makeloanpayment', $event)" (openStopRemita)="setChild('stopremita', $event)">
        </app-statementschedule>

        <!-- <div class="panel padding-regular" *ngIf="child === 'clearingfines'">
          <app-clearingfines [repayment]="repayment" [record_type]='21' (modalClosed)="child = ''"></app-clearingfines>
        </div> -->

        <!-- <div class="panel padding-regular" *ngIf="child === 'makeloanpayment'">
          <app-makeloanpayment [repayment]="repayment" [record_type]='21' (modalClosed)="child = ''">
          </app-makeloanpayment>
        </div> -->

        <app-applicationseligibility *ngIf="child === 'eligibility'" [requestId]="requestId">
        </app-applicationseligibility>

        <!-- <app-recommendations *ngIf="child === 'recommendation'" [requestId]="requestId">
        </app-recommendations> -->

        <!-- <app-collateral *ngIf="child === 'collateral'" [requestId]="requestId">
        </app-collateral> -->

        

        <app-loanactions [requestId]="requestId" [loan]="loan" *ngIf="child === 'actions'"></app-loanactions>

        <!-- <app-agents-repayment-mode *ngIf="child === 'agents'"></app-agents-repayment-mode> -->


        <!-- <div class="panel padding-regular" *ngIf="child === 'edit'">
          <app-newloanform [request_id]='loan?.top_up_request_id' [request_step]="1" [sub]='1' [from]='2' [contraints]="false"
            [contract_details]="{ currentUser: this.currentUser.token, request_id: loan?.top_up_request_id, loan: loan?.loan }"
            (close)="child = ''">
          </app-newloanform>
        </div> -->
        <!-- <div class="panel padding-regular" *ngIf="child === 'editcontract'">
          <app-modifyloanform [request_id]='requestId' [request_step]="1" [sub]='1' [from]='2' [contraints]="false"
            [contract_details]="{ currentUser: this.currentUser.token, request_id: requestId, loan: loan }"
            (close)="child = ''">
          </app-modifyloanform>
        </div> -->
      </div>
    </div>

    <div class="overlay" *ngIf="more">
      <button class="closer" (click)="more = ''"><i class="fa fa-times"></i></button>
      <div class="more panel overflow-visible">
        <div class="grid three mt-4" *ngIf="more === 'list'">
          <div class="flex-1 flex-center mb-3" (click)="more = 'bvn'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-fingerprint"></i></div>
              <div class="text">BVN</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'account'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-red round-icon"><i class="fas fa-building"></i></div>
              <div class="text">Account</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'card'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-credit-card"></i></div>
              <div class="text">Card</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'attachment'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-file"></i></div>
              <div class="text">Attachment</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="openModal.emit('location')" *ngIf="grantRight('26')">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-red round-icon"><i class="fas fa-map"></i></div>
              <div class="text">Request Location</div>
            </div>
          </div>
        </div>
        <div class="grid three mt-4" *ngIf="more === 'liste'">
          <div class="flex-1 flex-center mb-3" (click)="sendSMSToCustomer(loan)">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-mobile"></i></div>
              <div class="text">SMS</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="sendEmailToCustomer(loan)">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-red round-icon"><i class="fas fa-envelope"></i></div>
              <div class="text">EMAIL</div>
            </div>
          </div>
          <div class="flex-1 flex-center mb-3" (click)="more = 'card'">
            <div class="icon-text medium cursor-pointer mb-4 mt-4 text-center">
              <div class="icon color-blue round-icon"><i class="fas fa-whatsapp"></i></div>
              <div class="text">WhatsApp</div>
            </div>
          </div>

        </div>
        <!-- <div *ngIf="more === 'bvn'">
          <div class="padding-regular-x mt-4">
            <button class=" btn btn-outline-secondary btn-xs" (click)="more = 'list'">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <app-bvnmodal [request_id]="requestId" [token]="currentUser.token" [viewOnly]="true"></app-bvnmodal>
        </div>
        <div *ngIf="more === 'card'">
          <div class="padding-regular-x mt-4">
            <button class="btn btn-outline-secondary btn-xs" (click)="more = 'list'">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <app-cardmodal [request_id]="requestId" [token]="currentUser.token"></app-cardmodal>
        </div>
        <div *ngIf="more === 'account'">
          <div class="padding-regular-x mt-4">
            <button class="btn btn-outline-secondary btn-xs" (click)="more = 'list'">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <app-accountmodal [request_id]="requestId" [token]="currentUser.token"></app-accountmodal>
        </div> -->
        <!-- <div *ngIf="more === 'attachment'">
          <div class="padding-regular-x mt-4">
            <button class="btn btn-outline-secondary btn-xs" (click)="more = 'list'">
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
          <app-loanphotos [parentRouteId]="requestId" [where]='2'></app-loanphotos>
        </div> -->
      </div>
    </div>
  </div>
</div>