import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../app/login/login.component';
import { SignupComponent } from '../app/signup/signup.component';
import { PageNotFoundComponent } from '../app/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AuthGuard } from './_guards/auth.guard';
import { RequestpageguardGuard } from './_guards/requestpageguard.guard'; 
import { KudaloginComponent } from './kudalogin/kudalogin.component';
import { EcosystemsComponent } from './others/ecosystems/ecosystems.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TwofaComponent } from './twofa/twofa.component';
import { PrincipaloutstandingComponent } from './principaloutstanding/principaloutstanding.component';
import { LoanreportComponent } from './loanreport/loanreport.component';
import { LoancomparisonComponent } from './loancomparison/loancomparison.component';
import { LoanarrearsageingComponent } from './loanarrearsageing/loanarrearsageing.component';
import { AccrualsComponent } from './accruals/accruals.component';
import { PartnercollectionsComponent } from './partnercollections/partnercollections.component';
import { ClosedpartnerlloansComponent } from './closedpartnerlloans/closedpartnerlloans.component';

const routes: Routes = [
  {
    path: 'system/settings',
    loadChildren: '../app/setting-module/setting-module.module#SettingModuleModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'applications',
    loadChildren: '../app/applications/applications.module#ApplicationsModule',
    data: {
      preload: true
    },
    canActivate: [RequestpageguardGuard]
  },
  {
    path: 'portfolio',
    loadChildren: '../app/applicationportfolio/applicationportfolio.module#ApplicationportfolioModule',
    data: {
      preload: true
    },
    canActivate: [RequestpageguardGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'principaloutstanding', component: PrincipaloutstandingComponent },
  { path: 'loanreport', component: LoanreportComponent },
  { path: 'closedloans', component: ClosedpartnerlloansComponent },
  { path: 'comparison', component: LoancomparisonComponent },
  { path: 'arrearsageing', component: LoanarrearsageingComponent },
  { path: 'accruals', component: AccrualsComponent },
  { path: 'collections', component: PartnercollectionsComponent },
  { path: 'twofa', component: TwofaComponent },
  { path: 'reset', component: ResetPasswordComponent },
  { path: 'kudalogin', component: KudaloginComponent }, 
  { path: 'forgot', component: ForgotComponent },
  { path: 'signup', component: SignupComponent },
  {
    path: 'ecosystems', component: EcosystemsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'password',
    loadChildren: '../app/change-password/change-password.module#ChangePasswordModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'security',
    loadChildren: '../app/security/security.module#SecurityModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: '../app/reports/reports.module#ReportsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'marketplace',
    loadChildren: '../app/marketplace/marketplace.module#MarketplaceModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'operations',
    loadChildren: '../app/operations/operations.module#OperationsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: '../app/settings/settings.module#SettingsModule',
    data: {
      preload: true
    }
  },
  {
    path: 'unauthorized', component: UnauthorizedComponent
  },
  { path: '', redirectTo: 'applications/dashboard', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
