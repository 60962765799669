
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { Component, ViewEncapsulation, OnInit, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService } from '../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';

declare var google: any;

@Component({
  selector: 'app-locationdetails',
  templateUrl: './locationdetails.component.html',
  styleUrls: ['./locationdetails.component.css']
})
export class LocationdetailsComponent implements OnInit {
  @Input() from = '';
  @Input() customer_id = '';
  @Input() requestId;

  endDate = '1561935599000';
  LAT_: number = 51.678418;
  LNG_: number = 7.809007;
  home_lat: number = 6.5612464;
  home_lng: number = 3.3324454;
  office_lat: number = 6.488225;
  office_lng: number = 3.3463717;
  others_lat: number = 6.4866166;
  others_lng: number = 3.3812484;
  latlngBounds: any;
  view: string = 'home';
  showPicture = false;
  home_verification_view: boolean;
  no_home_request = false;
  office_verification_view: boolean;
  no_office_request: boolean;
  others_verification_view: boolean;
  no_others_request: boolean;
  home_data: any;
  office_data: any;
  others_data: any;
  office_result: any;
  home_result: any;
  others_result: any;
  home_address: string;
  office_address: string;
  office_img_arr: any;
  home_img_arr: any;
  others_img_arr: any;
  home_notes_arr: any;
  office_notes_arr: any;
  others_notes_arr: any;
  others_address: string;
  currentUser: any;
  parentRouteId: any;
  sub: any;
  loan = {
    'home_address': '',
    'work_address': ''
  };
  request_id: any;
  text = 'KYC';
  loading = true;
  previewSrc = '';
  kycs: any;
  constructor(
    private dataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public router: Router, private http: HttpClient,
    private __loader: MapsAPILoader,
    private location: Location
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.kycRequestFinished.subscribe(res => {
      this.getSummary();
    });
  }

  ngOnInit() {
    if (!this.from) {
      this.getSummary();
    } else if (this.from === 'drawer') {
      this.getSummary();
    } else {
      this.getCustomerKYCs()
    }


  }
  sendVal(val) {
    return parseFloat(val)
  }
  onChange(event) {
    this.view = event.target.value;
  }
  getHoursLeftFromNow(from) {
    from = new Date(from);
    const now = new Date(Date.now());
    return Math.floor((from - (+now)) / (1000 * 60 * 60))
  }
  getSummary() {
    this.loading = true;
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'] || this.requestId;
      this.request_id = +params['id'];
      //  this.getLoan();
      this.getKycRequest();
    });

  }
  getLoan() {
    this.loansService.getLoanForCustomer(this.currentUser.token, this.parentRouteId || this.requestId)
      .subscribe(data => {
        this.loan = data.loan;
        //this.dataService.onRequestLoadingEndFromBackend.emit({ loan: this.loan });
        //this.getKycRequest();
      });
  }
  doingDisbursement(event) {
    event.new_loan = this.loan;
    this.dataService.disbursingRequest.emit(event);
  }
  openModal(section) {
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId || this.requestId, loan: this.loan });
  }
  requestKYC(type) {
    this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_kyc_mod', request_id: this.parentRouteId || this.requestId, kyc_type_id: type });
  }
  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }
  showProfilePic(pic) {
    this.dataService.showProfilePic.emit({ pic: pic });
  }
  getCustomerKYCs() {
    this.loading = true;
    this.loansService.getCustomerKYcs(this.currentUser.token, this.customer_id)
      .subscribe(data => {
        this.kycs = data.data;
        this.loading = false;
      });
  }
  // getKycRequest() {
  //   this.loading = true;
  //   this.operationsService.getLoanKyc(this.currentUser.token, this.parentRouteId || this.requestId)
  //     .subscribe(res => {
  //       this.loading = false;
  //       this.loan.home_address = res.loan.home_address;
  //       this.loan.work_address = res.loan.work_address;
  //       if (res.status == true) {
  //         if (res.data.home.is_available == true) {
  //           this.home_data = res.data.home.data;
  //           if (this.home_data.verification.KYC_STATUS == '2') {
  //             this.home_verification_view = true;
  //             this.home_result = this.home_data.verification;
  //             this.home_img_arr = this.home_data.images.data;
  //             this.home_notes_arr = this.home_result.NOTES;
  //             try {
  //               this.__loader.load().then(() => {
  //                 const geocoder = new google.maps.Geocoder();
  //                 geocoder.geocode(
  //                   { address: this.home_result.GEO_TAGGED_ADDRESS },
  //                   (results, status) => {
  //                     if (status === google.maps.GeocoderStatus.OK) {
  //                       const place = results[0].geometry.location;
  //                       this.home_lat = place.lat();
  //                       this.home_lng = place.lng();
  //                     }
  //                   }
  //                 );
  //               });
  //             } catch (error) {
  //               //console.log(error);
  //             }
  //           } else {
  //             this.home_verification_view = false;
  //             this.home_data = res.data.home.data;
  //             this.home_address = this.home_data.verification.ADDRESS;
  //             this.home_data = res.data.home.data;
  //           }
  //         } else {
  //           this.no_home_request = true;
  //         }
  //         if (res.data.office.is_available == true) {
  //           this.office_data = res.data.office.data;
  //           if (this.office_data.verification.KYC_STATUS == '2') {
  //             this.office_verification_view = true;
  //             this.office_result = this.office_data.verification;
  //             this.office_img_arr = this.office_data.images.data;
  //             this.office_notes_arr = this.office_result.notes;
  //             try {
  //               this.__loader.load().then(() => {
  //                 const geocoder = new google.maps.Geocoder();
  //                 geocoder.geocode(
  //                   { address: this.office_result.GEO_TAGGED_ADDRESS },
  //                   (results, status) => {
  //                     if (status === google.maps.GeocoderStatus.OK) {
  //                       const place = results[0].geometry.location;
  //                       this.office_lat = place.lat();
  //                       this.office_lng = place.lng();
  //                     }
  //                   }
  //                 );
  //               });
  //             } catch (error) {
  //               //console.log(error);
  //             }
  //           } else {
  //             this.office_verification_view = false;
  //             this.office_address = this.office_data.verification.ADDRESS;
  //             this.office_data = res.data.office.data;
  //           }

  //         } else {
  //           this.no_office_request = true
  //         }
  //         if (res.data.others.is_available == true) {
  //           this.others_data = res.data.others.data;
  //           if (this.others_data.verification.KYC_STATUS == '2') {
  //             this.others_verification_view = true;
  //             this.others_result = this.others_data.verification;
  //             this.others_img_arr = this.others_data.images.data;
  //             this.others_notes_arr = this.others_result.notes;
  //             try {
  //               this.__loader.load().then(() => {
  //                 const geocoder = new google.maps.Geocoder();
  //                 geocoder.geocode(
  //                   { address: this.others_result.GEO_TAGGED_ADDRESS },
  //                   (results, status) => {
  //                     if (status === google.maps.GeocoderStatus.OK) {
  //                       const place = results[0].geometry.location;
  //                       this.others_lat = place.lat();
  //                       this.others_lng = place.lng();
  //                     }
  //                   }
  //                 );
  //               });
  //             } catch (error) {
  //               //console.log(error);
  //             }
  //           } else {
  //             this.others_verification_view = false;
  //             this.others_address = this.others_data.verification.ADDRESS;
  //           }

  //         } else {
  //           this.no_others_request = true;
  //         }
  //         this.loan.home_address = res.loan.home_address;
  //         this.loan.work_address = res.loan.work_address;
  //       }
  //       //this.getLoan();

  //     });

  // }
  getKycRequest() {
    this.loading = true;
    this.operationsService.getLoanKyc(this.currentUser.token, this.parentRouteId)
      .subscribe(res => {
        this.loading = false;
        this.loan.home_address = res.loan.home_address;
        this.loan.work_address = res.loan.work_address;
        if (res.status == true) {
          this.operationsService.getKYCResult(res.param.request_id, res.param.merchant_id)
            .subscribe(result => {
              this.loading = true;
              this.operationsService.getKYCResultDecoded(this.currentUser.token, this.parentRouteId, result)
                .subscribe(result => {
                  this.loading = false;
                  if (result.data.home.is_available == true) {
                    this.home_data = result.data.home.data;
                    if (this.home_data.verification.KYC_STATUS == '2') {
                      this.home_verification_view = true;
                      this.home_result = this.home_data.verification;
                      this.home_img_arr = this.home_data.images.data;
                      this.home_notes_arr = this.home_result.NOTES;
                      try {
                        this.__loader.load().then(() => {
                          const geocoder = new google.maps.Geocoder();
                          geocoder.geocode(
                            { address: this.home_result.GEO_TAGGED_ADDRESS },
                            (results, status) => {
                              if (status === google.maps.GeocoderStatus.OK) {
                                const place = results[0].geometry.location;
                                this.home_lat = place.lat();
                                this.home_lng = place.lng();
                              }
                            }
                          );
                        });
                      } catch (error) {
                        //console.log(error);
                      }
                    } else {
                      this.home_verification_view = false;
                      this.home_data = result.data.home.data;
                      this.home_address = this.home_data.verification.ADDRESS;
                      this.home_data = result.data.home.data;
                    }
                  } else {
                    this.no_home_request = true;

                  }
                  if (result.data.office.is_available == true) {
                    this.office_data = result.data.office.data;
                    if (this.office_data.verification.KYC_STATUS == '2') {
                      this.office_verification_view = true;
                      this.office_result = this.office_data.verification;
                      this.office_img_arr = this.office_data.images.data;
                      this.office_notes_arr = this.office_result.notes;
                      try {
                        this.__loader.load().then(() => {
                          const geocoder = new google.maps.Geocoder();
                          geocoder.geocode(
                            { address: this.office_result.GEO_TAGGED_ADDRESS },
                            (results, status) => {
                              if (status === google.maps.GeocoderStatus.OK) {
                                const place = results[0].geometry.location;
                                this.office_lat = place.lat();
                                this.office_lng = place.lng();
                              }
                            }
                          );
                        });
                      } catch (error) {
                        //console.log(error);
                      }
                    } else {
                      this.office_verification_view = false;
                      this.office_address = this.office_data.verification.ADDRESS;
                      this.office_data = result.data.office.data;
                    }

                  } else {
                    this.no_office_request = true
                  }
                  if (result.data.others.is_available == true) {
                    this.others_data = res.data.others.data;
                    if (this.others_data.verification.KYC_STATUS == '2') {
                      this.others_verification_view = true;
                      this.others_result = this.others_data.verification;
                      this.others_img_arr = this.others_data.images.data;
                      this.others_notes_arr = this.others_result.notes;
                      try {
                        this.__loader.load().then(() => {
                          const geocoder = new google.maps.Geocoder();
                          geocoder.geocode(
                            { address: this.others_result.GEO_TAGGED_ADDRESS },
                            (results, status) => {
                              if (status === google.maps.GeocoderStatus.OK) {
                                const place = results[0].geometry.location;
                                this.others_lat = place.lat();
                                this.others_lng = place.lng();
                              }
                            }
                          );
                        });
                      } catch (error) {
                        //console.log(error);
                      }
                    } else {
                      this.others_verification_view = false;
                      this.others_address = this.others_data.verification.ADDRESS;
                    }

                  } else {
                    this.no_others_request = true;
                  }
                  this.loan.home_address = result.loan.home_address;
                  this.loan.work_address = result.loan.work_address;


                });

            });


        }
        //this.getLoan();

      });

  }
  goBack() {
    this.location.back();
  }
}
