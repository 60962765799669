import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface Notification {
  status: boolean;
  response: Array<Object>;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  getUnreadMessages(data: object): Observable<any> {
    return this.http.post('https://eligibilitytest.creditclan.com/public/readmessages', data, httpOptions);
  }

  markMessageAsRead(data: object): Observable<any> {
    return this.http.post('https://eligibilitytest.creditclan.com/public/updatereadmessages', data, httpOptions);
  }
  
  readMessages(data: object): Observable<any> {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/readmessages`, data);
  }
}
