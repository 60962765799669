<!-- <div class="panel" [ngClass]="{'padding-regular': show_header, 'padding-regular-small bg-off-white': !show_header}">
    <div class="flex flex-1">
        <div class="flex-1 flex-center" (click)="is_individual = true">
            <div class="icon-text medium cursor-pointer" [ngClass]="{ 'active': is_individual }">
                <div class="text">Individual</div>
            </div>
        </div>
        <div class="flex-1 flex-center" (click)="is_individual = false">
            <div class="icon-text medium cursor-pointer" [ngClass]="{ 'active': !is_individual }">
                <div class="text">Business</div>
            </div>
        </div>
    </div>
</div> -->

<div *ngIf="customer && !is_individual">
    <!-- Company Information -->
    <div class="panel box edged_box mb-1">
        <div class="panel-body padding-regular">
            <div class="flex">
                <div class="flex-3 mb-2 mr-4" *ngIf="editing !== 'company_profile'">
                    <ul class="decList bigger">
                        <li><span class="title">Company Profile</span>
                            <span *ngIf="loan.loan_status != '3'">
                                <button class="btn btn-outline-secondary btn-xs" (click)="editing = 'company_profile'">
                                    <i class="fa fa-edit mr-2 no-padding"></i>Edit</button>
                            </span>
                        </li>
                        <li><span>Company Name</span>
                            <span>{{customer?.company_profile?.company_name || 'Not provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxx</span>
                        </li>
                        <li><span>Company Email</span>
                            <span *ngIf="!loading">{{customer?.company_profile?.company_email || 'Not provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxx</span>

                        </li>
                        <li><span>Company Website</span>
                            <span *ngIf="!loading">{{customer?.company_profile?.company_website || 'Not
                                provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx</span>
                        </li>
                        <li><span>TIN</span>
                            <span *ngIf="!loading">{{customer?.company_profile?.tin || 'Not provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxx</span>
                        </li>
                        <li><span>RC Number</span>
                            <span *ngIf="!loading">{{customer?.company_profile?.rc_number || 'Not provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Business
                                Sector</span><span *ngIf="!loading">{{customer?.company_profile?.business_sector || 'Not
                                provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxx</span>
                        </li>
                        <li><span>No of
                                Employees</span><span *ngIf="!loading">{{customer?.company_profile?.no_of_employees ||
                                'Not provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Start
                                Month</span><span *ngIf="!loading">{{customer?.company_profile?.business_start_month ||
                                'Not provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Start
                                Year</span><span *ngIf="!loading">{{customer?.company_profile?.business_start_year ||
                                'Not provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Contact
                                Person</span><span *ngIf="!loading">{{customer?.company_profile?.contact_person || 'Not
                                provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Contact Phone
                                Number</span><span
                                *ngIf="!loading">{{customer?.company_profile?.contact_person_phone_number || 'Not
                                provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                    </ul>
                    <div class="mb-2">
                        <ul class="decList bigger">
                            <li><span class="title">Company Address</span></li>
                            <li><span>Address</span><span class="pull-right width_1_2 text-right"
                                    *ngIf="!loading">{{customer?.business_address?.address || 'Not provided'}}</span>
                                <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>State</span>
                                <span *ngIf="!loading">{{customer?.business_address?.home_state_text || 'Not
                                    provided'}}/{{customer?.business_address?.home_lga_text || 'Not provided'}}</span>
                                <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Type</span><span *ngIf="!loading">{{customer?.business_address?.address_type_text
                                    || 'Not provided'}}</span>
                                <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Time at current address</span><span
                                    *ngIf="!loading">{{customer?.business_address?.time_at_current_address || 'Not
                                    provided'}}</span>
                                <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>


                        </ul>
                    </div>
                    <app-button *ngIf="loan.loan_status != '3'"
                        (click)="requestForData('Company Profile Information',-8)" class="small outline mt-3"
                        label="Request for data"></app-button>
                </div>

                <div class="section-poster">
                    <div class="image flex-center">
                        <img src="assets/images/personal-information.svg">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="panel bg-off-white" *ngIf="embeded">
        <div class="padding-regular flex justify-between align-center">
            <div class="user">
                <div class="image">
                    <img
                        src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
                </div>
                <div class="content" *ngIf="loan?.customer?.account_officer !=''">
                    <h4 class="title color-teal">{{loan?.customer?.account_officer?.ACCOUNT_OFF}}</h4>
                    <p class="subtitle">Principal account officer</p>
                </div>
                <div class="content" *ngIf="loan?.customer?.account_officer ==''">
                    <h4 class="title color-teal">No officer assigned</h4>
                </div>
            </div>
            <app-button (click)="openModalChild.emit('assign-account-officer')"
                *ngIf="loan?.customer?.account_officer ==''" class="medium outline block" label="Assign Officer"
                type="button"></app-button>
            <app-button (click)="openModalChild.emit('account-officer')" *ngIf="loan?.customer?.account_officer!=''"
                class="medium outline block" label="Change Officer" type="button"></app-button>
        </div>
    </div>
    <!-- Eligibility Check -->
    <!-- <div class="panel bg-off-white" *ngIf="embeded">
        <div class="alert">
            <div class="texts pl_0">
                <h4 class="title color-red">Eligibility Check</h4>
                <div class="subtitle">Run eligibility check on this customer</div>
            </div>
            <div class="action">
                <app-button class="outline small danger" label="Run" (click)="openModalChild.emit('eligibility')">
                </app-button>
            </div>
        </div>
    </div> -->

    <!-- Analyze bank statement -->
    <div class="panel padding-regular" *ngIf="embeded">
        <div class="flex align-center mt-5 mb-5">
            <div class="image flex-center justify-start mr-4">
                <img src="assets/images/form.svg" style="max-width: 100px;">
            </div>
            <div class="h-group flex-1 ml-4">
                <h3 class="title no-margin">Analyze bank statement</h3>
                <p class="subtitle fill-w mb-2">
                    Run bank statement analysis on this customer, view detailed account breakdown and more
                </p>
                <app-button class="small outline orange mt-2" label="Run analysis"
                    (click)="openModalChild.emit('bankstatement')"></app-button>
            </div>
        </div>
    </div>
    <div class="panel padding-regular">
        <div class="flex align-center mt-5 mb-5">
            <div class="image flex-center justify-start mr-4">
                <img src="assets/images/form.svg" style="max-width: 100px;">
            </div>
            <div class="h-group flex-1 ml-4">
                <h3 class="title no-margin">Tokenize customer account</h3>
                <p class="subtitle fill-w mb-2">
                    Tokenize customer bank card, direct debit mandate
                </p>
                <app-button class="small outline orange mt-2" label="Send Request"
                    (click)="openModalChild.emit('tokennization')"></app-button>
            </div>
        </div>
    </div>
    <!-- Attachment -->
    <app-attachments-card [reqid]="reqid"  *ngIf="embeded  && loan.status < 3" (openModal)="openModalChild.emit($event)">
    </app-attachments-card>


    <!-- Collection -->
    <!-- <div class="panel bg-off-white" *ngIf="embeded">
        <div class="alert">
            <div class="texts pl_0">
                <h4 class="title color-orange">View collections</h4>
            </div>
            <div class="action">
                <app-button class="outline small orange" label="View" (click)="setMore.emit('Recollection')">
                </app-button>
            </div>
        </div>
    </div> -->
    <!-- <app-recollectionoptions [requestId]="reqid" [loan]="loan" [embedModal]="true"
        (openModalChild)="openModalChild.emit($event)">
    </app-recollectionoptions> -->

    <!-- Account and Cards -->
    <!-- <app-accountandcards-card [loan]="loan" [from_loan]="from_loan" [pid]="pid" [reqid]="reqid"
        (open)="openModalChild.emit($event)">
    </app-accountandcards-card> -->

    <app-bvndetails-card [reqid]="reqid" [cid]="cid" (more)="openModalChild.emit($event)"></app-bvndetails-card>

    <!-- Analysis Shortcut -->
    <div class="panel padding-regular grid four" *ngIf="embeded">
        <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('creditbureau')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-orange">
                    <i class="fas fa-coins"></i>
                    <span class="check" *ngIf="checklist?.creditbureau"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Credit Bereau</div>
            </div>
        </a>
        <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('avs')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-blue">
                    <i class="fas fa-address-book"></i>
                    <span class="check" *ngIf="checklist?.avs"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Address verification</div>
            </div>
        </a>
        <!-- <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('analytics')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-teal">
                    <i class="fas fa-chart-line"></i>
                    <span class="check" *ngIf="checklist?.advanced_analytics"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Advance analytics</div>
            </div>
        </a> -->
        <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('nanocreditbureau')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-teal">
                    <i class="fas fa-chart-line"></i>
                    <span class="check" *ngIf="checklist?.nanocreditcheck"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Nano Credit Check</div>
            </div>
        </a>

        <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('identity')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-red">
                    <i class="fas fa-cog"></i>
                    <span class="check" *ngIf="checklist?.blockchain"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Identity check</div>
            </div>
        </a>
    </div>
    <!-- Income Informatoin -->
    <div class="panel box edged_box mb-1">
        <div class="panel-body padding-regular">
            <div class="flex">
                <div class="section-poster">
                    <div class="image flex-center">
                        <!--<img src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.customer?.profile_pic | imagenotfound}}">-->
                        <img src="assets/images/wallet.svg">
                    </div>
                </div>
                <div class="flex-3 ml-3 mb-2" *ngIf="editing !== 'income'">
                    <ul class="decList bigger">
                        <li><span class="title">Business Income</span>
                            <span *ngIf="loan.loan_status != '3'">
                                <button class="btn btn-outline-secondary btn-xs" (click)="editing = 'income'">
                                    <i class="fa fa-edit mr-2 no-padding"></i>Edit</button>
                            </span>
                        </li>
                        <li><span>Avg monthly sales</span><span
                                *ngIf="!loading">{{customer?.business_income?.average_monthly_sales_income | number:
                                ".2-2" || 'Not provided'}}</span><span class="skeleton"
                                *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Avg income from services</span><span
                                *ngIf="!loading">{{customer?.business_income?.average_income_from_services | number:
                                ".2-2" || 'Not provided'}}</span><span class="skeleton"
                                *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Avg income from other services</span><span
                                *ngIf="!loading">{{customer?.business_income?.average_income_from_other_services |
                                number: ".2-2" || 'Not provided'}}</span><span class="skeleton"
                                *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Gross monthly income</span><span
                                *ngIf="!loading">{{customer?.business_income?.gross_monthly_income | number: ".2-2" ||
                                'Not provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Avg cost of goods sold</span><span
                                *ngIf="!loading">{{customer?.business_income?.average_cost_of_goods_sold | number:
                                ".2-2" || 'Not provided'}}</span><span class="skeleton"
                                *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Direct labour costs</span><span
                                *ngIf="!loading">{{customer?.business_income?.direct_labour_costs | number: ".2-2" ||
                                'Not provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span>
                        </li>
                        <li><span>Commissions</span><span *ngIf="!loading">{{customer?.business_income?.commissions |
                                number: ".2-2" || 'Not provided'}}</span><span class="skeleton"
                                *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                xxxxxxxxxxxxxxxxxxx</span></li>
                    </ul>
                    <div class="mb_10">
                        <ul class="decList bigger">
                            <li><span class="title">Operating Expense</span></li>
                            <li><span>Bank Loans</span><span *ngIf="!loading">{{customer?.operating_expenses?.bank_loans
                                    || 'Not provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx
                                    %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Salaries / Wages</span><span
                                    *ngIf="!loading">{{customer?.operating_expenses?.salaries_and_wages || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Assets</span><span *ngIf="!loading">{{customer?.operating_expenses?.assets || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span></li>
                            <li><span>Insurance</span><span *ngIf="!loading">{{customer?.operating_expenses?.insurance
                                    || 'Not provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx
                                    %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Car / Transport</span><span
                                    *ngIf="!loading">{{customer?.operating_expenses?.car_and_transport || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Office Rent</span><span
                                    *ngIf="!loading">{{customer?.operating_expenses?.office_rent || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Power / Fuel</span><span
                                    *ngIf="!loading">{{customer?.operating_expenses?.power_and_fuel || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span></li>
                            <li><span>Maintenance Expense</span><span
                                    *ngIf="!loading">{{customer?.operating_expenses?.maintenance_expense || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Security</span><span *ngIf="!loading">{{customer?.operating_expenses?.security ||
                                    'Not provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Phone / Communication</span><span
                                    *ngIf="!loading">{{customer?.operating_expenses?.phone_and_communication || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span></li>
                            <li><span>Other Expenses</span><span
                                    *ngIf="!loading">{{customer?.operating_expenses?.other_expenses || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                        </ul>
                    </div>
                    <app-button *ngIf="loan.loan_status != '3'" (click)="requestForData('Operating Expenses',-11)"
                        class="small outline mt-3" label="Request for data"></app-button>
                </div>
            </div>
        </div>
    </div>

    <!-- Director -->
    <div class="panel box decSection edged_box mb_10">
        <div class="panel-body padding-regular flex justify-between align-center">
            <div class="user" *ngFor="let director of customer?.directors">
                <div class="image">
                    <img
                        src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
                </div>
                <div class="content">
                    <h4 class="title">{{director?.name}}</h4>
                    <p class="subtitle">{{director?.phone}} | {{director?.email}}</p>
                    <p class="subtitle">{{director?.bvn}}</p>
                </div>

            </div>
            <div class="action">{{director?.equity}}</div>
        </div>
    </div>
    <!-- <app-pastloans [reqid]="reqid" [people_id]="pid" *ngIf="embeded"></app-pastloans> -->

    <!-- <app-total-investments [people_id]="loan?.people_id"></app-total-investments> -->
</div>

<div *ngIf="customer && is_individual">
    <!-- Bio Information -->
    <div class="panel">
        
        <div class="panel-body padding-regular">
            <div class="flex">
                <div class="section-poster">
                    <div class="image flex-center">
                        <img src="assets/images/personal-information.svg">
                    </div>
                </div>
                <!-- <div class="flex-3 mb-2 ml-4" *ngIf="editing !== 'bio'">
                    <h3 class="section-title flex align-center justify-between mb-4">
                        Bio Information
                        <span>
                            <button class="btn btn-outline-danger btn-xs" (click)="editing = 'bio'">Details</button>
                        </span>
                    </h3>
                    <ul class="decList bigger">
                        <li><span class="text-primary analysis_text">Name match
                                across all available channels</span>

                            <span><i class="fa fa-times color-red"></i></span>

                        </li>
                        <li><span class="text-primary analysis_text">Date of birth
                                match across all channels</span>

                            <span><i class="fa fa-times color-red"></i></span>

                        </li>
                        <li><span class="text-primary analysis_text">Phone match
                                across all channels</span>

                            <span><i class="fa fa-times color-red"></i></span>

                        </li>
                        <li><span class="text-primary analysis_text">Gender match
                                across all channels</span>
                            <span><i class="fa fa-check color-green"></i></span>


                        </li>
                        <li><span class="text-primary analysis_text">BVN match
                                across all channels</span>


                            <span><i class="fas fa-question-circle"></i></span>
                        </li>
                        <li><span class="text-primary analysis_text">State of
                                origin match across all channels</span>
                            <span><i class="fa fa-check color-green"></i></span>


                        </li>
                        <li><span class="text-primary analysis_text">Home state
                                match across all channels</span>
                            <span><i class="fa fa-check color-green"></i></span>


                        </li>
                        <li><span class="text-primary analysis_text">Age match
                                across all channels</span>
                            <span><i class="fa fa-check color-green"></i></span>


                        </li>
                    </ul>

                </div> -->
                <!-- <div class="flex-3 mb-2 ml-4" *ngIf="editing === 'bio'"> -->
                <div class="flex-3 mb-2 ml-4">
                    <h3 class="section-title flex align-center justify-between mb-4">
                        Bio Information
                        <!-- <button *ngIf="loan.loan_status != '3'" class="btn btn-outline-secondary btn-xs"
                            (click)="editing = ''">
                            <i class="fa fa-edit mr-2 no-padding"></i>Summary</button> -->
                    </h3>
                    <ul class="decList bigger">
                        <li>
                            <span>Name</span>
                            <span *ngIf="!loading">{{customer?.profile?.legal_name || 'Not provided'}}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                        </li>
                        <li>
                            <span>Bio</span>
                            <span *ngIf="!loading">
                                {{customer?.profile?.gender == 0 ? 'Male' : 'Female' }} |
                                {{customer?.profile?.marital_status|maritalstatus}} |
                                {{ getAge(customer?.profile?.date_of_birth) }} years old
                            </span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                        </li>
                        <li>
                            <span>Contact</span>
                            <span *ngIf="!loading">{{customer?.profile?.phone }} | {{ customer?.profile?.email }}</span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                        </li>
                        <li><span>Address</span>
                            <span *ngIf="!loading">
                                {{ customer?.home_address?.home_address }}, {{ customer?.home_address?.home_lga_text }},
                                {{ customer?.home_address?.home_state_text }},
                                {{ customer?.home_address?.home_country_text }}
                            </span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                        </li>
                        <li *ngIf="customer?.home_address?.resident_years"><span>No. of years</span>
                            <span *ngIf="!loading">
                                {{ customer?.home_address?.resident_years }} year(s)
                            </span>
                            <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                        </li>

                    </ul>


                </div>
            </div>
        </div>
    </div>


    <!-- Work Information -->
    <div class="panel padding-regular flex">
        <div class="flex width_0 vertical-center" style="flex-grow: 1">
            <div class="h-group">
                <h4 class="title color-teal" *ngIf="!loading" style="font-size: 1.8rem;">
                    N{{customer?.work?.net_monthly_income | number: "0.0"}}</h4>
                <p class="subtitle no-margin fill-w" *ngIf="!loading">Monthly Income</p>
                <h4 class="title color-teal skeleton mb-1" *ngIf="loading">0000</h4>
                <p class="subtitle no-margin fill-w skeleton" *ngIf="loading">Lorem, ipsum dolor.</p>
            </div>
        </div>
        <!-- <div class="flex-3 ml-3 mb-2" *ngIf="editing !== 'work'">
            <h3 class="section-title flex align-center justify-between mb-4">
                Work / Income Summary
                <button   class="btn btn-outline-secondary btn-xs" (click)="editing = 'work'">
                    <i class="fa fa-edit mr-2 no-padding"></i>Details</button>
            </h3>
            <div class="mb_10">
                <ul class="decList bigger">


                    <li><span class="text-primary analysis_text">Consistent salary
                            date</span>
                        <span><i class="fa fa-check color-green"></i></span>


                    </li>
                    <li><span class="text-primary analysis_text">Consistent salary
                            amount</span>
                        <span><i class="fa fa-check color-green"></i></span>


                    </li>
                     
                </ul>
            </div>

        </div> -->
        <!-- <div class="flex-3 ml-3 mb-2" *ngIf="editing === 'work'"> -->
            <div class="flex-3 ml-3 mb-2">
            <h3 class="section-title flex align-center justify-between mb-4">
                Work / Income Summary
                <!-- <button   class="btn btn-outline-secondary btn-xs" (click)="editing = ''">
                    <i class="fa fa-edit mr-2 no-padding"></i>Summary</button> -->
            </h3>
            <div class="mb_10">
                <ul class="decList bigger">
                    <li>
                        <span>Company</span>
                        <span *ngIf="!loading">{{customer?.work?.company_of_work_id || 'Not provided'}}</span>
                        <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                    </li>
                    <li><span>Sector / Role</span>
                        <span *ngIf="!loading">
                            {{ customer?.work?.work_sector_text }} | {{ customer?.work?.designation_text }} |
                            {{customer?.work?.work_start_date }} - {{customer?.work?.work_end_date}}
                        </span>
                        <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                    </li>
                    <li><span>Work
                            Address</span><span *ngIf="!loading">{{customer?.work?.work_address || 'Not
                            provided'}}</span>
                        <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                    <li>
                        <span>Work Email</span>
                        <span *ngIf="!loading">
                            <span class="mr-4">{{customer?.work?.work_email || 'Not provided'}}</span>
                            
                        </span>
                        <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <!-- Eligibility Check -->
    <!-- <div class="panel bg-off-white" *ngIf="embeded && loan?.eligibility_analysis">
        <div class="alert">
            <div class="texts pl_0">
                <h4 class="title color-red">Eligibility Check</h4>
                <div class="subtitle">View eligibility check on this customer</div>
            </div>
            <div class="action">
                <app-button class="outline small danger" label="View" (click)="openModalChild.emit('profileanalysis')">
                </app-button>
            </div>
        </div>
    </div> -->

    <!-- Analyze bank statement -->
    <!-- <div class="panel padding-regular">
        <div class="flex align-center mt-5 mb-5">
            <div class="image flex-center justify-start mr-4">
                <img src="assets/images/form.svg" style="max-width: 100px;">
            </div>
            <div class="h-group flex-1 ml-4">
                <h3 class="title no-margin">Analyze bank statement</h3>
                <p class="subtitle fill-w mb-2">
                    Run bank statement analysis on this customer, view detailed account breakdown and more
                </p>
                <app-button class="small outline orange mt-2" label="Run analysis"
                    (click)="openModalChild.emit('bankstatement')"></app-button>
            </div>
        </div>
    </div>
    <div class="panel padding-regular">
        <div class="flex align-center mt-5 mb-5">
            <div class="image flex-center justify-start mr-4">
                <img src="assets/images/form.svg" style="max-width: 100px;">
            </div>
            <div class="h-group flex-1 ml-4">
                <h3 class="title no-margin">Tokennize customer account</h3>
                <p class="subtitle fill-w mb-2">
                    Tokenize customer bank card, direct debit mandate
                </p>
                <app-button class="small outline orange mt-2" label="Send Request"
                    (click)="openModalChild.emit('tokennization')"></app-button>
            </div>
        </div>
    </div> -->

    <!-- Attachment -->
    <app-attachments-card [reqid]="reqid" *ngIf="embeded" (openModal)="openModalChild.emit($event)">
    </app-attachments-card>

    <!-- Collection -->
    <!-- <div class="panel bg-off-white" *ngIf="embeded">
        <div class="alert">
            <div class="texts pl_0">
                <h4 class="title color-orange">View collections</h4>
            </div>
            <div class="action">
                <app-button class="outline small orange" label="View" (click)="setMore.emit('Recollection')">
                </app-button>
            </div>
        </div>
    </div> -->
    <!-- <app-recollectionoptions [requestId]="reqid" [loan]="loan" [embedModal]="true"
        (openModalChild)="openModalChild.emit($event)">
    </app-recollectionoptions> -->

    <!-- Account and Cards -->
    <app-accountandcards-card [loan]="loan" [from_loan]="from_loan" [pid]="pid" [reqid]="reqid"
        (open)="openModalChild.emit($event)">
    </app-accountandcards-card>

    <app-bvndetails-card [reqid]="reqid" [cid]="cid" (more)="openModalChild.emit($event)"></app-bvndetails-card>

    <!-- Analysis Shortcut -->
    <div class="panel padding-regular grid four" *ngIf="embeded  && loan.status < 3">
        <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('creditbureau')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-orange">
                    <i class="fas fa-coins"></i>
                    <span class="check" *ngIf="checklist?.creditbureau"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Credit Bereau</div>
            </div>
        </a>
        <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('avs')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-blue">
                    <i class="fas fa-address-book"></i>
                    <span class="check" *ngIf="checklist?.avs"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Address verification</div>
            </div>
        </a>
        <!-- <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('analytics')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-teal">
                    <i class="fas fa-chart-line"></i>
                    <span class="check" *ngIf="checklist?.advanced_analytics"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Advance analytics</div>
            </div>
        </a> -->
        <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('nanocreditbureau')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-teal">
                    <i class="fas fa-chart-line"></i>
                    <span class="check" *ngIf="checklist?.nanocreditcheck"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Nano Credit Check</div>
            </div>
        </a>

        <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('identity')">
            <div class="icon-text medium cursor-pointer">
                <div class="icon color-red">
                    <i class="fas fa-cog"></i>
                    <span class="check" *ngIf="checklist?.blockchain"><i class="fa fa-check"></i></span>
                </div>
                <div class="text">Identity check</div>
            </div>
        </a>
    </div>

    <!-- Identity Information -->
    <div class="panel" *ngIf="customer?.idcard?.name_on_id">
     
        <div class="panel-body padding-regular">
            <h3 class="section-title flex justify-between align-center">Identity Information
                <button class="icon-button ml-2" data-toggle="collapse" data-target="#collapseIdentity"
                    aria-expanded="false" aria-controls="collapseIdentity"><i class="fa fa-chevron-down"></i></button>
            </h3>
            <div class="collapse in" id="collapseIdentity" style="overflow: hidden">
                <div class="flex mt-4">
                    <div class="flex-3 mb-2 mr-4">
                        <ul class="decList bigger">
                            <li>
                                <span>Identity Type</span>
                                <span>{{customer?.idcard?.identity_type || 'Not provided'}}</span>
                                <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                            </li>
                            <li>
                                <span>Name on Id</span>
                                <span>{{customer?.idcard?.name_on_id || 'Not provided'}}</span>
                                <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                            </li>
                            <li>
                                <span>Number</span>
                                <span>{{customer?.idcard?.identity_code || 'Not provided'}}</span>
                                <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                            </li>
                            <li>
                                <span>Expires</span>
                                <span *ngIf="!loading">
                                    {{customer?.idcard?.idyear || 'Not provided'}}/{{customer?.idcard?.idmonth || 'Not
                                    provided'}}
                                </span>
                                <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                            </li>
                        </ul>
                        <!-- <app-button (click)="requestForData('Identity Information',-7)" class="small outline mt-3"
                            label="Request for data"></app-button> -->
                    </div>
                    <div class="section-poster">
                        <div class="image flex-center pointer">
                            <img src="assets/images/identity.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- NOK Information -->
    <div class="panel">
     
        <div class="panel-body padding-regular">
            <h3 class="section-title flex justify-between align-center">NOK Information
                <!-- <div class="flex align-center">
                    <span *ngIf="editing !== 'nok'">
                        <button *ngIf="loan.loan_status != '3'" class="btn btn-outline-info btn-xs"
                            (click)="editing = 'nok'">
                            <i class="fa fa-edit mr-2 no-padding"></i>Edit
                        </button>
                    </span>
                    <span *ngIf="editing === 'nok'">
                        <button class="btn btn-outline-success btn-xs mr-2"
                            (click)="editing = '';saveNok()">Save</button>
                        <button class="btn btn-outline-danger btn-xs" (click)="editing = ''">Cancel</button>
                    </span>
                    <button class="icon-button ml-2" data-toggle="collapse" data-target="#collapseExample"
                        aria-expanded="false" aria-controls="collapseExample"><i
                            class="fa fa-chevron-down"></i></button>
                </div> -->
            </h3>
            <div class="collapse in" id="collapseExample" style="overflow: hidden">
                <div class="flex">
                    <div class="flex-3 mr-3 mt-4" *ngIf="editing !== 'nok'">
                        <ul class="decList bigger">
                            <li><span>Name</span><span *ngIf="!loading">{{customer?.next_of_kin?.nok_name || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Email</span><span *ngIf="!loading">{{customer?.next_of_kin?.nok_email || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Address</span><span *ngIf="!loading">{{customer?.next_of_kin?.nok_address || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Phone</span><span *ngIf="!loading">{{customer?.next_of_kin?.nok_phone || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                            <li><span>Relationship</span><span
                                    *ngIf="!loading">{{customer?.next_of_kin?.nok_relationship || 'Not
                                    provided'}}</span><span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                    xxxxxxxxxxxxxxxxxxx</span>
                            </li>
                        </ul>
                        <app-button *ngIf="loan.loan_status != '3'"
                            (click)="requestForData('Next of Kin Information',-6)" class="small outline mt-3"
                            label="Request for data"></app-button>
                    </div>
                    <div class="flex-3 mr-3 mt-4" *ngIf="editing === 'nok'">
                        <ul class="decList bigger">
                            <li><span>Name</span>
                                <span><input type="text" [(ngModel)]="customer?.next_of_kin.nok_name"></span>
                            </li>
                            <li><span>Email</span>
                                <span><input type="text" [(ngModel)]="customer?.next_of_kin.nok_email"></span>
                            </li>
                            <li><span>Address</span>
                                <span><input type="text" [(ngModel)]="customer?.next_of_kin.nok_address"></span>
                            </li>
                            <li><span>Phone</span>
                                <span><input type="text" [(ngModel)]="customer?.next_of_kin.nok_phone"></span>
                            </li>
                            <li><span>Relationship</span>
                                <span>
                                    <select name="nok_relationship"
                                        [(ngModel)]="customer?.next_of_kin.nok_relationship">
                                        <option value="spouse">Spouse</option>
                                        <option value="sibling">Sibling</option>
                                        <option value="child">Child</option>
                                        <option value="relative">Relative</option>
                                        <option value="friend">Friend</option>
                                    </select>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="section-poster">
                        <div class="image flex-center">
                            <img src="assets/images/team-management.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>

    <!-- Request Location -->
    <!-- <div class="panel bg-off-white">
        <div class="alert">
            <div class="texts pl_0">
                <h4 class="title color-red">View request location</h4>
                <div class="subtitle">Find out where the request came from</div>
            </div>
            <div class="action">
                <app-button class="outline small danger" label="View" (click)="openModalChild.emit('loanlocation')">
                </app-button>
            </div>
        </div>
    </div> -->

    <!-- Checklist -->
    <!-- <div class="panel bg-off-white" *ngIf="embeded">
        <div class="flex justify-between align-center">
            <div class="alert">
                <div class="icon">
                    <i class="fa fa-list"></i>
                </div>
                <div class="texts">
                    <h4 class="title color-teal">View checklist</h4>
                </div>
                <div class="action">
                    <app-button class="outline small blue" label="View" (click)="openModalChild.emit('checklist')">
                    </app-button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="is_individual" class="mt-3">
        <app-loanchecklist [loan]="loan" class="flex-1" [where]="2" [parentRouteId]="reqid"></app-loanchecklist>
    </div> -->
    


    
</div>

<div *ngIf="!customer">
    <div class="panel mb-1 no-padding">
        <div class="alert padding-regular bg-off-white">
            <div class="texts">
                <div class="title skeleton mb-1">This is a sample text</div>
                <p class="subtitle skeleton">This is a sample subtext</p>
            </div>
            <div class="action flex flex-column align-end">
                <p class="default-text-size mb-1 skeleton">Sample date</p>
                <p class="default-text-size no-margin skeleton">Sample loan count</p>
            </div>
        </div>
    </div>
</div>

