import { Component, OnInit, Input } from '@angular/core';
import { StorageService, LoansService, AuthenticationService } from '../../_services/index';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  @Input('request_id') request_id: any;
  @Input('where') where = '1';
  @Input('from_investment') from_investment = false;
  sub: any;
  currentUser: any;
  loading = false;
  timeline: any;
  subb: any;
  vendor_id: any;
  staffs: any;
  task_enddate: any;
  adding_notes = false;
  adding_task = false;
  staffNames = [];
  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '350px',
    minHeight: '350px',
    placeholder: 'Enter your text here',
    translate: 'no',
    width: '100%',
    minWidth: '100%'
  };
  notes = {
    CONTENT: ''
  };


  tasks = {
    TASK: '',
    TASK_END_DATE: '',
    STAFFID: [],
    REQUESTID: '',
    TASKSTATUS: ''
  };


  public staffList = [
    { 'name': 'Oladimeji Ajeniya', 'id': 1 },
    { 'name': 'Kunle Adeolu', 'id': 0 },
    { 'name': 'Ikenna Himona', 'id': 2 },
    { 'name': 'Musa AbdulFatai', 'id': 3 }
  ];

  constructor(private storageService: StorageService,
    private loansService: LoansService,
    public router: Router,
    public toastr: ToastrService,
    public route: ActivatedRoute, private authService: AuthenticationService) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.request_id = route.snapshot.parent.params['id'];
    const vendorIdData = localStorage.getItem('platform');
    const userdata = JSON.parse(vendorIdData);
    this.vendor_id = userdata.PEOPLE_ID;
  }

  ngOnInit() {
    if(this.from_investment==true){
      this.getInvestmentRequestTimeline();
    }else{
      this.getRequestTimeline();

    // this.loansService.getStaff(this.currentUser.token, this.vendor_id)
    //   .subscribe(result => {
    //     this.staffs = result;
    //   });
    }
  }
  grantRight(right: String) {
    return this.authService.userHasRights(right);
  }
  getInvestmentRequestTimeline(){
    this.sub = this.route.params.subscribe(params => {
      this.request_id = +params['id'];
      this.loansService.getInvestmentRequestTimeline(this.currentUser.token, this.request_id)
        .subscribe(result => {
          if (result.status === true) {
            this.timeline = result.data;
          }
        });
    });
  }
  getRequestTimeline() {
    return this.loansService.getRequestTimeline(this.currentUser.token, this.request_id)
          .subscribe(result => {
            if (result.status === true) {
              this.timeline = result.data;
            }
          });
  }
  saveNotes(value, valid) {
    this.loading = true;
    this.loansService.saveNotes(this.currentUser.token, this.request_id, this.notes)
      .subscribe(result => {
        this.loading = false;
        if (result.status === true) {
          this.adding_notes = false;
          this.getRequestTimeline();
        }
      });
  }

  preferredstaff(event) {
    if (!this.tasks.STAFFID.includes(event.target.value)) {
      this.tasks.STAFFID.push(event.target.value);
    }

    const selectedOptions = event.target['options'];
    const selectedIndex = selectedOptions.selectedIndex;
    const selectElementText = selectedOptions[selectedIndex].text;

    if (!this.staffNames.includes(selectElementText)) {
      this.staffNames.push(selectElementText);
    }

  }

  removedchoosenstaff(id) {
    this.tasks.STAFFID
      .splice(this.tasks.STAFFID.indexOf(id), 1);

    this.staffNames
      .splice(this.staffNames.indexOf(id), 1);

  }

  saveTasks(value, valid) {
    const staff_id = JSON.stringify(this.tasks.STAFFID);
    const task = this.tasks.TASK;
    const task_status = this.tasks.TASKSTATUS;
    const task_end_date = this.tasks.TASK_END_DATE;
    const created_by = localStorage.getItem('user_id');

    this.loading = true;
    this.loansService.addTasks(this.currentUser.token, this.request_id, task,
      this.task_enddate, task_status, created_by, staff_id)
      .subscribe(result => {
        this.loading = false;
        this.toastr.success('Success', 'Tasked added!');
        if (result.status === true) {
          this.adding_task = false;
          this.toastr.error('Error', 'Unable to add task try again!');
          this.tasks = {
            TASK: '',
            TASK_END_DATE: '',
            STAFFID: [],
            REQUESTID: '',
            TASKSTATUS: ''
          };
        }
        this.getRequestTimeline();

      });
  }
}
