import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-queuemodal',
  templateUrl: './queuemodal.component.html',
  styleUrls: ['./queuemodal.component.css'],
  animations: [
    trigger('backdrop', [
      state('void', style({ opacity: 0 })),
      state('visible', style({ opacity: 1 })),
      transition(':enter', animate(300)),
      transition('visible => void', [
        animate(300), style({ display: 'none' })
      ])
    ]),
    trigger('dialogMobile', [
      state('void', style({ transform: 'translateY(100%)', opacity: 0 })),
      state('visible', style({ transform: 'translateY(0', opacity: 1 })),
      transition(':enter', animate('300ms ease-in-out')),
      transition('visible => void', animate('300ms ease-in-out'))
    ]),
    trigger('dialogDesktop', [
      state('void', style({ transform: 'translateX(100%)', opacity: 0 })),
      state('visible', style({ transform: 'translateX(0)', opacity: 1 })),
      transition(':enter', animate('300ms ease-in-out')),
      transition('visible => void', animate('300ms ease-in-out'))
    ]),
  ]
})
export class QueuemodalComponent implements OnInit {

  state = 'visible';
  @Output() close = new EventEmitter<any>();
  @Output() successAction = new EventEmitter<any>();
  @Input() title: string;
  @Input() image: string;
  @Input() successText: string;
  @Input() cancelText: string;
  @Input() size = 'md';
  // tslint:disable-next-line:no-input-rename
  @Input('close-button') closeButton = true;
  @Input() closable = true;
  @Input() fullscreen = false;
  @Input() centered = false;
  @Input() nested = false;
  @Input('confirmation') confirmation = false;
  // tslint:disable-next-line:no-input-rename
  @Input('no-padding') noPadding = false;

  mobile = window.innerWidth < 768;
  subscriptions: Subscription[] = [];
  cbOnClose: (args?) => {} = null;
  cbOnCloseArgs: any[] = null;

  constructor() {
  }

  ngOnInit() {
    if (!this.nested) {
      document.body.style.overflow = 'hidden';
    }
    this.subscriptions.push(
      fromEvent(window, 'resize').subscribe((e: any) => {
        this.mobile = e.target.innerWidth < 768;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  dismiss(cb?: any, bind?: any, args?: any[]) {
    this.state = 'void';
    if (cb) {
      this.cbOnClose = cb.bind(bind);
    }
    if (args) {
      this.cbOnCloseArgs = args;
    }
  }

  done(event) {
    if (event.toState === 'visible') {
      if (!this.nested && document.body.style.overflow !== 'hidden') {
        document.body.style.overflow = 'hidden';
      }
    }
    if (event.toState === 'void') {
      if (!this.nested) {
        document.body.style.overflow = 'auto';
      }
      this.close.emit();
      if (this.cbOnClose) {
        this.cbOnClose(...this.cbOnCloseArgs);
      }
    }
  }
}
