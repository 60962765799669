import { Component, OnInit, Input } from '@angular/core';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';
import { CustomerService, StorageService } from '../../_services/index';
@Component({
  selector: 'app-postingbulkpayments',
  templateUrl: './postingbulkpayments.component.html',
  styleUrls: ['./postingbulkpayments.component.css']
})
export class PostingbulkpaymentsComponent implements OnInit {
  @Input('due_date') due_date = 'today';
  endDate = '1561071540000';
  is_done = false;
  currentUser: any;
  repayments = [];
  loading = false;
  has_data = false;
  searchString = '';
  payment_date = '';
  param = {
    'search_string': '',
    'due_date': '',
    'start': '0'
  }
  adding = true;
  added_requests = [];
  send_payments_to_queue = '0';

  scrollDistance = 1;
  scrollUpDistance = 2;
  throttle = 300;
  page: number = 0;
  completed = false;

  constructor(private operationsService: OperationsService, public storageService: StorageService, private dataService: DataService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.param.due_date = this.due_date;
    this.getDueRepayments(this.page);
  }

  searchDueLoans() {
    this.loading = true;
    this.param.start = "0";
    this.operationsService.getBulkRepayments(this.currentUser.token, this.param)
      .subscribe(result => {
        this.loading = false;
        this.has_data = result.has_data;
        this.repayments = result.message;
        this.send_payments_to_queue = result.send_payments_to_queue;
        this.param.start = result.start;
      });
  }

  getDueRepayments(page) {
    this.param.start = page;
    this.loading = true;
    this.operationsService.getBulkRepayments(this.currentUser.token, this.param, false, page)
      .subscribe(result => {
        this.loading = false;
        this.has_data = result.has_data;
        this.repayments.push(...result.message);
        this.send_payments_to_queue = result.send_payments_to_queue;
        this.completed = !result.has_more;
        this.param.start = result.start;
        this.page++;
      });
  }

  postDuePayments() {
    this.is_done = true;
    this.operationsService.postDuePayments(
      this.currentUser.token,
      this.added_requests,
      this.payment_date
    );
  }

  addRecord(due, i) {
    if (this.added_requests.indexOf(due) == -1) {
      this.repayments[i].checked = true;
      this.added_requests.push(due);
    }

  }

  removeRecord(due, i) {
    if (this.added_requests.indexOf(due) != -1) {
      this.repayments[i].checked = false;
      this.added_requests.splice(due, 1);
    }

  }
  openSidemenuForDueLoan(due) {
    this.dataService.openSidemenuForDueLoan.emit({ due: due });
  }

  onScrollDown() {
    console.log('Scrolled down!');
    if (!this.completed) {
      this.getDueRepayments(this.page);
    }
  }
}
