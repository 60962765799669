import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs';

@Injectable()
export class UserService {
  private onboard = new Subject();
  onboard$ = this.onboard.asObservable();
  public shouldOnboardInterrupt = true;

  constructor() {
  }

  public get kycDone(): string {
    return JSON.parse(localStorage.getItem('kyc_done')) || null;
  }

  showOnboarding() {
    this.onboard.next();
  }
}
