<div class="panel padding-regular-y">
  <div class="filter__wrapper flex align-center justify-between padding-regular-x">
    <div class="section-title fill-w mb-5">Collections</div>
    <!-- <div class="filter_input">
      <input type="text" name="searchTerm" class="form-control search_input" placeholder="Search Here">
      <i class="fa fa-search search_icon" aria-hidden="true"></i>
    </div> -->
  </div>
  <div class="customers">
    <div class="customer" *ngFor="let collection of collections; let i = index">
      <div class="header">
         
        <div class="content">
          <div class="name cursor-pointer">
            ₦{{ collection.AMOUNT_PAID | number: ".2-2" }}
          </div>
          <p>Date: {{ collection.DATE_PAID }}</p>
        </div>
         
      </div>
       
    </div>
  </div>
  <!-- <div class="padding-regular mt-3 text-right">
    <app-button class="small outline teal" label="View all collections"></app-button>
  </div> -->
</div>