<div class="mb-1">
  <div class="panel padding-regular-small">
    <div class="grid four mt-4 mb-2">
      <div *ngIf="!loan?.is_accepted&&loan?.schedule_has_been_created" class="icon-text medium cursor-pointer mb-4"
        (click)="acceptContract()">
        <div class="icon color-green round-icon"><i class="fas fa-clipboard-check"></i></div>
        <div class="text">Accept contract</div>
      </div>
      <!-- <div class="icon-text medium cursor-pointer mb-4" (click)="requestToGetPaid()">
        <div class="icon color-grey round-icon"><i class="fas fa-external-link-square-alt"></i></div>
        <div class="text">Request to get paid</div>
      </div> -->
      <div *ngIf="loan?.is_peer_to_peer&&loan?.loan_status=='1'"
        class="icon-text medium cursor-pointer mb-4" (click)="requestToGetPaid()">
        <div class="icon color-grey round-icon"><i class="fas fa-external-link-square-alt"></i></div>
        <div class="text">Request to get paid</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" (click)="sendBVN()">
        <div class="icon color-grey round-icon"><i class="fas fa-external-link-square-alt"></i></div>
        <div class="text">Send BVN</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" (click)="requestCard()">
        <div class="icon color-grey round-icon"><i class="fas fa-external-link-square-alt"></i></div>
        <div class="text">Request Card</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" (click)="requestOthers()">
        <div class="icon color-grey round-icon"><i class="fas fa-external-link-square-alt"></i></div>
        <div class="text">Request Other Documents</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" (click)="requestGuarantors()">
        <div class="icon color-grey round-icon"><i class="fas fa-external-link-square-alt"></i></div>
        <div class="text">Request Guarantors</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" *ngIf="loan?.schedule_has_been_created"
        (click)="sendContract()">
        <div class="icon color-grey round-icon"><i class="fas fa-paper-plane"></i></div>
        <div class="text">Send Contract Docs.</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" (click)="confirmAccount()">
        <div class="icon color-grey round-icon"><i class="fas fa-external-link-square-alt"></i></div>
        <div class="text">Request Acct. Confimration</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" (click)="assignLoanOfficer()">
        <div class="icon color-grey round-icon"><i class="fas fa-user-friends"></i></div>
        <div class="text">Assign Loan Officer(Underwriter)</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" (click)="sendEmailToCustomer()">
        <div class="icon color-grey round-icon"><i class="fas fa-paper-plane"></i></div>
        <div class="text">Send Mail</div>
      </div>
      <div class="icon-text medium cursor-pointer mb-4" *ngIf="loan?.loan_status=='1'||loan?.loan_status=='2'"
        (click)="deleteRequest()">
        <div class="icon color-red round-icon"><i class="fas fa-trash"></i></div>
        <div class="text">Delete Request</div>
      </div>
    </div>
  </div>