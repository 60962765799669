<div class="tags">
  <!-- <div class="title flex justify-between align-center" [ngClass]="where_from!='self'? 'setags_': 'setags'"> -->

  <!-- <div [ngClass]="where_from!='self'? 'panel padd ptop': 'pd'"> -->
  <div class="panel padding-regular loading" *ngIf="loading">
    <div style="height: 100px;"></div>
  </div>
  <div class="panel padding-regular" *ngIf="!loading">
    <div class="flex justify-between align-center mb-4">
      <app-button *ngIf="where_from!='self'" class="small outline" label="Back" icon="fa fa-arrow-left"
        (click)="gobacktoloan()">
      </app-button>
      <div class="btn-group">
        <button type="button" class="btn btn-outline-secondary btn-sm dropdown-toggle" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">Select option you want to display
          <i class="fa fa-chevron-down ml-2"></i></button>
        <ul class="dropdown-menu">
          <!-- <li><a class="cursor-pointer" (click)="insertTextAtCursor('[CC_NUMBER]')">CC Number</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[LEGAL_NAME]')">Legal Name</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[ACCOUNT_NUMBER]')">Account Number</a>
          </li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[ADDRESS]')">Address</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[CURRENT_DATE]')">Current Date</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[EMAIL]')">Email</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[BVN_NUMBER]')">Bvn Number</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[LENDER]')">Lender</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[OBLIGOR]')">Obligor</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[FACILITY_TYPE]')">Facility Type</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[FACILITY_AMOUNT]')">Facility Amount</a>
          </li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[PURPOSE]')">Purpose</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[TENURE]')">Tenure</a></li>
          <li><a class="cursor-pointer" (click)="insertTextAtCursor('[FIRST_REPAYMENT_DATE]')">First repayment
              date</a></li> -->
              <!-- <app-emailtags (iTAC)="insertTextAtCursor($event)"></app-emailtags> -->
        </ul>
      </div>
    </div>

    <div *ngIf="where_from != 'investment'">
      <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='logo'">
        <div class="panel-heading no-border" role="tab" id="headingContact">
          <h4 class="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseLogo" aria-expanded="true"
              aria-controls="collapseFIR">
              Logo
            </a>
          </h4>
        </div>
        <div id="collapseLogo" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingFIR">
          <div class="panel-body">
            <div class="row">
              <div class="col-sm-8">
                <form #logoForm="ngForm" enctype="multipart/form-data" (ngSubmit)="onSubmitLogo()">
                  <div class="form-group">
                    <label for="exampleFormControlFile1" class="control-label normal">Upload logo Image</label>
                    <input type="file" class="form-control-file" required (change)="onFileChanged($event)"
                      [(ngModel)]="logo" name="logo" #logoName="ngModel">
                  </div>
                  <div *ngIf="!logoName.valid && logoName.touched">
                    <div class="alert alert-danger alert-sm" role="alert">
                      Logo is required!
                    </div>
                  </div>
                  <app-button type="submit" label="Upload" [loading]="loader" class="small">
                  </app-button>
                </form>
              </div>
              <div class="col-sm-4">
                <img width="120" src="{{logo_offer_letter}}">
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='logo'">
        <div class="panel-heading no-border" role="tab" id="headingContact">
          <h4 class="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseWebsiteInfo"
              aria-expanded="true" aria-controls="collapseWebsiteInfo">
              Website Information
            </a>
          </h4>
        </div>
        <div id="collapseWebsiteInfo" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingFIR">
          <div class="panel-body">
            <div class="col-md-12" *ngIf="companydescription">
              <form #contactSectionForm="ngForm" (ngSubmit)="onSubmitContactSection(contactSectionForm)">
                <label class="form-group">
                  <span class="control-label normal">Address</span>
                  <input type="text" class="form-control" [(ngModel)]="companydescription.website_address" name="address"
                    #address="ngModel">
                </label>
  
                <label class="form-group">
                  <span class="control-label normal">Email</span>
                  <input type="text" class="form-control" [(ngModel)]="companydescription.website_email" name="email"
                    #email="ngModel">
                </label>
  
                <label class="form-group">
                  <span class="control-label normal">Phone Number</span>
                  <input type="text" class="form-control" [(ngModel)]="companydescription.website_phone" name="phone"
                    #phone="ngModel">
                </label>
                <app-button type="submit" label="Update" [loading]="loader" class="small"></app-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div  class="panel-default setting dw" *ngIf="(updateSection=='' || updateSection=='address') && where_from != 'investment'">
      <div class="panel-heading no-border" role="tab" id="headingContact1">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact1" aria-expanded="true"
            aria-controls="collapseFIR">
            Address Section
          </a>
        </h4>
      </div>
      <div id="collapseContact1" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <form #addressBodyForm="ngForm" (ngSubmit)="onSubmitAddressLetterBody(addressBodyForm)">
          <div class="panel-body">
            <div class="form-check form-check-inline">
              <div class="form-check form-check-inline">
                <label class="form-check-label " for="name">Customer Name</label>
                <input class="form-check-input pull-right" type="checkbox" name="name"
                  [(ngModel)]="offerLetterObject.name" #name="ngModel">
              </div> 
              <label class="form-check-label" for="date">Current Date</label>
              <input class="form-check-input pull-right" type="checkbox" name="date"
                [(ngModel)]="offerLetterObject.date" #date="ngModel">
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label " for="address">Customer Address</label>
              <input class="form-check-input pull-right" type="checkbox" name="address"
                [(ngModel)]="offerLetterObject.address" #address="ngModel">
            </div>
            <app-button type="submit" [disabled]="!addressBodyForm.form.valid" label="Submit" [loading]="loader"
              class="small">
            </app-button>
          </div>

        </form>
      </div>
    </div>
    <div class="panel-default setting dw" *ngIf="(updateSection=='' || updateSection=='salutation') && where_from != 'investment'">
      <div class="panel-heading no-border" role="tab" id="headingContact2">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact2" aria-expanded="true"
            aria-controls="collapseFIR">
            Salutation
          </a>
        </h4>
      </div>
      <div id="collapseContact2" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <div class="panel-body">

          <form #salutationBodyForm="ngForm" (ngSubmit)="onSubmitSalutation(salutationBodyForm)">
            <!-- <label class="form-group">
                      <span class="control-label normal">Salutation</span>
                      <input type="text" class="form-control" id="textareaid" placeholder="Dear Olubodun" 
                        [(ngModel)]="offer_salutation" name="Salutation" #salutation="ngModel">
                    </label> -->
            <app-ngx-editor [config]="editorConfig2" [(ngModel)]='this.offer_salutation' name="Salutation">
            </app-ngx-editor>

            <app-button type="submit" [disabled]="!salutationBodyForm.form.valid" label="Submit" [loading]="loader"
              class="small">
            </app-button>
          </form>
        </div>
      </div>
    </div>

    <div class="panel-default setting dw" *ngIf="(updateSection=='' || updateSection=='subject') && where_from != 'investment'">
      <div class="panel-heading no-border" role="tab" id="headingContact3">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSubject" aria-expanded="true"
            aria-controls="collapseFIR">
            Subject
          </a>
        </h4>
      </div>
      <div id="collapseSubject" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <div class="panel-body">
          <form #subjectBodyForm="ngForm" (ngSubmit)="onSubmitSubjectBody(subjectBodyForm)">
            <app-ngx-editor [config]="editorConfig2" [(ngModel)]='this.subject' name="subject">
            </app-ngx-editor>
            <p></p>
            <app-button type="submit" [disabled]="!subjectBodyForm.form.valid" label="Submit" [loading]="loader"
              class="small">
            </app-button>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="where_from !='investment'">
      <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='tags'">
        <div class="panel-heading no-border" role="tab" id="headingContact3">
          <h4 class="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTags" aria-expanded="true"
              aria-controls="collapseFIR">
              Loan Tags
            </a>
          </h4>
        </div>
        <div id="collapseTags" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact"
          style="min-height: 300px;">
          <header class="panel-heading" style="border:0px !important">
            <span class="tools pull-right" style="overflow: visible">
              <ul class="list-inline">
                <li class=""><button class="btn btn-xs btn-white" (click)="createCustom()">Create Custom</button></li>
                <li class="dropdown">
                  <button type="button" class="dropdown-toggle btn btn-xs btn-white" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">Tags <span class="ml-2 caret"></span></button>
                  <ul class="dropdown-menu dropdown-menu-right mt-3">
                    <li *ngFor="let et of tags"><a (click)="chooseTag(et)">{{et.tag}}</a></li>
  
                  </ul>
                </li>
              </ul>
            </span>
          </header>
          <div class="mt-3">
            <div *ngIf="view == 'list'">
              <div class="dw">
                <div class="label-value-list mb-2">
                  <div class="item" *ngFor="let ct of chosenTags, let i = index">
                    <span class="label">{{ ct.tag }}</span>
                    <!-- <span class="value">{{ ct.tag }}</span> -->
                    <span class="value"><button class="chip-close" (click)="removeTag(i)"><i
                          class="fa fa-times"></i></button></span>
                  </div>
  
  
                </div>
              </div>
              <p></p>
              <app-button (click)="saveChosenTags()" *ngIf="chosenTags.length > 0" [disabled]="loader" label="Submit"
                [loading]="loader" class="small">
              </app-button>
            </div>
            <div *ngIf="view == 'custom'">
              <form #interestBandForm="ngForm" novalidate>
  
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label">Display</label>
                      <input type="text" required class="form-control" name="tag" [(ngModel)]="tagModel.tag">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">Value</label>
                    <input type="text" required class="form-control" name="value" [(ngModel)]="tagModel.value">
                  </div>
                </div>
                <button type="submit" (click)="saveTagModel(interestBandForm.value, interestBandForm.valid)"
                  class="btn btn-white pull-right">Add</button>
                <button type="cancel" (click)="view='list'" class="btn btn-white pull-right">Cancel</button>
              </form>
            </div>
  
          </div>
        </div>
      </div>
    </div>
 

    <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='body'">
      <div class="panel-heading no-border" role="tab" id="headingContact3">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact3" aria-expanded="true"
            aria-controls="collapseFIR">
            Letter Body
          </a>
        </h4>
      </div>
      <div id="collapseContact3" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <div class="panel-body">
          <form #letterBodyForm="ngForm" (ngSubmit)="onSubmitLetterBody(letterBodyForm)">
            <app-ngx-editor [config]="editorConfig" [(ngModel)]='this.body' name="body">
            </app-ngx-editor>
            <p></p>
            <app-button type="submit" [disabled]="!letterBodyForm.form.valid" label="Submit" [loading]="loader"
              class="small">
            </app-button>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="where_from !='investment'">
      <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='terms'">
        <div class="panel-heading no-border" role="tab" id="headingContact4">
          <h4 class="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact4" aria-expanded="true"
              aria-controls="collapseFIR">
              Terms and Conditions
            </a>
          </h4>
        </div>
        <div id="collapseContact4" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
          <div class="panel-body">
            <form #letterTermForm="ngForm" (ngSubmit)="onSubmitLetterTerms(letterTermForm)">
              <app-ngx-editor [config]="editorConfig" [(ngModel)]='this.term' name="term">
              </app-ngx-editor>
              <p></p>
              <app-button type="submit" [disabled]="!letterTermForm.form.valid" label="Submit" [loading]="loader"
                class="small">
              </app-button>
            </form>
          </div>
        </div>
      </div>
  
      <!-- <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='signature'">
        <div class="panel-heading no-border" role="tab" id="headingContact5">
          <h4 class="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact5" aria-expanded="true"
              aria-controls="collapseFIR">
              Remark
            </a>
          </h4>
        </div>
        <div id="collapseContact5" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
          <div class="panel-body">
            <form>
            </form>
          </div>
        </div>
      </div> -->
      <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='signature'">
        <div class="panel-heading no-border" role="tab" id="headingContact5">
          <h4 class="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact5" aria-expanded="true"
              aria-controls="collapseFIR">
              Remark & Singnatures
            </a>
          </h4>
        </div>
        <div id="collapseContact5" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
          <div class="panel-body">
            <form #signatureForm="ngForm" (ngSubmit)="onSubmitSignatures(signatureForm)">
              <label class="form-group">
                <span class="control-label normal">Remark</span>
                <select name="rm" class="form-control" [(ngModel)]="remark" #rm="ngModel">
                  <option value="" disabled>Select one</option>
                  <option [value]="1">Faithfully</option>
                  <option [value]="2">Sincerely</option>
                </select>
              </label>
              <!-- <div class="form-check form-check-inline">
                <label class="form-check-label " for="name">Enable Left Signature</label>
                <input class="form-check-input pull-right" type="checkbox" name="ls" [(ngModel)]="signatureleft"
                  #ls="ngModel">
              </div>
              <div *ngIf="signatureleft">
                <label class="form-group">
                  <span class="control-label normal">Name</span>
                  <input type="text" class="form-control" placeholder=""
                    [(ngModel)]="offerLetterObject.signature1Name" name="signature1Name" #signature1Name="ngModel">
                </label>
                <label class="form-group">
                  <span class="control-label normal">Position</span>
                  <input type="text" class="form-control" placeholder="CEO"
                    [(ngModel)]="offerLetterObject.signature1Position" name="signature1Position"
                    #signature1Position="ngModel">
                </label>
  
                <div class="row">
                  <div class="col-sm-8">
                    <div class="form-group">
                      <label for="exampleFormControlFile1" class="control-label normal">Upload Manager Signature </label>
                      <input type="file" class="form-control-file" (change)="onFileChangedSignature1($event)"
                        [(ngModel)]="selectedSignature1" #signaturevalidate1="ngModel" name="signature1">
                    </div>
  
                    <div *ngIf="!signaturevalidate1.valid && signaturevalidate1.touched">
                      <div class="alert alert-danger" role="alert">
                        Signature 1 is required!
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <img width="120" src="{{signatureimage1}}">
                  </div>
                </div>
              </div>
              <hr>
              <div class="form-check form-check-inline">
                <label class="form-check-label " for="name">Enable Right Signature</label>
                <input class="form-check-input pull-right" type="checkbox" name="rs" [(ngModel)]="signatureright"
                  #rs="ngModel">
              </div>
              <div *ngIf="signatureright">
                <label class="form-group">
                  <span class="control-label normal">Name</span>
                  <input type="text" class="form-control" placeholder="Olubodun Inena"
                    [(ngModel)]="offerLetterObject.signature2Name" name="signature2Name" #signature2Name="ngModel">
                </label>
                <label class="form-group">
                  <span class="control-label normal">Position</span>
                  <input type="text" class="form-control" placeholder="CEO"
                    [(ngModel)]="offerLetterObject.signature2Position" name="signature2Position"
                    #signature2Position="ngModel">
                </label>
  
                <div class="row">
                  <div class="col-sm-8">
                    <div class="form-group">
                      <label for="exampleFormControlFile1" class="control-label normal">Upload Other Signature </label>
                      <input type="file" class="form-control-file" (change)="onFileChangedSignature2($event)"
                        [(ngModel)]="selectedSignature2" #signaturevalidate2="ngModel" name="signature2">
                    </div>
                    <div *ngIf="!signaturevalidate2.valid && signaturevalidate2.touched">
                      <div class="alert alert-danger" role="alert">
                        Signature 2 is required!
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <img width="120" src="{{signatureimage2}}">
                  </div>
                </div>
              </div> -->
              <app-button type="submit" [disabled]="!signatureForm.form.valid" label="Upload" [loading]="loader"
                class="small mt-2">
              </app-button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="where_from !='investment'">
      <div class="panel-default setting dw" *ngIf="updateSection=='' || updateSection=='schedule'">
        <div class="panel-heading no-border" role="tab" id="headingContact6">
          <h4 class="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact6" aria-expanded="true"
              aria-controls="collapseFIR">
              Repayment Schedule Position
            </a>
          </h4>
        </div>
        <div id="collapseContact6" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
          <div class="panel-body">
            <form #repaymentForm="ngForm" enctype="multipart/form-data" (ngSubmit)="onSubmitRepayment(repaymentForm)">
              <fieldset class="form-group">
  
                <div class="form-check form-check-inline">
                  <label class="form-check-label" for="top">
                    After salutation
                  </label>
                  <input class="form-check-input pull-right" type="radio" [(ngModel)]="letter_repayment" name="letter_repayment" id="top" value="1">
                </div>
  
                <div class="form-check form-check-inline">
                  <label class="form-check-label" for="middle">
                    Before terms and agreement
                  </label>
                  <input class="form-check-input pull-right" type="radio" [(ngModel)]="letter_repayment" name="letter_repayment" id="middle" value="2">
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label" for="bottom">
                    After terms and agreement
                  </label>
                  <input class="form-check-input pull-right" type="radio" [(ngModel)]="letter_repayment" name="letter_repayment" id="bottom" value="3">
                </div>
              </fieldset>
              <app-button type="submit" [disabled]="!repaymentForm.form.valid" label="Submit" [loading]="loader"
                class="small">
              </app-button>
            </form>
          </div>
        </div>
      </div>
    </div>


    <div class="panel-default setting dw" *ngIf="(updateSection=='' || updateSection=='customer') && where_from != 'investment'">
      <div class="panel-heading no-border" role="tab" id="headingContact2">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseContact2" aria-expanded="true"
            aria-controls="collapseFIR">
            Customer
          </a>
        </h4>
      </div>
      <div id="collapseContact2" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingContact">
        <div class="panel-body">
          <form #customerForm="ngForm" (ngSubmit)="onSubmitCustomer(customerForm)">
            
            <div class="form-group">
              <textarea class="form-control" name="body" rows="3" [(ngModel)]="offerLetterObject.customer.body"
                #body="ngModel"></textarea>
            </div>
            <div class="align-center align-stretch flex flex-column form-group">
              <span class="color-grey control-label flex mt-2 normal">Customer's name</span>
              <div class="align-center flex mb-2">
                <div class="flex align-center mr-4">
                  <input name="name" type="radio" value="1" [(ngModel)]="offerLetterObject.customer.name"
                    required #name="ngModel">
                  <span class="control-label normal mr-4">Italicized</span>
                </div>
                <div class="flex align-center ml-4">
                  <input name="name" type="radio" value="2" [(ngModel)]="offerLetterObject.customer.name" required
                    #name="ngModel"><span class="control-label normal">Normal</span>
                </div>
              </div>
            </div>
            <div class="align-center align-stretch flex flex-column form-group"><span
                class="color-grey control-label flex mt-2 normal">Show date</span>
              <div class="align-center flex mb-2">
                <div class="flex align-center mr-4">
                  <input name="dateVisible" type="radio" value="1"
                    [(ngModel)]="offerLetterObject.customer.dateVisible" required #dateVisible="ngModel">
                  <span class="control-label normal mr-4">Yes</span>
                </div>
                <div class="flex align-center ml-4">
                  <input name="dateVisible" type="radio" value="2" [(ngModel)]="offerLetterObject.customer.dateVisible"
                    required #dateVisible="ngModel"><span class="control-label normal">No</span>
                </div>
              </div>
            </div> 
            <app-button type="submit" [disabled]="!customerForm.form.valid" label="Save" [loading]="loader"
              class="small green"></app-button>
          </form>
        </div>
      </div>
    </div>
 
  </div>