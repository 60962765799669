import {Component, Input, OnInit} from '@angular/core';
import { StorageService, OperationsService } from 'src/app/_services';

@Component({
  selector: 'app-portfoliotargetmodal',
  templateUrl: './portfoliotargetmodal.component.html',
  styleUrls: ['./portfoliotargetmodal.component.css']
})
export class PortfoliotargetmodalComponent implements OnInit {
  @Input('parentRouteID') parentRouteId: any;
  currentUser:any;
  target = {
    'timely_repayment':'',
    'request_tat':'',
    'profitable_loans':'',
    'npl':'',
    'par':'',
    'percentage_eligibility':''
  }
  loading = false;
  constructor(private storageService: StorageService, private operationsService: OperationsService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getPortfolioTarget();
  }
  updatePortfolioSettings(){
    this.loading = true;
    this.operationsService.updatePortfolioTarget(this.currentUser.token, this.target).subscribe(res=>{
      this.loading = false;
      this.target.timely_repayment = res.data.timely_repayment;
      this.target.request_tat = res.data.request_tat;
      this.target.profitable_loans = res.data.profitable_loans;
      this.target.npl = res.data.npl;
      this.target.par = res.data.par;
      this.target.percentage_eligibility = res.data.percentage_eligibility;

    });
  }
  getPortfolioTarget(){
    this.loading = true;
    this.operationsService.getPortfolioTarget(this.currentUser.token).subscribe(res=>{
      this.loading = false;
      this.target.timely_repayment = res.data.timely_repayment;
      this.target.request_tat = res.data.request_tat;
      this.target.profitable_loans = res.data.profitable_loans;
      this.target.npl = res.data.npl;
      this.target.par = res.data.par;
      this.target.percentage_eligibility = res.data.percentage_eligibility;

    });
  }
}
