<div class="padding-regular" [ngClass]="where_from!='settings'? 'panel': ''">
  <div class="flex align-center justify-end mb-4" *ngIf="where_from=='settings'">
      <app-button (click)="showmodal()" buttonclass="small outline" label="Show Setting"></app-button>
    </div>
    <div class="flex justify-end" *ngIf="where_from != 'settings'">
      <app-button (click)="closeSettings()" class="small red outline" label="Close"></app-button>
    </div>
  <form #profileanalysis="ngForm" novalidate>
    <div *ngIf="!profile_str" class="wrapper">
      <div class="linear-background">
        <div class="inter-draw"></div>
        <div class="inter-crop"></div>
        <div class="inter-right--top"></div>
        <div class="inter-right--bottom"></div>
      </div>
    </div>
    <div *ngIf="loading">
      <div class="panel mb-1 no-padding">
        <div class="alert padding-regular bg-off-white">

          <div class="texts">
            <div class="title skeleton mb-1">This is a sample text</div>
            <p class="subtitle skeleton">This is a sample subtext</p>
          </div>
          <div class="action flex flex-column align-end">
            <p class="default-text-size mb-1 skeleton">Sample date</p>
            <p class="default-text-size no-margin skeleton">Sample loan count</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!enderleavessetting" class="wrapper">
      <p>No setting available, Add setting</p>
    </div>
    <div *ngIf="profile_str">
      <div class="form-group row border-bottom">
        <div *ngIf="profile_str[0] && profile_str[0] == true">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Name Is Not Gibberish Or Nondescript</label>
          </div>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[1] && profile_str[1] == true">
        <div class="col-md-6">
          <label class="no-margin control-label normal">Minimum Age</label>
          <input type="text" type="number" class="form-control" name="MIN_AGE"
            [(ngModel)]="general_analytics_settings.MIN_AGE">
        </div>
        <div class="col-md-6">
          <label class="no-margin control-label normal">Maximum Age</label>
          <input type="text" type="number" class="form-control" name="MAX_AGE"
            [(ngModel)]="general_analytics_settings.MAX_AGE">
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[2] && profile_str[2] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Phone Number Is A Not A Special Number
          </label>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[3] && profile_str[3] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Mobile Validation Via OTP Has Been Carried Out
          </label>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[4]  && profile_str[4] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Name Is Found In Email String, Usually A Sign Of Authenticity
          </label>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[5] && profile_str[5] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Email Is A Common Mail Extension Eg. Gmail.Com, Yahoo.Com, Etc
          </label>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[6] && profile_str[6] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Email Validation By Borrower Is Complete
          </label>
        </div>
      </div>

      <div *ngIf="profile_str[7] && profile_str[7] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal">Select Preferred Borrowed Gender</span>
          <select (change)="preferredgender($event)" name="PREFERRED_BORROWER_GENDER" [(ngModel)]="stateOriginlist"
            id="stateOriginID" class="form-control">
            <option>Select</option>
            <option [value]="stateOriginlist.id"
              *ngFor="let stateOriginlist of preferredborrowedgender; let i = index;">
              {{ stateOriginlist.name }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of preferredgenderchoosen; index as i">{{item}}
            <button class="chip-close" (click)="removedpreferredgenderchoosen(item)"><i
                class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[8] && profile_str[8] == true">
        <div class="col-md-12 flex justify-between">
          <label class="no-margin control-label normal">ID Card Is Attached
          </label>
          <!-- <span class="tag green small" *ngIf="customer.ACCOUNT_OFFICER_ASSIGNED === '1'">Active <i
              class="fa fa-check"></i></span> -->
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[9] && profile_str[9] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Selfie was taken
          </label>
        </div>
      </div>

      <div *ngIf="profile_str[10] && profile_str[10] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal">Preferred Marital status</span>
          <select (change)="preferredmaritals($event)" name="PREFERRED_BORROWER_GENDER" [(ngModel)]="maritalstatus"
            id="stateOriginID" class="form-control">
            <option>Select State</option>
            <option [value]="maritalstatus.id" *ngFor="let maritalstatus of preferredmarital; let i = index;">
              {{ maritalstatus.name }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of preferredmaritalstatuschoosen; index as i">{{item}}
            <button class="chip-close" (click)="removedpreferredmaritalchoosen(item)">
              <i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div *ngIf="profile_str && profile_str[11] && profile_str[11] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Minimum Number of Dependants</label>
            <input type="text" type="number" class="form-control" name="MIN_DEPENDANTS"
              [(ngModel)]="general_analytics_settings.MIN_DEPENDANTS">
          </div>

          <div class="col-md-6">
            <label class="no-margin control-label normal">Maximum Number of Dependants</label>
            <input type="text" type="number" class="form-control" name="MAX_DEPENDANTS"
              [(ngModel)]="general_analytics_settings.MAX_DEPENDANTS">
          </div>
        </div>
      </div>

      <div *ngIf="profile_str && profile_str[12] && profile_str[12] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Minimum Number of Children</label>
            <input type="text" type="number" class="form-control" name="MIN_NO_OF_CHILDREN"
              [(ngModel)]="general_analytics_settings.MIN_NO_OF_CHILDREN">
          </div>

          <div class="col-md-6">
            <label class="no-margin control-label normal">Maximum Number of Children</label>
            <input type="text" type="number" class="form-control" name="MAX_NO_OF_CHILDREN"
              [(ngModel)]="general_analytics_settings.MAX_NO_OF_CHILDREN">
          </div>
        </div>
      </div>

      <div *ngIf="profile_str[13] && profile_str[13] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal">Allowed State of Origin</span>
          <select (change)="stateoriginallowed($event)" name="ALLOWED_STATE_OF_ORIGIN" [(ngModel)]="stateOriginlist"
            id="stateOriginID" class="form-control">
            <option>Select State</option>
            <option [value]="stateOriginlist.STATE_ID" *ngFor="let stateOriginlist of state; let i = index;">
              {{ stateOriginlist.STATE_NAME }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of stateOriginSelected; index as i">{{item}}
            <button class="chip-close" (click)="removeFromstateoriginallowed(item)"><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div *ngIf="profile_str[14] && profile_str[14] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal"> Allow Next of Kin</span>
          <select (change)="allowedNfk($event)" name="ALLOWED_NOK" [(ngModel)]="nextofkin" id="nextofkin"
            class="form-control">
            <option>Select</option>
            <option [value]="nextofkin.title" *ngFor="let nextofkin of allownextofkin; let i = index;">
              {{ nextofkin.title }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of nokSelectedupdate; index as i">{{item}}
            <button class="chip-close" (click)="removeFromNokallowedupdate(item)"><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[15] && profile_str[15] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Home Has A Valid GPS Address (Helps Digital Tracking Of
            Address Become Easier)
          </label>
        </div>
      </div>

      <div *ngIf="profile_str[16] && profile_str[16] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal">Allowed Home State</span>
          <select (change)="stateallowed($event)" name="ALLOW_ADDRESS_STATES" [(ngModel)]="stateOriginlist"
            id="stateOriginID" class="form-control">
            <option>Select State</option>
            <option [value]="stateOriginlist.STATE_ID" *ngFor="let stateOriginlist of state; let i = index;">
              {{ stateOriginlist.STATE_NAME }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of stateSelected; index as i">{{item}}
            <button class="chip-close" (click)="removeFromstateallowed(item)"><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>


      <!-- <div *ngIf="profile_str && profile_str[17] == true">
        <label class="form-group">
          <span class="control-label normal"> Home Ownership Status</span>
          <select (change)="homeownershipststus($event)" name="EXCLUDED_COUNTRIES" [(ngModel)]="homeowerstatus" id=""
            class="form-control">
            <option value="0">Select Type</option>
            <option [value]="homeowerstatus.id" *ngFor="let homeowerstatus of homeownerships; let i = index;">
              {{ homeowerstatus.name }}
            </option>
          </select>
        </label>
      </div> -->

      <div *ngIf="profile_str[17] && profile_str[17] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal">Home Ownership Status</span>
          <select (change)="homeownershipststus($event)" name="ALLOWED_OWNERSHIP_STATUS" id="ALLOWED_OWNERSHIP_STATUS"
            class="form-control">
            <option>Select Type</option>
            <option [value]="homeowerstatus.id" *ngFor="let homeowerstatus of homeownerships; let i = index;">
              {{ homeowerstatus.name }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of ownerShipSelected; index as i">{{item}}
            <button class="chip-close" (click)="removeFromownershipselected(item)"><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div *ngIf="profile_str[18] && profile_str[18] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Allowed Resident Year</label>
            <input type="text" type="number" class="form-control" name="ALLOWED_RESIDENT_YEARS"
              [(ngModel)]="general_analytics_settings.ALLOWED_RESIDENT_YEARS">
          </div>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[19] && profile_str[19] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Evidence Of Home Address Is Attached
          </label>
        </div>
      </div>

      <div *ngIf="profile_str[20] && profile_str[20] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Monthly Rent Amount</label>
            <input type="text" type="number" class="form-control" name="ALLOWED_MONTHLY_RENT"
              [(ngModel)]="general_analytics_settings.ALLOWED_MONTHLY_RENT">
          </div>
        </div>
      </div>

      <div *ngIf="profile_str && profile_str[21] && profile_str[21] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Premium Location Factor</label>
            <input type="text" class="form-control" name="LOCATION_PREMIUM_FACTOR" placeholder="Lagos Island, Ikoyi"
              [(ngModel)]="general_analytics_settings.LOCATION_PREMIUM_FACTOR">
          </div>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[22] && profile_str[22] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Office address has a valid gps
          </label>
        </div>
      </div>

      <div *ngIf="profile_str[23] && profile_str[23] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal">Allowed Work Address State</span>
          <select (change)="stateworkoriginallowed($event)" name="ALLOW_OFFICE_ADDRESS_STATES"
            [(ngModel)]="workstateOriginlist" id="stateOriginID" class="form-control">
            <option>Select State</option>
            <option [value]="stateOriginlist.STATE_ID" *ngFor="let stateOriginlist of state; let i = index;">
              {{ stateOriginlist.STATE_NAME }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of stateWorkOriginSelectedupdate; index as i">{{item}}
            <button class="chip-close" (click)="removeFromstateworkoriginallowed(item)"><i
                class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div *ngIf="profile_str && profile_str[24] && profile_str[24] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Premium Office Location Factor</label>
            <input type="text" class="form-control" name="OFFICE_PREMIUM_FACTOR" placeholder="Lagos Island, Ikoyi"
              [(ngModel)]="general_analytics_settings.OFFICE_PREMIUM_FACTOR">
          </div>
        </div>
      </div>

      <div class="flex" class="border-bottom mb-3">
        <div *ngIf="profile_str[25] && profile_str[25] == true" class="form-group flex-1 mr-1">
          <label class="control-label normal">Allow Net Gross Value</label>
          <input type="text" type="number" class="form-control" name="ALLOW_NET_GROSS_VALUE"
            [(ngModel)]="general_analytics_settings.ALLOW_NET_GROSS_VALUE">
        </div>
        <div *ngIf="profile_str[26] && profile_str[26] == true" class="form-group flex-1 ml-1">
          <label class="control-label normal">Allowed Monthly Income</label>
          <input type="text" type="number" class="form-control" name="ALLOWED_MONTHLY_INCOME"
            [(ngModel)]="general_analytics_settings.ALLOWED_MONTHLY_INCOME">
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[27] && profile_str[27] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Bank Statement is attached
          </label>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[32] && profile_str[32] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Work email is verified
          </label>
        </div>
      </div>

      <div *ngIf="profile_str[28] && profile_str[28] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal"> Preferred Borrowed Occupation</span>
          <select (change)="borrowedOccupation($event)" name="PREFERRED_BORROWER_OCCUPATION"
            [(ngModel)]="borrowedOccupa" id="" class="form-control">
            <option value="0">Select Occupation Sector</option>
            <option [value]="borrowedOccupa.OCCUPATION_ID" *ngFor="let borrowedOccupa of borrowedOccpa; let i = index;">
              {{ borrowedOccupa.DESCRIPTION }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of borrowedoccupation; index as i">{{item}}
            <button class="chip-close" (click)="removeBorrowedOccupation(item)"><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div *ngIf="profile_str[29] && profile_str[29] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal"> Preferred Occupation Designation</span>
          <select (change)="occupationDesignation($event)" name="PREFERRED_DESIGNATION"
            [(ngModel)]="occupationdesination" id="" class="form-control">
            <option value="0">Select Occupation Designation</option>
            <option [value]="occupationdesination.OCCUPATION_ID"
              *ngFor="let occupationdesination of occupationdesignation; let i = index;">
              {{ occupationdesination.DESCRIPTION }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of occupationDesigna; index as i">{{item}}
            <button class="chip-close" (click)="removeoccupationDesignation(item)"><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div *ngIf="profile_str[30] && profile_str[30] == true" class="border-bottom mb-3">
        <label class="form-group">
          <span class="control-label normal">Preferred Occupation Sector</span>
          <select (change)="borrowerOccupationSector($event)" name="PREFERRED_OCCUPATION_SECTOR"
            [(ngModel)]="occupationSectors" id="" class="form-control">
            <option value="0">Select Occupation Sector</option>
            <option [value]="occupationSectors.OCCUPATION_ID"
              *ngFor="let occupationSectors of sectorOccupation; let i = index;">
              {{ occupationSectors.DESCRIPTION }}
            </option>
          </select>
        </label>
        <div class="flex mb-4" style="flex-wrap:wrap">
          <div class="chip mr-2 mb-2" *ngFor="let item of occupationBorrowSector; index as i">{{item}}
            <button class="chip-close" (click)="removeborroweroccpation(item)"><i class="fa fa-times"></i></button>
          </div>
        </div>
      </div>

      <div *ngIf="profile_str" class="border-bottom mb-3">
        <div *ngIf="profile_str[31] && profile_str[31] == true" class="form-group row bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Excluded Companies</label>
            <input type="text" placeholder="dangote,renmoney" class="form-control" name="EXCLUDED_COMPANIES"
              [(ngModel)]="general_analytics_settings.EXCLUDED_COMPANIES">
          </div>

          <div *ngIf="profile_str[32] && profile_str[32] == true" class="col-md-6">
            <label class="no-margin control-label normal">Allowed Work Years</label>
            <input type="text" type="number" class="form-control" name="ALLOWED_WORK_YEARS"
              [(ngModel)]="general_analytics_settings.ALLOWED_WORK_YEARS">
          </div>
        </div>
      </div>

      <div *ngIf="profile_str[35] && profile_str[35] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Monthly Expense to Income Ratio is below your Maximum Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_MONTHLY_ENXPENSE_INCOME_RATIO"
              [(ngModel)]="general_analytics_settings.ALLOWED_MONTHLY_ENXPENSE_INCOME_RATIO">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[36] && profile_str[36] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Monthly Debt to Income Ratio is below your Maximum Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_DEBT_INCOME_RATION"
              [(ngModel)]="general_analytics_settings.ALLOWED_DEBT_INCOME_RATION">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[37] && profile_str[37] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Monthly Deductions Percentage is above your Minimum Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_MONTHLY_DEDUCTION_PERCENTAGE"
              [(ngModel)]="general_analytics_settings.ALLOWED_MONTHLY_DEDUCTION_PERCENTAGE">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[38] && profile_str[38] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Bonus to Gross Annual Ratio is above your Minimum Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_BONUS_ANNUAL_GROSS"
              [(ngModel)]="general_analytics_settings.ALLOWED_BONUS_ANNUAL_GROSS">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[39] && profile_str[39] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Rent to Gross Annual Ratio is above your Minimum Preference Settings
            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_RENT_ANNUAL_GROSS"
              [(ngModel)]="general_analytics_settings.ALLOWED_RENT_ANNUAL_GROSS">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[40] && profile_str[40] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">School Fees to Income Ratio is within your Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_SCHOOL_FEES_INCOME"
              [(ngModel)]="general_analytics_settings.ALLOWED_SCHOOL_FEES_INCOME">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[41] && profile_str[41] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Feeding to Income Ratio is within your Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_FEEDING_INCOME"
              [(ngModel)]="general_analytics_settings.ALLOWED_FEEDING_INCOME">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[42] && profile_str[42] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Transport to Income Ratio is within your Preference Settings</label>
            <input type="text" type="number" class="form-control" name="ALLOWED_TRANSPORT_INCOME"
              [(ngModel)]="general_analytics_settings.ALLOWED_TRANSPORT_INCOME">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[43] && profile_str[43] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Spouse to Income Ratio is within your Preference Settings</label>
            <input type="text" type="number" class="form-control" name="ALLOWED_SPOUSE_INCOME"
              [(ngModel)]="general_analytics_settings.ALLOWED_SPOUSE_INCOME">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[20] && profile_str[20] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Monthly Rent Amount</label>
            <input type="text" type="number" class="form-control" name="ALLOWED_MONTHLY_RENT"
              [(ngModel)]="general_analytics_settings.ALLOWED_MONTHLY_RENT">
          </div>
        </div>
      </div>
      <!-- <div *ngIf="profile_str[44] && profile_str[44] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Monthly Deductions Percentage is above your Minimum Preference Settings for Income            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_MONTHLY_DEDUCTIONS"
              [(ngModel)]="general_analytics_settings.ALLOWED_MONTHLY_DEDUCTIONS">
          </div>
        </div>
      </div> -->
      <div class="form-group row border-bottom" *ngIf="profile_str[46] && profile_str[46] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Payslip is attached
          </label>
        </div>
      </div>
      <div *ngIf="profile_str[47] && profile_str[47] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Total Expense is within your Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_TOTAL_EXPENSE"
              [(ngModel)]="general_analytics_settings.ALLOWED_TOTAL_EXPENSE">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[48] && profile_str[48] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Rent Expense amount is within your Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_RENT_EXPENSE"
              [(ngModel)]="general_analytics_settings.ALLOWED_RENT_EXPENSE">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[49] && profile_str[49] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Transport Expense amount  is within your Preference Settings Amount            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_TRANSPORT_EXPENSE"
              [(ngModel)]="general_analytics_settings.ALLOWED_TRANSPORT_EXPENSE">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[50] && profile_str[50] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">School Fees paid Expense is within your Preference Settings </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_SCHOOLFEES_EXPENSE"
              [(ngModel)]="general_analytics_settings.ALLOWED_SCHOOLFEES_EXPENSE">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[51] && profile_str[51] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Feeding Expense is within your Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_FEEDING_EXPENSE"
              [(ngModel)]="general_analytics_settings.ALLOWED_FEEDING_EXPENSE">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[52] && profile_str[52] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Spouse Earning Expense is within your Preference Settings</label>
            <input type="text" type="number" class="form-control" name="ALLOWED_SPOUSE_EARNINGS"
              [(ngModel)]="general_analytics_settings.ALLOWED_SPOUSE_EARNINGS">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[54] && profile_str[54] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Number of Years since Graduation is within your Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_GRADUATION_YEARS"
              [(ngModel)]="general_analytics_settings.ALLOWED_GRADUATION_YEARS">
          </div>
        </div>
      </div>
      <div *ngIf="profile_str[54] && profile_str[54] == true" class="border-bottom mb-3">
        <div class="form-group row border-bottom bg__hover">
          <div class="col-md-6">
            <label class="no-margin control-label normal">Number of Years since Graduation is within your Preference Settings            </label>
            <input type="text" type="number" class="form-control" name="ALLOWED_GRADUATION_YEARS"
              [(ngModel)]="general_analytics_settings.ALLOWED_GRADUATION_YEARS">
          </div>
        </div>
      </div>

      <div class="form-group row border-bottom" *ngIf="profile_str[57] && profile_str[57] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Multiple Date of Birth Entries
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="profile_str[58] && profile_str[58] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Profile Picture Match 
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="profile_str[59] && profile_str[59] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">More than 2 Phone Number Entries
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="profile_str[61] && profile_str[61] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Phone number changed during checkout
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="profile_str[60] && profile_str[60] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">More than 1 Bank Account Entries
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="profile_str[62] && profile_str[62] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Address changed during checkout
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="profile_str[63] && profile_str[63] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">More than 2 Address Entries
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="profile_str[64] && profile_str[64] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Address Verification Failed
          </label>
        </div>
      </div>




      

    </div>

    <div *ngIf="account_s && account_s[1] == true" class="border-bottom mb-3">
      <label class="form-group">
        <span class="control-label normal">Allowed Banks for Account</span>
        <select (change)="accountBankAllowed($event)" name="ALLOWED_ACCOUNT_BANKS" [(ngModel)]="bnk" id="stateOriginID"
          class="form-control">
          <option>Select Bank</option>
          <option [value]="bnk.bank_id" *ngFor="let bnk of bank; let i = index;">
            {{ bnk.bank_name }}
          </option>
        </select>
      </label>
      <div class="flex mb-4" style="flex-wrap:wrap">
        <div class="chip mr-2 mb-2" *ngFor="let item of accountSelectedBank; index as i">{{item}}
          <button class="chip-close" (click)="removeAccountAllowedBanks(item)"><i class="fa fa-times"></i></button>
        </div>
      </div>
    </div>
    <div class="form-group row border-bottom" *ngIf="account_s && account_s[0] == true">
      <div class="col-md-12">
        <label class="no-margin control-label normal">Borrower Name Matches Bank Account name 
        </label>
      </div>
    </div>

    <div *ngIf="card_s && card_s[2] == true" class="border-bottom mb-3">
      <label class="form-group">
        <span class="control-label normal">Allowed Cards Types</span>
        <select (change)="cardAllowedForCard($event)" name="ALLOWED_CARD_TYPE" [(ngModel)]="card" id="stateOriginID"
          class="form-control">
          <option>Select Card</option>
          <option [value]="card.id" *ngFor="let card of cards; let i = index;">
            {{ card.name }}
          </option>
        </select>
      </label>
      <div class="flex mb-4" style="flex-wrap:wrap">
        <div class="chip mr-2 mb-2" *ngFor="let item of allowedCards; index as i">{{item}}
          <button class="chip-close" (click)="removeCardAllowedCards(item)"><i class="fa fa-times"></i></button>
        </div>
      </div>
    </div>
    <div *ngIf="card_s && card_s[3] == true" class="border-bottom mb-3">
      <label class="form-group">
        <span class="control-label normal">Allowed Card Issuing Banks</span>
        <select (change)="cardAllowedForCard($event)" name="ALLOWED_CARD_TYPE" [(ngModel)]="card" id="stateOriginID"
          class="form-control"> 
          <option>Select Bank</option>
          <option [value]="bnk.bank_id" *ngFor="let bnk of bank; let i = index;">
            {{ bnk.bank_name }}
          </option>
        </select>
      </label>
      <div class="flex mb-4" style="flex-wrap:wrap">
        <div class="chip mr-2 mb-2" *ngFor="let item of allowedCards; index as i">{{item}}
          <button class="chip-close" (click)="removeCardAllowedCards(item)"><i class="fa fa-times"></i></button>
        </div>
      </div>
    </div>
    <div class="form-group row border-bottom" *ngIf="card_s[0] && card_s[0] == true">
      <div class="col-md-12">
        <label class="no-margin control-label normal">Card bank is same as disbursement bank
        </label>
      </div>
    </div>
    <div class="form-group row border-bottom" *ngIf="card_s[1] && card_s[1] == true">
      <div class="col-md-12">
        <label class="no-margin control-label normal">Card expires before loan maturity
        </label>
      </div>
    </div>

    <div *ngIf="creditbureau_s">
      <div class="form-group row border-bottom" *ngIf="creditbureau_s[0] && creditbureau_s[0] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Customer Bvn Matches
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="creditbureau_s[1] && creditbureau_s[1] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Customer Date Of Birth Match
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="creditbureau_s[2] && creditbureau_s[2] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Customer Name Match
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="creditbureau_s[3] && creditbureau_s[3] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Customer Gender Match
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="creditbureau_s[4] && creditbureau_s[4] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Customer phone matches
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="creditbureau_s[5] && creditbureau_s[5] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Customer Has Deliquency
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="creditbureau_s[6] && creditbureau_s[6] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Customer Has Performing Facilities
          </label>
        </div>
      </div>
      <div class="form-group row border-bottom" *ngIf="creditbureau_s[7] && creditbureau_s[7] == true">
        <div class="col-md-12">
          <label class="no-margin control-label normal">Customer Has Non Performing Facilities
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="loanspider_s">
      <div *ngIf="loanspider_s[0] && loanspider_s[0] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Allowed current ongoing loans</label>
            <input type="number" class="form-control" name="LOAN_SPIDER_ALLOWED_ONGOING_LOANS"
              [(ngModel)]="general_analytics_settings.LOAN_SPIDER_ALLOWED_ONGOING_LOANS">
          </div>
        </div>
      </div>
      <div *ngIf="loanspider_s[1] && loanspider_s[1] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Allowed loan rejections</label>
            <input type="number" class="form-control" name="LOAN_SPIDER_ALLOWED_LOAN_REJECTION"
              [(ngModel)]="general_analytics_settings.LOAN_SPIDER_ALLOWED_LOAN_REJECTION">
          </div>
        </div>
      </div>
      <div *ngIf="loanspider_s[2] && loanspider_s[2] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Allowed total loans borrowed(count)</label>
            <input type="number" class="form-control" name="LOAN_SPIDER_ALLOWED_LOANS_COUNT"
              [(ngModel)]="general_analytics_settings.LOAN_SPIDER_ALLOWED_LOANS_COUNT">
          </div>
        </div>
      </div>
      <div *ngIf="loanspider_s[3] && loanspider_s[3] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Allowed total loans borrowed(amount)</label>
            <input type="number" class="form-control" name="LOAN_SPIDER_ALLOWED_LOANS_AMOUNT"
              [(ngModel)]="general_analytics_settings.LOAN_SPIDER_ALLOWED_LOANS_AMOUNT">
          </div>
        </div>
      </div>
      <div *ngIf="loanspider_s[5] && loanspider_s[5] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Allowed late repayments</label>
            <input type="number" class="form-control" name="LOAN_SPIDER_ALLOWED_LATE_REPAYMENTS"
              [(ngModel)]="general_analytics_settings.LOAN_SPIDER_ALLOWED_LATE_REPAYMENTS">
          </div>
        </div>
      </div>
      <div *ngIf="loanspider_s[8] && loanspider_s[8] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Received loan within the past months(number)</label>
            <input type="number" class="form-control" name="LOAN_SPIDER_ALLOWED_LAST_LOAN_PERIOD"
              [(ngModel)]="general_analytics_settings.LOAN_SPIDER_ALLOWED_LAST_LOAN_PERIOD">
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="bankstatement_s">
      <div *ngIf="bankstatement_s[0] && bankstatement_s[0] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Minimum Bank Statement AI Score</label>
            <input type="number" class="form-control" name="BANK_STATEMENT_MIN_AI_SCORE"
              [(ngModel)]="general_analytics_settings.BANK_STATEMENT_MIN_AI_SCORE">
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[1] && bankstatement_s[1] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Minimum Avg Monthly Deposit</label>
            <input type="number" class="form-control" name="BANK_STATEMENT_MIN_AVG_MONTHLY_DEPOSIT"
              [(ngModel)]="general_analytics_settings.BANK_STATEMENT_MIN_AVG_MONTHLY_DEPOSIT">
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[2] && bankstatement_s[2] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Minimum Avg Weekly Deposit</label>
            <input type="number" class="form-control" name="BANK_STATEMENT_MIN_AVG_WEEKLY_DEPOSIT"
              [(ngModel)]="general_analytics_settings.BANK_STATEMENT_MIN_AVG_WEEKLY_DEPOSIT">
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[3] && bankstatement_s[3] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Minimum Avg Activity Rate</label>
            <input type="number" class="form-control" name="BANK_STATEMENT_MIN_AVG_ACTIVITY_RATE"
              [(ngModel)]="general_analytics_settings.BANK_STATEMENT_MIN_AVG_ACTIVITY_RATE">
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[4] && bankstatement_s[4] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Has no loans</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[5] && bankstatement_s[5] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Minimum Avg Loan Repayment</label>
            <input type="number" class="form-control" name="BANK_STATEMENT_MIN_AVG_LOAN_REPAYMENT"
              [(ngModel)]="general_analytics_settings.BANK_STATEMENT_MIN_AVG_LOAN_REPAYMENT">
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[6] && bankstatement_s[6] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Consistent Salary Date</label> 
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[7] && bankstatement_s[7] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Consistent Salary Amount</label> 
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[8] && bankstatement_s[8] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Has Investment</label> 
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[9] && bankstatement_s[9] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Not a Gambler</label> 
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[10] && bankstatement_s[10] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Not A Sweeper</label> 
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[11] && bankstatement_s[11] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Withdrawal to Deposit rate within your preference settings</label> 
            <input type="number" class="form-control" name="BANK_STATEMENT_MIN_WITHDRAWAL_DEPOSIT_RATIO"
              [(ngModel)]="general_analytics_settings.BANK_STATEMENT_MIN_WITHDRAWAL_DEPOSIT_RATIO">
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[12] && bankstatement_s[12] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Debt to Deposit rate within your preference settings</label>
            <input type="number" class="form-control" name="BANK_STATEMENT_MIN_DEBT_DEPOSIT_RATIO"
              [(ngModel)]="general_analytics_settings.BANK_STATEMENT_MIN_DEBT_DEPOSIT_RATIO">
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[13] && bankstatement_s[13] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Bank Statement Income Vs Declared Income</label> 
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[14] && bankstatement_s[14] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Traveller</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[15] && bankstatement_s[15] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Has a Bounced Check</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[17] && bankstatement_s[17] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Has Zero Balance</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[18] && bankstatement_s[18] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Frequent Borrowing</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[19] && bankstatement_s[19] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Weekly Deposit Rate</label>
            <input type="number" class="form-control" name="BANK_STATEMENT_WEEKLY_DEPOSIT_RATE"
              [(ngModel)]="general_analytics_settings.BANK_STATEMENT_WEEKLY_DEPOSIT_RATE">
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[20] && bankstatement_s[20] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Frequest ATM Location State</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[21] && bankstatement_s[21] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Frequently Sweeps Deposit Out Of Account</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[22] && bankstatement_s[22] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Frequently Sweeps Salary Out Of Account</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[23] && bankstatement_s[23] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Salary</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[22] && bankstatement_s[22] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Frequently Sweeps Salary Out Of Account</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[23] && bankstatement_s[23] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Salary</label>
          </div>
        </div>
      </div>
      <div *ngIf="bankstatement_s[64] && bankstatement_s[64] == true" class="border-bottom mb-3">
        <div class="form-group row bg__hover">
          <div class="col-md-12">
            <label class="no-margin control-label normal">Average deposit sweet within your preference settings</label>
            <input type="number" class="form-control" name="ALLOWED_AVERAGE_DEPOSIT_SWEEP"
              [(ngModel)]="general_analytics_settings.ALLOWED_AVERAGE_DEPOSIT_SWEEP">
          </div>
        </div>
      </div>
    </div>




    <app-button *ngIf="this.analysisdata" type="submit"
      (click)="saveprofileanalysis(profileanalysis.value, profileanalysis.valid)" [disabled]="loading" class="small"
      label="Submit" [loading]="loading"></app-button>
  </form>

</div>