<div class="row" *ngIf="where_from !='result'">
  <div class="col-md-12">
    <h3>{{band_details?.data?.min_amount}} - {{band_details?.data?.max_amount}} </h3>
  </div>
</div>
<div class="row" *ngIf="where_from !='result'">
  <div class="col-md-3">
    <div>
      <label class="form-group">
        <input (change)="loadLeaves()" type="radio" value="0" name="band_type" [(ngModel)]="band_type">
        <span class="control-label normal">No Offer</span>
      </label>
    </div>
  </div>
  <div class="col-md-3">
    <div>
      <label class="form-group">
        <input (change)="loadLeaves()" type="radio" value="1" name="band_type" [(ngModel)]="band_type">
        <span class="control-label normal">Just There</span>
      </label>
    </div>
  </div>
  <div class="col-md-3">
    <div>
      <label class="form-group">
        <input (change)="loadLeaves()" type="radio" value="2" name="band_type" [(ngModel)]="band_type">
        <span class="control-label normal">Good Enough</span>
      </label>
    </div>
  </div>
  <div class="col-md-3">
    <div>
      <label class="form-group">
        <input (change)="loadLeaves()" type="radio" value="3" name="band_type" [(ngModel)]="band_type">
        <span class="control-label normal">Fantastic</span>
      </label>
    </div>
  </div>
</div>
<div class="panel-body padding-regular" *ngIf="where_from =='result'">
  <div class="flex">
    <div class="section-poster">
      <div class="image flex-center">
        <img src="assets/images/eligibility.svg">
      </div>
    </div>
    <div class="flex-3 mb-2 ml-4">
      <div class="panel pd_15 mb-3">
        <h4 class="section-title mb-2 border-bottom medium-text-size">Legend</h4>
        <ul class="legend_flex no-margin d-flex justify-content-between">
          <li class="medium-text-size"><i class="fa fa-check color-green mr-1"></i><span>Passed</span>
          </li>
          <li class="medium-text-size"><i class="fa fa-check color-red mr-1"></i><span>Failed</span></li>
          <li class="medium-text-size"><i class="fas fa-question-circle mr-1"></i>
            <span>Not specified in your settings</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<h3 *ngIf="where_from !='result'" class="section-title flex align-center justify-between">


  <p style="margin-top:20px">Selection</p>
  <div>
    <!-- <app-button class="outline white small" *ngIf="currentView == '0'" (click)="currentView='1'" label="Advanced">
    </app-button> -->
    <app-button class="outline white small ml-1" *ngIf="currentView == '1'" (click)="currentView='0'" label="Basic">
    </app-button>
    <span class="">{{ counter }} / {{total_default}}</span>
    <app-button class="small ml-1" [disabled]="loading" (click)="submitGlobalAnalysis()" label="Save"
      [loading]="loading">
    </app-button>

  </div>

</h3>
<div [ngClass]="{'overpanel-scroll padding-regular-y': embedModal}">
  <div class="alert alert-danger" *ngIf="counter > 50">You can only select 50</div>
  <br />
  
  
  <div class="col-md-12" *ngIf="where_from =='result' && loan?.eligibility_analysis?.band_score?.questions?.data?.grades?.passed">
    <h3 class="section-title mb-3">Questionaire</h3>
    <form [formGroup]="questionform">
      <div *ngFor="let questione of question | searchanalysissetting : searchText;let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(question[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.questions?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="question"
          (change)="checkBox($event,question,i);">
          <span class="control-label normal">{{question[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="showProfile && where_from !='result'">
    <h3 class="section-title mb-3">Profile Analysis Setting</h3>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div *ngFor="let order of orders | searchanalysissetting : searchText; let i = index">
        <label class="form-group" formArrayName="orders">
          <input (change)="checkBox($event,orders,i,'profile');" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="orders[i].description" [checked]="orders[i].checked"
            [(ngModel)]="orders[i].checked">
          <span class="control-label normal">{{orders[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="showProfile && where_from =='result' && loan?.eligibility_analysis?.band_score?.profile?.data?.grades?.passed">
    <h3 class="section-title mb-3">Profile Analysis</h3>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div *ngFor="let order of orders | searchanalysissetting : searchText; let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(orders[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.profile?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="orders">
          <input (change)="checkBox($event,orders,i,'profile');" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="orders[i].description" [checked]="orders[i].checked"
            [(ngModel)]="orders[i].checked">
          <span class="control-label normal">{{orders[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>

        </label>
      </div>
    </form>
  </div>
  <!-- <div class="col-md-12" *ngIf="showCalllogs != where_from =='result'">
    <h3 class="section-title mb-3">Call Log Analysis Setting</h3>
    <form [formGroup]="callLogform">
      <div *ngFor="let callLog of callLogs | searchanalysissetting : searchText; let i = index">
        <label *ngIf="callLogs[i].leave_type == currentView" class="form-group" formArrayName="callLogs"
          (change)="checkBox($event,callLogs,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="callLogs[i].description"
            [checked]="callLogs[i].checked" [(ngModel)]="callLogs[i].checked">
          <span class="control-label normal">{{callLogs[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="showCalllogs && where_from =='result' && loan?.eligibility_analysis?.band_score?.calllogs">
    <h3 class="section-title mb-3">Call Log Analysis</h3>
    <form [formGroup]="callLogform">
      <div *ngFor="let callLog of callLogs | searchanalysissetting : searchText; let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(callLogs[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.calllogs?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="orders">
          (change)="checkBox($event,callLogs,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="callLogs[i].description"
            [checked]="callLogs[i].checked" [(ngModel)]="callLogs[i].checked">
          <span class="control-label normal">{{callLogs[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>



  <div class="col-md-12" *ngIf="showSMS  && where_from !='result'">
    <h3 class="section-title mb-3">Sms Analysis Setting</h3>
    <form [formGroup]="smsform">
      <div *ngFor="let smse of phonesms | searchanalysissetting : searchText; let i = index">
        <label *ngIf="phonesms[i].leave_type == currentView" class="form-group" formArrayName="phonesms"
          (change)="checkBox($event,phonesms,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="phonesms[i].description"
            [checked]="phonesms[i].checked" [(ngModel)]="phonesms[i].checked">
          <span class="control-label normal">{{phonesms[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="showSMS && where_from =='result' && loan?.eligibility_analysis?.band_score?.calllogs">
    <h3 class="section-title mb-3">Sms Analysis</h3>
    <form [formGroup]="smsform">
      <div *ngFor="let smse of phonesms | searchanalysissetting : searchText; let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(phonesms[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.smslogs?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="phonesms">
          (change)="checkBox($event,phonesms,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="phonesms[i].description"
            [checked]="phonesms[i].checked" [(ngModel)]="phonesms[i].checked">
          <span class="control-label normal">{{phonesms[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div> -->




  <div class="col-md-12" *ngIf="showAccount  && where_from !='result'">
    <h3 class="section-title mb-3">Account Analysis Setting</h3>
    
    <form [formGroup]="accountform">
      <div *ngFor="let acc of account | searchanalysissetting : searchText; let i = index">
        <label *ngIf="account[i].leave_type == currentView" class="form-group" formArrayName="account"
          (change)="checkBox($event,account,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="account[i].description"
            [checked]="account[i].checked" [(ngModel)]="account[i].checked">
          <span class="control-label normal">{{account[i].description}}</span>
        </label>
      </div>
    </form>
  </div>

  <div class="col-md-12" *ngIf="showAccount && where_from =='result' && loan?.eligibility_analysis?.band_score?.account?.data?.grades?.passed">
    <h3 class="section-title mb-3">Account Analysis</h3>
    <form [formGroup]="accountform">
      <div *ngFor="let acc of account | searchanalysissetting : searchText; let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(account[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.account?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="account">
          (change)="checkBox($event,account,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="account[i].description"
            [checked]="account[i].checked" [(ngModel)]="account[i].checked">
          <span class="control-label normal">{{account[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>


  <div class="col-md-12" *ngIf="showBVN  && where_from !='result'">
    <h3 class="section-title mb-3">BVN Analysis Setting</h3>
    <form [formGroup]="bvnform">
      <div *ngFor="let carde of bvn | searchanalysissetting : searchText; let i = index">
        <label *ngIf="bvn[i].leave_type == currentView" class="form-group" formArrayName="bvn"
          (change)="checkBox($event,bvn,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="bvn[i].description"
            [checked]="bvn[i].checked" [(ngModel)]="bvn[i].checked">
          <span class="control-label normal">{{bvn[i].description}}({{bvn[i].merchantclan_leave_id}})</span>
        </label>
      </div>
    </form>
  </div>

  <div class="col-md-12" *ngIf="showBVN && where_from =='result' && loan?.eligibility_analysis?.band_score?.bvn?.data?.grades?.passed">
    <h3 class="section-title mb-3">BVN Analysis</h3>
    <form [formGroup]="bvnform">
      <div *ngFor="let carde of bvn | searchanalysissetting : searchText; let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(bvn[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.bvn?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="bvn">
          (change)="checkBox($event,account,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="bvn[i].description"
            [checked]="bvn[i].checked" [(ngModel)]="bvn[i].checked">
          <span class="control-label normal">{{bvn[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>



  <!-- <div class="col-md-12" *ngIf="showGuarantor">
    <h3 class="section-title mb-3">Guarantor Analysis Setting</h3>
    <form [formGroup]="guarantorform">
      <div *ngFor="let guarantore of guarantor | searchanalysissetting : searchText; let i = index">
        <label *ngIf="guarantor[i].leave_type == currentView" class="form-group" formArrayName="guarantor"
          (change)="checkBox($event,guarantor,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="guarantor[i].description"
            [checked]="guarantor[i].checked" [(ngModel)]="guarantor[i].checked">
          <span class="control-label normal">{{guarantor[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->


  <div class="col-md-12" *ngIf="showRepayment   && where_from !='result'">
    <h3 class="section-title mb-3">Repayment Analysis Setting</h3>
    <form [formGroup]="repaymentform">
      <div *ngFor="let repayment of repayments | searchanalysissetting : searchText; let i = index">
        <label class="form-group" formArrayName="repayments" (change)="checkBox($event,repayments,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="repayments[i].description"
            [checked]="repayments[i].checked" [(ngModel)]="repayments[i].checked">
          <span class="control-label normal">{{repayments[i].description}}</span>
        </label>
      </div>
    </form>
  </div>

  <div class="col-md-12" *ngIf="showRepayment && where_from =='result' && loan?.eligibility_analysis?.band_score?.repayments?.data?.grades?.passed">
    <h3 class="section-title mb-3">Repayment Analysis</h3>
    <form [formGroup]="repaymentform">
      <div *ngFor="let repayment of repayments | searchanalysissetting : searchText; let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(repayments[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.repayments?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="repayments">
          (change)="checkBox($event,account,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="repayments[i].description"
            [checked]="repayments[i].checked" [(ngModel)]="repayments[i].checked">
          <span class="control-label normal">{{repayments[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>





  <div class="col-md-12" *ngIf="showCard   && where_from !='result'">
    <h3 class="section-title mb-3">Card Analysis Setting</h3>
    <form [formGroup]="cardform">
      <div *ngFor="let carde of atmcard | searchanalysissetting : searchText; let i = index">
        <label *ngIf="atmcard[i].leave_type == currentView" class="form-group" formArrayName="atmcard"
          (change)="checkBox($event,atmcard,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [checked]="atmcard[i].checked"
            [name]="atmcard[i].description" [checked]="atmcard[i].checked" [(ngModel)]="atmcard[i].checked">
          <span class="control-label normal">{{atmcard[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="showCard   && where_from =='result' && loan?.eligibility_analysis?.band_score?.card?.data?.grades?.passed">
    <h3 class="section-title mb-3">Card Analysis</h3>
    <form [formGroup]="cardform">
      <div *ngFor="let carde of atmcard | searchanalysissetting : searchText; let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(atmcard[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.card?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="atmcard"
          (change)="checkBox($event,atmcard,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="atmcard[i].description"
            [checked]="atmcard[i].checked" [(ngModel)]="atmcard[i].checked">
          <span class="control-label normal">{{atmcard[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>

  <!-- <div class="col-md-12"  *ngIf="showCard">
    <h3 class="section-title mb-3">Card Analysis Setting</h3>
    <form [formGroup]="cardform">
      <div *ngFor="let carde of atmcard | searchanalysissetting : searchText; let i = index">
        <label *ngIf="atmcard[i].leave_type == currentView" class="form-group" formArrayName="atmcard"
          (change)="checkBox($event,atmcard,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [checked]="atmcard[i].checked"
            [name]="atmcard[i].description" [checked]="atmcard[i].checked" [(ngModel)]="atmcard[i].checked">
          <span class="control-label normal">{{atmcard[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->


  <!-- <div class="col-md-12" *ngIf="showDirectDebit">
    <h3 class="section-title mb-3">Direct Debit Analysis Setting</h3>
    <form [formGroup]="directdebitform">
      <div *ngFor="let directdebite of directdebit | searchanalysissetting : searchText;let i = index">
        <label *ngIf="directdebit[i].leave_type == currentView" class="form-group" formArrayName="directdebit"
          (change)="checkBox($event,directdebit,i);">
          <input [disabled]="counter === 50 && !directdebit[i].checked" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="directdebit[i].description" [checked]="directdebit[i].checked"
            [(ngModel)]="directdebit[i].checked">
          <span class="control-label normal">{{directdebit[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="showDirectDebit">
    <h3 class="section-title mb-3">Direct Debit Analysis Setting</h3>
    <form [formGroup]="directdebitform">
      <div *ngFor="let directdebite of directdebit | searchanalysissetting : searchText;let i = index">
        <label *ngIf="directdebit[i].leave_type == currentView" class="form-group" formArrayName="directdebit"
          (change)="checkBox($event,directdebit,i);">
          <input [disabled]="counter === 50 && !directdebit[i].checked" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="directdebit[i].description" [checked]="directdebit[i].checked"
            [(ngModel)]="directdebit[i].checked">
          <span class="control-label normal">{{directdebit[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->

  <div class="col-md-12" *ngIf="where_from !='result'">
    <h3 class="section-title mb-3">Credit Bureau Analysis</h3>
    <form [formGroup]="creditbureauform">
      <div *ngFor="let creditbureaue of creditbureau | searchanalysissetting : searchText;let i = index">
        <label *ngIf="creditbureau[i].leave_type == currentView" class="form-group" formArrayName="creditbureau"
          (change)="checkBox($event,creditbureau,i);">
          <input [disabled]="counter === 50 && !creditbureau[i].checked" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="creditbureau[i].description" [checked]="creditbureau[i].checked"
            [(ngModel)]="creditbureau[i].checked">
          <span class="control-label normal">{{creditbureau[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="showCard   && where_from =='result' && loan?.eligibility_analysis?.band_score?.credit_bureau?.data?.grades?.passed">
    <h3 class="section-title mb-3">Credit Bureau</h3>
    <form [formGroup]="creditbureauform">
      <div *ngFor="let creditbureaue of creditbureau | searchanalysissetting : searchText;let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(creditbureau[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.credit_bureau?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="creditbureau"
          (change)="checkBox($event,creditbureau,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="creditbureau[i].description"
            [checked]="creditbureau[i].checked" [(ngModel)]="creditbureau[i].checked">
          <span class="control-label normal">{{creditbureau[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>
  




  <!-- <div class="col-md-12" *ngIf="where_from !='result'">
    <h3 class="section-title mb-3">Loan Spider Analysis</h3>
    <form [formGroup]="loanspiderform">
      <div *ngFor="let loanspidere of loanspider | searchanalysissetting : searchText;let i = index">
        <label *ngIf="loanspider[i].leave_type == currentView" class="form-group" formArrayName="loanspider"
          (change)="checkBox($event,loanspider,i);">
          <input [disabled]="counter === 50 && !loanspider[i].checked" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="loanspider[i].description" [checked]="loanspider[i].checked"
            [(ngModel)]="loanspider[i].checked">
          <span class="control-label normal">{{loanspider[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->

  <!-- <div class="col-md-12" *ngIf="where_from =='result' && loan?.eligibility_analysis?.band_score?.loanspider?.data?.grades?.passed">
    <h3 class="section-title mb-3">Loan Spider</h3>
    <form [formGroup]="loanspiderform">
      <div *ngFor="let loanspidere of loanspider | searchanalysissetting : searchText;let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(loanspidere[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.loanspider?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="loanspider"
          (change)="checkBox($event,loanspider,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="loanspider[i].description"
            [checked]="loanspider[i].checked" [(ngModel)]="loanspider[i].checked">
          <span class="control-label normal">{{loanspider[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div> -->
    



  <div class="col-md-12" *ngIf="where_from !='result'">
    <h3 class="section-title mb-3">Identity Analysis</h3>
    <form [formGroup]="identityform">
      <div *ngFor="let identitye of identity | searchanalysissetting : searchText;let i = index">
        <label *ngIf="identity[i].leave_type == currentView" class="form-group" formArrayName="identity"
          (change)="checkBox($event,identity,i);">
          <input [disabled]="counter === 50 && !identity[i].checked" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="identity[i].description" [checked]="identity[i].checked"
            [(ngModel)]="identity[i].checked">
          <span class="control-label normal">{{identity[i].description}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="where_from =='result' && loan?.eligibility_analysis?.band_score?.identity?.data?.grades?.passed">
    <h3 class="section-title mb-3">Identity Analysis</h3>
    <form [formGroup]="identityform">
      <div *ngFor="let identitye of identity | searchanalysissetting : searchText;let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(identity[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.identity?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="identity"
          (change)="checkBox($event,identity,i);">
          <input [disabled]="counter === 50 && !identity[i].checked" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="identity[i].description" [checked]="identity[i].checked"
            [(ngModel)]="identity[i].checked">
          <span class="control-label normal">{{identity[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>


  <div class="col-md-12" *ngIf="where_from !='result'">
    <h3 class="section-title mb-3">Bank Statement Analysis</h3>
    <form [formGroup]="bankstatementform">
      <div *ngFor="let bankstatemente of bankstatement | searchanalysissetting : searchText;let i = index">
        <label *ngIf="bankstatement[i].leave_type == currentView" class="form-group" formArrayName="bankstatement"
          (change)="checkBox($event,bankstatement,i);">
          <input type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="bankstatement[i].description" [checked]="bankstatement[i].checked"
            [(ngModel)]="bankstatement[i].checked">
          <span class="control-label normal">{{bankstatement[i].description}} - {{bankstatement[i].merchantclan_leave_id}}</span>
        </label>
      </div>
    </form>
  </div>
  <div class="col-md-12" *ngIf="where_from =='result' && loan?.eligibility_analysis?.band_score?.bankstatement?.data?.grades?.passed">
    <h3 class="section-title mb-3">Bank Statement Analysis</h3>
    <form [formGroup]="bankstatementform">
      <div *ngFor="let bankstatemente of bankstatement | searchanalysissetting : searchText;let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(bankstatement[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.bankstatement?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="bankstatement"
          (change)="checkBox($event,bankstatement,i);">
          <input [disabled]="counter === 50 && !bankstatement[i].checked" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="bankstatement[i].description" [checked]="bankstatement[i].checked"
            [(ngModel)]="bankstatement[i].checked">
          <span class="control-label normal">{{bankstatement[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div>




  <!-- <div class="col-md-12" *ngIf="where_from !='result'">
    <h3 class="section-title mb-3">Questionaire Analysis</h3>
    <form [formGroup]="questionform">
      <div *ngFor="let questione of question | searchanalysissetting : searchText;let i = index">
        <label *ngIf="question[i].leave_type == currentView" class="form-group" formArrayName="question"
          (change)="checkBox($event,question,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="question[i].description"
            [checked]="question[i].checked" [(ngModel)]="question[i].checked">
          <span class="control-label normal">{{question[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->

  <!-- <div class="col-md-12" *ngIf="where_from !='result'">
    <h3 class="section-title mb-3">Frequently Called Numbers</h3>
    <form [formGroup]="fcnform">
      <div *ngFor="let fcne of fcn | searchanalysissetting : searchText;let i = index">
        <label *ngIf="fcn[i].leave_type == currentView" class="form-group" formArrayName="fcn"
          (change)="checkBox($event,fcn,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="fcn[i].description"
            [checked]="fcn[i].checked" [(ngModel)]="fcn[i].checked">
          <span class="control-label normal">{{fcn[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->


  <!-- <div class="col-md-12" *ngIf="where_from !='result'">
    <h3 class="section-title mb-3">Merchant Repeat Customer</h3>
    <form [formGroup]="merchantform">
      <div *ngFor="let merchante of merchant | searchanalysissetting : searchText;let i = index">
        <label *ngIf="merchant[i].leave_type == currentView" class="form-group" formArrayName="merchant"
          (change)="checkBox($event,merchant,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="merchant[i].description"
            [checked]="merchant[i].checked" [(ngModel)]="merchant[i].checked">
          <span class="control-label normal">{{merchant[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->
  <!-- <div class="col-md-12" *ngIf="where_from =='result' && loan?.eligibility_analysis?.band_score?.merchant_feedback?.data?.grades?.passed">
    <h3 class="section-title mb-3">Merchant Repeat</h3>
    <form [formGroup]="merchantform">
      <div *ngFor="let merchante of merchant | searchanalysissetting : searchText;let i = index">
        <label
          *ngIf="checkIfLeaveWasPassed(merchant[i].merchantclan_leave_id,loan?.eligibility_analysis?.band_score?.merchant_feedback?.data?.grades?.passed)"
          class="form-group d-flex justify-content-between" formArrayName="merchant"
          (change)="checkBox($event,merchant,i);">
          <input [disabled]="counter === 50 && !merchant[i].checked" type="checkbox" *ngIf="where_from !='result'"
            [formControlName]="i" [name]="merchant[i].description" [checked]="merchant[i].checked"
            [(ngModel)]="merchant[i].checked">
          <span class="control-label normal">{{merchant[i].description}}</span>
          <span>
            <i class="fa fa-check color-green"></i>
          </span>
        </label>
      </div>
    </form>
  </div> -->
  <!-- <div class="col-md-12">
    <h3 class="section-title mb-3">Social Analysis Setting</h3>
    <form [formGroup]="socialform">
      <div *ngFor="let soc of social | searchanalysissetting : searchText; let i = index">
        <label class="form-group" formArrayName="social" (change)="checkBox($event,social,i);">
          <input type="checkbox" *ngIf="where_from !='result'" [formControlName]="i" [name]="social[i].description"
            [checked]="social[i].checked" [(ngModel)]="social[i].checked">
          <span class="control-label normal">{{social[i].description}}</span>
        </label>
      </div>
    </form>
  </div> -->

  <!-- <button class="btn btn-primary pull-right" style="margin-right:15px" [disabled]="loading"
        (click)="submitGlobalAnalysis()">
        <span *ngIf="!loading">Submit settings</span>
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button> -->
</div>