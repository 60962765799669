import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AnalysisService } from '../../setting-module/analysis.service';
import { ToastrService } from 'ngx-toastr';

import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { of } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { OptionsserviceService, DataService } from 'src/app/_services';
@Component({
  selector: 'app-eligibilityofferstable',
  templateUrl: './eligibilityofferstable.component.html',
  styleUrls: ['./eligibilityofferstable.component.css']
})
export class EligibilityofferstableComponent implements OnInit {

  @Output() closeSelectedAnalyticsModal = new EventEmitter();
  @Input() embedModal = false;
  @Input('where_from') where_from = 'settings';
  // @Input('where_from') where_from = 'settings';
  total_default = 0;
  form: FormGroup;
  orders = [];
  callLogform: FormGroup;
  callLogs = [];
  socialform: FormGroup;
  social = [];
  currentView = '0';
  repaymentform: FormGroup;
  repayments = [];
  smsform: FormGroup;
  phonesms = [];
  guarantorform: FormGroup;
  guarantor = [];
  directdebitform: FormGroup;
  directdebit = [];
  creditbureauform: FormGroup;
  creditbureau = [];
  loanspiderform: FormGroup;
  loanspider = [];
  identityform: FormGroup;
  identity = [];
  bankstatementform: FormGroup;
  bankstatement = [];
  questionform: FormGroup;
  question = [];
  fcnform: FormGroup;
  fcn = [];
  merchantform: FormGroup;
  merchant = [];

  cardform: FormGroup;
  atmcard = [];
  accountform: FormGroup;
  account = [];
  bvnform: FormGroup;
  bvn = [];
  userFilter: any = { description: '' };
  private vendor_id: any;
  private data: any;
  private profileAnalysisData: any;
  private analysisData: any;
  private globalAnalysisData: any;
  public stateList: any;
  public modal = true;
  counter = 0;
  masterprofileanalysis: any;
  loading: boolean;
  analysisdata = [];
  enderleavessetting: any;
  enderleavesvalues: any;
  preferredgenderchoosen = [];
  preferredmaritalstatuschoosen = [];

  stateSelected = [];
  stateOriginSelected = [];
  excludedcountryselected = [];
  stateWorkOriginSelected = [];
  preferredmaritalvalue = [];
  preferredborrowedgendervalue = [];
  homeownership = [];
  blacklistedcountrycallselected = [];
  externalCountrycallselected = [];
  blacklistedselected = [];
  acceptedcountrycallselected = [];
  timebeltcalllogselected = [];
  stateOriginSelectedsocial = [];
  guarantorOccupaionAllowed = [];
  allowedAccountBank = [];
  allowedAccountCards = [];
  allowedCardBank = [];
  allowedCards = [];
  nokSelectedupdate = [];
  borrowedoccupation = [];
  occupationDesigna = [];
  occupationSectors = [];
  occupationBorrowSector = [];
  accountSelectedBank = [];

  public show: any = false;
  public buttonName: any = 'Show';

  stateSelectedupdate = [];
  stateOriginSelectedupdate = [];
  excludedcountryselectedupdate = [];
  stateWorkOriginSelectedupdate = [];
  preferredmaritalvalueupdate = [];
  preferredborrowedgendervalueupdate = [];
  homeownershipupdate = [];
  minQualifications = [];
  otherQualifications = [];

  profile_str: any;
  calllog_str: any;
  social_s: any;
  guarantor_s: any;
  sms_s: any;
  directdebit_s: any;
  creditbureau_s: any;
  loanspider_s: any;
  identity_s: any;
  bankstatement_s: any;
  question_s: any;
  fcn_s: any;
  merchant_s: any;
  account_s: any;
  card_s: any;
  bvn_s: any;


  lenderSetting: any;
  arrayToString: any;
  state: any;
  country: any;
  occupation: any;
  bank: any;
  borrowedOccpa: any;
  occupationdesignation: any;
  sectorOccupation: any;
  qualification: any;
  minQualification: any;
  OtherQalification: any;


  general_analytics_settings = {
    MIN_AGE: '',
    MAX_AGE: '',
    ALLOW_ADDRESS_STATES: [],
    ALLOW_ADDRESS_STATES_DISPLAY: [],
    PREFERRED_BORROWER_GENDER: [],
    PREFERRED_BORROWER_GENDER_DISPLAY: [],
    ALLOW_MARITAL_STATUS: [],
    ALLOW_MARITAL_STATUS_DISPLAY: [],
    MIN_DEPENDANTS: '',
    MAX_DEPENDANTS: '',
    MIN_NO_OF_CHILDREN: '',
    MAX_NO_OF_CHILDREN: '',
    ALLOWED_STATE_OF_ORIGIN: [],
    ALLOWED_STATE_OF_ORIGIN_DISPLAY: [],
    ALLOWED_NOK: [],
    ALLOW_ADDRESS_STATES_HOME: [],
    ALLOWED_OWNERSHIP_STATUS: [],
    ALLOWED_OWNERSHIP_STATUS_DISPLAY: [],
    ALLOWED_RESIDENT_YEARS: '',
    ALLOWED_MONTHLY_RENT: '',
    LOCATION_PREMIUM_FACTOR: '',
    EXCLUDED_COUNTRIES: [],
    EXCLUDED_COUNTRIES_DISPLAY: [],
    ALLOW_OFFICE_ADDRESS_STATES: [],
    ALLOW_OFFICE_ADDRESS_STATES_DISPLAY: [],
    OFFICE_PREMIUM_FACTOR: '',
    PREFERRED_BORROWER_OCCUPATION: [],
    PREFERRED_BORROWER_OCCUPATION_DISPLAY: [],
    PREFERRED_OCCUPATION_SECTOR: [],
    PREFERRED_OCCUPATION_SECTOR_DISPLAY: [],
    PREFERRED_DESIGNATION: [],
    PREFERRED_DESIGNATION_DISPLAY: [],
    EXCLUDED_COMPANIES: '',
    ALLOWED_WORK_YEARS: '',
    MAX_EXPENSE_INCOME_RATIO: '',
    MAX_DEBT_INCOME_RATIO: '',
    MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE: '',
    MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE: '',
    MIN_RENT_TO_GROSS_ANNUAL_PERCENTAGE: '',
    MAX_RENT_TO_GROSS_ANNUAL_PERCENTAGE: '',
    MIN_SCHOOL_FEES_TO_INCOME: '',
    MAX_SCHOOL_FEES_TO_INCOME: '',
    MIN_FEEDING_TO_INCOME: '',
    MAX_FEEDING_TO_INCOME: '',
    MIN_TRANSPORT_TO_INCOME: '',
    MAX_TRANSPORT_TO_INCOME: '',
    MIN_SPOUSE_TO_INCOME: '',
    MAX_SPOUSE_TO_INCOME: '',
    ALLOW_NET_GROSS_VALUE: '',
    ALLOWED_MONTHLY_INCOME: '',
    MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE_INCOME: '',
    MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE_INCOME: '',
    MIN_TOTAL_EXPENSES: '',
    MAX_TOTAL_EXPENSES: '',
    MIN_MONTHLY_RENT_EXPENSE: '',
    MAX_MONTHLY_RENT_EXPENSE: '',
    MIN_MONTHLY_TRANSPORT: '',
    MAX_MONTHLY_TRANSPORT: '',
    MIN_SCHOOL_FEES_EXPENSE: '',
    MAX_SCHOOL_FEES_EXPENSE: '',
    MIN_FEEDING_EXPENSES: '',
    MAX_FEEDING_EXPENSES: '',
    MIN_SPOUSE_INCOME: '',
    MAX_SPOUSE_INCOME: '',
    ACCEPTED_SCHOOLS: '',
    MIN_YRS_SINCE_GRADUATION: '',
    MIN_QUALIFICATION: [],
    MIN_QUALIFICATION_DISPLAY: [],
    HAS_OTHER_QUALIFICATIONS: [],
    HAS_OTHER_QUALIFICATIONS_DISPLAY: [],
  };

  call_log_analytics_settings = {
    PH_AN_MONTHLY_CALL: '',
    PH_AN_MONTHLY_CALL_MARKET_AVG_MIN: '',
    PH_AN_MONTHLY_CALL_MARKET_AVG_MAX: '',
    PH_AN_PERCENTAGE_MONTHLY_CALL_TO_DECLARED_INCOME: '',
    PH_AN_PERCENTAGE_NAMELESS_CALLS_TO_LOG: '',
    PH_AN_PERCENTAGE_MONTHLY_EXTERNAL_COUNTRY_TO_TOTAL: '',
    PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES: [],
    PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES_DISPLAY: [],
    PH_AN_ACCEPTED_EXTERNAL_COUNTRIES: [],
    PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY: [],
    PH_AN_REJECTED_FREQUENT_CALL_TIME_BELT_DISPLAY: [],
    PH_AN_REJECTED_FREQUENT_CALL_TIME_BELT: [],
    PH_AN_PERCENTAGE_OF_FAMILY_KEY_WORD_TO_TOTAL: '',
    PH_AN_VANITY_NUMBER_COUNT: ''
  };

  guarantor_analytics_settings = {
    GUARANTOR_ALLOWED_SECTOR: [],
    GUARANTOR_ALLOWED_SECTOR_DISPLAY: [],
    GUARANTOR_ALLOWED_DESIGNATION: [],
    GUARANTOR_ALLOWED_DESIGNATION_DISPLAY: [],
    GUARANTOR_ALLOWED_LOAN_TO_INCOME_RATIO: '',
    GUARANTOR_ALLOWED_HOME_OWNERSHIP_STATUS: '',
    GUARANTOR_ALLOWED_HOME_MINIMUM_YEARS: '',
    GUARANTOR_ALLOWED_RESPONSE_TIME: '',
  };

  direct_debit_analytics_settings = {
    MINIMUM_DIRECT_DEBIT_ACCOUNTS: ''
  };

  credit_bureau_analytics_settings = {};

  accounts_analytics_settings = {
    ALLOWED_ACCOUNT_BANKS: [],
    ALLOWED_ACCOUNT_BANKS_DISPLAY: [],
    ALLOWED_CARD_BANKS: [],
    ALLOWED_CARD_BANKS_DISPLAY: [],
  };

  card_analytics_settings = {
    ALLOWED_CARD_BANKS: [],
    ALLOWED_CARD_BANKS_DISPLAY: [],
    ALLOWED_CARD_TYPE: [],
    ALLOWED_CARD_TYPE_DISPLAY: [],
  };

  sms_analytics_settings = {
    PERCENTAGE_OVERALL: '',
    PERCENTAGE_INCOME_TO_CREDIT: '',
    PERCENTAGE_OVERSPENDING: '',
    PERCENTAGE_CREDIT_DEBIT_INCOME: '',
    PERCENTAGE_INCOME_TO_DEBIT: '',
    PERCENTAGE_AIRTIME_TO_INCOME: '',
    PERCENTAGE_OTHER_EXPENSES_TO_INCOME: '',
    PERCENTAGE_INCOME_TO_MONTHLY_SALARY_TOTAL: '',
    PERCENTAGE_AVERAGE_CREDIT: '',
    PERCENTAGE_AVERAGE_DEBIT: ''
  };

  social_analytics_settings = {
    ALLOWED_CONNECTIONS: '',
    PREFERED_LOCATION_STATE: [],
    PREFERED_LOCATION_STATE_DISPLAY: [],
    MIN_RANGE: '',
    MAX_RANGE: '',
    LAST_POST_DATE_ALLOWED: '',
    FOLLOWERS_COUNT_ALLOWED: '',
    FOLLOWING_COUNT_ALLOWED: '',
    FOLLOWERS_TO_FOLLOWING_RATIO_ALLOWED: '',
    FAVOURITE_COUNT_ALLOWED: '',
    STATUSES_COUNT_ALLOWED: '',
    CREATED_AT_ALLOWED: ''
  };


  update_analytics_settings = {
    MIN_AGE: '',
    MIN_AGE_CHOOSE: '',
    MAX_AGE: '',
    MAX_AGE_CHOOSE: '',
    ALLOW_ADDRESS_STATES: [],
    ALLOW_ADDRESS_STATES_CHOOSE: '',
    PREFERRED_BORROWER_GENDER: [],
    PREFERRED_BORROWER_GENDER_CHOOSE: '',
    ALLOW_MARITAL_STATUS: [],
    ALLOW_MARITAL_STATUS_CHOOSE: '',
    MIN_DEPENDANTS: '',
    MIN_DEPENDANTS_CHOOSE: '',
    MAX_DEPENDANTS: '',
    MAX_DEPENDANTS_CHOOSE: '',
    MIN_NO_OF_CHILDREN: '',
    MIN_NO_OF_CHILDREN_CHOOSE: '',
    MAX_NO_OF_CHILDREN: '',
    MAX_NO_OF_CHILDREN_CHOOSE: '',
    ALLOWED_STATE_OF_ORIGIN: [],
    ALLOWED_STATE_OF_ORIGIN_CHOOSE: '',
    ALLOWED_NOK: '',
    ALLOWED_NOK_CHOOSE: '',
    ALLOW_ADDRESS_STATES_HOME: [],
    ALLOW_ADDRESS_STATES_HOME_CHOOSE: '',
    ALLOWED_OWNERSHIP_STATUS: [],
    ALLOWED_OWNERSHIP_STATUS_CHOOSE: '',
    ALLOWED_RESIDENT_YEARS: '',
    ALLOWED_RESIDENT_YEARS_CHOOSE: '',
    ALLOWED_MONTHLY_RENT: '',
    ALLOWED_MONTHLY_RENT_CHOOSE: '',
    LOCATION_PREMIUM_FACTOR: '',
    LOCATION_PREMIUM_FACTOR_CHOOSE: '',
    EXCLUDED_COUNTRIES: [],
    EXCLUDED_COUNTRIES_CHOOSE: '',
    ALLOW_OFFICE_ADDRESS_STATES: [],
    ALLOW_OFFICE_ADDRESS_STATES_CHOOSE: '',
    OFFICE_PREMIUM_FACTOR: '',
    OFFICE_PREMIUM_FACTOR_CHOOSE: '',
    PREFERRED_BORROWER_OCCUPATION: '',
    PREFERRED_BORROWER_OCCUPATION_CHOOSE: '',
    PREFERRED_OCCUPATION_SECTOR: '',
    PREFERRED_OCCUPATION_SECTOR_CHOOSE: '',
    PREFERRED_DESIGNATION: '',
    PREFERRED_DESIGNATION_CHOOSE: '',
    EXCLUDED_COMPANIES: '',
    EXCLUDED_COMPANIES_CHOOSE: '',
    ALLOWED_WORK_YEARS: '',
    ALLOWED_WORK_YEARS_CHOOSE: '',
    MAX_EXPENSE_INCOME_RATIO: '',
    MAX_EXPENSE_INCOME_RATIO_CHOOSE: '',
    MAX_DEBT_INCOME_RATIO: '',
    MAX_DEBT_INCOME_RATIO_CHOOSE: '',
    MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE: '',
    MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE_CHOOSE: '',
    MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE: '',
    MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE_CHOOSE: '',
    MIN_RENT_TO_GROSS_ANNUAL_PERCENTAGE: '',
    MIN_RENT_TO_GROSS_ANNUAL_PERCENTAGE_CHOOSE: '',
    MAX_RENT_TO_GROSS_ANNUAL_PERCENTAGE: '',
    MAX_RENT_TO_GROSS_ANNUAL_PERCENTAGE_CHOOSE: '',
    MIN_SCHOOL_FEES_TO_INCOME: '',
    MIN_SCHOOL_FEES_TO_INCOME_CHOOSE: '',
    MAX_SCHOOL_FEES_TO_INCOME: '',
    MAX_SCHOOL_FEES_TO_INCOME_CHOOSE: '',
    MIN_FEEDING_TO_INCOME: '',
    MIN_FEEDING_TO_INCOME_CHOOSE: '',
    MAX_FEEDING_TO_INCOME: '',
    MAX_FEEDING_TO_INCOME_CHOOSE: '',
    MIN_TRANSPORT_TO_INCOME: '',
    MIN_TRANSPORT_TO_INCOME_CHOOSE: '',
    MAX_TRANSPORT_TO_INCOME: '',
    MAX_TRANSPORT_TO_INCOME_CHOOSE: '',
    MIN_SPOUSE_TO_INCOME: '',
    MIN_SPOUSE_TO_INCOME_CHOOSE: '',
    MAX_SPOUSE_TO_INCOME: '',
    MAX_SPOUSE_TO_INCOME_CHOOSE: '',
    ALLOW_NET_GROSS_VALUE: '',
    ALLOW_NET_GROSS_VALUE_CHOOSE: '',
    ALLOWED_MONTHLY_INCOME: '',
    ALLOWED_MONTHLY_INCOME_CHOOSE: '',
    MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE_INCOME: '',
    MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE_INCOME_CHOOSE: '',
    MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE_INCOME: '',
    MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE_INCOME_CHOOSE: '',
    MIN_TOTAL_EXPENSES: '',
    MIN_TOTAL_EXPENSES_CHOOSE: '',
    MAX_TOTAL_EXPENSES: '',
    MAX_TOTAL_EXPENSES_CHOOSE: '',
    MIN_MONTHLY_RENT_EXPENSE: '',
    MIN_MONTHLY_RENT_EXPENSE_CHOOSE: '',
    MAX_MONTHLY_RENT_EXPENSE: '',
    MAX_MONTHLY_RENT_EXPENSE_CHOOSE: '',
    MIN_MONTHLY_TRANSPORT: '',
    MIN_MONTHLY_TRANSPORT_CHOOSE: '',
    MAX_MONTHLY_TRANSPORT: '',
    MAX_MONTHLY_TRANSPORT_CHOOSE: '',
    MIN_SCHOOL_FEES_EXPENSE: '',
    MIN_SCHOOL_FEES_EXPENSE_CHOOSE: '',
    MAX_SCHOOL_FEES_EXPENSE: '',
    MAX_SCHOOL_FEES_EXPENSE_CHOOSE: '',
    MIN_FEEDING_EXPENSES: '',
    MIN_FEEDING_EXPENSES_CHOOSE: '',
    MAX_FEEDING_EXPENSES: '',
    MAX_FEEDING_EXPENSES_CHOOSE: '',
    MIN_SPOUSE_INCOME: '',
    MIN_SPOUSE_INCOME_CHOOSE: '',
    MAX_SPOUSE_INCOME: '',
    MAX_SPOUSE_INCOME_CHOOSE: '',
    ACCEPTED_SCHOOLS: '',
    ACCEPTED_SCHOOLS_CHOOSE: '',
    MIN_YRS_SINCE_GRADUATION: '',
    MIN_YRS_SINCE_GRADUATION_CHOOSE: '',
    MIN_QUALIFICATION: '',
    MIN_QUALIFICATION_CHOOSE: '',
    HAS_OTHER_QUALIFICATIONS: '',
    HAS_OTHER_QUALIFICATIONS_CHOOSE: ''
  };


  // Checkbox value
  single: boolean;
  married: boolean;
  male: boolean;
  female: boolean;
  rented: boolean;
  owned: boolean;

  offer_just_there = {
    "upfront_percentage": "0",
    "maximum_tenor": "0",
    "interest": "0"
  };
  offer_good_enough = {
    "upfront_percentage": "0",
    "maximum_tenor": "0",
    "interest": "0"
  };
  offer_fantastic = {
    "upfront_percentage": "0",
    "maximum_tenor": "0",
    "interest": "0"
  };



  public preferredmarital = [
    { 'name': 'Single', 'id': 1 },
    { 'name': 'Married', 'id': 2 },
    { 'name': 'Divorced', 'id': 3 },
    { 'name': 'Widowed', 'id': 4 }
  ];

  public cards = [
    { 'name': 'Master Card', 'id': 1 },
    { 'name': 'Visa', 'id': 2 },
    { 'name': 'Verve', 'id': 3 },
  ];

  public preferredborrowedgender = [
    { 'name': 'Female', 'id': 1 },
    { 'name': 'Male', 'id': 2 }
  ];

  public homeownerships = [
    { 'name': 'Rented', 'id': 0 },
    { 'name': 'Owned', 'id': 1 }
  ];


  public allownextofkin = [
    { 'title': 'Brother', 'id': 1 },
    { 'title': 'Son', 'id': 2 },
    { 'title': 'Wife', 'id': 3 },
    { 'title': 'Uncle', 'id': 4 },
    { 'title': 'Sister', 'id': 5 },
    { 'title': 'Aunt', 'id': 6 },
    { 'title': 'Niece', 'id': 7 },
    { 'title': 'Nephew', 'id': 8 },
    { 'title': 'Daughter', 'id': 9 },
    { 'title': 'Husband', 'id': 10 },
    { 'title': 'Cousin', 'id': 11 },
  ];

  public timebelt_list = [
    'early morning',
    'morning',
    'afternoon',
    'evening'
  ];

  public getStateSubscribe: Subscription;
  public getCountrySubscribe: Subscription;
  public getOccupationborrowedSubscribe: Subscription;
  public getOccupationdesignationSubscribe: Subscription;
  public getOccupationsectorSubscribe: Subscription;
  public getQualificationSubscribe: Subscription;
  public getBanksSubscribe: Subscription;
  public getprofileanalysisSubscribe: Subscription;
  public getprofileanalysiscallSubscribe: Subscription;
  public getprofileanalysissocialSubscribe: Subscription;
  public getprofileanalysisrepaymentSubscribe: Subscription;
  public getprofileanalysissmsSubscribe: Subscription;
  public getprofileanalysisguarantorSubscribe: Subscription;
  public getprofileanalysisdirectdebitSubscribe: Subscription;
  public getprofileanalysiscreditbureauSubscribe: Subscription;
  public getprofileanalysisloanspiderSubscribe: Subscription;
  public getprofileanalysisidentitySubscribe: Subscription;
  public getprofileanalysisbankstatementSubscribe: Subscription;
  public getprofileanalysisquestionSubscribe: Subscription;
  public getprofileanalysisfcnSubscribe: Subscription;
  public getprofileanalysismerchantSubscribe: Subscription;
  public getprofileanalysiscardSubscribe: Subscription;
  public getprofileanalysisaccountSubscribe: Subscription;
  public getprofileanalysisbvnSubscribe: Subscription;
  public getlenderleavessettingSubscribe: Subscription;

  searchText = '';
  showProfile = false;
  showCalllogs = false;
  showSocial = false;
  showSMS = false;
  showAccount = false;
  showGuarantor = false;
  showRepayment = false;
  showCard = false;
  showDirectDebit = false;
  showCreditBureau = false;
  showIdentity = false;
  showQuestion = false;
  showFcn = false;
  showMerchant = false;
  showLoanSpider = false;
  showBVN = false;
  @Input() band_id: any;
  @Input() ecosystem: any;
  band_type = '1'
  band_details: any;
  ecosystem_text = '';
  constructor(private profileAnalysis: AnalysisService, public toastr: ToastrService,
    private formBuilder: FormBuilder, public optionsService: OptionsserviceService, private dataService: DataService) {
  }

  loadLeaves() {

    const vendorIdData = localStorage.getItem('platform');
    const userdata = JSON.parse(vendorIdData);
    this.vendor_id = userdata.PEOPLE_ID;

    const getToken = localStorage.getItem('token');
    const passToken = {
      token: getToken,
      band_id: this.band_id,
      ecosystem: this.ecosystem,
      band_type: this.band_type
    };


    this.form = this.formBuilder.group({
      orders: new FormArray([])
    });

    this.profileAnalysis.getBanddetails(passToken).subscribe(band_details => {

      this.band_details = band_details;
    });
    // Get state
    this.getStateSubscribe = this.optionsService.getStates().subscribe(states => {
      this.state = states;
      const stateLists = this.state;
    });
    // Get country
    this.getCountrySubscribe = this.optionsService.getCountries().subscribe(country => {
      this.country = country;
      const country_list = this.country;
    });


    this.getOccupationborrowedSubscribe = this.optionsService.getOcc().subscribe(
      borrowedOccupation => {
        this.borrowedOccpa = borrowedOccupation;
      });

    this.getOccupationdesignationSubscribe = this.optionsService.getDes().subscribe(
      occupationdesignations => {
        this.occupationdesignation = occupationdesignations;
      });

    this.getOccupationsectorSubscribe = this.optionsService.getSec().subscribe(
      sectorOccupations => {
        this.sectorOccupation = sectorOccupations;
      });

    this.getQualificationSubscribe = this.profileAnalysis.getQualification().subscribe(
      qualifications => {
        this.qualification = qualifications;
      });


    // Get banks
    this.getBanksSubscribe = this.profileAnalysis.getBanks().subscribe(bank => {
      this.bank = bank;
    });

    // async orders
    this.getprofileanalysisSubscribe = this.profileAnalysis.getprofileanalysis(passToken).subscribe(orders => {
      this.orders = orders;
      this.addCheckboxes();
    });


    this.callLogform = this.formBuilder.group({
      callLogs: new FormArray([])
    });

    // async call logs
    this.getprofileanalysiscallSubscribe = this.profileAnalysis.getprofileanalysiscall(passToken).subscribe(callLogs => {

      this.callLogs = callLogs;
      this.addCheckboxesCallLogs();
    });


    this.socialform = this.formBuilder.group({
      social: new FormArray([])
    });

    // async call logs
    this.getprofileanalysissocialSubscribe = this.profileAnalysis.getprofileanalysissocial(passToken).subscribe(social => {
      this.social = social;
      this.addCheckboxesSocial();
    });


    this.repaymentform = this.formBuilder.group({
      repayments: new FormArray([])
    });


    this.getprofileanalysisrepaymentSubscribe = this.profileAnalysis.getprofileanalysisrepayment(passToken).subscribe(repayments => {
      this.repayments = repayments;
      this.addCheckboxesRepayment();
    });

    this.smsform = this.formBuilder.group({
      phonesms: new FormArray([])
    });


    this.getprofileanalysissmsSubscribe = this.profileAnalysis.getprofileanalysissms(passToken).subscribe(sms => {
      this.phonesms = sms;
      this.addCheckboxesSms();
    });


    this.guarantorform = this.formBuilder.group({
      guarantor: new FormArray([])
    });

    this.getprofileanalysisguarantorSubscribe = this.profileAnalysis.getprofileanalysisguarantor(passToken).subscribe(guarantor => {
      this.guarantor = guarantor;
      this.addCheckboxesGuarantor();
    });


    // this.directdebitform = this.formBuilder.group({
    //   directdebit: new FormArray([])
    // });

    // this.getprofileanalysisdirectdebitSubscribe = this.profileAnalysis.getprofileanalysisdirectdebit(passToken).subscribe(directdebit => {
    //   this.directdebit = directdebit;
    //   this.addCheckboxesDirectdebit();
    // });

    this.creditbureauform = this.formBuilder.group({
      creditbureau: new FormArray([])
    });

    this.getprofileanalysiscreditbureauSubscribe = this.profileAnalysis.getprofileanalysiscreditbueau(passToken).subscribe(creditbureau => {
      this.creditbureau = creditbureau;
      this.addCheckboxesCreditbureau();
    });

    this.loanspiderform = this.formBuilder.group({
      loanspider: new FormArray([])
    });

    this.getprofileanalysisloanspiderSubscribe = this.profileAnalysis.getprofileanalysisloanspider(passToken).subscribe(loanspider => {
      this.loanspider = loanspider;
      this.addCheckboxesLoanspider();
    });

    this.identityform = this.formBuilder.group({
      identity: new FormArray([])
    });

    this.getprofileanalysisidentitySubscribe = this.profileAnalysis.getprofileanalysisidentity(passToken).subscribe(identity => {
      this.identity = identity;
      this.addCheckboxesIdentity();
    });

    this.bankstatementform = this.formBuilder.group({
      bankstatement: new FormArray([])
    });

    this.getprofileanalysisbankstatementSubscribe = this.profileAnalysis.getprofileanalysisbankstatement(passToken).subscribe(bankstatement => {
      this.bankstatement = bankstatement;
      this.addCheckboxesBankstatement();
    });

    this.questionform = this.formBuilder.group({
      question: new FormArray([])
    });

    this.getprofileanalysisquestionSubscribe = this.profileAnalysis.getprofileanalysisquestion(passToken).subscribe(question => {
      this.question = question;
      this.addCheckboxesQuestion();
    });

    this.fcnform = this.formBuilder.group({
      fcn: new FormArray([])
    });

    this.getprofileanalysisfcnSubscribe = this.profileAnalysis.getprofileanalysisfcn(passToken).subscribe(fcn => {
      this.fcn = fcn;
      this.addCheckboxesFcn();
    });
    this.merchantform = this.formBuilder.group({
      merchant: new FormArray([])
    });

    this.getprofileanalysismerchantSubscribe = this.profileAnalysis.getprofileanalysismerchant(passToken).subscribe(merchant => {
      this.merchant = merchant;
      this.addCheckboxesMerchant();
    });

    this.cardform = this.formBuilder.group({
      atmcard: new FormArray([])
    });

    this.getprofileanalysiscardSubscribe = this.profileAnalysis.getprofileanalysiscard(passToken).subscribe(card => {
      this.atmcard = card;
      this.addCheckboxesCard();
    });

    this.accountform = this.formBuilder.group({
      account: new FormArray([])
    });

    this.getprofileanalysisaccountSubscribe = this.profileAnalysis.getprofileanalysisaccount(passToken).subscribe(account => {
      this.account = account;
      this.addCheckboxesAcount();
    });

    this.bvnform = this.formBuilder.group({
      bvn: new FormArray([])
    });

    this.getprofileanalysisbvnSubscribe = this.profileAnalysis.getprofileanalysisbvn(passToken).subscribe(bvn => {
      this.bvn = bvn;
      this.addCheckboxesBvn();
    });


    this.getprofileanalysisSubscribe = this.profileAnalysis.getprofileanalysis(passToken)
      .subscribe((data) => {
        this.globalAnalysisData = data;
      });

    this.getSettingData();

  }
  ngOnInit() {
    //this.loadLeaves();
    const getToken = localStorage.getItem('token');
    const passToken = {
      token: getToken,
      band_id: this.band_id,
      ecosystem: this.ecosystem,
      band_type: this.band_type
    };
    this.profileAnalysis.getBanddetails(passToken).subscribe(band_details => {

      this.band_details = band_details;
    });
    this.loadoffers()
  }
  loadoffers() {
    const getToken = localStorage.getItem('token');
    const passToken = {
      token: getToken,
      band_id: this.band_id,
      ecosystem: this.ecosystem,
      band_type: this.band_type
    };
    this.profileAnalysis.loadEligibilityOffers(passToken).subscribe(band_details => {

      this.offer_just_there = band_details.data.offer_just_there;
      this.offer_good_enough = band_details.data.offer_good_enough;
      this.offer_fantastic = band_details.data.offer_fantastic;
    });
  }
  saveoffer(offer, offer_type) {
    this.loading = true;
    const token = localStorage.getItem('token');

    this.profileAnalysis.saveoffer(offer, offer_type, this.ecosystem, this.band_type, this.band_details.data)
      .subscribe((band_details) => {
        this.toastr.success('Success!');
        this.loading = false;
        this.offer_just_there = band_details.data.offer_just_there;
        this.offer_good_enough = band_details.data.offer_good_enough;
        this.offer_fantastic = band_details.data.offer_fantastic;
      });
  }
  getSettingData() {
    const getToken = localStorage.getItem('token');
    const passToken = {
      token: getToken
    };

    this.getlenderleavessettingSubscribe = this.profileAnalysis.getlenderleavessetting(passToken)
      .subscribe((data) => {
        this.enderleavessetting = data;

        const settings = this.enderleavessetting.profiles_leaves;
        const profiles_leaves = JSON.parse(this.enderleavessetting.profiles_leaves);
        const guarantor_leaves = JSON.parse(this.enderleavessetting.guarantor_leaves);
        const account_leaves = JSON.parse(this.enderleavessetting.account_leaves);
        const bvn_leaves = JSON.parse(this.enderleavessetting.bvn_leaves);
        const card_leaves = JSON.parse(this.enderleavessetting.card_leaves);
        const sms_leaves = JSON.parse(this.enderleavessetting.sms_leaves);
        const calllog_leaves = JSON.parse(this.enderleavessetting.calllog_leaves);
        const social_leaves = JSON.parse(this.enderleavessetting.social_leaves);
        const repayment_leaves = JSON.parse(this.enderleavessetting.repayment_leaves);
        const directdebit_leaves = JSON.parse(this.enderleavessetting.directdebit_leaves);
        const creditbureau_leaves = this.enderleavessetting.creditbureau_leaves ? JSON.parse(this.enderleavessetting.creditbureau_leaves) : [];
        const loanspider_leaves = this.enderleavessetting.loanspider_leaves ? JSON.parse(this.enderleavessetting.loanspider_leaves) : [];
        const identity_leaves = this.enderleavessetting.identity_leaves ? JSON.parse(this.enderleavessetting.identity_leaves) : [];
        const bankstatement_leaves = this.enderleavessetting.bankstatement_leaves ? JSON.parse(this.enderleavessetting.bankstatement_leaves) : [];
        const question_leaves = this.enderleavessetting.question_leaves ? JSON.parse(this.enderleavessetting.question_leaves) : [];

        this.profile_str = JSON.parse(this.enderleavessetting.profile_s);
        this.guarantor_s = JSON.parse(this.enderleavessetting.guarantor_s);
        this.account_s = JSON.parse(this.enderleavessetting.account_s);
        this.card_s = JSON.parse(this.enderleavessetting.card_s);
        this.directdebit_s = JSON.parse(this.enderleavessetting.directdebit_s);
        this.creditbureau_s = this.enderleavessetting.creditbureau_s ? JSON.parse(this.enderleavessetting.creditbureau_s) : [];
        this.loanspider_s = this.enderleavessetting.loanspider_s ? JSON.parse(this.enderleavessetting.loanspider_s) : [];
        this.identity_s = this.enderleavessetting.identity_s ? JSON.parse(this.enderleavessetting.identity_s) : [];
        this.bankstatement_s = this.enderleavessetting.bankstatement_s ? JSON.parse(this.enderleavessetting.bankstatement_s) : [];
        this.question_s = this.enderleavessetting.question_s ? JSON.parse(this.enderleavessetting.question_s) : [];
        this.fcn_s = this.enderleavessetting.fcn_s ? JSON.parse(this.enderleavessetting.fcn_s) : [];
        this.merchant_s = this.enderleavessetting.merchant_s ? JSON.parse(this.enderleavessetting.merchant_s) : [];
        this.sms_s = JSON.parse(this.enderleavessetting.sms_s);
        this.calllog_str = JSON.parse(this.enderleavessetting.calllog_s);
        this.social_s = JSON.parse(this.enderleavessetting.social_s);
        this.bvn_s = JSON.parse(this.enderleavessetting.bvn_s);
        const repayment_s = JSON.parse(this.enderleavessetting.repayment_s);


      });


    this.profileAnalysis.getlenderleavesvalue(passToken)
      .subscribe((data) => {
        this.enderleavesvalues = data;
        if (this.enderleavesvalues.length > 0) {
          const profilevalue = JSON.parse(this.enderleavesvalues[0].setting);

          if (profilevalue.analysis) {
            this.general_analytics_settings.ACCEPTED_SCHOOLS = profilevalue.analysis.ACCEPTED_SCHOOLS;
            this.general_analytics_settings.ALLOWED_MONTHLY_INCOME = profilevalue.analysis.ALLOWED_MONTHLY_INCOME;

            this.general_analytics_settings.ALLOWED_MONTHLY_RENT = profilevalue.analysis.ALLOWED_MONTHLY_RENT;

            profilevalue.analysis.ALLOWED_NOK.map(s => {
              if (!this.nokSelectedupdate.includes(s)) {
                this.nokSelectedupdate.push(s);
              }
            });

            profilevalue.analysis.ALLOWED_NOK.map(s => {
              this.general_analytics_settings.ALLOWED_NOK.push(s);
            });

            this.general_analytics_settings.ALLOWED_OWNERSHIP_STATUS = profilevalue.analysis.ALLOWED_OWNERSHIP_STATUS;
            this.general_analytics_settings.ALLOWED_RESIDENT_YEARS = profilevalue.analysis.ALLOWED_RESIDENT_YEARS;

            profilevalue.analysis.ALLOWED_STATE_OF_ORIGIN_DISPLAY.map(s => {
              this.stateOriginSelected.push(s);
            });
            profilevalue.analysis.ALLOWED_STATE_OF_ORIGIN_DISPLAY.map(s => {
              this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN_DISPLAY.push(s);
            });
            profilevalue.analysis.ALLOWED_STATE_OF_ORIGIN.map(s => {
              this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN.push(s);
            });



            this.general_analytics_settings.ALLOWED_WORK_YEARS = profilevalue.analysis.ALLOWED_WORK_YEARS;

            profilevalue.analysis.ALLOW_ADDRESS_STATES_DISPLAY.map(s => {
              this.stateSelected.push(s);
            });
            profilevalue.analysis.ALLOW_ADDRESS_STATES_DISPLAY.map(s => {
              this.general_analytics_settings.ALLOW_ADDRESS_STATES_DISPLAY.push(s);
            });
            profilevalue.analysis.ALLOW_ADDRESS_STATES.map(s => {
              this.general_analytics_settings.ALLOW_ADDRESS_STATES.push(s);
            });


            profilevalue.analysis.ALLOW_MARITAL_STATUS_DISPLAY.map(s => {
              this.preferredmaritalstatuschoosen.push(s);
            });
            profilevalue.analysis.ALLOW_MARITAL_STATUS_DISPLAY.map(s => {
              this.general_analytics_settings.ALLOW_MARITAL_STATUS_DISPLAY.push(s);
            });
            profilevalue.analysis.ALLOW_MARITAL_STATUS.map(s => {
              this.general_analytics_settings.ALLOW_MARITAL_STATUS.push(s);
            });



            this.general_analytics_settings.ALLOW_NET_GROSS_VALUE = profilevalue.analysis.ALLOW_NET_GROSS_VALUE;

            profilevalue.analysis.ALLOW_OFFICE_ADDRESS_STATES_DISPLAY.map(s => {
              this.stateWorkOriginSelectedupdate.push(s);
            });
            profilevalue.analysis.ALLOW_OFFICE_ADDRESS_STATES_DISPLAY.map(s => {
              this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES_DISPLAY.push(s);
            });
            profilevalue.analysis.ALLOW_OFFICE_ADDRESS_STATES.map(s => {
              this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES.push(s);
            });


            profilevalue.analysis.EXCLUDED_COUNTRIES_DISPLAY.map(s => {
              this.excludedcountryselected.push(s);
            });
            profilevalue.analysis.EXCLUDED_COUNTRIES_DISPLAY.map(s => {
              this.general_analytics_settings.EXCLUDED_COUNTRIES_DISPLAY.push(s);
            });
            profilevalue.analysis.EXCLUDED_COUNTRIES.map(s => {
              this.general_analytics_settings.EXCLUDED_COUNTRIES.push(s);
            });


            profilevalue.analysis.HAS_OTHER_QUALIFICATIONS_DISPLAY.map(s => {
              this.otherQualifications.push(s);
            });
            profilevalue.analysis.HAS_OTHER_QUALIFICATIONS_DISPLAY.map(s => {
              this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS_DISPLAY.push(s);
            });
            profilevalue.analysis.HAS_OTHER_QUALIFICATIONS.map(s => {
              this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS.push(s);
            });


            this.general_analytics_settings.LOCATION_PREMIUM_FACTOR = profilevalue.analysis.LOCATION_PREMIUM_FACTOR;
            this.general_analytics_settings.MAX_AGE = profilevalue.analysis.MAX_AGE;
            this.general_analytics_settings.MAX_DEBT_INCOME_RATIO = profilevalue.analysis.MAX_DEBT_INCOME_RATIO;
            this.general_analytics_settings.MAX_DEPENDANTS = profilevalue.analysis.MAX_DEPENDANTS;
            this.general_analytics_settings.MAX_EXPENSE_INCOME_RATIO = profilevalue.analysis.MAX_EXPENSE_INCOME_RATIO;
            this.general_analytics_settings.MAX_FEEDING_EXPENSES = profilevalue.analysis.MAX_FEEDING_EXPENSES;
            this.general_analytics_settings.MAX_FEEDING_TO_INCOME = profilevalue.analysis.MAX_FEEDING_TO_INCOME;
            this.general_analytics_settings.MAX_MONTHLY_RENT_EXPENSE = profilevalue.analysis.MAX_MONTHLY_RENT_EXPENSE;
            this.general_analytics_settings.MAX_MONTHLY_TRANSPORT = profilevalue.analysis.MAX_MONTHLY_TRANSPORT;
            this.general_analytics_settings.MAX_NO_OF_CHILDREN = profilevalue.analysis.MAX_NO_OF_CHILDREN;
            this.general_analytics_settings.MAX_RENT_TO_GROSS_ANNUAL_PERCENTAGE = profilevalue.analysis.MAX_RENT_TO_GROSS_ANNUAL_PERCENTAGE;
            this.general_analytics_settings.MAX_SCHOOL_FEES_EXPENSE = profilevalue.analysis.MAX_SCHOOL_FEES_EXPENSE;
            this.general_analytics_settings.MAX_SCHOOL_FEES_TO_INCOME = profilevalue.analysis.MAX_SCHOOL_FEES_TO_INCOME;
            this.general_analytics_settings.MAX_SPOUSE_INCOME = profilevalue.analysis.MAX_SPOUSE_INCOME;
            this.general_analytics_settings.MAX_SPOUSE_TO_INCOME = profilevalue.analysis.MAX_SPOUSE_TO_INCOME;
            this.general_analytics_settings.MAX_TOTAL_EXPENSES = profilevalue.analysis.MAX_TOTAL_EXPENSES;
            this.general_analytics_settings.MAX_TRANSPORT_TO_INCOME = profilevalue.analysis.MAX_TRANSPORT_TO_INCOME;
            this.general_analytics_settings.MIN_AGE = profilevalue.analysis.MIN_AGE;
            this.general_analytics_settings.MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE = profilevalue.analysis.MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE;
            this.general_analytics_settings.MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE_INCOME = profilevalue.analysis.MIN_BONUS_TO_GROSS_ANNUAL_PERCENTAGE_INCOME;
            this.general_analytics_settings.MIN_DEPENDANTS = profilevalue.analysis.MIN_DEPENDANTS;
            this.general_analytics_settings.MIN_FEEDING_EXPENSES = profilevalue.analysis.MIN_FEEDING_EXPENSES;
            this.general_analytics_settings.MIN_FEEDING_TO_INCOME = profilevalue.analysis.MIN_FEEDING_TO_INCOME;
            this.general_analytics_settings.MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE = profilevalue.analysis.MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE;
            this.general_analytics_settings.MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE_INCOME = profilevalue.analysis.MIN_MONTHLY_DEDUCTIONS_TO_GROSS_PERCENTAGE_INCOME;
            this.general_analytics_settings.MIN_MONTHLY_RENT_EXPENSE = profilevalue.analysis.MIN_MONTHLY_RENT_EXPENSE;
            this.general_analytics_settings.MIN_MONTHLY_TRANSPORT = profilevalue.analysis.MIN_MONTHLY_TRANSPORT;
            this.general_analytics_settings.MIN_NO_OF_CHILDREN = profilevalue.analysis.MIN_NO_OF_CHILDREN;

            profilevalue.analysis.MIN_QUALIFICATION_DISPLAY.map(s => {
              this.minQualifications.push(s);
            });
            profilevalue.analysis.MIN_QUALIFICATION_DISPLAY.map(s => {
              this.general_analytics_settings.MIN_QUALIFICATION_DISPLAY.push(s);
            });
            profilevalue.analysis.MIN_QUALIFICATION.map(s => {
              this.general_analytics_settings.MIN_QUALIFICATION.push(s);
            });

            this.general_analytics_settings.MIN_RENT_TO_GROSS_ANNUAL_PERCENTAGE = profilevalue.analysis.MIN_RENT_TO_GROSS_ANNUAL_PERCENTAGE;
            this.general_analytics_settings.MIN_SCHOOL_FEES_EXPENSE = profilevalue.analysis.MIN_SCHOOL_FEES_EXPENSE;
            this.general_analytics_settings.MIN_SCHOOL_FEES_TO_INCOME = profilevalue.analysis.MIN_SCHOOL_FEES_TO_INCOME;
            this.general_analytics_settings.MIN_SPOUSE_INCOME = profilevalue.analysis.MIN_SPOUSE_INCOME;
            this.general_analytics_settings.MIN_SPOUSE_TO_INCOME = profilevalue.analysis.MIN_SPOUSE_TO_INCOME;
            this.general_analytics_settings.MIN_TOTAL_EXPENSES = profilevalue.analysis.MIN_TOTAL_EXPENSES;
            this.general_analytics_settings.MIN_TRANSPORT_TO_INCOME = profilevalue.analysis.MIN_TRANSPORT_TO_INCOME;
            this.general_analytics_settings.MIN_YRS_SINCE_GRADUATION = profilevalue.analysis.MIN_YRS_SINCE_GRADUATION;
            this.general_analytics_settings.OFFICE_PREMIUM_FACTOR = profilevalue.analysis.OFFICE_PREMIUM_FACTOR;

            profilevalue.analysis.PREFERRED_BORROWER_GENDER_DISPLAY.map(s => {
              this.preferredgenderchoosen.push(s);
            });
            profilevalue.analysis.PREFERRED_BORROWER_GENDER_DISPLAY.map(s => {
              this.general_analytics_settings.PREFERRED_BORROWER_GENDER_DISPLAY.push(s);
            });
            profilevalue.analysis.PREFERRED_BORROWER_GENDER.map(s => {
              this.general_analytics_settings.PREFERRED_BORROWER_GENDER.push(s);
            });


            profilevalue.analysis.PREFERRED_BORROWER_OCCUPATION_DISPLAY.map(s => {
              this.borrowedoccupation.push(s);
            });
            profilevalue.analysis.PREFERRED_BORROWER_OCCUPATION_DISPLAY.map(s => {
              this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION_DISPLAY.push(s);
            });
            profilevalue.analysis.PREFERRED_BORROWER_OCCUPATION.map(s => {
              this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION.push(s);
            });


            profilevalue.analysis.PREFERRED_DESIGNATION_DISPLAY.map(s => {
              this.occupationDesigna.push(s);
            });
            profilevalue.analysis.PREFERRED_DESIGNATION_DISPLAY.map(s => {
              this.general_analytics_settings.PREFERRED_DESIGNATION_DISPLAY.push(s);
            });
            profilevalue.analysis.PREFERRED_DESIGNATION.map(s => {
              this.general_analytics_settings.PREFERRED_DESIGNATION.push(s);
            });


            profilevalue.analysis.PREFERRED_OCCUPATION_SECTOR_DISPLAY.map(s => {
              this.occupationBorrowSector.push(s);
            });
            profilevalue.analysis.PREFERRED_OCCUPATION_SECTOR_DISPLAY.map(s => {
              this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR_DISPLAY.push(s);
            });
            profilevalue.analysis.PREFERRED_OCCUPATION_SECTOR.map(s => {
              this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR.push(s);
            });
          }

          if (profilevalue.callLog) {

            this.call_log_analytics_settings.PH_AN_MONTHLY_CALL = profilevalue.callLog.PH_AN_MONTHLY_CALL;
            this.call_log_analytics_settings.PH_AN_MONTHLY_CALL_MARKET_AVG_MIN = profilevalue.callLog.PH_AN_MONTHLY_CALL_MARKET_AVG_MIN;
            this.call_log_analytics_settings.PH_AN_MONTHLY_CALL_MARKET_AVG_MAX = profilevalue.callLog.PH_AN_MONTHLY_CALL_MARKET_AVG_MAX;
            this.call_log_analytics_settings.PH_AN_PERCENTAGE_MONTHLY_CALL_TO_DECLARED_INCOME = profilevalue.callLog.PH_AN_PERCENTAGE_MONTHLY_CALL_TO_DECLARED_INCOME;
            this.call_log_analytics_settings.PH_AN_PERCENTAGE_NAMELESS_CALLS_TO_LOG = profilevalue.callLog.PH_AN_PERCENTAGE_NAMELESS_CALLS_TO_LOG;
            this.call_log_analytics_settings.PH_AN_PERCENTAGE_MONTHLY_EXTERNAL_COUNTRY_TO_TOTAL = profilevalue.callLog.PH_AN_PERCENTAGE_MONTHLY_EXTERNAL_COUNTRY_TO_TOTAL;


            this.call_log_analytics_settings.PH_AN_PERCENTAGE_OF_FAMILY_KEY_WORD_TO_TOTAL = profilevalue.callLog.PH_AN_PERCENTAGE_OF_FAMILY_KEY_WORD_TO_TOTAL;
            this.call_log_analytics_settings.PH_AN_VANITY_NUMBER_COUNT = profilevalue.callLog.PH_AN_VANITY_NUMBER_COUNT;

            profilevalue.callLog.PH_AN_REJECTED_FREQUENT_CALL_TIME_BELT.map(s => {
              this.call_log_analytics_settings.PH_AN_REJECTED_FREQUENT_CALL_TIME_BELT.push(s);
            });


            profilevalue.callLog.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES_DISPLAY.map(s => {
              this.blacklistedcountrycallselected.push(s);
            });
            profilevalue.callLog.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES_DISPLAY.map(s => {
              this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES_DISPLAY.push(s);
            });
            profilevalue.callLog.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES.map(s => {
              this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES.push(s);
            });


            profilevalue.callLog.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.map(s => {
              this.acceptedcountrycallselected.push(s);
            });
            profilevalue.callLog.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.map(s => {
              this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.push(s);
            });
            profilevalue.callLog.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES.map(s => {
              this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES.push(s);
            });
          }

          if (profilevalue.social) {
            this.social_analytics_settings.ALLOWED_CONNECTIONS = profilevalue.social.ALLOWED_CONNECTIONS;
            this.social_analytics_settings.PREFERED_LOCATION_STATE = profilevalue.social.PREFERED_LOCATION_STATE;
            this.social_analytics_settings.PREFERED_LOCATION_STATE_DISPLAY = profilevalue.social.PREFERED_LOCATION_STATE_DISPLAY;
            this.social_analytics_settings.MIN_RANGE = profilevalue.social.MIN_RANGE;
            this.social_analytics_settings.MAX_RANGE = profilevalue.social.MAX_RANGE;
            this.social_analytics_settings.LAST_POST_DATE_ALLOWED = profilevalue.social.LAST_POST_DATE_ALLOWED;
            this.social_analytics_settings.FOLLOWERS_COUNT_ALLOWED = profilevalue.social.FOLLOWERS_COUNT_ALLOWED;
            this.social_analytics_settings.FOLLOWING_COUNT_ALLOWED = profilevalue.social.FOLLOWING_COUNT_ALLOWED;
            this.social_analytics_settings.FOLLOWERS_TO_FOLLOWING_RATIO_ALLOWED = profilevalue.social.FOLLOWERS_TO_FOLLOWING_RATIO_ALLOWED;
            this.social_analytics_settings.FAVOURITE_COUNT_ALLOWED = profilevalue.social.FAVOURITE_COUNT_ALLOWED;
            this.social_analytics_settings.STATUSES_COUNT_ALLOWED = profilevalue.social.STATUSES_COUNT_ALLOWED;
            this.social_analytics_settings.CREATED_AT_ALLOWED = profilevalue.social.CREATED_AT_ALLOWED;
          }

          if (profilevalue.guarantor) {

            profilevalue.guarantor.GUARANTOR_ALLOWED_SECTOR_DISPLAY.map(s => {
              this.guarantorOccupaionAllowed.push(s);
            });
            profilevalue.guarantor.GUARANTOR_ALLOWED_SECTOR_DISPLAY.map(s => {
              this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR_DISPLAY.push(s);
            });
            // profilevalue.callLog.GUARANTOR_ALLOWED_SECTOR.map( s => {
            //   this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR.push(s);
            // });

            this.guarantor_analytics_settings.GUARANTOR_ALLOWED_LOAN_TO_INCOME_RATIO = profilevalue.guarantor.GUARANTOR_ALLOWED_LOAN_TO_INCOME_RATIO;
            this.guarantor_analytics_settings.GUARANTOR_ALLOWED_HOME_OWNERSHIP_STATUS = profilevalue.guarantor.GUARANTOR_ALLOWED_HOME_OWNERSHIP_STATUS;
            this.guarantor_analytics_settings.GUARANTOR_ALLOWED_HOME_MINIMUM_YEARS = profilevalue.guarantor.GUARANTOR_ALLOWED_HOME_MINIMUM_YEARS;
            this.guarantor_analytics_settings.GUARANTOR_ALLOWED_RESPONSE_TIME = profilevalue.guarantor.GUARANTOR_ALLOWED_RESPONSE_TIME;
          }

          if (profilevalue.direct_debit) {
            this.direct_debit_analytics_settings.MINIMUM_DIRECT_DEBIT_ACCOUNTS = profilevalue.direct_debit.MINIMUM_DIRECT_DEBIT_ACCOUNTS;
          }

          profilevalue.accounts.ALLOWED_ACCOUNT_BANKS_DISPLAY.map(s => {
            this.accountSelectedBank.push(s);
          });
          profilevalue.accounts.ALLOWED_ACCOUNT_BANKS_DISPLAY.map(s => {
            this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS_DISPLAY.push(s);
          });
          profilevalue.accounts.ALLOWED_ACCOUNT_BANKS.map(s => {
            this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS.push(s);
          });


          profilevalue.accounts.ALLOWED_CARD_BANKS_DISPLAY.map(s => {
            this.allowedAccountCards.push(s);
          });
          profilevalue.accounts.ALLOWED_CARD_BANKS_DISPLAY.map(s => {
            this.accounts_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY.push(s);
          });
          profilevalue.accounts.ALLOWED_CARD_BANKS.map(s => {
            this.accounts_analytics_settings.ALLOWED_CARD_BANKS.push(s);
          });



          if (profilevalue.card) {
            profilevalue.card.ALLOWED_CARD_BANKS_DISPLAY.map(s => {
              this.allowedCardBank.push(s);
            });
            profilevalue.card.ALLOWED_CARD_BANKS_DISPLAY.map(s => {
              this.card_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY.push(s);
            });
            profilevalue.card.ALLOWED_CARD_BANKS.map(s => {
              this.card_analytics_settings.ALLOWED_CARD_BANKS.push(s);
            });


            profilevalue.card.ALLOWED_CARD_TYPE_DISPLAY.map(s => {
              this.allowedCards.push(s);
            });
            profilevalue.card.ALLOWED_CARD_TYPE_DISPLAY.map(s => {
              this.card_analytics_settings.ALLOWED_CARD_TYPE_DISPLAY.push(s);
            });
            profilevalue.card.ALLOWED_CARD_TYPE.map(s => {
              this.card_analytics_settings.ALLOWED_CARD_TYPE.push(s);
            });


          }

          if (profilevalue.sms) {
            this.sms_analytics_settings.PERCENTAGE_OVERALL = profilevalue.sms.PERCENTAGE_OVERALL;
            this.sms_analytics_settings.PERCENTAGE_INCOME_TO_CREDIT = profilevalue.sms.PERCENTAGE_INCOME_TO_CREDIT;
            this.sms_analytics_settings.PERCENTAGE_OVERSPENDING = profilevalue.sms.PERCENTAGE_OVERSPENDING;
            this.sms_analytics_settings.PERCENTAGE_CREDIT_DEBIT_INCOME = profilevalue.sms.PERCENTAGE_CREDIT_DEBIT_INCOME;
            this.sms_analytics_settings.PERCENTAGE_INCOME_TO_DEBIT = profilevalue.sms.PERCENTAGE_INCOME_TO_DEBIT;
            this.sms_analytics_settings.PERCENTAGE_AIRTIME_TO_INCOME = profilevalue.sms.PERCENTAGE_AIRTIME_TO_INCOME;
            this.sms_analytics_settings.PERCENTAGE_OTHER_EXPENSES_TO_INCOME = profilevalue.sms.PERCENTAGE_OTHER_EXPENSES_TO_INCOME;
            this.sms_analytics_settings.PERCENTAGE_INCOME_TO_MONTHLY_SALARY_TOTAL =
              profilevalue.sms.PERCENTAGE_INCOME_TO_MONTHLY_SALARY_TOTAL;
            this.sms_analytics_settings.PERCENTAGE_AVERAGE_CREDIT = profilevalue.sms.PERCENTAGE_AVERAGE_CREDIT;
            this.sms_analytics_settings.PERCENTAGE_AVERAGE_DEBIT = profilevalue.sms.PERCENTAGE_AVERAGE_DEBIT;
          }


        }
      });
  }

  showmodal() {
    this.modal = true;
  }

  hidemodal() {
    this.modal = false;
  }

  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.buttonName = "Hide";
    } else {
      this.buttonName = "Show";
    }
  }

  checkedchange(event) {
  }


  isAttrubuteChecked(order, i) {
    if (this.analysisdata.indexOf(order.value) != -1) {
      return true;
    } else {
      return false;
    }
  }

  private addCheckboxes() {
    this.orders.map((o, i) => {
      if (o.checked === true) {
        this.counter++;

      }
      if (o.leave_type == '0') {
        this.showProfile = true;
      }
      this.total_default = 50;
      const control = new FormControl(); // if first item set to true, else false
      (this.form.controls.orders as FormArray).push(control);
    });

  }

  private addCheckboxesGuarantor() {
    this.guarantor.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showGuarantor = true;
      }
      const control = new FormControl(); // if first item set to true, else false
      (this.guarantorform.controls.guarantor as FormArray).push(control);
    });
  }

  private addCheckboxesDirectdebit() {
    this.directdebit.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showDirectDebit = true;
      }
      const control = new FormControl(); // if first item set to true, else false
      (this.directdebitform.controls.directdebit as FormArray).push(control);
    });
  }

  private addCheckboxesCreditbureau() {
    this.creditbureau.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      // if (o.leave_type == '0') {
      //   this.showDirectDebit = true;
      // }
      const control = new FormControl(); // if first item set to true, else false
      (this.creditbureauform.controls.creditbureau as FormArray).push(control);
    });
  }

  private addCheckboxesLoanspider() {
    this.loanspider.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      // if (o.leave_type == '0') {
      //   this.showDirectDebit = true;
      // }
      const control = new FormControl(); // if first item set to true, else false
      (this.loanspiderform.controls.loanspider as FormArray).push(control);
    });
  }
  private addCheckboxesIdentity() {
    this.identity.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      // if (o.leave_type == '0') {
      //   this.showDirectDebit = true;
      // }
      const control = new FormControl(); // if first item set to true, else false
      (this.identityform.controls.identity as FormArray).push(control);
    });
  }
  private addCheckboxesBankstatement() {
    this.bankstatement.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      // if (o.leave_type == '0') {
      //   this.showDirectDebit = true;
      // }
      const control = new FormControl(); // if first item set to true, else false
      (this.bankstatementform.controls.bankstatement as FormArray).push(control);
    });
  }
  private addCheckboxesQuestion() {
    this.question.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      // if (o.leave_type == '0') {
      //   this.showDirectDebit = true;
      // }
      const control = new FormControl(); // if first item set to true, else false
      (this.questionform.controls.question as FormArray).push(control);
    });
  }
  private addCheckboxesFcn() {
    this.fcn.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      // if (o.leave_type == '0') {
      //   this.showDirectDebit = true;
      // }
      const control = new FormControl(); // if first item set to true, else false
      (this.fcnform.controls.fcn as FormArray).push(control);
    });
  }
  private addCheckboxesMerchant() {
    this.merchant.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      // if (o.leave_type == '0') {
      //   this.showDirectDebit = true;
      // }
      const control = new FormControl(); // if first item set to true, else false
      (this.merchantform.controls.merchant as FormArray).push(control);
    });
  }
  private addCheckboxesCard() {
    this.atmcard.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showCard = true;
      }
      const control = new FormControl(); // if first item set to true, else false
      (this.cardform.controls.atmcard as FormArray).push(control);
    });
  }

  private addCheckboxesCallLogs() {
    this.callLogs.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showCalllogs = true;
      }
      const control = new FormControl(); // if first item set to true, else false
      (this.callLogform.controls.callLogs as FormArray).push(control);
    });
  }

  private addCheckboxesSocial() {
    this.social.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showSocial = true;
      }
      const control = new FormControl(); // if first item set to true, else false
      (this.socialform.controls.social as FormArray).push(control);
    });
  }

  private addCheckboxesRepayment() {
    this.repayments.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showRepayment = true;
      }
      const control = new FormControl(); // if first item set to true, else false
      (this.repaymentform.controls.repayments as FormArray).push(control);
    });
  }

  private addCheckboxesSms() {
    this.phonesms.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showSMS = true;
      }
      const control = new FormControl(); // if first item set to true, else false
      (this.smsform.controls.phonesms as FormArray).push(control);
    });
  }

  private addCheckboxesAcount() {
    this.account.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showAccount = true;
      }
      const control = new FormControl(); // if first item set to true, else false
      (this.accountform.controls.account as FormArray).push(control);
    });
  }
  private addCheckboxesBvn() {
    this.bvn.map((o, i) => {
      if (o.checked === true) {
        this.counter++;
      }
      if (o.leave_type == '0') {
        this.showBVN = true;
      }
      const control = new FormControl();
      (this.bvnform.controls.bvn as FormArray).push(control);
    });
  }

  checkBox(e, objs, i, section) {

    if (e.target.checked === true) {
      if (this.counter == 50) {
        objs[i].checked = false;
      } else {
        this.counter++;
      }

    }

    if (e.target.checked === false) {
      this.counter--;

    }
  }
  disableCheckboxes(event) {
    if (event == false && this.counter == 50) {
      return true;
    }
  }
  submitGlobalAnalysis() {
    if (this.counter > 50) {
      this.toastr.error('You can only select 50', 'Error!');
    } else {
      this.loading = true;
      const selectedGuarantorIds = this.guarantorform.value.guarantor
        .map((v, i) => v ? this.guarantor[i].merchantclan_leave_id : null)
        .filter(v => v !== null);
      const guarantorStructure = this.guarantorform.value.guarantor;


      const selectedCreditbureauIds = this.creditbureauform.value.creditbureau
        .map((v, i) => v ? this.creditbureau[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const creditBureauStructure = this.creditbureauform.value.creditbureau;



      const selectedLoanSpiderIds = this.loanspiderform.value.loanspider
        .map((v, i) => v ? this.loanspider[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const loanSpiderStructure = this.loanspiderform.value.loanspider;

      const selectedIdentityIds = this.identityform.value.identity
        .map((v, i) => v ? this.identity[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const identityStructure = this.identityform.value.identity;

      const selectedBankstatementIds = this.bankstatementform.value.bankstatement
        .map((v, i) => v ? this.bankstatement[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const bankStatementStructure = this.bankstatementform.value.bankstatement;

      const selectedQuestionIds = this.questionform.value.question
        .map((v, i) => v ? this.question[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const questionStructure = this.questionform.value.question;

      const selectedFcnIds = this.fcnform.value.fcn
        .map((v, i) => v ? this.fcn[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const fcnStructure = this.fcnform.value.fcn;

      const selectedMerchantIds = this.merchantform.value.merchant
        .map((v, i) => v ? this.merchant[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const merchantStructure = this.merchantform.value.merchant;

      // const selectedDirectdebitIds = this.directdebitform.value.directdebit
      //   .map((v, i) => v ? this.directdebit[i].merchantclan_leave_id : null)
      //   .filter(v => v !== null);

      //const directDebitStructure = this.directdebitform.value.directdebit;

      const selectedCardIds = this.cardform.value.atmcard
        .map((v, i) => v ? this.atmcard[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const atmCardStructure = this.cardform.value.atmcard;

      const selectedCallIds = this.callLogform.value.callLogs
        .map((v, i) => v ? this.callLogs[i].merchantclan_leave_id : null)
        .filter(v => v !== null);

      const callLogStructure = this.callLogform.value.callLogs;

      const selectedSocialIds = this.socialform.value.social
        .map((v, i) => v ? this.social[i].merchantclan_leave_id : null)
        .filter(v => v !== null);
      const socialStructure = this.socialform.value.social;

      const selectedRepaymentIds = this.repaymentform.value.repayments
        .map((v, i) => v ? this.repayments[i].merchantclan_leave_id : null)
        .filter(v => v !== null);
      const repaymentStructure = this.repaymentform.value.repayments;

      const selectedOrderIds = this.form.value.orders
        .map((v, i) => v ? this.orders[i].merchantclan_leave_id : null)
        .filter(v => v !== null);
      const profileStructure = this.form.value.orders;

      const selectedAccountIds = this.accountform.value.account
        .map((v, i) => v ? this.account[i].merchantclan_leave_id : null)
        .filter(v => v !== null);
      const accountStructure = this.accountform.value.account;

      const selectedBvnIds = this.bvnform.value.bvn
        .map((v, i) => v ? this.bvn[i].merchantclan_leave_id : null)
        .filter(v => v !== null);
      const bvnStructure = this.bvnform.value.bvn;

      const selectedSmsIds = this.smsform.value.phonesms
        .map((v, i) => v ? this.phonesms[i].merchantclan_leave_id : null)
        .filter(v => v !== null);
      const smsStructure = this.smsform.value.phonesms;



      const getToken = localStorage.getItem('token');
      const passToken = {
        token: getToken,
        band_id: this.band_id,
        ecosystem: this.ecosystem,
        band_type: this.band_type,
        band_details: this.band_details
      };

      const param = {
        token: getToken,
        profile: selectedOrderIds,
        guarantor: selectedGuarantorIds,
        account: selectedAccountIds,
        card: selectedCardIds,
        bvn: selectedBvnIds,
        //directdebit: selectedDirectdebitIds,
        creditbureau: selectedCreditbureauIds,
        loanspider: selectedLoanSpiderIds,
        identity: selectedIdentityIds,
        bankstatement: selectedBankstatementIds,
        question: selectedQuestionIds,
        fcn: selectedFcnIds,
        merchant: selectedMerchantIds,
        sms: selectedSmsIds,
        call: selectedCallIds,
        social: selectedSocialIds,
        repayment: selectedRepaymentIds,

        profile_s: profileStructure,
        guarantor_s: guarantorStructure,
        account_s: accountStructure,
        card_s: atmCardStructure,
        //directdebit_s: directDebitStructure,
        creditbureau_s: creditBureauStructure,
        loanspider_s: loanSpiderStructure,
        identity_s: identityStructure,
        bankstatement_s: bankStatementStructure,
        question_s: questionStructure,
        fcn_s: fcnStructure,
        merchant_s: merchantStructure,
        sms_s: smsStructure,
        call_s: callLogStructure,
        social_s: socialStructure,
        repayment_s: repaymentStructure,
        bvn_s: bvnStructure,
      };

      this.profileAnalysis.updatemasteranalysis(passToken, param)
        .subscribe((result) => {
          this.profileAnalysis.updatemerchantclan(passToken, param)
            .subscribe((result) => {

            });
          this.loading = false;
          this.toastr.success(result.data, 'Success!');
          this.getSettingData();
          this.hidemodal();
          this.dataService.eligibilitySettingsChanged.emit({})
        });

    }
  }


  stateallowed(event) {
    this.general_analytics_settings.ALLOW_ADDRESS_STATES.push(event.target.value);


    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;
    this.stateSelected.push(selectElementText);
    this.general_analytics_settings.ALLOW_ADDRESS_STATES.push(selectElementText);
  }

  removeFromstateallowed(id) {
    this.general_analytics_settings.ALLOW_ADDRESS_STATES
      .splice(this.general_analytics_settings.ALLOW_ADDRESS_STATES
        .indexOf(id), 1);

    this.general_analytics_settings.ALLOW_ADDRESS_STATES_DISPLAY
      .splice(this.general_analytics_settings.ALLOW_ADDRESS_STATES_DISPLAY
        .indexOf(id), 1);

    this.stateSelected.splice(this.stateSelected.indexOf(id), 1);
  }

  isAvailableInacceptedState(i) {
    if (this.general_analytics_settings.ALLOW_ADDRESS_STATES.indexOf(i) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // Occupation borrowed
  borrowedOccupation(event) {

    if (!this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION.includes(event.target.value)) {
      this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.borrowedoccupation.includes(selectElementText)) {
      this.borrowedoccupation.push(selectElementText);
    }

    if (!this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION_DISPLAY.push(selectElementText);
    }

  }

  removeBorrowedOccupation(id) {
    this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION
      .splice(this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION
        .indexOf(id), 1);

    this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION_DISPLAY
      .splice(this.general_analytics_settings.PREFERRED_BORROWER_OCCUPATION_DISPLAY
        .indexOf(id), 1);

    this.borrowedoccupation.splice(this.borrowedoccupation.indexOf(id), 1);
  }


  // Occupation designation
  occupationDesignation(event) {

    if (!this.general_analytics_settings.PREFERRED_DESIGNATION.includes(event.target.value)) {
      this.general_analytics_settings.PREFERRED_DESIGNATION.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.occupationDesigna.includes(selectElementText)) {
      this.occupationDesigna.push(selectElementText);
    }

    if (!this.general_analytics_settings.PREFERRED_DESIGNATION_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.PREFERRED_DESIGNATION_DISPLAY.push(selectElementText);
    }

  }

  removeoccupationDesignation(id) {
    this.general_analytics_settings.PREFERRED_DESIGNATION
      .splice(this.general_analytics_settings.PREFERRED_DESIGNATION
        .indexOf(id), 1);

    this.general_analytics_settings.PREFERRED_DESIGNATION_DISPLAY
      .splice(this.general_analytics_settings.PREFERRED_DESIGNATION_DISPLAY
        .indexOf(id), 1);

    this.occupationDesigna.splice(this.occupationDesigna.indexOf(id), 1);
  }


  // Occupation section
  borrowerOccupationSector(event) {

    if (!this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR.includes(event.target.value)) {
      this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.occupationBorrowSector.includes(selectElementText)) {
      this.occupationBorrowSector.push(selectElementText);
    }

    if (!this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR_DISPLAY.push(selectElementText);
    }

  }

  removeborroweroccpation(id) {
    this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR
      .splice(this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR
        .indexOf(id), 1);

    this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR_DISPLAY
      .splice(this.general_analytics_settings.PREFERRED_OCCUPATION_SECTOR_DISPLAY
        .indexOf(id), 1);

    this.occupationBorrowSector.splice(this.occupationBorrowSector.indexOf(id), 1);
  }


  // Minimum qualification
  minimumQualification(event) {

    if (!this.general_analytics_settings.MIN_QUALIFICATION.includes(event.target.value)) {
      this.general_analytics_settings.MIN_QUALIFICATION.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;


    if (!this.minQualifications.includes(selectElementText)) {
      this.minQualifications.push(selectElementText);
    }

    if (!this.general_analytics_settings.MIN_QUALIFICATION_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.MIN_QUALIFICATION_DISPLAY.push(selectElementText);
    }

  }

  removeminimunmqualification(id) {
    this.general_analytics_settings.MIN_QUALIFICATION
      .splice(this.general_analytics_settings.MIN_QUALIFICATION
        .indexOf(id), 1);

    this.general_analytics_settings.MIN_QUALIFICATION_DISPLAY
      .splice(this.general_analytics_settings.MIN_QUALIFICATION_DISPLAY
        .indexOf(id), 1);

    this.minQualifications.splice(this.minQualifications.indexOf(id), 1);
  }

  // Other qualification
  otherQualification(event) {

    if (!this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS.includes(event.target.value)) {
      this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.otherQualifications.includes(selectElementText)) {
      this.otherQualifications.push(selectElementText);
    }

    if (!this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS_DISPLAY.push(selectElementText);
    }


  }

  removeotherqualification(id) {
    this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS
      .splice(this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS
        .indexOf(id), 1);

    this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS_DISPLAY
      .splice(this.general_analytics_settings.HAS_OTHER_QUALIFICATIONS_DISPLAY
        .indexOf(id), 1);

    this.otherQualifications.splice(this.otherQualifications.indexOf(id), 1);
  }



  // Excluded state
  stateoriginallowed(event) {


    if (!this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN.includes(event.target.value)) {
      this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.stateOriginSelected.includes(selectElementText)) {
      this.stateOriginSelected.push(selectElementText);
    }

    if (!this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN_DISPLAY.push(selectElementText);
    }

  }

  removeFromstateoriginallowed(id) {
    this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN
      .splice(this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN
        .indexOf(id), 1);

    this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN_DISPLAY
      .splice(this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN_DISPLAY
        .indexOf(id), 1);

    this.stateOriginSelected.splice(this.stateOriginSelected.indexOf(id), 1);
  }

  isAvailableInacceptedStateOrigin(i) {
    if (this.general_analytics_settings.ALLOWED_STATE_OF_ORIGIN.indexOf(i) > -1) {
      return true;
    } else {
      return false;
    }
  }

  // Social state allowed
  statesocial(event) {

    if (!this.social_analytics_settings.PREFERED_LOCATION_STATE.includes(event.target.value)) {
      this.social_analytics_settings.PREFERED_LOCATION_STATE.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.stateOriginSelectedsocial.includes(selectElementText)) {
      this.stateOriginSelectedsocial.push(selectElementText);
    }


    if (!this.social_analytics_settings.PREFERED_LOCATION_STATE_DISPLAY.includes(selectElementText)) {
      this.social_analytics_settings.PREFERED_LOCATION_STATE_DISPLAY.push(selectElementText);
    }

  }

  removeFromsocialallowed(id) {
    this.social_analytics_settings.PREFERED_LOCATION_STATE
      .splice(this.social_analytics_settings.PREFERED_LOCATION_STATE
        .indexOf(id), 1);

    this.social_analytics_settings.PREFERED_LOCATION_STATE_DISPLAY
      .splice(this.social_analytics_settings.PREFERED_LOCATION_STATE_DISPLAY
        .indexOf(id), 1);

    this.stateOriginSelectedsocial.splice(this.stateOriginSelectedsocial.indexOf(id), 1);
  }


  // Guarantor occupation allowed
  guarantorOccupation(event) {

    if (!this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR.includes(event.target.value)) {
      this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;


    if (!this.guarantorOccupaionAllowed.includes(selectElementText)) {
      this.guarantorOccupaionAllowed.push(selectElementText);
    }

    if (!this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR_DISPLAY.includes(selectElementText)) {
      this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR_DISPLAY.push(selectElementText);
    }

  }

  removeGuarantorOccupation(id) {
    this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR
      .splice(this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR
        .indexOf(id), 1);

    this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR_DISPLAY
      .splice(this.guarantor_analytics_settings.GUARANTOR_ALLOWED_SECTOR_DISPLAY
        .indexOf(id), 1);

    this.guarantorOccupaionAllowed.splice(this.guarantorOccupaionAllowed.indexOf(id), 1);
  }


  // Account allowed banks
  accountBankAllowed(event) {

    if (!this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS.includes(event.target.value)) {
      this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.accountSelectedBank.includes(selectElementText)) {
      this.accountSelectedBank.push(selectElementText);
    }


    if (!this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS_DISPLAY.includes(selectElementText)) {
      this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS_DISPLAY.push(selectElementText);
    }

  }



  removeAccountAllowedBanks(id) {
    this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS
      .splice(this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS
        .indexOf(id), 1);

    this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS_DISPLAY
      .splice(this.accounts_analytics_settings.ALLOWED_ACCOUNT_BANKS_DISPLAY
        .indexOf(id), 1);

    this.accountSelectedBank.splice(this.accountSelectedBank.indexOf(id), 1);
  }




  // Account allowed banks
  accountCardAllowed(event) {

    if (!this.accounts_analytics_settings.ALLOWED_CARD_BANKS.includes(event.target.value)) {
      this.accounts_analytics_settings.ALLOWED_CARD_BANKS.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.allowedAccountCards.includes(selectElementText)) {
      this.allowedAccountCards.push(selectElementText);
    }

    if (!this.accounts_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY.includes(selectElementText)) {
      this.accounts_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY.push(selectElementText);
    }

  }

  removeAccountAllowedCards(id) {
    this.accounts_analytics_settings.ALLOWED_CARD_BANKS
      .splice(this.accounts_analytics_settings.ALLOWED_CARD_BANKS
        .indexOf(id), 1);

    this.accounts_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY
      .splice(this.accounts_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY
        .indexOf(id), 1);

    this.allowedAccountCards.splice(this.allowedAccountCards.indexOf(id), 1);
  }



  // Card allowed banks
  cardBankAllowed(event) {

    if (!this.card_analytics_settings.ALLOWED_CARD_BANKS.includes(event.target.value)) {
      this.card_analytics_settings.ALLOWED_CARD_BANKS.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;


    if (!this.allowedCardBank.includes(selectElementText)) {
      this.allowedCardBank.push(selectElementText);
    }


    if (!this.card_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY.includes(selectElementText)) {
      this.card_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY.push(selectElementText);
    }

  }

  removeAccountAllowedCard(id) {
    this.card_analytics_settings.ALLOWED_CARD_BANKS
      .splice(this.card_analytics_settings.ALLOWED_CARD_BANKS
        .indexOf(id), 1);

    this.card_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY
      .splice(this.card_analytics_settings.ALLOWED_CARD_BANKS_DISPLAY
        .indexOf(id), 1);

    this.allowedCardBank.splice(this.allowedCardBank.indexOf(id), 1);
  }


  // Card allowed banks
  cardAllowedForCard(event) {

    if (!this.card_analytics_settings.ALLOWED_CARD_TYPE.includes(event.target.value)) {
      this.card_analytics_settings.ALLOWED_CARD_TYPE.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;


    if (!this.allowedCards.includes(selectElementText)) {
      this.allowedCards.push(selectElementText);
    }

    if (!this.card_analytics_settings.ALLOWED_CARD_TYPE_DISPLAY.includes(selectElementText)) {
      this.card_analytics_settings.ALLOWED_CARD_TYPE_DISPLAY.push(selectElementText);
    }

  }

  removeCardAllowedCards(id) {
    this.card_analytics_settings.ALLOWED_CARD_TYPE
      .splice(this.card_analytics_settings.ALLOWED_CARD_TYPE
        .indexOf(id), 1);

    this.card_analytics_settings.ALLOWED_CARD_TYPE_DISPLAY
      .splice(this.card_analytics_settings.ALLOWED_CARD_TYPE_DISPLAY
        .indexOf(id), 1);

    this.allowedCards.splice(this.allowedCards.indexOf(id), 1);
  }


  // Excluded work state

  stateworkoriginallowed(event) {

    if (!this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES.includes(event.target.value)) {
      this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;


    if (!this.stateWorkOriginSelectedupdate.includes(selectElementText)) {
      this.stateWorkOriginSelectedupdate.push(selectElementText);
    }

    if (!this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES_DISPLAY.push(selectElementText);
    }
  }

  removeFromstateworkoriginallowed(id) {
    this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES
      .splice(this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES
        .indexOf(id), 1);

    this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES_DISPLAY
      .splice(this.general_analytics_settings.ALLOW_OFFICE_ADDRESS_STATES_DISPLAY
        .indexOf(id), 1);

    this.stateWorkOriginSelectedupdate.splice(this.stateWorkOriginSelectedupdate.indexOf(id), 1);
  }



  // Allow country of origin

  excludedcountry(event) {

    if (!this.general_analytics_settings.EXCLUDED_COUNTRIES.includes(event.target.value)) {
      this.general_analytics_settings.EXCLUDED_COUNTRIES.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.excludedcountryselected.includes(selectElementText)) {
      this.excludedcountryselected.push(selectElementText);
    }
    if (!this.general_analytics_settings.EXCLUDED_COUNTRIES_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.EXCLUDED_COUNTRIES_DISPLAY.push(selectElementText);
    }

  }

  excludedcountryallowed(id) {
    this.general_analytics_settings.EXCLUDED_COUNTRIES
      .splice(this.general_analytics_settings.EXCLUDED_COUNTRIES.indexOf(id), 1);

    this.general_analytics_settings.EXCLUDED_COUNTRIES_DISPLAY
      .splice(this.general_analytics_settings.EXCLUDED_COUNTRIES_DISPLAY.indexOf(id), 1);

    this.excludedcountryselected.splice(this.excludedcountryselected.indexOf(id), 1);
  }




  // Preferred gender

  preferredgender(event) {
    if (!this.general_analytics_settings.PREFERRED_BORROWER_GENDER.includes(event.target.value)) {
      this.general_analytics_settings.PREFERRED_BORROWER_GENDER.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.preferredgenderchoosen.includes(selectElementText)) {
      this.preferredgenderchoosen.push(selectElementText);
    }

    if (!this.general_analytics_settings.PREFERRED_BORROWER_GENDER_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.PREFERRED_BORROWER_GENDER_DISPLAY.push(selectElementText);
    }
  }

  removedpreferredgenderchoosen(id) {
    this.general_analytics_settings.PREFERRED_BORROWER_GENDER
      .splice(this.general_analytics_settings.PREFERRED_BORROWER_GENDER.indexOf(id), 1);

    this.general_analytics_settings.PREFERRED_BORROWER_GENDER_DISPLAY
      .splice(this.general_analytics_settings.PREFERRED_BORROWER_GENDER_DISPLAY.indexOf(id), 1);

    this.preferredgenderchoosen.splice(this.preferredgenderchoosen.indexOf(id), 1);
  }


  // Preferred marital status

  preferredmaritals(event) {

    if (!this.general_analytics_settings.ALLOW_MARITAL_STATUS.includes(event.target.value)) {
      this.general_analytics_settings.ALLOW_MARITAL_STATUS.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.preferredmaritalstatuschoosen.includes(selectElementText)) {
      this.preferredmaritalstatuschoosen.push(selectElementText);
    }

    if (!this.general_analytics_settings.ALLOW_MARITAL_STATUS_DISPLAY.includes(selectElementText)) {
      this.general_analytics_settings.ALLOW_MARITAL_STATUS_DISPLAY.push(selectElementText);
    }
  }

  removedpreferredmaritalchoosen(id) {
    this.general_analytics_settings.ALLOW_MARITAL_STATUS
      .splice(this.general_analytics_settings.ALLOW_MARITAL_STATUS.indexOf(id), 1);

    this.general_analytics_settings.ALLOW_MARITAL_STATUS_DISPLAY
      .splice(this.general_analytics_settings.ALLOW_MARITAL_STATUS_DISPLAY.indexOf(id), 1);

    this.preferredmaritalstatuschoosen.splice(this.preferredmaritalstatuschoosen.indexOf(id), 1);
  }


  // Accepted time belt call log

  calllogtimebelts(event) {

    if (!this.call_log_analytics_settings.PH_AN_REJECTED_FREQUENT_CALL_TIME_BELT.includes(event.target.value)) {
      this.call_log_analytics_settings.PH_AN_REJECTED_FREQUENT_CALL_TIME_BELT.push(event.target.value);
    }

    if (!this.timebeltcalllogselected.includes(event.target.value)) {
      this.timebeltcalllogselected.push(event.target.value);
    }
  }

  acceptedtimebeltRemoved(id) {
    this.call_log_analytics_settings.PH_AN_REJECTED_FREQUENT_CALL_TIME_BELT
      .splice(this.call_log_analytics_settings.PH_AN_REJECTED_FREQUENT_CALL_TIME_BELT.indexOf(id), 1);

    this.timebeltcalllogselected.splice(this.acceptedcountrycallselected.indexOf(id), 1);
  }


  // Call Log Blacklisted external country PH_AN_ACCEPTED_EXTERNAL_COUNTRIES

  blacklistedcountrycall(event) {

    if (!this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES.includes(event.target.value)) {
      this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;


    if (!this.blacklistedcountrycallselected.includes(selectElementText)) {
      this.blacklistedcountrycallselected.push(selectElementText);
    }

    if (!this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES_DISPLAY.includes(selectElementText)) {
      this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES_DISPLAY.push(selectElementText);
    }
  }

  blacklistedcountrycallallowed(id) {
    this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES
      .splice(this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES.indexOf(id), 1);

    this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES_DISPLAY
      .splice(this.call_log_analytics_settings.PH_AN_BLACKLISTED_EXTERNAL_COUNTRIES_DISPLAY.indexOf(id), 1);

    this.blacklistedcountrycallselected.splice(this.blacklistedcountrycallselected.indexOf(id), 1);
  }

  // Accepted external country call log

  acceptedcountrycall(event) {

    if (!this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES.includes(event.target.value)) {
      this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;


    if (!this.acceptedcountrycallselected.includes(selectElementText)) {
      this.acceptedcountrycallselected.push(selectElementText);
    }

    if (!this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.includes(selectElementText)) {
      this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.push(selectElementText);
    }
  }

  acceptedcountryRemoved(id) {
    this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES
      .splice(this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES.indexOf(id), 1);

    this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY
      .splice(this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.indexOf(id), 1);

    this.acceptedcountrycallselected.splice(this.acceptedcountrycallselected.indexOf(id), 1);
  }



  // Accepted external country

  externalCountrycall(event) {

    if (!this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES.includes(event.target.value)) {
      this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.externalCountrycallselected.includes(selectElementText)) {
      this.externalCountrycallselected.push(selectElementText);
    }

    if (!this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.includes(selectElementText)) {
      this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.push(selectElementText);
    }
  }

  externalCountrycallallowed(id) {
    this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES
      .splice(this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES.indexOf(id), 1);

    this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY
      .splice(this.call_log_analytics_settings.PH_AN_ACCEPTED_EXTERNAL_COUNTRIES_DISPLAY.indexOf(id), 1);

    this.externalCountrycallselected.splice(this.externalCountrycallselected.indexOf(id), 1);
  }



  preferredmaritalevent(event) {
    if (this.update_analytics_settings.ALLOW_MARITAL_STATUS.includes(event.srcElement.value)) {
      return false;
    } else {
      this.update_analytics_settings.ALLOW_MARITAL_STATUS.push(event.srcElement.value);
    }
  }



  allowedNfk(event) {

    if (!this.general_analytics_settings.ALLOWED_NOK.includes(event.target.value)) {
      this.general_analytics_settings.ALLOWED_NOK.push(event.target.value);
    }

    let selectedOptions = event.target['options'];
    let selectedIndex = selectedOptions.selectedIndex;
    let selectElementText = selectedOptions[selectedIndex].text;

    if (!this.nokSelectedupdate.includes(selectElementText)) {
      this.nokSelectedupdate.push(selectElementText);
    }
  }

  removeFromNokallowedupdate(id) {
    this.general_analytics_settings.ALLOWED_NOK
      .splice(this.general_analytics_settings.ALLOWED_NOK
        .indexOf(id), 1);

    this.nokSelectedupdate.splice(this.nokSelectedupdate.indexOf(id), 1);
  }

  homeownershipststus(event) {
    this.general_analytics_settings.ALLOWED_OWNERSHIP_STATUS.push(event.srcElement.name);
  }




  saveprofileanalysis() {
    this.loading = true;
    const token = localStorage.getItem('token');
    const dataTosubmit = {
      'analysis': this.general_analytics_settings,
      'callLog': this.call_log_analytics_settings,
      'social': this.social_analytics_settings,
      'guarantor': this.guarantor_analytics_settings,
      'direct_debit': this.direct_debit_analytics_settings,
      'credit_bureau': this.credit_bureau_analytics_settings,
      'accounts': this.accounts_analytics_settings,
      'card': this.card_analytics_settings,
      'sms': this.sms_analytics_settings
    };

    this.profileAnalysis.updateprofileanalysissetting(dataTosubmit, token)
      .subscribe((responsdata) => {

        this.loading = false;
        this.toastr.success(responsdata.data, 'Success!');
      });
  }




  updateanalysis() {
    const token = localStorage.getItem('token');

    this.profileAnalysis.updateprofileanalysis(this.update_analytics_settings, token)
      .subscribe((responsdata) => {
      });
  }

  submitCallLogs() {
    const selectedCallLogsIds = this.callLogform.value.callLogs
      .map((v, i) => v ? this.callLogs[i].id : null)
      .filter(v => v !== null);

    this.loading = true;
    const decode = this.callLogform.value.callLogs;

    const token = localStorage.getItem('token');

    this.profileAnalysis.updatecallogeanalysis(selectedCallLogsIds, token)
      .subscribe((responsdata) => {
        this.toastr.success(responsdata.data, 'Success!');
        this.loading = false;
      });
  }

  saveCallLoganalysis() {
    this.loading = true;
    const token = localStorage.getItem('token');

    this.profileAnalysis.updatecallloganalysisseting(this.call_log_analytics_settings, token)
      .subscribe((responsdata) => {
        this.toastr.success(responsdata.data, 'Success!');
        this.loading = false;
      });
  }
  



  submit() {
    const selectedOrderIds = this.form.value.orders
      .map((v, i) => v ? this.orders[i].id : null)
      .filter(v => v !== null);
    if (selectedOrderIds.length > 50) {
      alert(' You can only choose 50 settings')
    } else {
      this.loading = true;
      const decode = this.form.value.orders;

      const token = localStorage.getItem('token');
    }
  }

  ngOnDestroy() {
    // this.getStateSubscribe.unsubscribe();
    // this.getCountrySubscribe.unsubscribe();
    // this.getOccupationborrowedSubscribe.unsubscribe();
    // this.getOccupationdesignationSubscribe.unsubscribe();
    // this.getOccupationsectorSubscribe.unsubscribe();
    // this.getQualificationSubscribe.unsubscribe();
    // this.getBanksSubscribe.unsubscribe();
    // this.getprofileanalysisSubscribe.unsubscribe();
    // this.getprofileanalysiscallSubscribe.unsubscribe();
    // this.getprofileanalysissocialSubscribe.unsubscribe();
    // this.getprofileanalysisrepaymentSubscribe.unsubscribe();
    // this.getprofileanalysissmsSubscribe.unsubscribe();
    // this.getprofileanalysisguarantorSubscribe.unsubscribe();
    // //this.getprofileanalysisdirectdebitSubscribe.unsubscribe();
    // this.getprofileanalysiscreditbureauSubscribe.unsubscribe();
    // this.getprofileanalysisloanspiderSubscribe.unsubscribe();
    // this.getprofileanalysisidentitySubscribe.unsubscribe();
    // this.getprofileanalysisbankstatementSubscribe.unsubscribe();
    // this.getprofileanalysisquestionSubscribe.unsubscribe();
    // this.getprofileanalysisfcnSubscribe.unsubscribe();
    // this.getprofileanalysismerchantSubscribe.unsubscribe();
    // this.getprofileanalysiscardSubscribe.unsubscribe();
    // this.getprofileanalysisaccountSubscribe.unsubscribe();
    // this.getlenderleavessettingSubscribe.unsubscribe();
  }
  closeProfileANalysisModal() {
    this.dataService.closePAnalysisModal.emit();
  }
}

function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      .map(control => control.value)
      .reduce((prev, next) => next ? prev + next : prev, 0);

    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}
