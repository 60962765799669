
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
  ModuleWithProviders,
  NgModule
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  FormArray,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  FormBuilder,
  FormControl,
  FormGroup
} from '@angular/forms';
import {
  RouterModule,
  Routes
} from '@angular/router';
// import {CalendarComponent} from 'angular2-fullcalendar/src/calendar/calendar';
import { CalendarComponent } from 'ap-angular2-fullcalendar/src/calendar/calendar';

import { MomentModule } from 'angular2-moment';

import { ColorPickerModule } from 'ngx-color-picker';
import { DateinvalidPipe } from '../_pipes/dateinvalid.pipe';
import { SafehtmlPipe } from '../_pipes/safehtml.pipe';
import { LoanStatusTextPipe } from '../_pipes/loan-status-text.pipe';
import { LoanStatusPipe } from '../_pipes/loan-status.pipe';
import { OfferstatusPipe } from '../_pipes/offerstatus.pipe';
import { PeertopeerPipe } from '../_pipes/peertopeer.pipe';
import { SafePipe } from '../_pipes/safe.pipe';
import { CollateralPipe } from '../_pipes/collateral.pipe';
import { LoandurationPipe } from '../_pipes/loanduration.pipe';
import { RequestdurationPipe } from '../_pipes/requestduration.pipe';
import { AoffPipe } from '../_pipes/aoff.pipe';
import { CalendardetailsComponent } from '../calendardetails/calendardetails.component';
import { PayscheduleComponent } from '../payschedule/payschedule.component';
import { RepayComponent } from '../repay/repay.component';
import { ScheduleComponent } from '../schedule/schedule.component';
import { UserlocationComponent } from '../userlocation/userlocation.component';
import { MyDatePickerModule } from 'mydatepicker';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { NguiDatetimePickerModule } from '@ngui/datetime-picker';
// import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';

import { NgxTypeaheadModule } from 'ngx-typeahead';
import { SearchanalysissettingPipe } from '../_pipes/searchanalysissetting.pipe';
import { MaritalstatusPipe } from '../_pipes/maritalstatus.pipe';
import { FilterpipePipe } from '../_pipes/filterpipe.pipe';
import { ImagenotfoundPipe } from '../_pipes/imagenotfound.pipe';
import { JournalapprovedPipe } from '../_pipes/journalapproved.pipe';
import { LimittoPipe } from '../_pipes/limitto.pipe';

import { LoanstatementComponent } from '../loanstatement/loanstatement.component';

import { NoaPipe } from '../_pipes/noa.pipe';
import { MoneyPipe } from '../_pipes/money.pipe';
import { CalendarviewComponent } from '../calendarview/calendarview.component';
import { MainNavigationComponent } from '../main-navigation/main-navigation.component';
import { RatingComponent } from '../rating/rating.component';
import { EmpsearchComponent } from '../operations/empsearch/empsearch.component';
import { UiSwitchModule } from '../../../node_modules/ngx-ui-switch';
import { LoanchecklistComponent } from '../loanchecklist/loanchecklist.component';
import { LoanlocationComponent } from '../loanlocation/loanlocation.component';
import { RequestformComponent } from '../requestform/requestform.component';
import { BulkpaymentComponent } from '../operations/bulkpayment/bulkpayment.component';
import { AgefilterPipe } from '../_pipes/agefilter.pipe';
import { MycurrencyPipe } from '../_pipes/mycurrency.pipe';
import { NgxEditorModule } from 'ngx-editor';
import { PercentageFundedPipe } from '../_pipes/percentage-funded.pipe';
import { SelectModule } from 'ng2-select';
import { GeneralformComponent } from '../operations/generalform/generalform.component';
import { InterestformComponent } from '../operations/interestform/interestform.component';
import { LoanamountComponent } from '../operations/loanamount/loanamount.component';
import { OriginationfeeComponent } from '../operations/originationfee/originationfee.component';
import { InterestratefloorComponent } from '../operations/interestratefloor/interestratefloor.component';
import { UnauthorizedComponent } from '../unauthorized/unauthorized.component';
import { TimelineComponent } from './timeline/timeline.component';
import { UploadModule } from '../upload/upload.module';
import { ButtonComponent } from '../components/button/button.component';
import { InputComponent } from '../components/input/input.component';
import { PhonenumbersComponent } from './phonenumbers/phonenumbers.component';
import { ItemComponent } from '../applications/item/item.component';
import { PortfoliocalendarComponent } from '../portfoliocalendar/portfoliocalendar.component';
import { CountdownModule } from 'ngx-countdown';
import { LoanactionComponent } from './loanaction/loanaction.component';
import { SecurityComponent } from '../setting-module/security/security.component';
import { ProductSettingComponent } from '../setting-module/product-setting/product-setting.component';
import { RunbankstatementanalysisComponent } from './runbankstatementanalysis/runbankstatementanalysis.component';
import { LoanportfolioactionComponent } from './loanportfolioaction/loanportfolioaction.component';
import { RequestcalendarComponent } from './requestcalendar/requestcalendar.component';
import { ContractheaderComponent } from '../applications/contractheader/contractheader.component';
import { PortfoliotargetmodalComponent } from '../applications/modal/portfoliotargetmodal/portfoliotargetmodal.component';
import { RoundPipe } from '../_pipes/round.pipe';
import { ApplicationspendingComponent } from '../applications/applicationspending/applicationspending.component';
import { CalendarpopoutmodalComponent } from '../applications/modal/calendarpopoutmodal/calendarpopoutmodal.component';
import { EnlargeimageComponent } from '../applications/modal/enlargeimage/enlargeimage.component';
import { LoandetailsComponent } from '../applications/modal/loandetails/loandetails.component';
import { RepaymentfooterComponent } from '../applications/modal/repaymentfooter/repaymentfooter.component';
import { RunfullanalyticsmodalComponent } from '../applications/modal/runfullanalyticsmodal/runfullanalyticsmodal.component';
import { UserheaderComponent } from '../applications/modal/userheader/userheader.component';
import { ContractdetailsComponent } from '../applications/contractdetails/contractdetails.component';
import { RepaymentscheduleComponent } from '../applications/repaymentschedule/repaymentschedule.component';
import { ApplicationstimelineComponent } from '../applications/applicationstimeline/applicationstimeline.component';
import { CurrentapprovallevelComponent } from '../applications/currentapprovallevel/currentapprovallevel.component';
import { RequestheaderComponent } from '../applications/requestheader/requestheader.component';
import { RequestfooterComponent } from '../applications/requestfooter/requestfooter.component';
import { LocationdetailsComponent } from '../applications/locationdetails/locationdetails.component';
import { ApplicationseligibilityComponent } from '../applications/applicationseligibility/applicationseligibility.component';
import { InitiatebankstatementanalysisComponent } from '../applications/initiatebankstatementanalysis/initiatebankstatementanalysis.component';
import { ContractstatusComponent } from '../applications/contractstatus/contractstatus.component';
import { OtherheaderComponent } from './otherheader/otherheader.component';
import { BreakingloanComponent } from './breakingloan/breakingloan.component';
import { LoancontractsectionComponent } from './loancontractsection/loancontractsection.component';
import { ApplicationportfolioheaderComponent } from '../applicationportfolio/applicationportfolioheader/applicationportfolioheader.component';
import { CreditbureausComponent } from '../setting-module/creditbureaus/creditbureaus.component';
import { ProfileAnalysisComponent } from '../setting-module/profile-analysis/profile-analysis.component';
import { GeneralComponent } from '../setting-module/general/general.component';
import { LenderTypePipe } from '../_pipes/lender-type.pipe';
import { LoanpurposePipe } from '../_pipes/loanpurpose.pipe';
import { PostingbulkpaymentsComponent } from '../operations/postingbulkpayments/postingbulkpayments.component';
import { CellComponent } from './cell/cell.component';
import { ProfileanalysismodalComponent } from './profileanalysismodal/profileanalysismodal.component';
import { RequestitemComponent } from './requestitem/requestitem.component';
import { AgeingComponent } from './ageing/ageing.component';
import { CalendarcashflowComponent } from './calendarcashflow/calendarcashflow.component';
import { TimerComponent } from './timer/timer.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { OfferLetterComponent } from '../setting-module/offer-letter/offer-letter.component';
import { IndebtednessletterComponent } from '../setting-module/indebtednessletter/indebtednessletter.component';
import { UploadpaymentsComponent } from '../operations/uploadpayments/uploadpayments.component';

import { DemoNumberPipe } from '../_pipes/demo-number.pipe';
import { RequeststatusPipe } from '../_pipes/requeststatus.pipe';
import { GlcategoryPipe } from '../_pipes/glcategory.pipe';
import { NoRightComponent } from './no-right/no-right.component';
import { BulkdisbursementdownloadComponent } from './bulkdisbursementdownload/bulkdisbursementdownload.component';
import { PerformancesnapshotComponent } from './performancesnapshot/performancesnapshot.component';
import { PortfolioageinganalysisComponent } from './portfolioageinganalysis/portfolioageinganalysis.component';
import { PortfoliostaffperformanceComponent } from './portfoliostaffperformance/portfoliostaffperformance.component';
import { PageLoadingComponent } from './page-loading/page-loading.component';
import { NoAnalyticsDataComponent } from './no-analytics-data/no-analytics-data.component';
import { OfferlettertemplateComponent } from './offerlettertemplate/offerlettertemplate.component';
import { WidgetPagesSettingsComponent } from './widget-pages-settings/widget-pages-settings.component';
import { ChartsModule } from 'ng2-charts';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { CaryearPipe } from '../_pipes/caryear.pipe';
import { AnalyticscontractheaderComponent } from './analyticscontractheader/analyticscontractheader.component';
import { BorrowedbasedamountComponent } from './borrowedbasedamount/borrowedbasedamount.component';
import { ProductchecklistComponent } from './productchecklist/productchecklist.component';
import { ApplicationschecklistComponent } from './applicationschecklist/applicationschecklist.component';
import { GenderPipe } from '../_pipes/gender.pipe';

import { InsurancePipe } from '../_pipes/insurance.pipe';
import { PlatformearningsComponent } from './platformearnings/platformearnings.component';
import { LoanactionsComponent } from './loanactions/loanactions.component';
import { CardiframeComponent } from './cardiframe/cardiframe.component';
import { HtmlpipePipe } from '../_pipes/htmlpipe.pipe';
import { InterestdurationPipe } from '../_pipes/interestduration.pipe';
import { RequsetSideviewComponent } from '../applications/requset-sideview/requset-sideview.component';
import { DueloansbydateComponent } from './dueloansbydate/dueloansbydate.component';
import { SavingsinterestPipe } from '../_pipes/savingsinterest.pipe';
import { AnalysisAreaOfFocusComponent } from './analysis-area-of-focus/analysis-area-of-focus.component';
import { BankStatementAnalyzeComponent } from './bank-statement-analyze/bank-statement-analyze.component';
import { IdentityCheckComponent } from './identity-check/identity-check.component';
import { RequestQuickviewComponent } from './request-quickview/request-quickview.component';
import { AttachmentsCardComponent } from './attachments-card/attachments-card.component';
import { BankStatementAnalysisModalComponent } from './bank-statement-analysis-modal/bank-statement-analysis-modal.component';
import { AccountandcardsCardComponent } from './accountandcards-card/accountandcards-card.component';
import { BvndetailsCardComponent } from './bvndetails-card/bvndetails-card.component';
import { BankStatementAnalysisResultComponent } from './bank-statement-analysis-result/bank-statement-analysis-result.component';
import { BreakingActiveLoanComponent } from './breaking-active-loan/breaking-active-loan.component';
import { CollectionsComponent } from './collections/collections.component';
import { MiniPerfomanceSnapshotComponent } from './mini-perfomance-snapshot/mini-perfomance-snapshot.component';
import { RequestNotificationComponent } from './request-notification/request-notification.component';
import { ApplicationsPortfolioComponent } from '../applicationportfolio/applications/applications.component';
import { PortfoliotrendsComponent } from '../applicationportfolio/portfoliotrends/portfoliotrends.component';
import { PortfoliodistributionComponent } from '../applicationportfolio/portfoliodistribution/portfoliodistribution.component';
import { RepaymentsourcedistributionComponent } from '../applicationportfolio/repaymentsourcedistribution/repaymentsourcedistribution.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { RepaymentsnapshotComponent } from './repaymentsnapshot/repaymentsnapshot.component';
import { RequestHeroComponent } from './request-hero/request-hero.component';
import { RequestingdataComponent } from './requestingdata/requestingdata.component';
import { MicrocreditsummaryComponent } from './microcreditsummary/microcreditsummary.component';
import { MicrocreditresultComponent } from './microcreditresult/microcreditresult.component';
import { NinbyphonenumberComponent } from './ninbyphonenumber/ninbyphonenumber.component';
import { IppisdetailsComponent } from './ippisdetails/ippisdetails.component';
import { YeargeneratorPipe } from '../_pipes/yeargenerator.pipe';
import { EligibilitycriteriaComponent } from './eligibilitycriteria/eligibilitycriteria.component';
import { RequestlastactivityComponent } from './requestlastactivity/requestlastactivity.component';
import { EligibilityanalysismodalComponent } from './eligibilityanalysismodal/eligibilityanalysismodal.component';
import { HomecustomersideviewComponent } from './homecustomersideview/homecustomersideview.component';
import { CustomerinfoonlyComponent } from './customerinfoonly/customerinfoonly.component';
import { DuerepaymentsComponent } from '../applicationportfolio/duerepayments/duerepayments.component';
import { PortfoliorequestComponent } from '../applicationportfolio/portfoliorequest/portfoliorequest.component';
import { PortfoliotargetComponent } from '../applicationportfolio/portfoliotarget/portfoliotarget.component';
import { ChooseoptionComponent } from '../applicationportfolio/repaymentflow/chooseoption/chooseoption.component';
import { BorrowerdetailsComponent } from '../applicationportfolio/borrowerdetails/borrowerdetails.component';
import { RepaymentflowComponent } from '../applicationportfolio/repaymentflow/repaymentflow.component';
import { ConfirmpaymentComponent } from '../applicationportfolio/repaymentflow/confirmpayment/confirmpayment.component';
import { CardpaymentComponent } from '../applicationportfolio/repaymentflow/cardpayment/cardpayment.component';
import { RemitaComponent } from '../applicationportfolio/repaymentflow/remita/remita.component';
import { PortfolioheaderComponent } from '../applicationportfolio/portfolioheader/portfolioheader.component';
import { FlowsummaryComponent } from '../applicationportfolio/flowsummary/flowsummary.component';
import { MakingpaymentComponent } from '../applicationportfolio/makingpayment/makingpayment.component';
import { PrepaymentComponent } from '../applicationportfolio/prepayment/prepayment.component';
import { PortfolioSideviewComponent } from '../applicationportfolio/portfolio-sideview/portfolio-sideview.component';
import { ApplicationsindebtednessComponent } from '../applicationportfolio/applicationsindebtedness/applicationsindebtedness.component';
import { IndcustomerComponent } from './indcustomer/indcustomer.component';
import { CardtokennizationComponent } from './cardtokennization/cardtokennization.component';
import { IppischeckerComponent } from './ippischecker/ippischecker.component';
import { BankstatementsettingsComponent } from './bankstatementsettings/bankstatementsettings.component';
import { NibbsdebitComponent } from './nibbsdebit/nibbsdebit.component';
import { CountdownComponent } from './countdown/countdown.component';
import { BarchartComponent } from './barchart/barchart.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PortfolioinfoComponent } from '../applicationportfolio/portfolioinfo/portfolioinfo.component';
import { ReportportfolioComponent } from '../applicationportfolio/reportportfolio/reportportfolio.component';
import { AnalyticsSideviewComponent } from '../applications/analytics-sideview/analytics-sideview.component';
import { EligibilityofferstableComponent } from './eligibilityofferstable/eligibilityofferstable.component';
import { CompulsorydataComponent } from './compulsorydata/compulsorydata.component';
import { RequestsideviewComponent } from './requestsideview/requestsideview.component';
import { RequestparentsideviewComponent } from './requestparentsideview/requestparentsideview.component';
import { EcosystemPipe } from '../_pipes/ecosystem.pipe';
import { BandsPipe } from '../_pipes/bands.pipe';
import { EcosystemsComponent } from '../others/ecosystems/ecosystems.component';
import { ModalComponent } from '../others/modal/modal.component';
import { OnlineStoreComponent } from '../others/online-store/online-store.component';
import { SettingsCollectionBannerComponent } from '../others/settings-collection-banner/settings-collection-banner.component';
import { SettingsCollectionFilterComponent } from '../others/settings-collection-filter/settings-collection-filter.component';
import { SettingsCollectionItemsComponent } from '../others/settings-collection-items/settings-collection-items.component';
import { SettingsHomepageBannerComponent } from '../others/settings-homepage-banner/settings-homepage-banner.component';
import { SettingsHomepageSectionsComponent } from '../others/settings-homepage-sections/settings-homepage-sections.component';
import { SettingsHomepageTemplateComponent } from '../others/settings-homepage-template/settings-homepage-template.component';
import { SettingsItemDetailsTemplateComponent } from '../others/settings-item-details-template/settings-item-details-template.component';
import { SettingsItemDetailsComponent } from '../others/settings-item-details/settings-item-details.component';
import { SettingsThemeColorComponent } from '../others/settings-theme-color/settings-theme-color.component';
import { SquareBoxComponent } from '../others/square-box/square-box.component';
import { RequestsSummaryComponent } from '../others/requests-summary/requests-summary.component';
import { MicrocreditwrapperComponent } from '../applications/microcreditwrapper/microcreditwrapper.component';
import { RequestSideviewMorePicturesComponent } from '../others/request-sideview-more-pictures/request-sideview-more-pictures.component';
import { StatementscheduleComponent } from '../statementschedule/statementschedule.component';
import { RequestslistComponent } from '../requestslist/requestslist.component';
import { LoanscheduleComponent } from '../loanschedule/loanschedule.component';
import { BackendsettingsComponent } from '../backend/backendsettings/backendsettings.component';
import { ModalRegularComponent } from '../others/modal-regular/modal-regular.component';
import { OnboardComponent } from '../others/onboard/onboard.component';
import { RequestspecialcommentsComponent } from './requestspecialcomments/requestspecialcomments.component';
import { DisbursementqueueapprovalComponent } from './disbursementqueueapproval/disbursementqueueapproval.component';
import { QueuemodalComponent } from './queuemodal/queuemodal.component';
import { LenderpqueueComponent } from './lenderpqueue/lenderpqueue.component';
import { DisbursementstatusComponent } from './disbursementstatus/disbursementstatus.component';
import { DailyschedulesnapshotComponent } from './dailyschedulesnapshot/dailyschedulesnapshot.component';
import { MonthlyrepaymentsnapshotComponent } from './monthlyrepaymentsnapshot/monthlyrepaymentsnapshot.component';
import { ParportfolioComponent } from './parportfolio/parportfolio.component';
import { AtaglanceComponent } from './ataglance/ataglance.component';
import { MothlysnapshotviewComponent } from './mothlysnapshotview/mothlysnapshotview.component';
import { LoanschedulemodalComponent } from './loanschedulemodal/loanschedulemodal.component';
import { MonthlyrepaymenttableComponent } from './monthlyrepaymenttable/monthlyrepaymenttable.component';
import { CollectorComponent } from './collector/collector.component';
import { RemittancereportComponent } from './remittancereport/remittancereport.component';
import { StatementlinkComponent } from './statementlink/statementlink.component';

@NgModule({
  imports: [
    CommonModule, FormsModule, RouterModule, ReactiveFormsModule, MyDatePickerModule,
    IonRangeSliderModule, MomentModule, UiSwitchModule, NgxEditorModule, ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCWvWygNM2UBh-WY8FMilv2GghJmtSRt0k'
    }),
    OrderModule, UploadModule, NguiDatetimePickerModule, InfiniteScrollModule,
    NgxTypeaheadModule, CountdownModule, ColorPickerModule,
    CarouselModule
  ],
  entryComponents: [],
  declarations: [
    InsurancePipe, GlcategoryPipe, CaryearPipe, GenderPipe,StatementscheduleComponent,
    RequeststatusPipe, LimittoPipe, SafePipe, LoanpurposePipe, DemoNumberPipe, ApplicationportfolioheaderComponent,
    RoundPipe, LoandurationPipe, RequestdurationPipe, LoanStatusPipe, OfferstatusPipe, PeertopeerPipe, SafehtmlPipe,
    MaritalstatusPipe, DateinvalidPipe, PercentageFundedPipe, AgefilterPipe,
    MycurrencyPipe, SearchanalysissettingPipe, FilterpipePipe, ImagenotfoundPipe, PayscheduleComponent,JournalapprovedPipe, MoneyPipe, MainNavigationComponent,
    LoanchecklistComponent, CreditbureausComponent,
    RequestformComponent,
    RequsetSideviewComponent,AnalyticsSideviewComponent,
    CalendarComponent, UnauthorizedComponent, ProductSettingComponent,RequestslistComponent,
    ProfileAnalysisComponent,
    CalendarviewComponent, CalendardetailsComponent,
    RatingComponent, ScheduleComponent, RepayComponent,
    InterestdurationPipe,
    UserlocationComponent,
    LoanStatusTextPipe, EmpsearchComponent, LoanlocationComponent, NoaPipe, GeneralComponent,
    BulkpaymentComponent, GeneralformComponent,
    InterestformComponent, LoanamountComponent, InterestratefloorComponent, OriginationfeeComponent, TimelineComponent, ContractheaderComponent,  ReportportfolioComponent, ButtonComponent, InputComponent,
    LoanstatementComponent, PhonenumbersComponent, ItemComponent,
    PortfoliocalendarComponent, LoanactionComponent, SecurityComponent,RunbankstatementanalysisComponent,
    LoanportfolioactionComponent,RequestcalendarComponent, PortfoliotargetmodalComponent,
    CalendarpopoutmodalComponent, PostingbulkpaymentsComponent,
    ApplicationspendingComponent,
    EnlargeimageComponent,
    LoandetailsComponent,
    RepaymentfooterComponent,
    RunfullanalyticsmodalComponent,
    UserheaderComponent,
    ContractdetailsComponent,
    RepaymentscheduleComponent,
    ApplicationstimelineComponent,
    CurrentapprovallevelComponent,
    RequestheaderComponent,
    RequestfooterComponent,LocationdetailsComponent,
    ApplicationseligibilityComponent,InitiatebankstatementanalysisComponent, ContractstatusComponent,PortfoliotargetmodalComponent,OtherheaderComponent,
    BreakingloanComponent, LoancontractsectionComponent,
    LenderTypePipe,CellComponent,OfferLetterComponent, UploadpaymentsComponent, PortfoliotargetmodalComponent,
    OtherheaderComponent,
    BreakingloanComponent, LoancontractsectionComponent,
    LenderTypePipe, CellComponent,
    ProfileanalysismodalComponent, RequestitemComponent, AgeingComponent, CalendarcashflowComponent,
    TimerComponent, PlaceholderComponent, NoRightComponent, BulkdisbursementdownloadComponent,
    PerformancesnapshotComponent,
    PortfolioageinganalysisComponent,
    PortfoliostaffperformanceComponent,
    PageLoadingComponent,
    NoAnalyticsDataComponent,
    OfferlettertemplateComponent,
    WidgetPagesSettingsComponent, CollateralPipe,
    CustomerInfoComponent,
    AnalyticscontractheaderComponent,
    BorrowedbasedamountComponent,
    ProductchecklistComponent,
    ApplicationschecklistComponent,
    PlatformearningsComponent,SavingsinterestPipe,
    CardiframeComponent,
    HtmlpipePipe,
    LoanactionsComponent,IndebtednessletterComponent, DueloansbydateComponent,
     AnalysisAreaOfFocusComponent, BankStatementAnalyzeComponent, IdentityCheckComponent,RequestQuickviewComponent, AttachmentsCardComponent, BankStatementAnalysisModalComponent, AccountandcardsCardComponent, BvndetailsCardComponent,
    BankStatementAnalysisResultComponent, BreakingActiveLoanComponent, CollectionsComponent, MiniPerfomanceSnapshotComponent,
    RequestNotificationComponent,
    ApplicationsPortfolioComponent,
    PortfoliotrendsComponent,
    PortfoliodistributionComponent,
    RepaymentsourcedistributionComponent,EcosystemPipe,BandsPipe,
    ProfilePictureComponent,YeargeneratorPipe,
    RepaymentsnapshotComponent,
    RequestHeroComponent,
    AoffPipe,
    RequestingdataComponent,
    MicrocreditsummaryComponent, MicrocreditresultComponent, NinbyphonenumberComponent, IppisdetailsComponent,
    EligibilitycriteriaComponent, RequestlastactivityComponent, EligibilityanalysismodalComponent, HomecustomersideviewComponent, CustomerinfoonlyComponent,
    DuerepaymentsComponent, PortfoliorequestComponent,
    PortfoliotargetComponent, BorrowerdetailsComponent,
    RepaymentflowComponent, ChooseoptionComponent, ConfirmpaymentComponent, CardpaymentComponent,
    RemitaComponent, PortfolioheaderComponent, FlowsummaryComponent, MakingpaymentComponent, PrepaymentComponent, PortfolioSideviewComponent, ApplicationsindebtednessComponent,
    IndcustomerComponent,CardtokennizationComponent, IppischeckerComponent, BankstatementsettingsComponent, NibbsdebitComponent,
    CountdownComponent, BarchartComponent, PortfolioinfoComponent, EligibilityofferstableComponent, CompulsorydataComponent, RequestsideviewComponent, RequestparentsideviewComponent,
    EcosystemsComponent,
    ModalComponent,
    ModalRegularComponent,
    SquareBoxComponent,
    OnlineStoreComponent,
    SettingsHomepageBannerComponent,
    SettingsHomepageSectionsComponent,
    SettingsHomepageTemplateComponent,
    SettingsCollectionBannerComponent,
    SettingsCollectionFilterComponent,BackendsettingsComponent,
    SettingsCollectionItemsComponent,
    SettingsItemDetailsComponent,
    SettingsItemDetailsTemplateComponent,
    SettingsThemeColorComponent,
    RequestsSummaryComponent,MicrocreditwrapperComponent,
    RequestSideviewMorePicturesComponent ,LoanscheduleComponent,
    OnboardComponent,
    RequestspecialcommentsComponent,
    DisbursementqueueapprovalComponent,
    QueuemodalComponent,
    LenderpqueueComponent,
    DisbursementstatusComponent,
    DailyschedulesnapshotComponent,
    MonthlyrepaymentsnapshotComponent,
    ParportfolioComponent,
    AtaglanceComponent,
    MothlysnapshotviewComponent,
    LoanschedulemodalComponent,
    MonthlyrepaymenttableComponent,
    CollectorComponent,
    RemittancereportComponent,
    StatementlinkComponent
  ],
  exports: [
    BackendsettingsComponent,CarouselModule,BandsPipe,StatementscheduleComponent,RequestslistComponent ,LoanscheduleComponent ,
    PlatformearningsComponent,YeargeneratorPipe, EligibilityanalysismodalComponent, IndebtednessletterComponent,
    RequsetSideviewComponent,AnalyticsSideviewComponent, HtmlpipePipe, CardiframeComponent, DueloansbydateComponent, RequestlastactivityComponent,
    InsurancePipe, BarchartComponent,PortfolioinfoComponent, CompulsorydataComponent,
    RemittancereportComponent,StatementlinkComponent,
    LenderpqueueComponent,DailyschedulesnapshotComponent,MonthlyrepaymentsnapshotComponent,
    MonthlyrepaymenttableComponent,
    CollectorComponent,
    DisbursementstatusComponent,
    LoanschedulemodalComponent,
    MothlysnapshotviewComponent,
    AtaglanceComponent,
    ParportfolioComponent,
    QueuemodalComponent,
    DisbursementqueueapprovalComponent,
    CountdownComponent, RequestparentsideviewComponent,MicrocreditwrapperComponent,
    RequestspecialcommentsComponent,
    ProductchecklistComponent, GenderPipe, ApplicationschecklistComponent, BorrowedbasedamountComponent,
    WidgetPagesSettingsComponent, AnalyticscontractheaderComponent, IndcustomerComponent,PerformancesnapshotComponent, OfferlettertemplateComponent,
    RequestingdataComponent, MicrocreditsummaryComponent, MicrocreditresultComponent, NinbyphonenumberComponent, IppisdetailsComponent,
    RequeststatusPipe, ColorPickerModule, DemoNumberPipe, CaryearPipe, LoancontractsectionComponent,BulkdisbursementdownloadComponent, BankstatementsettingsComponent,
    ApplicationportfolioheaderComponent, LoandurationPipe,
    RequestdurationPipe, LimittoPipe, SafePipe, RoundPipe, GlcategoryPipe, IppischeckerComponent, CustomerinfoonlyComponent,
    PortfolioageinganalysisComponent, LoanStatusPipe, OfferstatusPipe, LoanStatusTextPipe, TimelineComponent, CardtokennizationComponent,
    PeertopeerPipe, SafehtmlPipe, MaritalstatusPipe, SearchanalysissettingPipe, BreakingloanComponent, PlaceholderComponent,
    FilterpipePipe, ImagenotfoundPipe, PayscheduleComponent, DateinvalidPipe, CalendarcashflowComponent,
    JournalapprovedPipe, RouterModule, IonRangeSliderModule, ChartsModule,
    MoneyPipe, MyDatePickerModule,RequestsideviewComponent,
    RequestformComponent, NoaPipe, ProductSettingComponent, RequestitemComponent, EligibilityofferstableComponent,
    MomentModule, FormsModule, ReactiveFormsModule, MainNavigationComponent, InterestdurationPipe,
    EmpsearchComponent,NgxEditorModule,
    UiSwitchModule, CountdownModule, LoanlocationComponent,EligibilitycriteriaComponent,
    CalendarComponent, UnauthorizedComponent, CreditbureausComponent, AgeingComponent, HomecustomersideviewComponent,
    NguiDatetimePickerModule, ProfileAnalysisComponent, GeneralComponent, LoanpurposePipe,
    CalendarviewComponent, LoanchecklistComponent,
    CalendardetailsComponent, RatingComponent,
    ReportportfolioComponent, ScheduleComponent, RepayComponent,   UserlocationComponent,OrderModule, ContractheaderComponent,
    BulkpaymentComponent,SelectModule, GeneralformComponent,
    InterestformComponent, LoanamountComponent, InterestratefloorComponent, OriginationfeeComponent, OtherheaderComponent,PostingbulkpaymentsComponent,InfiniteScrollModule,
    NgxTypeaheadModule, ButtonComponent, PhonenumbersComponent, ItemComponent, PortfoliocalendarComponent,
    LoanstatementComponent, InputComponent, LoanactionComponent, SecurityComponent,
    LoanportfolioactionComponent, RequestcalendarComponent, PortfoliotargetmodalComponent,
    CalendarpopoutmodalComponent,OfferLetterComponent, UploadpaymentsComponent,
    ApplicationspendingComponent,
    EnlargeimageComponent,
    LoandetailsComponent,
    RepaymentfooterComponent,
    RunfullanalyticsmodalComponent,
    UserheaderComponent,
    ContractdetailsComponent,
    RepaymentscheduleComponent,
    ApplicationstimelineComponent,
    CurrentapprovallevelComponent,
    RequestheaderComponent,
    RequestfooterComponent,LocationdetailsComponent,
    ApplicationseligibilityComponent,InitiatebankstatementanalysisComponent, ContractstatusComponent,LoanportfolioactionComponent, RequestcalendarComponent,PortfoliotargetmodalComponent,
    LenderTypePipe,CellComponent,
    TimerComponent,
    NoRightComponent,
    PageLoadingComponent,
    NoAnalyticsDataComponent,
    CollateralPipe,
    CustomerInfoComponent,
    LoanactionsComponent,
    SavingsinterestPipe,
    BreakingActiveLoanComponent,
    CollectionsComponent,
    RequestNotificationComponent,
    ApplicationsPortfolioComponent,
    PortfoliotrendsComponent,EcosystemPipe,
    PortfoliodistributionComponent,
    RepaymentsourcedistributionComponent,
    RepaymentsnapshotComponent,
    RequestHeroComponent,
    AoffPipe,
    DuerepaymentsComponent, PortfoliorequestComponent,
    PortfoliotargetComponent, BorrowerdetailsComponent,
    RepaymentflowComponent, ChooseoptionComponent, ConfirmpaymentComponent, CardpaymentComponent,
    RemitaComponent, PortfolioheaderComponent, FlowsummaryComponent, MakingpaymentComponent, PrepaymentComponent, PortfolioSideviewComponent, ApplicationsindebtednessComponent,
    EcosystemsComponent,
    ModalComponent,
    ModalRegularComponent,
    SquareBoxComponent,
    OnlineStoreComponent,
    SettingsHomepageBannerComponent,
    SettingsHomepageSectionsComponent,
    SettingsHomepageTemplateComponent,
    SettingsCollectionBannerComponent,
    SettingsCollectionFilterComponent,
    SettingsCollectionItemsComponent,
    SettingsItemDetailsComponent,
    SettingsItemDetailsTemplateComponent,
    SettingsThemeColorComponent,
    RequestsSummaryComponent,
    RequestSideviewMorePicturesComponent,
    OnboardComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [AgmCoreModule]
    };
  }
}
