<div class="wrapper">
    <div class="row">
        <div class="col-md-6 left">
            <div class="content">
                <div class="flex justify-center logos">
                    <img src="/assets/images/cc-logo-md.png" alt="creditclan's logo" />
                    <span>+</span>
                    <img src="/assets/images/kuda-logo-white.svg" alt="kuda bank logo" class="ml-4" />
                </div>
                <img src="/assets/images/kuda-illustration.svg" alt="kuda illustration" class="illustration" />
            </div>
        </div>
        <div class="col-md-6 right">
            <div class="content">
                <form>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Email">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
                    </div>
                    <div class="checkbox">
                        <label>
              <input type="checkbox"> Keep me logged in
            </label>
                    </div>
                    <div class="mt-4">
                        <app-button type="submit" label="Sign in"></app-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>