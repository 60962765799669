import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LoansService, StorageService, AuthenticationService, DataService, AnalyticsService } from '../../_services/index';

@Component({
  selector: 'app-analytics-sideview',
  templateUrl: './analytics-sideview.component.html',
  styleUrls: ['./analytics-sideview.component.css']
})
export class AnalyticsSideviewComponent implements OnInit {

  @Input() band_id:any;
  @Input() ecosystem:any;
  @Input() band_type = '1';
  @Input() type:any;
  @Input() requestId;
  @Input() loan = null;
  @Input() autoLoad = false;
  @Output() close = new EventEmitter();
  @Output() openModal = new EventEmitter();
  view_type="lender_leaves";
  // @Output() doingDisbursement = new EventEmitter();
  @Output() approveThisLoan = new EventEmitter();
  @Output() rejectThisLoan = new EventEmitter();
  analytics_data_status: any;
  currentUser: any;
  customer: any;
  is_individual: any;
  loading: boolean;
  child = '';
  test_mode = JSON.parse(localStorage.getItem('test_mode'));
  card: any;
  account: any;
  disbursement: any;
  checklist: any;
  more: string = 'More';
  view = 'application';
  customerdetails:any;
  requesting_data:any;
  microcredit:any;
  eligibilitycriteria:any;
  runstatus = true;
  constructor(
    public loansService: LoansService,
    public analyticsService: AnalyticsService,
    public storageService: StorageService,
    private dataService: DataService,
    public authService: AuthenticationService,
    private router: Router,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    document.body.style.overflow = "hidden";
    this.child = 'profileanalysis';
  }
updatebandtype(event){
  this.band_type = event.band_type;
}
  ngOnDestroy() {
    document.body.style.overflow = "initial";
  }

  ngOnChanges() {
    // this.checklist = null;
    // this.child = '';
    // this.getCustomer(this.requestId);
    // this.getAnalysisStatus();
    // if (this.autoLoad) this.loadRequest();
  }

  setMore(name) {
    this.more = name;
    this.view = 'more';
  }

  setChild(child, event) {
    if (event) this[event.key] = event.value;
    this.child = child;
  }
  showEligCrit(event){
    this.child ='eligibilitycriteria';
    this.eligibilitycriteria = event;
  }
  showCreditBureau(){
    this.child='nanocreditbureau';
  }
  showBankStatement(){
    this.child='bankstatement';
  }
  setView(view) {
    this.view = view;
  }

  closeView() {
    this.close.emit();
  }

  doingDisbursement(event) {
    this.disbursement = event;
    this.child = 'disbursement';
  }

  loadRequest() {
    this.child = '';
    this.loan = null;
    this.loansService.getLoanDetails(this.currentUser.token, this.requestId)
      .subscribe(data => {
        this.loan = data.loan;
      });
  }
  setupmandate(){
    this.child = '';
    this.loan = null;
    this.loansService.setupmandate(this.currentUser.token, this.requestId)
      .subscribe(data => {
        this.loan = data.loan;
      });
  }
  getCustomer(people_id) {
    this.loading = true;
    this.loansService.getLoanContractCustomerFromLoan(this.currentUser.token, people_id)
      .subscribe(data => {
        this.customer = data.data;
        this.is_individual = data.is_individual;
        this.loading = false;
      });
  }

  grantRight(right) {
    return this.authService.userHasRights(right);
  }
  getRequestCustomer(loan){
    this.loading = true;
    this.loansService.getLoanContractCustomerFromLoan(this.currentUser.token, loan.REQUEST_ID)
      .subscribe(data => {
        this.customer = data.data;
        this.is_individual = data.is_individual;
        this.loading = false;
      });
  }
  requestCard() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('39')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_card_mod', data: this.loan });
    }
  }

  requestGuarantors() {
    if (this.test_mode == '1') {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    } else if (!this.grantRight('41')) {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'request_guarantor_mod', data: this.loan });
    }
  }

  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }

  sendContract() {
    if (this.test_mode == '0') {
      if (!this.grantRight('47')) {
        this.dataService.onOpenLoanChildModal.emit({ 'location': 'no_right', data: this.loan });
      } else {
        this.dataService.onOpenLoanChildModal.emit({ 'location': 'send_contract_mod', data: this.loan });
      }

    } else {
      this.dataService.onOpenLoanChildModal.emit({ 'location': 'test_mode' });
    }
  }

  getAnalysisStatus() {
    this.analyticsService.getAnalysisStatus(this.currentUser.token, this.requestId)
      .subscribe(({ status, checklist }: any) => {
        if (status) this.checklist = checklist;
      })
  }
  openDefaultModal(section) {
    // if (this.authService.canViewModule('1,2,3,1026')) {

    // }else{

    // }
    if (section === 'bvn') {
      if (!this.grantRight('80')) {
        section = 'no_right';
      }
    }
    if (section === 'cards') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'accounts') {
      if (!this.grantRight('35')) {
        section = 'no_right';
      }
    }
    if (section === 'attachments') {
      if (!this.grantRight('28')) {
        section = 'no_right';
      }
    }
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.loan.request_id, loan: this.loan });
  }
  requestingData(event) {
    this.customerdetails = {
      people_id: this.loan.people_id
    }
    this.requesting_data = event; 
    this.child = 'requesting-data';
    console.log(event)
    //this.dataService.requestingfordata.emit(event);
  }

  calculateMonthlyRepayment(principal, duration, rate) {
    if (!principal) return 0;
    return (((rate / 100) * principal) + principal) / duration;
  }

  isUnderConstruction(feat) {
    return [
      'countdown',
      'blockchain',
      'analytics',
      'loanspider'
    ].includes(feat)
  }
  closeSMS(){
    this.child = 'smsbank';

  }
  showResultForMicroCredit(event){
    this.microcredit = event.summary;
    this.child = 'micro-result';
  }
  sendEmailToCustomer(request) { 
    this.dataService.sendMailToCustomer.emit({ customer_id: request.people_id, is_multiple: false });
  }
  sendSMSToCustomer(request) { 
    this.dataService.sendSMSToCustomer.emit({ customer_id: request.people_id, is_multiple: false });
  }
  doEligibility(event){
    this.runstatus = false;
    this.child = 'eligibility';
    this.dataService.runEligibility.emit(event);
  }
}