import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UserService, OperationsService, AuthenticationService, StorageService, OptionsserviceService, DataService } from '../_services/index';

import { CustomersService } from '../_services/customers.service';

import { ToastrService } from 'ngx-toastr';

import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { of } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { ProductsService } from 'src/app/_services/products.service';

@Component({
  selector: 'app-accruals',
  templateUrl: './accruals.component.html',
  styleUrls: ['./accruals.component.css']
})
export class AccrualsComponent implements OnInit {

  @Input() report_types = '1';
  public loading = false;
  public showAdvSearch = false;
  showAdvSearchBody = true;
  customers: any;
  occupations: any;
  sectors: any;
  companies: any;
  filterStatus = 'date_duration';
  downloadLinkReceived = false;
  downloadLink = "";
  public currentUser: any;
  public data: any;
  public rows = [];
  public columns = {};
  objectKeys = Object.keys;

  state: any;
  country: any;
  occupation: any;
  bank: any;
  borrowedOccpa: any;
  occupationdesignation: any;
  sectorOccupation: any;
  qualification: any;
  minQualification: any;
  OtherQalification: any;

  public getStateSubscribe: Subscription;
  public getCountrySubscribe: Subscription;
  public getOccupationborrowedSubscribe: Subscription;
  public getOccupationdesignationSubscribe: Subscription;
  public getOccupationsectorSubscribe: Subscription;
  public getQualificationSubscribe: Subscription;

  filter = {
    TOKEN: localStorage.getItem('token'),
    start: '0',
    report_types: '1',
    NAME: '',
    STATE: '',
    OCCUPATION: '',
    GENDER: '',
    HOME_STATE: '',
    WORK_SECTOR: '',
    WORK_DESIGNATION: '',
    MARITAL_STATUS: '',
    MIN_SALARY: '',
    MAX_SALARY: '',
    QUALIFICATION: '',
    REQUEST_PRINCIPAL: '',
    LOAN_REQUEST_RATE: '',
    EXPIRATION_DATE: '',
    DATE_LOAN_ADDED: '',
    DATE_LOAN_CLOSED: '',
    DATE_LOAN_REPAYMENT_STARTED_WHEN: '',
    DATE_LOAN_DISBURSMENT: '',
    LOAN_ID: '',
    branch_id: '',
    company_id: '',
    is_export: '0',
    product_id: '',
    request_status: '',
    limit:'25',
    date_start:'',
    date_end:''
  };
  products: any;
  public preferredmarital = [
    { 'name': 'Single', 'id': 1 },
    { 'name': 'Married', 'id': 2 },
    { 'name': 'Divorced', 'id': 3 },
    { 'name': 'Widowed', 'id': 4 }
  ];
  public incomes = [
    { display: '< 50K', checked: false, min: 0, max: 50000 },
    { display: '51 - 100k', checked: false, min: 50001, max: 100000 },
    { display: '101k - 250k', checked: false, min: 100001, max: 250000 },
    { display: '250k - 500k', checked: false, min: 250001, max: 500000 },
    { display: '500k - 1m', checked: false, min: 500001, max: 1000000 },
    { display: '> 1m', checked: false, min: 1000001, max: 120000000 }
  ];
  public durations = [
    { display: '0 - 1', checked: false, min: 0, max: 30 },
    { display: '0 - 3', checked: false, min: 31, max: 90 },
    { display: '4 - 6', checked: false, min: 91, max: 180 },
    { display: '7 - 12', checked: false, min: 181, max: 365 },
    { display: '1+ Years', checked: false, min: 366, max: 3650 }
  ];
  branches:any;
  public statuses = [
    // { value: '-2', display: 'All' },
    { value: '6', display: 'Contract Created' },
    { value: '-4', display: 'Disbursements' },
    { value: '-6', display: 'Terminated' },
    { value: '5', display: 'Rejected' },
    { value: '-3', display: 'Ineligible' },
  ];
  showNext = false;
  title = 'Pending Requests Reports';
  aggregate:any;
  total='0';
  loadingRouteConfig = false;
  constructor(
    public dataService: DataService, public optionsService: OptionsserviceService, public productService: ProductsService, public operationsService: OperationsService, public storageService: StorageService,
    protected customersSrvc: CustomersService,
    public toastr: ToastrService, private formBuilder: FormBuilder) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.loadingRoute.subscribe(res => {
      this.loadingRouteConfig = res.loadingRouteConfig;
    });
  }
  

  ngOnInit() {
    // this.productService
    //   .getLoanProducts(this.currentUser.token)
    //   .subscribe(companies => (this.products = companies));
    this.loading = true;
    this.columns = {
      'Customer': true,
      'Loan ID': true,
      'Interest Date': true,
      'Interest Amount': true
    };
    this.filterCustomer();
  }

  // set the keys of the response object as column names on the table
  resetColumn(obj) {
    var cols = {};
    for (var key in obj) {
      cols[key] = true;
    }
    this.columns = cols;
  }

  filterCustomer() { 
    this.loading = true;
    // this.showNext = false;
    this.operationsService.getInterestAccruedReport(this.filter).subscribe(
      dataobject => {
        this.loading = false;
        if (this.filter.is_export == '1') {
          this.downloadLinkReceived = false;
          this.downloadLink = dataobject.message;
          alert("Data Successfully exported. Download would start automatically.");
          window.open(dataobject.message);
        } else {
          this.showNext = true;
          this.filter.start = dataobject.start;
          this.rows = dataobject.data;
          this.total = dataobject.total;

          if (typeof this.rows !== 'undefined' && this.rows.length > 0) {
            this.resetColumn(dataobject.data[0]);
          }
        }

      });
  }


  exportReport() {
    this.loading = true;
    var csvData = this.ConvertToCSV(this.rows);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    var x: Date = new Date();
    var link: string = "filename_" + x.getMonth() + "_" + x.getDay() + '.csv';
    a.download = link.toLocaleLowerCase();
    a.click();
    this.loading = false;

  }



  // convert Json to CSV data in Angular2
  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

}
