import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { AppConfig } from '../app.config';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  model: any = {};
  loading = false;
  error = '';

  constructor(
    public router: Router,
    private _user: UserService,
    public authenticationService: AuthenticationService,
    private toastr: ToastrService
  ) {
  }

  showSuccess(msg) {
    this.toastr.success(msg);
  }

  showError(msg) {
    this.toastr.error(msg);
  }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();
    this._user.shouldOnboardInterrupt = true;
  }

  login() {
    this.loading = true;
    this.authenticationService.login(this.model.email, this.model.password)
      .subscribe(result => {
        if (result === true) {

          this.router.navigate(['/applications/dashboard']);
          //this.router.navigate(['/twofa']);
          // if (this.authenticationService.canViewModule('1,2,4,5,1026')) {
          //   this.router.navigate(['/applications/dashboard']);
          // }else
          // if (this.authenticationService.canViewModule('3')) {
          //   this.router.navigate(['/operations/queue']);
          // }else{
          //   this.router.navigate(['/login']);
          // }


        } else {
          this.showError('Unable to login');
          this.loading = false;
        }
      }, err => {
        this.loading = false;
      });
  }
}
