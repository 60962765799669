import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';
import { CustomerService, StorageService } from '../../_services/index';
@Component({
  selector: 'app-lenderpqueue',
  templateUrl: './lenderpqueue.component.html',
  styleUrls: ['./lenderpqueue.component.css']
})
export class LenderpqueueComponent implements OnInit {
partner= '';
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = '';
  is_done = false;
  currentUser: any;
  repayments:any;
  loading = false;
  has_data = false;
  searchString = '';
  @Input() nocheck = false;
  @Input('payment_date') payment_date='';
  @Input('disburse') disburse:any;
  @Input('showing_due_loans') showing_due_loans = false;
  @Input('show_for_account_officer') show_for_account_officer = false;
  param = {
    'search_string':'',
    'start':'0'
  }
  adding = true;
  added_requests = [];
  send_payments_to_queue = '0';
  start = '0';
  has_more = false;
  constructor(private operationsService: OperationsService,public storageService: StorageService, private dataService: DataService) { 
    this.currentUser = this.storageService.read<any>('currentUser');
    this.partner = '';
    this.dataService.bulkUploadRecordCleared.subscribe(res => {
      this.getDueRepayments();
    });
  }

  ngOnInit() {
    this.getDueRepayments();
   
  }
  getPaymentsForTheScheduleDate(){
    this.loading = true;
    this.operationsService.getPaymentsForTheScheduleDate(this.currentUser.token,this.param, this.payment_date,this.show_for_account_officer)
      .subscribe(result => {
        this.loading = false;
        this.has_data = result.has_data;
        this.repayments = result.message;
        this.send_payments_to_queue = result.send_payments_to_queue;
        
      });
  }
  
  getDueRepayments(){
    this.loading = true;
    this.operationsService.getLenderPQueue(this.currentUser.token,'')
      .subscribe(result => {
        this.loading = false;
        this.repayments = result;
      });
  }
  loanmoregetDueRepayments(){
    this.loading = true;
    this.operationsService.getBulkRepayments(this.currentUser.token,this.param)
      .subscribe(result => {
        this.loading = false;
        this.has_data = result.has_data;
        result.message.map(x => this.repayments.push(x))
        this.send_payments_to_queue = result.send_payments_to_queue;
        this.start = result.start;
        this.has_more = result.has_more;
        this.param.start = result.start;
      });
  }
  postDuePayments(){
    // this.loading = true;
    // this.operationsService.postDuePayments(this.currentUser.token,this.repayments,this.payment_date)
    //   .subscribe(result => {
    //     this.loading = false; 
    //   });
    this.loading = true;
    this.loading = false; 
    this.is_done = true;
    this.operationsService.postDuePayments(
      this.currentUser.token,
      this.added_requests,
      this.payment_date
    );
  }
  addRecord(due,i){
    if(this.added_requests.indexOf(due)==-1){
      this.repayments[i].checked = true;
      this.added_requests.push(due);
    }
    
  }
  removeRecord(due,i){
    if(this.added_requests.indexOf(due)!=-1){
      this.repayments[i].checked = false;
      this.added_requests.splice(due,1);
    }
    
  }
  onScrollDown(){

  }
  openSidemenuForDueLoan(due){  
    this.dataService.openSidemenuForDueLoan.emit({due: due});
  }
  paymentDone() { 
    this.dataService.pushingtokuda.emit({disburse: this.repayments.data, partner: this.partner});


  }
  loadpartnerrequests(){
    this.loading = true;
    this.operationsService.getLenderPQueue(this.currentUser.token, this.partner)
      .subscribe(result => {
        this.loading = false;
        this.repayments = result;
      });
  }
}
