<div class="padding-regular animated fadeIn">
  <div class="title mb-4">Accounts
  </div>
  <div>

    <section class="panel padding-regular" *ngIf="eligibilitycriteria?.criteria?.account?.status == true">
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Select account to analyse</label>
          <select class="form-control custom small" name="account_to_analyse" [(ngModel)]="criteria.account_to_analyse" id="type1">
            <option [value]="0"></option>
            <option [value]="acc.account_card_id" *ngFor="let acc of eligibilitycriteria?.criteria?.account?.data">{{acc.last_four_digits}}/{{acc.card_name}}/{{acc.bank_name}}</option> 
          </select>
          <div class="subtitle"><label for="skip_account"><input type="checkbox" name="skip_account" [(ngModel)]="criteria.skip_account"> Skip</label></div>
        </div>

      </div>
    </section>
    <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.account?.status == false">
      <div class="alert">
        <div class="texts no-padding">
          <div class="title">No account found</div>
          <div class="subtitle">Send account request</div>
          <div class="subtitle"><label for="skip_account"><input type="checkbox" name="skip_account" [(ngModel)]="criteria.skip_account"> Skip</label></div>
        </div>
        <div class="action">
          <app-button [disabled]="loading" *ngIf="!loading" class="small outline mr-1" label="Send Account Request" (click)="confirmAccount()"></app-button> 
        </div>
      </div>
    </div>
  </div>
  <div class="title mb-4">Cards
  </div>
  <div>

    <section class="panel padding-regular" *ngIf="eligibilitycriteria?.criteria?.card?.status == true">
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Select card to analyse</label>
          <select class="form-control custom small" name="card_to_analyse" [(ngModel)]="criteria.card_to_analyse" id="type1">
            <option [value]="0"></option>
            <option [value]="acc.ACCOUNT_CARD_ID" *ngFor="let acc of eligibilitycriteria?.criteria?.card?.data">**** **** **** {{acc.LAST_FOUR_DIGITS}}|{{acc.EXPIRY_DATE}}|{{getCardBrand(acc)}}</option> 
          </select>
          <div class="subtitle"><label for="skip_card"><input type="checkbox" name="skip_card" [(ngModel)]="criteria.skip_card"> Skip</label></div>
        </div>

      </div>
    </section>
    <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.card?.status == false">
      <div class="alert">
        <div class="texts no-padding">
          <div class="title">No card found</div>
          <div class="subtitle">Send card request</div>
          <div class="subtitle"><label for="skip_card"><input type="checkbox" name="skip_card" [(ngModel)]="criteria.skip_card"> Skip</label></div>
        </div>
        <div class="action">
          <app-button [disabled]="loading" *ngIf="!loading" class="small outline mr-1" label="Send Card Request" (click)="requestCard()"></app-button> 
        </div>
      </div>
    </div>
  </div>
  <div class="title mb-4">Identity
  </div>
  <div>

    <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.nin?.status == false">
      <div class="alert">
        <div class="texts no-padding">
          <div class="title">No identity details found</div>
          <div class="subtitle">Send request for nin details</div>
          <div class="subtitle"><label for="skip_identity"><input type="checkbox" name="skip_identity" [(ngModel)]="criteria.skip_identity"> Skip</label></div>
        </div>
        <div class="action">
          <app-button [disabled]="loading" *ngIf="!loading" class="small outline mr-1" label="Send NIN Request" (click)="requestNIN()"></app-button> 
        </div>
      </div>
    </div> 
    <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.nin?.status == true">
      <div class="padding-regular flex justify-between align-center">
        <div class="user"> 
          <div class="image">
            <img
              src="{{eligibilitycriteria?.criteria?.nin?.data?.NIN_DETAILS?.Image}}">
          </div>
          <div class="content">
            <h4 class="title color-teal">{{eligibilitycriteria?.criteria?.nin?.data?.NIN_DETAILS?.LastName}} {{eligibilitycriteria?.criteria?.nin?.data?.NIN_DETAILS?.FirstName}}</h4>
            <p class="subtitle">{{eligibilitycriteria?.criteria?.nin?.data?.NIN_DETAILS?.DOB}}</p>
            
          </div>
        </div>
        <div class="subtitle"><label for="skip_identity"><input type="checkbox" name="skip_identity" [(ngModel)]="criteria.skip_identity"> Skip</label></div>
      </div>
    </div>
  </div>
  <div class="title mb-4">Credit Bureau
  </div>
  <div>

    <section class="panel padding-regular" *ngIf="eligibilitycriteria?.criteria?.cb?.status == true">
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Select credit bureau to analyse</label>
          <select class="form-control custom small" name="credit_bureau_to_analyse" [(ngModel)]="criteria.credit_bureau_to_analyse" id="type1">
            <option [value]="0"></option>
            <option [value]="acc.CREDITCHECK_ID" *ngFor="let acc of eligibilitycriteria?.criteria?.cb?.data">{{acc.DATE_ADDED}}</option> 
          </select>
        </div>
        <div class="subtitle"><label for="skip_credit_bureau"><input type="checkbox" name="skip_credit_bureau" [(ngModel)]="criteria.skip_credit_bureau"> Skip</label></div>
      </div>
    </section>
    <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.cb?.status == false">
      <div class="alert">
        <div class="texts no-padding">
          <div class="title">No credit bureau details found</div>
          <div class="subtitle">Send credit bureau request</div>
          <div class="subtitle"><label for="skip_credit_bureau"><input type="checkbox" name="skip_credit_bureau" [(ngModel)]="criteria.skip_credit_bureau"> Skip</label></div>
        </div>
        <div class="action">
          <app-button [disabled]="loading" *ngIf="!loading" class="small outline mr-1" label="Run Credit Check" (click)="requestCB()"></app-button> 
        </div>
      </div>
    </div>
  </div> 
  <div class="title mb-4">Bank Statement
  </div>
  <div>

    <section class="panel padding-regular" *ngIf="eligibilitycriteria?.criteria?.bs?.status == true">
      <div class="row">
        <div class="form-group col-md-6">
          <label class="control-label" for="type1">Select bank statement to analyse</label>
          <select class="form-control custom small" name="bankstate_to_analyse" [(ngModel)]="criteria.bankstate_to_analyse" id="type1">
            <option [value]="0"></option>
            <option [value]="acc.ATTACHMENT_ID" *ngFor="let acc of eligibilitycriteria?.criteria?.bs?.data">{{acc.COMMENTS}}|{{acc.DATE_ADDED}}</option> 

          </select>
        </div>
        <div class="subtitle"><label for="skip_bank_statement"><input type="checkbox" name="skip_bank_statement" [(ngModel)]="criteria.skip_bank_statement"> Skip</label></div>
      </div>
    </section>
    <div class="panel bg-off-white" *ngIf="eligibilitycriteria?.criteria?.bs?.status == false">
      <div class="alert">
        <div class="texts no-padding">
          <div class="title">No bank statements found</div>
          <div class="subtitle">Send bank statement request</div>
          <div class="subtitle"><label for="skip_bank_statement"><input type="checkbox" name="skip_bank_statement" [(ngModel)]="criteria.skip_bank_statement"> Skip</label></div>
        </div>
        <div class="action">
          <app-button *ngIf="!loading" class="small outline mr-1" label="Run Bank Statement" (click)="requestBS()"></app-button> 
        </div>
      </div>
    </div>
  </div> 
  <div>

    <section class="panel padding-regular">
     
  
      <app-button class="small default mr-2" label="Run Eligibility" (click)="runEligibility()"  [loading]="loading"></app-button>
    </section>
  </div> 
</div>