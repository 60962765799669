<div>
    <div class="panel mb-1 overflow-visible">
        <div class="padding-regular">
          <div class="small-menu no-padding">
            <h4 class="section-title flex flex-row align-center"><span>{{text}}</span></h4>

            <ul class="list-inline" *ngIf="!nooptions && request_id_">
              <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/request/' + this.request_id_]">Contract</a></li>
              <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/profile/' + this.request_id_]">Profile</a></li>
              <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/timeline/' + this.request_id_]">Timeline</a></li>
              <li class="dropdown" *ngIf="!loan?.is_general_market">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" [ngClass]="{'active': text !== 'More'}"
                aria-expanded="false">{{text}} <span class="ml-2 caret"></span></a>
                <ul class="dropdown-menu dropdown-menu-right mt-3">
                  <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/eligibility/' + this.request_id_]">Eligibility</a></li>
                  <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/analytics/' + this.request_id_]">Analytics</a></li>
                  <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/kyc/' + request_id_]">Address VS</a></li>
                  <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/creditcheck/' + request_id_]">Credit Bureau</a></li>
                  <li *ngIf="enable_peer_to_peer=='1'"><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/p2p/' + request_id_]">P2P</a></li>
                  <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/cover/' + request_id_]">Loan cover</a></li>
                  <li><a [routerLinkActive]="'active'" [routerLink]="['../../../portfolio/requests/' + request_id_]">Other loans</a></li>
                </ul>
              </li>
            </ul>

            <!-- <ul class="list-inline" *ngIf="!nooptions">
              <li><button (click)="openContract()">Contract</button></li>
              <li><button (click)="openProfile()">Profile</button></li>
              <li><button (click)="openEligibility()">Eligibility</button></li>
              <li class="dropdown">
                <button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right mt-3">
                  <li><a (click)="openTimeline()">Timeline</a></li>
                  <li><a (click)="openAnalytics()">Analytics</a></li>
                  <li><a [routerLink]="['../../../portfolio/kyc/' + request_id_]">Address VS</a></li>
                  <li><a [routerLink]="['../../../portfolio/creditcheck/' + request_id_]">Credit Bureau</a></li>
                  <li *ngIf="enable_peer_to_peer=='1'"><a [routerLink]="['../../../portfolio/p2p/' + request_id_]">P2P</a></li>
                  <li><a [routerLink]="['../../../portfolio/cover/' + request_id_]">Loan cover</a></li>
                  <li><a [routerLink]="['../../../portfolio/requests/' + request_id_]">Other loans</a></li>
                </ul>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
      
</div>
