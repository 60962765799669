import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maritalstatus'
})
export class MaritalstatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == '1') {
      return 'Single';
    }
    if (value == '2') {
      return 'Married';
    }
    if (value == '3') {
      return 'Divorced';
    }
    if (value == '4') {
      return 'Widowed';
    } 
  }

}
