import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductsService } from 'src/app/_services/products.service';
import { LoansService } from 'src/app/_services/loans.service';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/_services';

@Component({
  selector: 'app-productchecklist',
  templateUrl: './productchecklist.component.html',
  styleUrls: ['./productchecklist.component.css']
})
export class ProductchecklistComponent implements OnInit {
  @Input('from_where') from_where: any;
  @Input('product_checklist') product_checklist = [];
  view = 'list';
  currentUser: any; 
  tagModel = {
    display: '',
    checked: false,
    checked_by:''
  };
  @Output() saveProductChecklists = new EventEmitter();
  @Input('loading') loading = false;
  constructor(public storageService: StorageService, public toastr: ToastrService,
    private productService: ProductsService, private loansService: LoansService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() { 
    if(this.from_where!='product'){
      console.log(this.product_checklist);
    }
    this.getCheckList();
  }
  getCheckList() {
    if (this.from_where == 'loan') {

    }
  } 
  addNewCheckListItem(value,valid){
    this.tagModel.display = '';
    this.product_checklist.push(value);
    this.view = 'list'
  }
  removeTag(index) {
    this.product_checklist.splice(index, 1);
  }
  saveProductCheckList() {
    this.saveProductChecklists.emit({product_checklist: this.product_checklist});
    
  }
  createCustom() {
    this.view = 'custom'
  }

}
