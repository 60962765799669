<app-main-navigation></app-main-navigation>
<div class="app-container navbar-margin">
  <div class="flex" *ngIf="!loadingRouteConfig">
    <div class="col-md-12 no-padding">
      <h3>Collections Report</h3>

      <div class="panel">
        <div class="panel-body">
          <table class="table table-stripped">
            <thead>
              <tr>
                <td colspan="6">
                  <!-- <div>
                    <label>Product</label>
                    <select (change)="getRecords_()" name="loan_product_id" [(ngModel)]="loan_product_id">
                      <option value=""></option>
                      <option *ngFor="let p of products" value="{{p.loan_product_id}}">{{p.loan_title}}</option>
                    </select>
                  </div> -->
                 
                  <div class="pull-right"> 
                      <i *ngIf="loading" class="fa fa-spin fa-spinner"></i>
                       
                  </div>
                </td>
              </tr>

            </thead>
            <thead>
              <th></th>
              <th></th>
              <th></th>
              <th>Amount</th>
              <th>Date</th>
              <th>Channel</th>
            </thead>

            <tbody *ngFor="let record of data">
              <tr style="background-color: #dcdfdf;">
                <td colspan="7">
                  {{record.loan_id}}-{{record.legal_name}}-{{record.loan_title}}-{{record.disbursement_date}}
                  <!-- <button class="btn btn-xs pull-right" (click)="openSchedule(record)">Schedule</button> -->
                </td>
              </tr>
              <tr>
                <td colspan="7">
                  <app-collector [record]="record"></app-collector>
                </td>
              </tr>
              <!-- <tr>
                <td>1</td>
                <td>{{record.request_principal}}</td>
                <td>Due Loans</td>
                <td>{{(+record.principal_due||0)}}</td>
                <td>{{(+record.interest_due||0)}}</td>
                <td>{{((+record.principal_due||0)+(+record.interest_due||0))}}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Payments</td>
                <td>{{record.principal_paid==null?0:record.principal_paid}}</td>
                <td>{{record.interest_paid==null?0:record.interest_paid}}</td>
                <td>{{record.how_much_paid==null?0:record.how_much_paid}}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>Net Due</td>
                <td>{{record.principal_net_due==null?0:record.principal_net_due}}</td>
                <td>{{record.interest_net_due==null?0:record.interest_net_due}}</td>
                <td>{{record.total_net_due==null?0:record.total_net_due}}</td>
              </tr> -->
            </tbody>

            <thead>
              <tr>
                <td colspan="7">
                  <app-button class="small outline mr-1" (click)="getRecords()" label="Next"></app-button>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="flex" *ngIf="loadingRouteConfig">

    <div class="col-md-6 no-padding">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 60%;"></div>
          <div class="skeleton mb-2 mt-4" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3 pr_0">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 80%;"></div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="panel mb-1 sticky">
        <div class="padding-regular">
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
          <div class="skeleton mb-2" style="height: 20px; width: 100%;"></div>
        </div>
      </div>
    </div>
  </div>
</div>