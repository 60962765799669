import { OnChanges, SimpleChanges, SimpleChange, Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DataService, StorageService } from '../../_services/index'; 

@Component({
  selector: 'app-applicationportfolioheader',
  templateUrl: './applicationportfolioheader.component.html',
  styleUrls: ['./applicationportfolioheader.component.css']
})
export class ApplicationportfolioheaderComponent implements OnInit {

  @Input('request_id') request_id = '0';
  @Input('loan') loan :any;
  request_id_: any;
  @Input('text') text = 'More';
  enable_peer_to_peer = '0';
  @Input('nooptions') nooptions = false;
  constructor(private router: Router, private dataService: DataService, public storageService: StorageService) { 
    this.enable_peer_to_peer = this.storageService.read<any>('enable_peer_to_peer');
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    const request_id: SimpleChange = changes.request_id;
    if (request_id) {
      this.request_id_ = request_id.currentValue;
    }

  }
  openContract() {
    this.router.navigate(['./portfolio/request/' + this.request_id_]);
  }
  openProfile() {
    this.router.navigate(['./portfolio/profile/' + this.request_id_]);
  }
  openAnalytics() {
    this.router.navigate(['./portfolio/analytics/' + this.request_id_]);
  }
  openTimeline() {
    this.router.navigate(['./portfolio/timeline/' + this.request_id_]);
  }
  openEligibility() {
    this.router.navigate(['./portfolio/eligibility/' + this.request_id_]);
  }
  

}
