﻿export class Customer {
  LEGAL_NAME: string;
  PEOPLE_CUSTOMERS_ID: number;
  //ADDRESSES: [];
  //WORK_DETAILS: [];
  EMAIL: string;
  PHONE: string;
  TAX_ACCOUNT_SETTINGS: number;
  BIRTH_DAY: number;
  BIRTH_MONTH: number;
  BIRTH_YEAR: number;
  NATIONALITY: number;
  GENDER: number;
  MARITAL_STATUS: number;
  HOME_COUNTRY: number;
  HOME_STATE: number;
  HOME_ADDRESS: string;
  HOME_LANDMARK: string;
  NATURE_OF_ACCOMODATION: number;
  TIME_AT_CURRENT_ADDRESS: number;
  OCCUPATION_ID: number;
  NET_MONTHLY_INCOME: string;
  WORK_SECTOR: number;
  WORK_DESIGNATION: number;
  COMPANY_OF_WORK_ID: string;
  WORK_ADDRESS: string;
  WORK_COUNTRY_ID: number;
  WORK_STATE_ID: number;
  WORK_START_DATE: number;
  WORK_END_DATE: number;
  EDU_SECTOR: number;
  INSTITUTION_ID: string;
  WORK_RETIRED_DATE: number;
  WORK_RETIRED_YEAR: number;
  YEARS_OF_SERVICE: number;
  IDENTY_TYPE_ID: number;
  NAME_ON_ID: string;
  IDENTITY_CODE: string;
  IDMONTH: number;
  IDYEAR: number;
  BVN: string;
  //BANK_ACCOUNTS:[];
  NOK_NAME: string;
  NOK_PHONE: string;
  NOK_EMAIL: string;
  NUMBER_OF_DEPENDANTS: number;
  MONTHLY_RENT_EXPENSES: string;
  MONTHLY_TRANSPORT: string;
  FILE_NAME: string;
  CUSTOMER_TYPE: number;
}
