import { AfterViewInit, Component, OnInit } from '@angular/core';

const $ = window['$'];

@Component({
  selector: 'app-ecosystems',
  templateUrl: './ecosystems.component.html',
  styleUrls: ['./ecosystems.component.css']
})
export class EcosystemsComponent implements OnInit, AfterViewInit {

  modal = '';

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // $('.owl-carousel').owlCarousel({
    //   stagePadding: 50,
    //   loop: true,
    //   margin: 10,
    //   nav: true,
    //   responsive: {
    //     0: {
    //       items: 1
    //     },
    //     600: {
    //       items: 3
    //     },
    //     1000: {
    //       items: 5
    //     }
    //   }
    // });
  }
}
