<!--<div class="d-flex align-items-center mb-5">-->
<!--  <div class="fw-bold text-primary font-weight-medium m-0">Theme color</div>-->
<!--  <div *ngIf="loading" class="spinner-grow spinner-grow-sm ms-3" role="status">-->
<!--    <span class="visually-hidden">Loading...</span>-->
<!--  </div>-->
<!--</div>-->

<div class="container">
  <div class="row">
    <div class="col-3 col-md-2 col-lg-2 px-0" *ngFor="let color of colors">
      <app-square-box>
        <div
          (click)="saveThemeColor(color)" class="cursor-pointer flex-center h-100 w-100"
          [ngClass]="{ selected: merchant.theme_color === color}" [ngStyle]="{backgroundColor: color}"
        >
          <i class="fa fa-check text-white" *ngIf="merchant.theme_color === color"></i>
        </div>
      </app-square-box>
    </div>
  </div>
</div>
