<div class="padding-regular">
  <h3 class="title">Portfolio Target</h3>
  <div class="form-group mb-1">
    <label class="control-label normal">Timely Repayments</label>
    <input type="text" name="timely_repayment" [(ngModel)]="target.timely_repayment" class="form-control" />
  </div>
  <div class="form-group mb-1">
    <label class="control-label normal">Request Turnaround Time</label>
    <input type="text" name="request_tat" [(ngModel)]="target.request_tat" class="form-control" />
  </div>
  <div class="form-group mb-1">
    <label class="control-label normal">Profitable Loans</label>
    <input type="text" name="profitable_loans" [(ngModel)]="target.profitable_loans" class="form-control" />
  </div>
  <div class="form-group mb-1">
    <label class="control-label normal">Non Performing Loans</label>
    <input type="text" name="npl" [(ngModel)]="target.npl" class="form-control" />
  </div>
  <div class="form-group mb-1">
    <label class="control-label normal">Portfolio at risk</label>
    <input type="text" name="par" [(ngModel)]="target.par" class="form-control" />
  </div>
  <div class="form-group mb-1">
    <label class="control-label normal">Percentage Eligibility</label>
    <input type="text" name="percentage_eligibility" [(ngModel)]="target.profitable_loans" class="form-control" />
  </div>
  <div class="form-group mt-4">
    <label class="control-label normal"></label>
    <app-button (click)="updatePortfolioSettings()" [loading]="loading" [disabled]="loading" class="small" label="Save"></app-button>
  </div>
</div>
