
  <div class="row">
    <div class="col-md-12"> 
     <h3 *ngIf="band_type=='1'">Just There</h3>
     <h3 *ngIf="band_type=='2'">Good Enough</h3>
     <h3 *ngIf="band_type=='3'">Fantastic</h3>
     
     <h3>{{band_details?.data?.min_amount}} - {{band_details?.data?.max_amount}} </h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-stripped">
        <thead>
          <tr>
            <th>Psycho</th>
            <th>ID</th>
            <th>FCN</th>
            <th>C-Flow</th>
            <th>C-Bureau</th>
            <th>AVS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><input type="checkbox" name="QS" [(ngModel)]="compdata.QS"></td>
            <td><input type="checkbox" name="ID" [(ngModel)]="compdata.ID"></td>
            <td><input type="checkbox" name="FC" [(ngModel)]="compdata.FC"></td>
            <td><input type="checkbox" name="BS" [(ngModel)]="compdata.BS"></td>
            <td><input type="checkbox" name="CB" [(ngModel)]="compdata.CB"></td>
            <td><input type="checkbox" name="AVS" [(ngModel)]="compdata.AVS"></td>
            <td><button class="btn btn-xs" (click)="save()">Save</button></td>
       
          </tr>
        </tbody>
      </table>
    </div>
  </div>

