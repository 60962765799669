export class Loan_ {
  REQUEST_ID: any;
  REQUEST_CURRENCY_ID: any;
  REQUEST_PRINCIPAL: any;
  REQUEST_RATE_PERIOD_ID: any;
  REQUEST_RATE: any;
  REQUEST_PERIOD_ID: any;
  REQUEST_TENOR: any;
  TYPE_OF_CREDIT: any;
  INTEREST_RATE_TYPE_ID: any;
  REPAYMENT_TYPE_ID: any;
  ALL_INTERESTS_UPFRONTED: boolean;
  ALL_INTERESTS_HAVE_BEEN_PAID: boolean;
  FEES_EXCLUDE_INCLUDE: any;
  WHAT_HAPPENS_TO_INTEREST: any;
  WHAT_HAPPENS_TO_PRINCIPAL: any;
  ALLOW_MORATORIUM: any;
  MORATORIUM_TYPE: any;
  MORATORIUM_PERIOD: any;
  DAYS_PER_YEAR: any;
  RP_SET_TYPE: any;
  PAID_SECURITY_DEPOSIT: boolean;
  SECURITY_DEPOSIT_DEDUCTIBLE: boolean;
  SECURITY_DEPOSIT: boolean;
  TOTAL_SECURITY_DEPOSIT: any;
  DISBURSEMENT_DATE: any;
  LOAN_OFFICER: any;
  REPAYMENT_STARTED_WHEN: any;
  REPAYMENT_SOURCE: any;
  MONTHLY_PERIOD: any;
  PERIODIC_FEES_DISCOUNTED: any;
  ALL_FEES_AND_CHARGES_PAID: boolean;
  LOAN_STATUS: any;
  IS_PEER_TO_PEER: any;
  IS_REJECTED: any;
  ADDED_TO_PAYMENT_QUEUE: any;
  DO_NOT_COMPOUND: any;
} 
