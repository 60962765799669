import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requeststatus'
})
export class RequeststatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value.SCHEDULE_HAS_BEEN_SAVED == '1') {
      if (value.IS_ACCEPTED == '1') {
        return 'ring green';
      } else {
        return 'ring orange';
      }
    } else {
      return 'ring blue';
    }
  }

}
