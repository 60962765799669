import { Component, ViewEncapsulation, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService } from '../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';

@Component({
  selector: 'app-currentapprovallevel',
  templateUrl: './currentapprovallevel.component.html',
  styleUrls: ['./currentapprovallevel.component.css', './custom_style.css', './boot-outline-btn.css']
})
export class CurrentapprovallevelComponent implements OnInit {
  loading = false;
  @Input('request_id') request_id: any;
  @Input('from_investment') from_investment = false;
  @Input('loan_') loan_: any;
  loan: any;
  has_workflow = false;
  @Output() openContarct = new EventEmitter();
  @Output() openWorkFlow = new EventEmitter();

  sub: any;
  parentRouteId: any;
  currentUser: any;
  floor = Math.floor;

  constructor(private dataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public router: Router) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getSummary();
  }
  getSummary() {
    this.sub = this.route.params.subscribe(params => {
      this.parentRouteId = +params['id'] || this.request_id;
      this.getLoan();
    });

  }
  getLoan() {
    this.loading = true;
    if(this.from_investment==true){
      this.loansService.getInvestmentWorkflowLevel(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {
        this.loading = false;
        this.loan = data.loan;
        this.has_workflow = data.loan.has_workflow;
      });
    }else{
      this.loansService.getLoanWorkflowLevel(this.currentUser.token, this.parentRouteId)
      .subscribe(data => {
        this.loading = false;
        this.loan = data.loan;
        this.has_workflow = data.loan.has_workflow;
      });
    }
  }
  openWorkflow() {
    this.openWorkFlow.emit({ loan: this.loan_ })
    // this.dataService.openWorkflowModal.emit({ currentUser: this.currentUser, request_id: this.parentRouteId, loan: this.loan_ });
  }
  restartWorkflow(){
    this.dataService.restartWorkflowModal.emit({ currentUser: this.currentUser, request_id: this.parentRouteId, loan: this.loan_ });
  }
}
