<div class="d-flex align-items-center">
  <div class="fw-bold text-primary font-weight-medium m-0">Show banner</div>
  <div *ngIf="loading" class="spinner-grow spinner-grow-sm ms-3" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div class="form-check form-switch ms-auto">
    <input
      class="form-check-input" type="checkbox" id="collections-banner"
      (change)="handleChange($event)" [checked]="!!merchant.collection_banner"
    />
  </div>
</div>
