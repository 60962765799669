import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from '../../_services/constants.service';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nibbsdebit',
  templateUrl: './nibbsdebit.component.html',
  styleUrls: ['./nibbsdebit.component.css']
})
export class NibbsdebitComponent implements OnInit {

  mandate_history = false;
  sending_account_request = false;
  has_default_card = false;
  @Input('parentRouteId') parentRouteId: any;
  @Input('loan') loan: any;
  @Input('repayment') repayment: any;
  @Input('where') where = 'accountdebit';
  @Input('token') token: any;
  accounts: any;
  accounts_added = 0;
  is_done = '0';
  loading = false;
  account: any;
  sending_direct_request = false;
  PASSWORD = '';
  CHANNEL = '2';
  checking_mandate_status = false;
  resending_link = false;
  cancelling_dd = false;
  loadingData = false;
  checking_instructions = false;
  records_found = false;
  history: any; public pay_opt = [
    { value: '1' },
    { value: '2' },
    { value: '3' }
  ]
  state: any;
  link:any;
  @ViewChild('file') file;
  photoHasBeenUploaded = false;
  filesUploaded = [];
  uploadingfile = false;
  payment = { PAY_WITH: '0', LENDER_ACCOUNT_ID: 0, SECURITY_QUESTION_ANSWER: 0, REQUEST_ID: 0, PAYMENT_DATE: '', AMOUNT_TO_PAY: '0', PAYMENT_OPTION: '1' };
  @Input('sending_instruction') sending_instruction = false;
  loading_ = false; 
  statement_request = {
    "bank":[],
    "paystack":false,
    "agent":false,
    "flutterwave":false,
    "direct_debit_mandate":true,
    "select_account":"1"
  }
  start_date='';
  end_date='';
  amount='';
  first_payment_date='';
  uploadedfile:any;
  nf = '';
  constructor(private toastr: ToastrService, private http: HttpClient,
    private constant: ConstantsService,
    public operationsService: OperationsService, public loansService: LoansService) { }

  ngOnInit() { 
    this.sending_instruction = false;
    this.checking_mandate_status = false;
    this.getMandateDates();
    return this.getAccounts();

  }
  openUploadDialog() {
    this.file.nativeElement.click();
  }
  requestAccount() {
    this.loading = true;
    this.loading = false;
    this.is_done = '1';
    this.operationsService.sendAccountConfirmationRequest(this.token, this.parentRouteId);
  }
  sendAccountRequest() {
    this.sending_account_request = !this.sending_account_request;
    this.sending_direct_request = false;  
    this.resending_link = false;
    this.cancelling_dd = false;
  }
  linkGenerated(data){ 
    this.link = data.url;
  }
  onFilesAdded(event) {
    this.filesUploaded = [];
    this.uploadingfile = true;
    let files = event.target.files;
    this.loansService.doUploads(files[0])
      .subscribe(result => {
        this.uploadingfile = false;
        if (result.status == true) {
          this.photoHasBeenUploaded = true;
          this.filesUploaded.push(result.result);
        }

      });
  }
  getAccounts() {
    this.loadingData = true;
    return this.http.post<any>(`${this.constant.read('api_loan')}loan/accounts/nibbsdebitg`, {
      request_id: this.parentRouteId,
      token: this.token
    }).subscribe(response => {
      this.loadingData = false;
      this.accounts_added = response.count;
      this.accounts = response.data;
      if (response.count == '0') {
        this.has_default_card = false;
        this.sending_account_request = true;

      } else {
        this.account = response.data[0];
      }
      this.loansService.getRepaymentData(this.token, this.parentRouteId)
      .subscribe(customers => {
        this.state = customers;
        this.payment.REQUEST_ID = this.parentRouteId;
        this.payment.PAYMENT_OPTION = this.pay_opt[0].value;
        this.loansService.getTheUploadedFile(this.token, this.parentRouteId)
        .subscribe(res => {
          if (res.status == true) {
            this.uploadedfile = res.file_name; 
            this.filesUploaded.push(this.uploadedfile)
          }  
        });
      });
    }, error => {
    });
  }
  changeTheDefaultAccount(event) {
    this.sending_direct_request = false;
    this.sending_account_request = false;
    this.account = event.account_card;
    this.has_default_card = true;
    this.resending_link = false;
    this.cancelling_dd = false;
  }
  checkDirectDebitStatus(event) {
    this.checking_mandate_status = true;
    this.sending_direct_request = false;
    this.sending_account_request = false;
    this.resending_link = false;
    this.cancelling_dd = false;
  }
  cancelDirectDebit(event) {
    this.cancelling_dd = true;
    this.sending_direct_request = false;
    this.sending_account_request = false;
    this.checking_mandate_status = false;
    this.resending_link = false;
    //this.cancelling_dd = false;
  }
  setupDirectDebit(event) {
    this.account = event;
    this.sending_account_request = false;
    this.is_done = '0';
    this.sending_direct_request = true;
    this.checking_mandate_status = false;
    this.resending_link = false;
    this.cancelling_dd = false;
  } 
  resendingLink(event) {
    this.sending_account_request = false;
    this.sending_direct_request = false;
    this.checking_mandate_status = false;
    this.resending_link = true;
    this.cancelling_dd = false;
  } 
  sendForDirectDebitOnAccount() {
    
    this.loading = true;

    if(this.CHANNEL=='1'){

      this.loansService.sendRequestForDirectDebitOnAccountNibbsAttachment(this.token, this.parentRouteId, this.account, this.PASSWORD, this.filesUploaded[0], this.start_date, this.end_date, this.amount, this.first_payment_date)
      .subscribe(data => {
        this.loading = false;
        if (data.status) {
          this.is_done = '1';
          this.showSuccess('Mandate set up initiated');
          this.cancelling_dd = false;
          this.sending_direct_request = false;
          this.sending_account_request = false;
          this.checking_mandate_status = false;
          this.resending_link = false;
          this.cancelling_dd = false;
          this.getAccounts();
        } else {
          this.showError(data.message);
        }
      });

    }
    if(this.CHANNEL=='2'){
    
      this.statement_request.bank = this.account;
      this.loading = true;
      this.loansService.sendRequestToCustomerForCardTokennizationNibbs(this.token, this.parentRouteId, this.statement_request, this.PASSWORD, this.start_date, this.end_date, this.amount, this.first_payment_date)
        .subscribe(result => {
          this.loading = false;
          if(result.status == true){
            this.showSuccess(result.message);
            this.link = result.link;
            
          }else{
            this.showError('Error processing request');
          }
        });
    }
    if(this.CHANNEL=='3'){
    
      this.statement_request.bank = this.account;
      this.loading = true;
      this.loansService.sendRequestToCustomerForCardTokennizationNibbsFile(this.token, this.parentRouteId, this.account, this.PASSWORD, this.start_date, this.end_date, this.amount, this.first_payment_date)
        .subscribe(result => {
          this.loading = false;
          if(result.status == true){
            this.showSuccess(result.message);
            this.link = result.link;
            
          }else{
            this.showError('Error processing request');
          }
        });
    }
    if(this.CHANNEL=='4'){
      this.accounts.forEach(element => {
        if(element.checked){
          this.account = element;
          this.nf = '';
          this.statement_request.bank = this.account;
          this.loading = true;
          this.loansService.sendRequestToCustomerForCardTokennizationNibbsFileNew(this.token, this.parentRouteId, this.account, this.PASSWORD, this.start_date, this.end_date, this.amount, this.first_payment_date)
            .subscribe(result => {
              this.loading = false;
              if(result.status == true){
                this.showSuccess(result.message);
                this.link = result.link;
                
              }else{
                this.showError(result.message);
                this.nf = result.nf;
              }
            });
        }
        
      });
      this.getAccounts();
    }
  }
  getMandateDates(){
     
    this.loansService.getMandateDates(this.token, this.parentRouteId)
      .subscribe(result => {
        this.loading = false;
        this.start_date = result.data.start_date;
        this.end_date = result.data.end_date;
        this.first_payment_date = result.data.first_payment_date;
        this.amount = result.data.amount;
      });
  }
  sendForDirectDebitStatus() {
    this.loading = true;
    this.loansService.sendForDirectDebitStatusNIBBS(this.token, this.parentRouteId, this.account)
      .subscribe(data => {
        this.loading = false;

        this.is_done = '1';

        if (data.status == true) {
          this.showSuccess('Mandate Is Active');
        } else {
          this.showError("Mandate is not active");
        }
      });

  }
  sendForDirectDebitStatuss(account) {
    this.loading = true;
    this.loansService.sendForDirectDebitStatusNIBBS(this.token, this.parentRouteId, account)
      .subscribe(data => {
        this.loading = false;

        this.is_done = '1';

        if (data.status == true) {
          this.showSuccess('Mandate Is Active');
        } else {
          this.showError("Mandate is not active");
        }
        this.getAccounts();
      });

  }
  sendForResendDirectDebitStatus() {
    this.loading = true;
    this.loansService.sendForResendDirectDebitStatus(this.token, this.parentRouteId, this.account)
      .subscribe(data => {
        this.loading = false;

        this.is_done = '1';

        if (data.status == true) {
          this.showSuccess('Mandate Is Active');
        } else {
          this.showError("Mandate is not active");
        }
      });
  }
  sendForCancelDirectDebitStatus(account) {
    this.loading = true;
    this.loansService.sendForCancelDirectDebitStatusNibbs(this.token, this.parentRouteId, account)
      .subscribe(data => {
        this.loading = false;

        this.is_done = '1';

        if (data.isActive == true) {
          this.showSuccess('Mandate Is Cancelled');
        }
      });
  }
  skipNextDebit() {
    this.loading = true;
    this.loansService.skipNextDebit(this.token, this.parentRouteId, this.account)
      .subscribe(data => {
        this.loading = false;

        this.is_done = '1';

        if (data.isActive == true) {
          this.showSuccess('Mandate Is Cancelled');
        }
      });
  }
  showSuccess(msg) {
    this.toastr.success(msg);
  }
  showError(msg) {
    this.toastr.error(msg);
  } 
  showMandateHistory(){

  }
  debitMandateHistory(loan, acc) {
    this.checking_instructions = true;
    this.mandate_history = true;
    this.operationsService.getMandateTransactionHistoryNibbs(this.token, this.loan, this.account)
      .subscribe(data => {
        this.checking_instructions = false;
        if (data.statuscode == "00") {
          this.records_found = true;
          this.history = data.data.data.paymentDetails;
        } else {
          this.records_found = false;
          this.showError("No record found");
        }
      });
  }
  sendDebitInstruction(){
    this.sending_instruction = true
  }
  doSendDebitInstruction(): void {
    
    this.loading_ = true;
    this.operationsService.sendDebitInstruction_(this.token, this.payment, this.account, this.repayment)
      .subscribe(status => {
        this.loading_ = false;
        if (status.status == true) {
          this.showSuccess(status.message)
           
        } else {
          this.showError(status.message);
        }



      });
  }
  sendRequest(){
   // this.currentUser = this.storageService.read<any>('currentUser');
   this.statement_request.bank = this.account;
    this.loading = true;
    this.loansService.sendRequestToCustomerForCardTokennization(this.token, this.parentRouteId, this.statement_request)
      .subscribe(result => {
        this.loading = false;
        if(result.status == true){
          if (result.status == true) {
            this.showSuccess(result.message)
             
          } else {
            this.showError(result.message);
          }
          
        }else{
          this.showError('Error processing request');
        }
      });
  }
}
