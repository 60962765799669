import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-restriction',
  templateUrl: './mobile-restriction.component.html',
  styleUrls: ['./mobile-restriction.component.css']
})
export class MobileRestrictionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
