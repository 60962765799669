import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit, OnDestroy {

  @Input() expiration_date: any;

  timer = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  date = '11/05/2022';
  interval = null;

  ngOnInit() {
    this.startTimer();
  }

  startTimer() {
    this.countdown(this.expiration_date);
    this.interval = setInterval(() => {
      this.countdown(this.expiration_date);
    }, 1000);
  }

  countdown(date) {
    const future = new Date(date);
    const now = new Date(Date.now());
    if (future < now) {
      this.timer = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
      clearInterval(this.interval);
      this.interval = null;
      return;
    }
    // @ts-ignore
    const diff = future - now;
    this.timer.days = Math.floor(diff / (1000 * 60 * 60 * 24));
    this.timer.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.timer.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    this.timer.seconds = Math.floor((diff % (1000 * 60)) / 1000);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
