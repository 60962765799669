<div>
  <div class="mb-4"  *ngIf="!nocheck"> 
    <div class="flex mt-4 flex-center">
      <label class="form-group">
        <label class="control-label">Select Partner</label>
        <select (change)="loadpartnerrequests()" class="form-control" name="partner" [(ngModel)]="partner">
            <option value=""></option>
            <option value="40460">Kuda Microfinance Bank</option>
            <option value="40490">Credit Direct Limited</option>
            <option value="40497">Advancly</option>
            <option value="17036">VFD  Microfinance Bank</option>
        </select>
      </label>
    </div>
    <app-button [disabled]="partner==''" [buttonclass]="'success'" class="small mr-1 " (click)="paymentDone()"
                label="Push To Partners"></app-button> 

  </div>
  <div class="contract-table">
    <table class="table medium">
      <thead>
        <tr>
          <th *ngIf="!nocheck"></th>
          <th>Customer Name</th>
          <th>Amount</th>
          <th>Bank</th>
          <th>Bank Code</th>
          <th>Account Number</th>
          <th>Account Name</th>
          <th>Narration</th> 
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let due of repayments?.data; let i=index">
          <td *ngIf="!nocheck"><input type="checkbox" name="due{{due?.REQUEST_ID}}" [(ngModel)]="due.checked"></td>
          <td class="flex align-center">
            {{due.legal_name}}
          </td>
          <td>{{due.request_principal}}</td>
          <td>{{due.bank_name}}</td>
          <td>{{due.bank_code}}</td>
          <td>{{due.last_four_digits}}</td>
          <td>{{due.card_name}}</td>
          <td>Being Disbursement For {{due.LOAN_TITLE}} , Obligor : {{due.legal_name}}</td> 
          
        </tr>
      </tbody>
    </table>
  </div>
</div>