import { Component, OnInit } from '@angular/core';
import { DataService, OperationsService, StorageService } from '../_services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-partnercollections',
  templateUrl: './partnercollections.component.html',
  styleUrls: ['./partnercollections.component.css']
})
export class PartnercollectionsComponent implements OnInit {

  currentUser:any;
  loading = false;
  data:any;
  start = '0';
  loan_product_id = '';
  products:any;
  loadingRouteConfig = false;
  customers:any;
  constructor(
    public toastr: ToastrService,
    public router: Router,
    private dataService: DataService, private operationsService: OperationsService, private storageService: StorageService) { 
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.loadingRoute.subscribe(res => {
      this.loadingRouteConfig = res.loadingRouteConfig;
    });
  }

  ngOnInit() {
    this.getRecords();
  }
  openSchedule(record){
    this.dataService.openSchedule.emit({record: record})
  }
  getRecords(){
    //this.getRecordsLoans();
    this.loading = true;
    this.operationsService.getCollectionReports(this.currentUser.token, this.start)
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start; 
      });
  }
  getRecords_(){
    this.start = '0';
    this.loading = true;
    this.operationsService.getLoanReport(this.currentUser.token, this.start, this.loan_product_id, false, '')
      .subscribe(result => {
        this.loading = false;
        this.data = result.data;
        this.start = result.start;
        this.products = result.products;
      });
  }
  getRecordsLoans(){
    this.loading = true;
    this.operationsService.getLoanRecords(this.currentUser.token, this.loan_product_id)
      .subscribe(result => {
        this.loading = false;
        this.customers = result.data;
      });
  }
  getTotal(key, schedule) {
    if (schedule === undefined || schedule === null) { } else {
      let total = 0;
      if (key === 'request_principal') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.request_principal); }, 0);
      }
      if (key === 'principal_paid') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.principal_paid); }, 0);
      }
      if (key === 'principal_balance') {
        total = schedule.reduce(function (cnt, o) { return cnt + parseInt(o.principal_balance); }, 0);
      }
      if (key === 'principal_due') {
        total = schedule.reduce(function (cnt, o) { if(o.principal_due){return cnt + parseInt(o.principal_due);} }, 0);
      }
      if (key === 'principal_arrears') {
        total = schedule.reduce(function (cnt, o) { if(o.principal_arrears){return cnt + parseInt(o.principal_arrears);} }, 0);
      }
      
      return total;
    }
    //

  }
}
