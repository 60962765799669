import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoansService, CustomerService, StorageService, OptionsserviceService } from 'src/app/_services';

@Component({
  selector: 'app-attachments-card',
  templateUrl: './attachments-card.component.html',
  styleUrls: ['./attachments-card.component.css']
})
export class AttachmentsCardComponent implements OnInit {
  attachments = [];
  currentUser: any;
  loadingAttachments = false;
  doc_types = [];
  photos = [];
  @Input() reqid = '';
  @Input() pid = '';
  @Input() where = 'loan';
  @Output() openModal = new EventEmitter();


  constructor(
    public loansService: LoansService,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    console.log(this.pid)
    this.loadRecords();
  }

  loadRecords() {
    if(this.where == 'loan'){

      this.loadingAttachments = true;
    this.currentUser = this.storageService.read<any>('currentUser');

    this.loansService.getLoanAttachments(this.currentUser.token, +this.reqid)
      .subscribe(result => {
        // this.attachments = result.data.attachments.filter(a => a.FILE_TYPE === '1' && a.FILE_NAME !== '').slice(0, 3);
        // this.photos = result.data.attachments.filter(a => a.FILE_TYPE === '2' && a.FILE_NAME !== '').slice(0, 3);
        this.attachments = result.data.attachments.filter(a => a.FILE_TYPE === '1' && a.FILE_NAME !== '');
        this.photos = result.data.attachments.filter(a => a.FILE_TYPE === '2' && a.FILE_NAME !== '');
        this.doc_types = result.data.doctypes;
        this.loadingAttachments = false;
      });

    }else{
      this.loadingAttachments = true;
      this.loansService.getLoanAttachments_(this.currentUser.token, 0, this.pid, '')
        .subscribe(result => {
          this.photos = result.data.attachments.filter(attach => attach.FILE_TYPE === '2' && attach.FILE_NAME);
          this.attachments = result.data.attachments.filter(attach => attach.FILE_TYPE === '1' && attach.FILE_NAME);
          this.doc_types = result.data.doctypes;
          this.loadingAttachments = false;
        });
    }
    
  }
}
