import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AnalysisService } from '../analysis.service';
import { DataService } from 'src/app/_services';
import { EmailService } from '../email.service';

@Component({
  selector: 'app-offer-letter',
  templateUrl: './offer-letter.component.html',
  styleUrls: ['./offer-letter.component.css']
})
export class OfferLetterComponent implements OnInit {
  loading = false;
  view = 'list';
  @Input('where_from') where_from = 'self';
  @Input('request_id') request_id: any;
  @Input('updateSection') updateSection = '';
  @Input('loan_product_id') loan_product_id: any;
  @Output() goback = new EventEmitter();
  viewTitle: string;
  loader = false;
  logo_exist = false;
  selectedFile: File;
  selectedFileSignauture1: File;
  selectedFileSignauture2: File;
  selectedLogo: boolean;
  vendor_id: any;
  token: string;
  setScroll: boolean;
  altsetScroll: boolean;
  selectedSignature1: any;
  selectedSignature2: any;
  offer_salutation: any;
  body: any;
  term: any;
  subject: any;
  logo_offer_letter: string;
  signatureimage1: string;
  signatureimage2: string;
  signatureright: any;
  signatureleft: any;
  remark: any;

  letter_address: any;
  letter_body: any;
  letter_logo: any;
  letter_repayment: any;
  letter_salutation: any;
  letter_signature: any;
  letter_term: any;
  tags = [
    {
      tag: 'Lender Name',
      value: '[LENDER_NAME]'
    },
    {
      tag: 'Borrower Name',
      value: '[LEGAL_NAME]',
    },
    {
      tag: 'Facility Type',
      value: '[LOAN_PURPOSE]'
    },
    {
      tag: 'Product',
      value: '[LOAN_PRODUCT]'
    },
    {
      tag: 'Principal',
      value: '[REQUEST_PRINCIPAL]'
    },
    {
      tag: 'Duration',
      value: '[REQUEST_TENOR] [LOAN_DURATION]'
    },
    {
      tag: 'Interest Rate',
      value: '[REQUEST_RATE] [INTEREST_DURATION]'
    },
    {
      tag: 'Purpose',
      value: '[LOAN_PURPOSE]'
    },
    {
      tag: 'Disbursement Date',
      value: '[DISBURSEMENT_DATE]'
    },
    {
      tag: 'First Repayment Date',
      value: '[REPAYMENT_STARTED_WHEN]'
    },
    {
      tag: 'Monthly Installments',
      value: '[MONTHLY_INSTALLMENT]'
    },
    {
      tag: 'Total Repayment',
      value: '[MONTHLY_INSTALLMENT]'
    },
    {
      tag: 'Maturity Date',
      value: '[MATURITY_DATE]'
    },
    {
      tag: 'Total Repayment',
      value: '[MONTHLY_INSTALLMENT]'
    }
  ];
  tagModel = {
    tag: '',
    value: ''
  };
  chosenTags = [];
  companydescription: any;

  constructor(public toastr: ToastrService, public services: AnalysisService, public dataService: DataService, public emailService: EmailService) { }
  gobacktoloan() {
    this.goback.emit({})
  }
  offerLetterObject = {
    logo: '',
    name: '',
    company: '',
    date: '',
    address: '',
    salutation: '',
    body: '',
    term: '',
    signature1: '',
    signature1Name: '',
    signature1Position: '',
    signature2: '',
    signature2Name: '',
    signature2Position: '',
    customer: {}
  };

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '5rem',
    minHeight: '7rem',
    translate: 'no',
    width: "100%",
    minWidth: "100%"
  };

  editorConfig2 = {
    editable: true,
    spellcheck: false,
    height: '3rem',
    minHeight: '3rem',
    translate: 'no',
    width: "100%",
    minWidth: "100%"
  };

  editorConfig3 = {
    editable: true,
    spellcheck: false,
    height: '5rem',
    minHeight: '7rem',
    translate: 'no',
    width: "100%",
    minWidth: "100%",
    "toolbar": [
      ["bold", "italic", "underline"]
    ]
  };

  lender: string;
  borrower: string;
  facility_type: string;
  facility_amount: string;
  purpose: string;
  tenor: string;
  intrest_rate: string;
  management_fee: string;
  commitment_fee: string;
  credit_life: string;
  credit_search: string;
  upfront_payment: string;
  disbursment: string;
  repayment: string;
  repayment_source: string;
  monthly_installment: string;
  bottom = '';
  top = '';
  middle = '';
  signatureright2: any;
  logo = '';
  ngOnInit() {
    this.loading = true;
    this.viewTitle = 'Offer Letter';

    const vendorIdData = localStorage.getItem('platform');
    const userdata = JSON.parse(vendorIdData);
    this.vendor_id = userdata.PEOPLE_ID;
    this.token = localStorage.getItem('token');


    const payload = {
      'token': this.token,
      'request_id': this.request_id, 
      'where_from': this.where_from,
      'loan_product_id': this.loan_product_id
    };

    this.services.getOfferLetterDetails(payload).subscribe((data) => {
      if(this.where_from !='investment'){
        this.letter_address = data.offer_letter_address;
        this.logo_offer_letter = data.offer_letter_logo;
      this.letter_repayment = data.offer_letter_repayment;
      this.offer_salutation = data.offer_letter_salutation; 
      this.remark = data.offer_letter_remark; 
      this.remark = data.offer_letter_remark;
      this.subject = data.offer_letter_subject;

      const address = JSON.parse(this.letter_address);
      this.offerLetterObject.name = address.name;
      this.offerLetterObject.company = address.company;
      this.offerLetterObject.date = address.date;
      this.offerLetterObject.address = address.address;
      
      this.offerLetterObject.customer = {
        name: data.offer_letter_name,
        dateVisible: data.date_visible,
        body: data.offer_body
      }

      this.chosenTags = JSON.parse(data.offer_letter_tags);
      }
      this.body = data.offer_letter_body;
      
      this.term = data.offer_letter_term;
      
 

      this.loading = false;
    });

    this.emailService.getWebsiteContent(this.vendor_id).subscribe((data) => {
      this.companydescription = data;
      this.loading = false;
    });
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    this.selectedLogo = false;
  }
  chooseTag(tag) {
    this.chosenTags.push(tag);
  }
  createCustom() {
    this.view = 'custom'
  }
  saveTagModel(value, valid) {
    this.chosenTags.push(value);
    this.view = 'list'
  }
  removeTag(index) {
    this.chosenTags.splice(index, 1);
  }
  onFileChangedSignature1(event) {
    this.selectedFileSignauture1 = event.target.files[0];
    this.selectedSignature1 = false;
  }

  onFileChangedSignature2(event) {
    this.selectedFileSignauture2 = event.target.files[0];
    this.selectedSignature2 = false;
  }

  onSubmitAddressLetterBody(form: NgForm) {
    this.loader = true;
    const addressValue = JSON.stringify(form.value);

    const payload = {
      'token': this.token,
      'address': addressValue,
      'where_from': this.where_from,
      'request_id': this.request_id,
      'loan_product_id': this.loan_product_id
    };

    this.services.updateAddress(payload).subscribe((data) => {
      if (data == true) {
        this.logo_exist = true;
        this.logo_offer_letter = data.offer_letter_logo;
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });
  }

  onSubmitLogo() {
    this.loader = true;
    const uploadLogo = new FormData();
    uploadLogo.append('img', this.selectedFile, this.selectedFile.name);
    uploadLogo.append('token', this.token);
    uploadLogo.append('where_from', this.where_from);
    uploadLogo.append('request_id', this.request_id);
    uploadLogo.append('loan_product_id', this.loan_product_id);

    this.services.updateOfferLetterLogo(uploadLogo).subscribe((data) => {
      this.logo_exist = true;
      this.logo_offer_letter = data.offer_letter_logo;
      this.loader = false;
      this.toastr.success('Success', 'Success!');
      this.dataService.reloadOfferLetter.emit();
    });
  }

  onSubmitSalutation(form: NgForm) {
    this.loader = true;
    const payload = {
      'token': this.token,
      'salutation': form.value.Salutation,
      'where_from': this.where_from,
      'request_id': this.request_id,
      'loan_product_id': this.loan_product_id
    };
    this.services.salutation(payload).subscribe((data) => {
      if (data == true) {
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });
  }

  onSubmitSubjectBody(formfield) {
    this.loader = true;

    const payload = {
      'token': this.token,
      'subject': this.subject,
      'where_from': this.where_from,
      'request_id': this.request_id,
      'loan_product_id': this.loan_product_id
    };

    this.services.offerLetterSubject(payload).subscribe((data) => {
      if (data == true) {
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });
  }

  saveChosenTags() {
    this.loader = true;
    this.services.saveChosenTags(this.token, this.chosenTags, this.where_from, this.request_id, this.loan_product_id).subscribe((data) => {
      if (data == true) {
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });
  }

  onSubmitTag() {
    this.loader = true;
    const value = {
      'lender': this.lender,
      'chosenTags': this.chosenTags,
    };
    const tagsValue = JSON.stringify(value);

    const payload = {
      'token': this.token,
      'tags': tagsValue,
      'where_from': this.where_from,
      'request_id': this.request_id,
      'loan_product_id': this.loan_product_id
    };

    this.services.offerLetterTags(payload).subscribe((data) => {
      if (data == true) {
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });
  }

  onSubmitCustomer(customerForm: NgForm) {
    this.loader = true;
    const data = {
      ...customerForm.value,
      token: this.token,
      where_from: this.where_from,
      request_id: this.request_id,
      loan_product_id: this.loan_product_id,
    };
    this.services.updateOfferLetterCustomer(data).subscribe((data) => {
      this.loader = false;
      this.toastr.success('Your changes has been saved!', 'Saved');
      // this.dataService.reloadOfferLetter.emit();
    });
  }

  onSubmitSignatures(signatureForm: NgForm) {
    this.loader = true;
    const uploadData = new FormData();
    if (this.selectedFileSignauture1) {
      uploadData.append('managerSignature', this.selectedFileSignauture1, this.selectedFileSignauture1.name);
    }

    if (this.selectedFileSignauture2) {
      uploadData.append('otherSignature', this.selectedFileSignauture2, this.selectedFileSignauture2.name);
    }

    uploadData.append('remark', signatureForm.value.remark);
    uploadData.append('signature1Name', signatureForm.value.signature1Name);
    uploadData.append('signature1Position', signatureForm.value.signature1Position);
    uploadData.append('signature2Name', signatureForm.value.signature2Name);
    uploadData.append('signature2Position', signatureForm.value.signature2Position);
    uploadData.append('signatureLeft', this.signatureleft);
    uploadData.append('signatureRight', this.signatureright);
    uploadData.append('token', this.token);
    uploadData.append('where_from', this.where_from);
    uploadData.append('request_id', this.request_id);
    uploadData.append('loan_product_id', this.loan_product_id); 
    this.services.updateSignature(uploadData).subscribe((data) => {
      // this.selectedLogo = true;
      if (data) {
        this.signatureimage1 = data.res1;
        this.signatureimage2 = data.res2;

        this.loader = false;
        this.toastr.success('Success', 'Success!');
        this.services.saveRemark(
          this.token,
          this.request_id,
          this.remark,
          this.where_from,
          this.loan_product_id
        );
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });

  }

  onSubmitContactSection(form: NgForm) {
    this.loader = true;
    const fd = new FormData();
    // fd.append('img', this.selectedFooterImg, this.selectedFooterImg.name);
    // fd.append('company_id', this.vendor_id);
    // fd.append('companyDescription', this.companydescription.website_company_description);
    fd.append('address', form.value.address);
    fd.append('email', form.value.email);
    fd.append('phone', form.value.phone);
    fd.append('no_image', '1');
    fd.append('company_id', this.vendor_id);
    this.emailService.postContactSection(fd).subscribe((data) => {
      // this.footerBgImg = data.website_bk_img;
      // this.selectedFtImg = true;
      this.loader = false;
      this.toastr.success('Success', 'Success!');
    });
  }

  onSubmitLetterBody(form: NgForm) {
    this.loader = true;
    const payload = {
      'token': this.token,
      'body': this.body,
      'where_from': this.where_from,
      'request_id': this.request_id,
      'loan_product_id': this.loan_product_id
    };
    this.services.offerLetterBody(payload).subscribe((data) => {
      if (data == true) {
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });
  }

  onSubmitLetterTerms(form: NgForm) {
    this.loader = true;
    const payload = {
      'token': this.token,
      'term': this.term,
      'where_from': this.where_from,
      'request_id': this.request_id,
      'loan_product_id': this.loan_product_id
    };
    this.services.offerLetterTerm(payload).subscribe((data) => {
      if (data == true) {
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });
  }

  onSubmitRepayment(form: NgForm) {
    this.loader = true;
    const payload = {
      'token': this.token,
      'repayment': form.value.letter_repayment,
      'where_from': this.where_from,
      'request_id': this.request_id,
      'loan_product_id': this.loan_product_id
    };
    this.services.getOfferLetterRepayment(payload).subscribe((data) => {
      if (data == true) {
        this.loader = false;
        this.toastr.success('Success', 'Success!');
      } else {
        this.loader = false;
        this.toastr.error('Error', 'Failed: Unable to save check and try again!');
      }
      this.dataService.reloadOfferLetter.emit();
    });
  }


  insertTextAtCursor(text) {
    const doc = document as any;
    var sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(text));
      }
    } else if (doc.selection && doc.selection.createRange) {
      doc.selection.createRange().text = text;
    }
  }

  insertAtCaret(areaId, text) {
    var txtarea: HTMLInputElement = <HTMLInputElement>document.getElementById(areaId);
    var scrollPos = txtarea.scrollTop;
    var caretPos = txtarea.selectionStart;

    var front = (txtarea.value).substring(0, caretPos);
    var back = (txtarea.value).substring(txtarea.selectionEnd, txtarea.value.length);
    txtarea.value = front + text + back;
    caretPos = caretPos + text.length;
    txtarea.selectionStart = caretPos;
    txtarea.selectionEnd = caretPos;
    txtarea.focus();
    txtarea.scrollTop = scrollPos;
  }


}
