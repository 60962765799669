<app-mobile-restriction *ngIf="showMobile"></app-mobile-restriction>

<div *ngIf="!showMobile">
  <ng2-slim-loading-bar></ng2-slim-loading-bar>
  <router-outlet></router-outlet>
</div>

<app-modal-regular
  *ngIf="modal === 'onboard'" size="xl" [no-padding]="true" [closable]="false" #dialogOnboard (close)="modal = ''"
>
  <app-onboard (done)="dialogOnboard.dismiss()"></app-onboard>
</app-modal-regular>
