<app-main-navigation></app-main-navigation>

<div class="container navbar-margin">
  <div class="row">
    <div class="col-md-8">
      <div class="panel p-4 d-flex flex-row justify-content-end rounded-3">
        <button class="btn btn-link">Merchants</button>
        <button class="btn btn-link ml-4">Rent</button>
        <button class="btn btn-link ml-4">School</button>
        <button class="btn btn-link ml-4">Restaurants</button>
      </div>

      <div class="panel padding-regular bg-light-blue mt-4">
        <h3 class="title font-bold m-0" style="font-size: 1.6rem;">Sell on credit</h3>
        <p class="mb-0" style="font-size: .98rem;">Share link to store</p>
        <div class="mt-3">
          <button class="btn btn-outline-secondary">
            <i class="fa fa-share-alt mr-2"></i>Share
          </button>
        </div>
        <img class="w-50 ms-auto mt-4" src="assets/images/svg/social-girl.svg" alt="Illustration">
      </div>

      <div class="panel padding-regular d-flex flex-row align-items-center mt-4">
        <div (click)="modal = 'category'" class="panel padding-regular cursor-pointer">
          <h6 class="title font-bold">Laptop</h6>
        </div>
        <div (click)="modal = 'category'" class="panel padding-regular cursor-pointer ml-4">
          <h6 class="title font-bold">Furniture</h6>
        </div>
      </div>

      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius, quas.
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Enim, quaerat?
      <div class="owl-carousel owl-theme">
        <div class="item">
          <h4>1</h4>
        </div>
        <div class="item">
          <h4>2</h4>
        </div>
        <div class="item">
          <h4>3</h4>
        </div>
        <div class="item">
          <h4>4</h4>
        </div>
        <div class="item">
          <h4>5</h4>
        </div>
        <div class="item">
          <h4>6</h4>
        </div>
        <div class="item">
          <h4>7</h4>
        </div>
        <div class="item">
          <h4>8</h4>
        </div>
        <div class="item">
          <h4>9</h4>
        </div>
        <div class="item">
          <h4>10</h4>
        </div>
        <div class="item">
          <h4>11</h4>
        </div>
        <div class="item">
          <h4>12</h4>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <h5 class="title font-bold">
        N2.4b funded this month
      </h5>
      <div class="mt-4">
        <ul class="list-group list-group-flush w-100 border-0">
          <li class="list-group-item bg-transparent d-flex align-items-center px-0 py-3">
            <div>
              <img class="image-avatar small" src="/assets/images/icon-user.png" alt="" />
            </div>
            <div class="mx-3">
              <div class="fs-6 fw-medium">School</div>
              <div>Ecosystem of schools</div>
            </div>
            <button class="btn btn-sm btn-link ml-auto">View</button>
          </li>
          <li class="list-group-item bg-transparent d-flex align-items-center px-0 py-3">
            <div>
              <img class="image-avatar small" src="/assets/images/icon-user.png" alt="" />
            </div>
            <div class="mx-3">
              <div class="fs-6 fw-medium">School</div>
              <div>Ecosystem of schools</div>
            </div>
            <button class="btn btn-sm btn-link ml-auto">View</button>
          </li>
          <li class="list-group-item bg-transparent d-flex align-items-center px-0 py-3">
            <div>
              <img class="image-avatar small" src="/assets/images/icon-user.png" alt="" />
            </div>
            <div class="mx-3">
              <div class="fs-6 fw-medium">School</div>
              <div>Ecosystem of schools</div>
            </div>
            <button class="btn btn-sm btn-link ml-auto">View</button>
          </li>
          <li class="list-group-item bg-transparent d-flex align-items-center px-0 py-3">
            <div>
              <img class="image-avatar small" src="/assets/images/icon-user.png" alt="" />
            </div>
            <div class="mx-3">
              <div class="fs-6 fw-medium">School</div>
              <div>Ecosystem of schools</div>
            </div>
            <button class="btn btn-sm btn-link ml-auto">View</button>
          </li>
          <li class="list-group-item bg-transparent d-flex align-items-center px-0 py-3">
            <div>
              <img class="image-avatar small" src="/assets/images/icon-user.png" alt="" />
            </div>
            <div class="mx-3">
              <div class="fs-6 fw-medium">School</div>
              <div>Ecosystem of schools</div>
            </div>
            <button class="btn btn-sm btn-link ml-auto">View</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<br><br>

<app-modal *ngIf="modal === 'category'" (close)="modal = ''">
  <h5 class="title fw-bold">Select tags</h5>
  <div class="row mt-5">
    <div class="col-6">
      <div class="form-check d-flex align-items-center mb-3 p-0">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
        <label class="form-check-label ms-2" for="flexCheckChecked">
          Checked checkbox
        </label>
      </div>
    </div>
    <div class="col-6">
      <div class="form-check d-flex align-items-center m-0 p-0">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
        <label class="form-check-label ms-2" for="flexCheckChecked">
          Checked checkbox
        </label>
      </div>
    </div>
    <div class="col-6">
      <div class="form-check d-flex align-items-center m-0 p-0">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
        <label class="form-check-label ms-2" for="flexCheckChecked">
          Checked checkbox
        </label>
      </div>
    </div>
    <div class="col-6">
      <div class="form-check d-flex align-items-center m-0 p-0">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
        <label class="form-check-label ms-2" for="flexCheckChecked">
          Checked checkbox
        </label>
      </div>
    </div>
  </div>
</app-modal>