<div class="panel" [ngClass]="{'padding-regular': show_header, 'padding-regular-small bg-off-white': !show_header}">
  <div class="flex flex-1">
      <div class="flex-1 flex-center" (click)="is_individual = true">
          <div class="icon-text medium cursor-pointer" [ngClass]="{ 'active': is_individual }">
              <div class="text">Individual</div>
          </div>
      </div>
      <div class="flex-1 flex-center" (click)="is_individual = false">
          <div class="icon-text medium cursor-pointer" [ngClass]="{ 'active': !is_individual }">
              <div class="text">Business</div>
          </div>
      </div>
  </div>
</div>

<div *ngIf="customer && !is_individual">
  <!-- Company Information -->
  <div class="panel box edged_box mb-1"> 
      <div class="panel-body padding-regular">
          <div class="flex">
              <div class="flex-3 mb-2 mr-4" *ngIf="editing !== 'company_profile'">
                  <ul class="decList bigger">
                      <li><span class="title">Company Profile</span>
                          <span>
                              <button class="btn btn-outline-secondary btn-xs" (click)="editing = 'company_profile'">
                                  <i class="fa fa-edit mr-2 no-padding"></i>Edit</button>
                          </span>
                      </li>
                      <li><span>Company Name</span>
                          <span>{{customer?.company_profile?.company_name || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxx</span>
                      </li>
                      <li><span>Company Email</span>
                          <span *ngIf="!loading">{{customer?.company_profile?.company_email || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxx</span>

                      </li>
                      <li><span>Company Website</span>
                          <span
                              *ngIf="!loading">{{customer?.company_profile?.company_website || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx</span>
                      </li>
                      <li><span>TIN</span>
                          <span *ngIf="!loading">{{customer?.company_profile?.tin || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxx</span>
                      </li>
                      <li><span>RC Number</span>
                          <span *ngIf="!loading">{{customer?.company_profile?.rc_number || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Business
                              Sector</span><span
                              *ngIf="!loading">{{customer?.company_profile?.business_sector || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxx</span>
                      </li>
                      <li><span>No of
                              Employees</span><span
                              *ngIf="!loading">{{customer?.company_profile?.no_of_employees || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Start
                              Month</span><span
                              *ngIf="!loading">{{customer?.company_profile?.business_start_month || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Start
                              Year</span><span
                              *ngIf="!loading">{{customer?.company_profile?.business_start_year || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Contact
                              Person</span><span
                              *ngIf="!loading">{{customer?.company_profile?.contact_person || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Contact Phone
                              Number</span><span
                              *ngIf="!loading">{{customer?.company_profile?.contact_person_phone_number || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                  </ul>
                  <div class="mb-2">
                      <ul class="decList bigger">
                          <li><span class="title">Company Address</span></li>
                          <li><span>Address</span><span class="pull-right width_1_2 text-right"
                                  *ngIf="!loading">{{customer?.business_address?.address || 'Not provided'}}</span>
                              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>State</span>
                              <span
                                  *ngIf="!loading">{{customer?.business_address?.home_state_text || 'Not provided'}}/{{customer?.business_address?.home_lga_text || 'Not provided'}}</span>
                              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Type</span><span
                                  *ngIf="!loading">{{customer?.business_address?.address_type_text || 'Not provided'}}</span>
                              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Time at current address</span><span
                                  *ngIf="!loading">{{customer?.business_address?.time_at_current_address || 'Not provided'}}</span>
                              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span></li>


                      </ul>
                  </div>
                  <app-button (click)="requestForData('Company Profile Information',-8)" class="small outline mt-3"
                      label="Request for data"></app-button>
              </div>

              <div class="section-poster">
                  <div class="image flex-center">
                      <img src="assets/images/personal-information.svg">
                  </div>
              </div>
          </div>
      </div>
  </div>

  <!-- Income Informatoin -->
  <div class="panel box edged_box mb-1">
      <div class="panel-body padding-regular">
          <div class="flex">
              <div class="section-poster">
                  <div class="image flex-center">
                      <!--<img src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/{{loan?.customer?.profile_pic | imagenotfound}}">-->
                      <img src="assets/images/wallet.svg">
                  </div>
              </div>
              <div class="flex-3 ml-3 mb-2" *ngIf="editing !== 'income'">
                  <ul class="decList bigger">
                      <li><span class="title">Business Income</span>
                          <span>
                              <button class="btn btn-outline-secondary btn-xs" (click)="editing = 'income'">
                                  <i class="fa fa-edit mr-2 no-padding"></i>Edit</button>
                          </span>
                      </li>
                      <li><span>Avg monthly sales</span><span
                              *ngIf="!loading">{{customer?.business_income?.average_monthly_sales_income | number: ".2-2" || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Avg income from services</span><span
                              *ngIf="!loading">{{customer?.business_income?.average_income_from_services | number: ".2-2" || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Avg income from other services</span><span
                              *ngIf="!loading">{{customer?.business_income?.average_income_from_other_services | number: ".2-2" || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Gross monthly income</span><span
                              *ngIf="!loading">{{customer?.business_income?.gross_monthly_income | number: ".2-2" || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Avg cost of goods sold</span><span
                              *ngIf="!loading">{{customer?.business_income?.average_cost_of_goods_sold | number: ".2-2" || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Direct labour costs</span><span
                              *ngIf="!loading">{{customer?.business_income?.direct_labour_costs | number: ".2-2" || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Commissions</span><span
                              *ngIf="!loading">{{customer?.business_income?.commissions | number: ".2-2" || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span></li>
                  </ul>
                  <div class="mb_10">
                      <ul class="decList bigger">
                          <li><span class="title">Operating Expense</span></li>
                          <li><span>Bank Loans</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.bank_loans || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Salaries / Wages</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.salaries_and_wages || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Assets</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.assets || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span></li>
                          <li><span>Insurance</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.insurance || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Car / Transport</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.car_and_transport || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Office Rent</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.office_rent || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Power / Fuel</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.power_and_fuel || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span></li>
                          <li><span>Maintenance Expense</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.maintenance_expense || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Security</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.security || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Phone / Communication</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.phone_and_communication || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span></li>
                          <li><span>Other Expenses</span><span
                                  *ngIf="!loading">{{customer?.operating_expenses?.other_expenses || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                      </ul>
                  </div>
                  <app-button (click)="requestForData('Operating Expenses',-11)" class="small outline mt-3"
                      label="Request for data"></app-button>
              </div>
          </div>
      </div>
  </div>

  <!-- Director -->
  <div class="panel box decSection edged_box mb_10">
      <div class="panel-body padding-regular flex justify-between align-center">
          <div class="user" *ngFor="let director of customer?.directors">
              <div class="image">
                  <img
                      src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
              </div>
              <div class="content">
                  <h4 class="title">{{director?.name}}</h4>
                  <p class="subtitle">{{director?.phone}} | {{director?.email}}</p>
                  <p class="subtitle">{{director?.bvn}}</p>
              </div>

          </div>
          <div class="action">{{director?.equity}}</div>
      </div>
  </div>
</div>

<div *ngIf="customer && is_individual">
  <!-- Bio Information -->
  <div class="panel"> 
      <div class="panel-body padding-regular">
          <div class="flex">
              <div class="section-poster">
                  <div class="image flex-center">
                      <img src="assets/images/personal-information.svg">
                  </div>
              </div>
              <div class="flex-3 mb-2 ml-4" *ngIf="editing !== 'bio'">
                  <h3 class="section-title flex align-center justify-between mb-4">
                      Bio Information
                      <button class="btn btn-outline-secondary btn-xs" (click)="editing = 'bio'">
                          <i class="fa fa-edit mr-2 no-padding"></i>Edit</button>
                  </h3>
                  <ul class="decList bigger">
                      <li>
                          <span>Name</span>
                          <span *ngIf="!loading">{{customer?.profile?.legal_name || 'Not provided'}}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxx%xxxxxxxxxxxxx</span>
                      </li>
                      <li>
                          <span>Bio</span>
                          <span *ngIf="!loading">
                              {{customer?.profile?.gender == 0 ? 'Male' : 'Female' }} |
                              {{customer?.profile?.marital_status|maritalstatus}} |
                              {{ getAge(customer?.profile?.date_of_birth) }} years old
                          </span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                      </li>
                      <li>
                          <span>Contact</span>
                          <span *ngIf="!loading">{{customer?.profile?.phone }} | {{ customer?.profile?.email }}</span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                      </li>
                      <li><span>Address</span>
                          <span *ngIf="!loading">
                              {{ customer?.home_address?.home_address }}, {{ customer?.home_address?.home_lga_text }},
                              {{ customer?.home_address?.home_state_text }},
                              {{ customer?.home_address?.home_country_text }}
                          </span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                      </li>
                      <li *ngIf="customer?.home_address?.resident_years"><span>No. of years</span>
                          <span *ngIf="!loading">
                              {{ customer?.home_address?.resident_years }} year(s)
                          </span>
                          <span class="skeleton" *ngIf="loading">xxxxxxxxxxxx%xxxxxxxxxxxx</span>
                      </li> 
                  </ul> 
                  <app-button (click)="requestForData('Profile Information',-1)" class="small outline mt-3"
                      label="Request for data"></app-button>
              </div>
              <div class="flex-3 mb-2 ml-4" *ngIf="editing === 'bio'">
                  <h3 class="section-title flex align-center justify-between mb-4">
                      Bio Information
                      <span>
                          <button class="btn btn-outline-success btn-xs mr-2"
                              (click)="editing = '';saveBio()">Save</button>
                          <button class="btn btn-outline-danger btn-xs" (click)="editing = ''">Cancel</button>
                      </span>
                  </h3>
                  <ul class="decList bigger">
                      <li><span>Name</span>
                          <span><input type="text" [(ngModel)]="customer.profile.legal_name"></span>
                      </li>
                      <li><span>Email</span>
                          <span><input type="text" [(ngModel)]="customer.profile.email"></span>
                      </li>
                      <li><span>Phones</span>
                          <span><input type="text" [(ngModel)]="customer.profile.phone"></span>
                      </li>
                      <li><span>DOB</span>
                          <span><input type="date" [(ngModel)]="customer.profile.date_of_birth"></span>
                      </li>
                      <li><span>Gender</span>
                          <span>
                              <select name="gender" [(ngModel)]="customer.profile.gender">
                                  <option value="1">Female</option>
                                  <option value="0">Male</option>
                              </select></span>
                      </li>
                      <li><span>Marital Status</span>
                          <span>
                              <select name="marital_status" [(ngModel)]="customer.profile.marital_status">
                                  <option value="2">Married</option>
                                  <option value="1">Single</option>
                                  <option value="3">Divorced</option>
                                  <option value="4">Widowed</option>
                              </select>
                          </span>
                      </li>
                  </ul>
                  <ul class="decList bigger">
                      <li><span class="title">Home Address</span></li>
                      <li><span>Address</span>
                          <span><input type="text" [(ngModel)]="customer.home_address.home_address"></span>
                      </li> 
                      <li><span>State</span>
                          <span><select (ngModelChange)="loadLGAs($event)" name="home_state"
                                  [(ngModel)]="customer.home_address.home_state">
                                  <option *ngFor="let state of states" value="{{state.state_id}}">{{state.state_name}}
                                  </option>
                              </select></span></li>
                      <li><span>Lga</span>
                          <span><select name="home_lga" [(ngModel)]="customer.home_address.home_lga">
                                  <option *ngFor="let lga of lgas" value="{{lga.LGA_ID}}">{{lga.LGA}}</option>
                              </select></span></li>
                      <li><span>Nature of Accomodation</span>
                          <span>
                              <select name="noa" [(ngModel)]="customer.home_address.nature_of_accomodation">
                                  <option value="1">Owned</option>
                                  <option value="2">Rented</option>
                              </select>
                          </span>
                      </li>
                      <li><span>Years at residence</span>
                          <span><input type="text" [(ngModel)]="customer.home_address.resident_years"></span>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div> 
  <div class="panel bg-off-white" *ngIf="embeded">
      <div class="padding-regular flex justify-between align-center">
        <div class="user"> 
          <div class="image">
            <img
              src="https://res.cloudinary.com/africacodes-concepts-limited/image/upload/v1507561173/avatar-mini.jpg">
          </div>
          <div class="content" *ngIf="loan?.customer?.account_officer !=''">
            <h4 class="title color-teal">{{loan?.customer?.account_officer?.ACCOUNT_OFF}}</h4>
            <p class="subtitle">Principal account officer</p>
          </div>
          <div class="content" *ngIf="loan?.customer?.account_officer ==''"> 
            <h4 class="title color-teal">No officer assigned</h4>
          </div>
        </div>
        <app-button (click)="openModalChild.emit('assign-account-officer')" *ngIf="loan?.customer?.account_officer ==''"
          class="medium outline block" label="Assign Officer" type="button"></app-button>
        <app-button (click)="openModalChild.emit('account-officer')" *ngIf="loan?.customer?.account_officer!=''"
          class="medium outline block" label="Change Officer" type="button"></app-button>
      </div>
    </div>

  <!-- Work Information -->
  <div class="panel padding-regular flex">
      <div class="flex width_0 vertical-center" style="flex-grow: 1">
          <div class="h-group">
              <h4 class="title color-teal" *ngIf="!loading" style="font-size: 1.8rem;">
                  N{{customer?.work?.net_monthly_income | number: "0.0"}}</h4>
              <p class="subtitle no-margin fill-w" *ngIf="!loading">Monthly Income</p>
              <h4 class="title color-teal skeleton mb-1" *ngIf="loading">0000</h4>
              <p class="subtitle no-margin fill-w skeleton" *ngIf="loading">Lorem, ipsum dolor.</p>
          </div>
      </div>
      <div class="flex-3 ml-3 mb-2" *ngIf="editing !== 'work'">
          <h3 class="section-title flex align-center justify-between mb-4">
              Work / Income Summary
              <button class="btn btn-outline-secondary btn-xs" (click)="editing = 'work'">
                  <i class="fa fa-edit mr-2 no-padding"></i>Edit</button>
          </h3>
          <div class="mb_10">
              <ul class="decList bigger">
                  <li>
                      <span>Company</span>
                      <span *ngIf="!loading">{{customer?.work?.company_of_work_id || 'Not provided'}}</span>
                      <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                  </li>
                  <li><span>Sector / Role</span>
                      <span *ngIf="!loading">
                          {{ customer?.work?.work_sector_text }} | {{ customer?.work?.designation_text }} |
                          {{customer?.work?.work_start_date }} - {{customer?.work?.work_end_date}}
                      </span>
                      <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                  </li>
                  <li><span>Work
                          Address</span><span
                          *ngIf="!loading">{{customer?.work?.work_address || 'Not provided'}}</span>
                      <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                  <li>
                      <span>Work Email</span>
                      <span *ngIf="!loading">
                          <span class="mr-4">{{customer?.work?.work_email || 'Not provided'}}</span>
                          <span class="tag green smaller ml-4" *ngIf="customer?.work?.work_email_verified=='1'">Verified</span>
                          <span class="inline-block tag red smaller" *ngIf="customer?.work?.work_email_verified!='1'">Not verified</span>
                      </span>
                      <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span></li>
              </ul>
          </div>
          <app-button (click)="requestForData('Work Information',-3)" class="small outline mt-3"
              label="Request for data">
          </app-button>
          <!-- <app-button [disabled]="!customer?.work?.work_email" *ngIf="from_loan" (click)="requestForData('Work Email',100)" class="small orange outline mt-3 ml-2"
              label="Verify work email" icon="fa fa-envelope">
          </app-button> -->
          <app-button (click)="sendRequestForWorkEmail('Work Email',100)" class="small orange outline mt-3 ml-2"
              label="Verify work email" icon="fa fa-envelope">
          </app-button>
      </div>
      <div class="flex-3 ml-3 mb-2" *ngIf="editing === 'work'">
          <h3 class="section-title flex align-center justify-between mb-4">
              Work / Income Summary
              <span>
                  <button class="btn btn-outline-success btn-xs mr-2" (click)="editing = '';saveWork()">Save</button>
                  <button class="btn btn-outline-danger btn-xs" (click)="editing = ''">Cancel</button>
              </span>
          </h3>
          <div class="mb_10">
              <ul class="decList bigger">
                  <li><span>Company Name</span>
                      <span><input type="text" [(ngModel)]="customer.work.company_of_work_id"></span>
                  </li>
                  <li><span>Occupation</span>
                      <span><select name="work_sector" [(ngModel)]="customer.work.occupation_id">
                              <option *ngFor="let occ of occupations" value="{{occ.OCCUPATION_ID}}">
                                  {{occ.DESCRIPTION}}
                              </option>
                          </select></span>
                  </li>
                  <li><span>Sector</span>
                      <span><select name="work_sector" [(ngModel)]="customer.work.work_sector">
                              <option *ngFor="let occ of sectors" value="{{occ.OCCUPATION_ID}}">{{occ.DESCRIPTION}}
                              </option>
                          </select></span>
                  </li>
                  <li><span>Designation</span>
                      <span><select name="work_designation" [(ngModel)]="customer.work.work_designation">
                              <option *ngFor="let occ of designations" value="{{occ.OCCUPATION_ID}}">
                                  {{occ.DESCRIPTION}}
                              </option>
                          </select></span>
                  </li>
                  <li><span>Monthly Income</span>
                      <span><input type="text" name="net_monthly_income" [(ngModel)]="customer.work.net_monthly_income"></span>
                  </li>
                  <li><span>Gross Annual Income</span>
                      <span><input type="text" name="gross_annual" [(ngModel)]="customer.work.gross_annual"></span>
                  </li>
                  <li><span>Official Pay Day</span>
                      <span><input type="date" name="official_pay_day" [(ngModel)]="customer.work.official_pay_day"></span>
                  </li>
                  <li><span>Institution Name</span>
                      <span><input type="text" [(ngModel)]="customer.work.institution_id"></span>
                  </li>
                  <li><span>Work Start Month</span>
                      <!-- <span><input type="text" [(ngModel)]="customer.work.work_start_date"></span> -->
                      <span><select name="work_start_date" [(ngModel)]="customer.work.work_start_date">
                          <option value=""></option>
                          <option *ngFor="let key of 12 | demoNumber" [value]="key">{{key}}
                          </option>
                      </select></span>
                  </li>
                  <li><span>Work Start Year</span>
                      <!-- <span><input type="text" [(ngModel)]="customer.work.work_end_date"></span> -->
                      <span><select name="work_end_date" [(ngModel)]="customer.work.work_end_date">
                          <option value=""></option>
                          <option *ngFor="let key of anio | yeargenerator" [value]="key">{{key}}
                          </option>
                      </select></span>
                  </li>
                  <li><span>Work Address</span>
                      <span><input type="text" [(ngModel)]="customer.work.work_address"></span>
                  </li>
                  <li><span>Work Email</span>
                      <span><input type="text" [(ngModel)]="customer.work.work_email"></span>
                  </li>
                  <li><span>Staff Number</span>
                      <span><input type="text" [(ngModel)]="customer.work.staff_number"></span>
                  </li>
                  <li><span>Pension No.</span>
                      <span><input type="text" [(ngModel)]="customer.work.pension_number"></span>
                  </li>
                  <li><span>TIN</span>
                      <span><input type="text" [(ngModel)]="customer.work.tax_number"></span>
              </ul>
          </div>
      </div>
  </div>
 
  <!-- Attachment -->
  <app-attachments-card  [where]="customer" [reqid]="reqid" [pid]="pid" *ngIf="embeded" (openModal)="openModalChild.emit($event)">
  </app-attachments-card>
 
  <!-- Account and Cards -->
  <app-accountandcards-card [loan]="loan" [where]="'customer'"  [from_loan]="from_loan" [pid]="pid" [reqid]="reqid" (open)="openModalChild.emit($event)">
  </app-accountandcards-card>

  <app-bvndetails-card [reqid]="reqid" [cid]="pid" [from]='1' (more)="openModalChild.emit($event)"></app-bvndetails-card>

  <!-- Analysis Shortcut -->
  <!-- <div class="panel padding-regular grid four" *ngIf="embeded">
      <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('creditbureau')">
          <div class="icon-text medium cursor-pointer">
              <div class="icon color-orange">
                  <i class="fas fa-coins"></i>
                  <span class="check" *ngIf="checklist?.creditbureau"><i class="fa fa-check"></i></span>
              </div>
              <div class="text">Credit Bereau</div>
          </div>
      </a>
      <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('avs')">
          <div class="icon-text medium cursor-pointer">
              <div class="icon color-blue">
                  <i class="fas fa-address-book"></i>
                  <span class="check" *ngIf="checklist?.avs"><i class="fa fa-check"></i></span>
              </div>
              <div class="text">Address verification</div>
          </div>
      </a>
      <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('analytics')">
          <div class="icon-text medium cursor-pointer">
              <div class="icon color-teal">
                  <i class="fas fa-chart-line"></i>
                  <span class="check" *ngIf="checklist?.advanced_analytics"><i class="fa fa-check"></i></span>
              </div>
              <div class="text">Advance analytics</div>
          </div>
      </a>
      <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('nanocreditbureau')">
          <div class="icon-text medium cursor-pointer">
              <div class="icon color-teal">
                  <i class="fas fa-chart-line"></i>
                  <span class="check" *ngIf="checklist?.nanocreditcheck"><i class="fa fa-check"></i></span>
              </div>
              <div class="text">Nano Credit Check</div>
          </div>
      </a>
      
      <a class="flex flex-center mb-4 mt-4" (click)="openModalChild.emit('identity')">
          <div class="icon-text medium cursor-pointer">
              <div class="icon color-red">
                  <i class="fas fa-cog"></i>
                  <span class="check" *ngIf="checklist?.blockchain"><i class="fa fa-check"></i></span>
              </div>
              <div class="text">Identity check</div>
          </div>
      </a>
  </div> -->

  <!-- Identity Information -->
  <div class="panel" *ngIf="customer?.idcard?.name_on_id">
       
      <div class="panel-body padding-regular">
          <h3 class="section-title flex justify-between align-center">Identity Information
              <button class="icon-button ml-2" data-toggle="collapse" data-target="#collapseIdentity"
                  aria-expanded="false" aria-controls="collapseIdentity"><i class="fa fa-chevron-down"></i></button>
          </h3>
          <div class="collapse in" id="collapseIdentity" style="overflow: hidden">
              <div class="flex mt-4">
                  <div class="flex-3 mb-2 mr-4">
                      <ul class="decList bigger">
                          <li>
                              <span>Identity Type</span>
                              <span>{{customer?.idcard?.identity_type || 'Not provided'}}</span>
                              <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                          </li>
                          <li>
                              <span>Name on Id</span>
                              <span>{{customer?.idcard?.name_on_id || 'Not provided'}}</span>
                              <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                          </li>
                          <li>
                              <span>Number</span>
                              <span>{{customer?.idcard?.identity_code || 'Not provided'}}</span>
                              <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                          </li>
                          <li>
                              <span>Expires</span>
                              <span *ngIf="!loading">
                                  {{customer?.idcard?.idyear || 'Not provided'}}/{{customer?.idcard?.idmonth || 'Not provided'}}
                              </span>
                              <span class="skeleton" *ngIf="loading">Lorem, ipsum dolor.</span>
                          </li>
                      </ul>
                      <!-- <app-button (click)="requestForData('Identity Information',-7)" class="small outline mt-3"
                          label="Request for data"></app-button> -->
                  </div>
                  <div class="section-poster">
                      <div class="image flex-center pointer">
                          <img src="assets/images/identity.svg">
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <!-- NOK Information -->
  <div class="panel">
  
      <div class="panel-body padding-regular">
          <h3 class="section-title flex justify-between align-center">NOK Information
              <div class="flex align-center">
                  <span *ngIf="editing !== 'nok'">
                      <button class="btn btn-outline-info btn-xs" (click)="editing = 'nok'">
                          <i class="fa fa-edit mr-2 no-padding"></i>Edit
                      </button>
                  </span>
                  <span *ngIf="editing === 'nok'">
                      <button class="btn btn-outline-success btn-xs mr-2"
                          (click)="editing = '';saveNok()">Save</button>
                      <button class="btn btn-outline-danger btn-xs" (click)="editing = ''">Cancel</button>
                  </span>
                  <button class="icon-button ml-2" data-toggle="collapse" data-target="#collapseExample"
                      aria-expanded="false" aria-controls="collapseExample"><i
                          class="fa fa-chevron-down"></i></button>
              </div>
          </h3>
          <div class="collapse in" id="collapseExample" style="overflow: hidden">
              <div class="flex">
                  <div class="flex-3 mr-3 mt-4" *ngIf="editing !== 'nok'">
                      <ul class="decList bigger">
                          <li><span>Name</span><span
                                  *ngIf="!loading">{{customer?.next_of_kin?.nok_name || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Email</span><span
                                  *ngIf="!loading">{{customer?.next_of_kin?.nok_email || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Address</span><span
                                  *ngIf="!loading">{{customer?.next_of_kin?.nok_address || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Phone</span><span
                                  *ngIf="!loading">{{customer?.next_of_kin?.nok_phone || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Relationship</span><span
                                  *ngIf="!loading">{{customer?.next_of_kin?.nok_relationship || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                      </ul>
                      <app-button (click)="requestForData('Next of Kin Information',-6)" class="small outline mt-3"
                          label="Request for data"></app-button>
                  </div>
                  <div class="flex-3 mr-3 mt-4" *ngIf="editing === 'nok'">
                      <ul class="decList bigger">
                          <li><span>Name</span>
                              <span><input type="text" [(ngModel)]="customer?.next_of_kin.nok_name"></span>
                          </li>
                          <li><span>Email</span>
                              <span><input type="text" [(ngModel)]="customer?.next_of_kin.nok_email"></span>
                          </li>
                          <li><span>Address</span>
                              <span><input type="text" [(ngModel)]="customer?.next_of_kin.nok_address"></span>
                          </li>
                          <li><span>Phone</span>
                              <span><input type="text" [(ngModel)]="customer?.next_of_kin.nok_phone"></span>
                          </li>
                          <li><span>Relationship</span>
                              <span>
                                  <select name="nok_relationship"
                                      [(ngModel)]="customer?.next_of_kin.nok_relationship">
                                      <option value="spouse">Spouse</option>
                                      <option value="sibling">Sibling</option>
                                      <option value="child">Child</option>
                                      <option value="relative">Relative</option>
                                      <option value="friend">Friend</option>
                                  </select>
                              </span>
                          </li>
                      </ul>
                  </div>
                  <div class="section-poster">
                      <div class="image flex-center">
                          <img src="assets/images/team-management.svg">
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

   

  <!-- Remitta Information -->
  <div class="panel box edged_box mb_10" *ngIf="customer?.remita?.has_remita">
      <div class="panel-body padding-regular">
          <div class="flex">
              <div class="flex-3 mr-4">
                  <ul class="decList bigger">
                      <li><span class="title">Remita Information</span></li>
                      <li><span>Remita</span>
                          <span>{{customer?.remita?.data?.CUSTOMER_ID || 'Not provided'}}</span></li>
                      <li><span>Name</span>
                          <span>{{customer?.remita?.data?.CUSTOMER_NAME || 'Not provided'}}</span></li>
                      <li><span>Account Number</span>
                          <span>{{customer?.remita?.data?.ACCOUNT_NUMBER || 'Not provided'}}</span></li>
                      <li><span>Bank</span>
                          <span>{{customer?.remita?.data?.bank_name || 'Not provided'}}</span></li>
                      <li><span>Company</span>
                          <span>{{customer?.remita?.data?.COMPANY_NAME || 'Not provided'}}</span></li>
                  </ul>
              </div>
              <div class="section-poster flex-center flex-column">
                  <div class="image flex-center">
                      <img src="assets/images/remita.svg">
                  </div>
              </div>
          </div>
          <h4 class="section-title mb-3 mt-5">Salary History</h4>
          <ul class="decList bigger">
              <li class="title">
                  <span class="color-blue">Date</span>
                  <span class="color-blue">Amount</span>
              </li>
              <li *ngFor="let sal of customer?.remita?.data?.SALARY_PAYMENT_DETAILS">
                  <span class="color-grey">{{sal.paymentDate}}</span>
                  <span>{{sal.amount|number:'.2-2'}}</span>
              </li>
          </ul>
      </div>
  </div>

  <!-- <app-pastloans [reqid]="reqid" [people_id]="pid" *ngIf="embeded"></app-pastloans>

  <app-total-investments [people_id]="loan?.people_id"></app-total-investments> -->

  <!-- Spending Informatoin -->
  <div class="panel padding-regular">
      <h3 class="section-title flex justify-between align-center">Spending Information
          <div class="flex align-center">
              <span *ngIf="editing !== 'spending'">
                  <button class="btn btn-outline-secondary btn-xs" (click)="editing = 'spending'">
                      <i class="fa fa-edit mr-2 no-padding"></i>Edit</button>
              </span>
              <span *ngIf="editing === 'spending'">
                  <button class="btn btn-outline-success btn-xs mr-2"
                      (click)="editing = '';saveExpenses()">Save</button>
                  <button class="btn btn-outline-danger btn-xs" (click)="editing = ''">Cancel</button>
              </span>
              <button class="icon-button ml-2" data-toggle="collapse" data-target="#collapseSpending"
                  aria-expanded="false" aria-controls="collapseSpending"><i class="fa fa-chevron-down"></i></button>
          </div>
      </h3>
      <div class="collapse in" id="collapseSpending" style="overflow: hidden">
          <div class="flex mt-4">
              <div class="section-poster">
                  <div class="image flex-center">
                      <img src="assets/images/spend.svg">
                  </div>
              </div>
              <div class="flex-3 ml-3 mb-2" *ngIf="editing !== 'spending'">
                  <div class="mb_10">
                      <ul class="decList bigger">
                          <li><span>Rent</span><span
                                  *ngIf="!loading">{{customer?.expenses?.monthly_rent_expenses | number: ".2-2" || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Telephone</span>
                              <span
                                  *ngIf="!loading">{{customer?.expenses?.monthly_tel_internet | number: ".2-2"}}</span>
                              <!-- <span *ngIf="!loading && !customer?.expenses?.monthly_tel_internet">Not provided</span> -->
                              <span class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Transport</span><span
                                  *ngIf="!loading">{{customer?.expenses?.monthly_transport | number: ".2-2" || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li><span>Number of
                                  children</span><span
                                  *ngIf="!loading">{{customer?.next_of_kin?.number_of_children || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                          <li>
                              <span>Dependants</span><span
                                  *ngIf="!loading">{{customer?.next_of_kin?.number_of_dependants || 'Not provided'}}</span><span
                                  class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                                  xxxxxxxxxxxxxxxxxxx</span>
                          </li>
                      </ul>
                  </div>
                  <app-button (click)="requestForData('Spending Information',-5)" class="small outline mt-3"
                      label="Request for data"></app-button>
              </div>
              <div class="flex-3 ml-3 mb-2" *ngIf="editing === 'spending'">
                  <div class="mb-2">
                      <ul class="decList bigger">
                          <li><span>Rent</span>
                              <span><input type="text" [(ngModel)]="customer.expenses.monthly_rent_expenses"></span>
                          </li>
                          <li><span>Telephone</span>
                              <span><input type="text" [(ngModel)]="customer.expenses.monthly_tel_internet"></span>
                          </li>
                          <li><span>Transport</span>
                              <span><input type="text" [(ngModel)]="customer.expenses.monthly_transport"></span>
                          </li>
                          <li><span>Number of children</span>
                              <span><input type="text" [(ngModel)]="customer.next_of_kin.number_of_children"></span>
                          </li>
                          <li><span>Dependants</span>
                              <span><input type="text" [(ngModel)]="customer.next_of_kin.number_of_dependants"></span>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
  <!-- Education Information -->
  <div class="panel box edged_box mb-1" *ngIf="customer?.education?.educational_qualification">
      <div class="panel-body padding-regular">
          <div class="flex">
              <div class="section-poster">
                  <div class="image flex-center">
                      <img src="assets/images/education.svg" alt="">
                  </div>
              </div>
              <div class="flex-3">
                  <ul class="decList bigger">
                      <li><span class="title">Education Information</span>
                          <span>
                              <button class="btn btn-outline-info btn-xs">
                                  <i class="fa fa-edit mr-2 no-padding"></i>Edit</button>
                          </span>
                      </li>
                      <li>
                          <span>Qualification</span><span
                              *ngIf="!loading">{{customer?.education?.educational_qualification || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li>
                          <span>Institution</span><span
                              *ngIf="!loading">{{customer?.education?.educational_institution || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Year</span><span
                              *ngIf="!loading">{{customer?.education?.qualification_year || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                      <li><span>Others</span><span
                              *ngIf="!loading">{{customer?.education?.specify_qualifications || 'Not provided'}}</span><span
                              class="skeleton" *ngIf="loading">xxxxxxxxxxxxxxxxxxx %
                              xxxxxxxxxxxxxxxxxxx</span>
                      </li>
                  </ul>
                  <app-button (click)="requestForData('Education Information',-4)" class="small outline mt-3"
                      label="Request for data"></app-button>
              </div>
          </div>
      </div>
  </div>
 
 
  <!-- Linkedin -->
  <div class="panel box edged_box mb-1" *ngIf="customer?.linkedin?.email!=''">
 

      <div class="panel-body padding-regular">
          <div class="flex">
              <div class="section-poster flex-center flex-column justify-start"
                  *ngIf="customer?.linkedin?.picture!=''">
                  <div class="image flex-center">
                      <img src="assets/images/linkedin.svg">
                  </div>
                  <div class="mt-4">
                      <a href="{{customer?.linkedin?.profile}}" class="btn btn-outline-info btn-sm">View Profile</a>
                  </div>
              </div>
              <div class="flex-3 ml-3">
                  <ul class="decList bigger mb-2">
                      <li><span class="title">LinkedIn Summary</span></li>
                      <!-- <li><span>Profile picture</span>
            <div class="image-avatar"><img src="{{customer?.linkedin?.picture}}" alt=""></div>
          </li> -->
                      <li><span>Email</span><span>{{customer?.linkedin?.email || 'Not provided'}}</span></li>
                      <li><span>Name</span><span>{{customer?.linkedin?.fullname || 'Not provided'}}</span></li>
                      <li><span>Headline</span><span>{{customer?.linkedin?.headline || 'Not provided'}}</span></li>
                      <li><span>Industry</span><span>{{customer?.linkedin?.industry || 'Not provided'}}</span></li>
                      <li><span>Location</span><span>{{customer?.linkedin?.location || 'Not provided'}}</span></li>
                      <li><span>Summary</span><span>{{customer?.linkedin?.summary || 'Not provided'}}</span></li>
                      <li><span>Connections</span><span>{{customer?.linkedin?.connection || 'Not provided'}}</span>
                      </li>
                  </ul>
                  <ul class="decList bigger mb-2" *ngFor="let item of customer?.linkedin?.companies">
                      <li><span class="title">Company Information</span></li>
                      <li><span>Name</span><span>{{ item?.company?.name }}</span></li>
                      <li><span>Industy</span><span>{{ item?.company?.industry }}</span></li>
                      <li><span>Type</span><span>{{ item?.company?.type }}</span></li>
                      <li><span>Size</span><span>{{ item?.company?.size }}</span></li>
                      <li><span>Title</span><span>{{ item?.title }}</span></li>
                      <li><span>Location</span><span>{{ item?.location?.name }}</span></li>
                      <li><span>Summary</span><span>{{ item?.summary }}</span></li>
                  </ul>
              </div>
          </div>
      </div>
  </div>

  <!-- Facebook -->
  <div class="panel box edged_box mb_10" *ngIf="customer?.facebook?.name!=''">
 
      <div class="panel-body padding-regular">
          <div class="flex">
              <div class="flex-3 mr-3">
                  <ul class="decList bigger">
                      <li><span class="title">Facebook Summary</span></li>
                      <li><span>Profile picture</span>
                          <div class="image-avatar"><img src="{{customer?.facebook?.picture}}"></div>
                      </li>
                      <li><span>Name</span>
                          <span>{{customer?.facebook?.name || 'Not provided'}}</span></li>
                      <li><span>Email</span>
                          <span>{{customer?.facebook?.email || 'Not provided'}}</span></li>
                  </ul>
              </div>
              <div class="section-poster flex-center flex-column justify-start"
                  *ngIf="customer?.facebook?.picture!=''">
                  <div class="image flex-center">
                      <img src="assets/images/facebook.svg">
                  </div>
                  <div class="mt-4">
                      <a href="{{customer?.facebook?.profile}}" class="btn btn-outline-info btn-sm">View Profile</a>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <!-- Twitter -->
  <div class="panel box edged_box mb_10" *ngIf="customer?.twitter">
    
      <div class="panel-body padding-regular">
          <div class="flex">
              <div class="section-poster flex-center flex-column justify-start"
                  *ngIf="customer?.twitter?.profile_image_url_https!=''">
                  <div class="image flex-center">
                      <img src="assets/images/twitter.svg" alt="">
                  </div>
                  <div class="mt-4">
                      <a href="{{customer?.twitter?.url}}" class="btn btn-outline-info btn-sm">View Profile</a>
                  </div>
              </div>
              <div class="flex-3 ml-3">
                  <ul class="decList bigger">
                      <li><span class="title">Twitter Summary</span></li>
                      <li><span>Profile picture</span>
                          <div class="image-avatar"><img src="{{customer?.twitter?.profile_image_url_https}}"></div>
                      </li>
                      <li><span>Name</span>
                          <span>{{customer?.twitter?.name || 'Not provided'}}</span></li>
                      <li><span>Description</span>
                          <span>{{customer?.twitter?.description || 'Not provided'}}</span></li>
                      <li><span>Location</span>
                          <span>{{customer?.twitter?.location || 'Not provided'}}</span></li>
                      <li><span>Number of
                              followers</span><span>{{ customer?.twitter?.followers_count || 'Not provided' }}</span>
                      </li>
                      <li><span>Number of
                              friends</span><span>{{ customer?.twitter?.friends_count || 'Not provided' }}</span>
                      </li>
                      <li><span>Number of
                              statuses</span><span>{{ customer?.twitter?.statuses_count || 'Not provided' }}</span>
                      </li>
                      <li><span>Date Joined</span><span>{{ customer?.twitter?.created_at || 'Not provided' }}</span>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>

<div *ngIf="!customer">
  <div class="panel mb-1 no-padding">
      <div class="alert padding-regular bg-off-white">
          <div class="texts">
              <div class="title skeleton mb-1">This is a sample text</div>
              <p class="subtitle skeleton">This is a sample subtext</p>
          </div>
          <div class="action flex flex-column align-end">
              <p class="default-text-size mb-1 skeleton">Sample date</p>
              <p class="default-text-size no-margin skeleton">Sample loan count</p>
          </div>
      </div>
  </div>
</div>

