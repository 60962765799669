import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService, StorageService } from '../../_services/index';
import { DataService, OptionsserviceService, LoansService, OperationsService } from '../../_services/index';

@Component({
  selector: 'app-applicationstimeline',
  templateUrl: './applicationstimeline.component.html',
  styleUrls: ['./applicationstimeline.component.css']
})
export class ApplicationstimelineComponent implements OnInit {
  @Input() requestId;
  @Input('view_from') view_from = 'request';
  @Input('loan') loan:any;
  @Output() openWorkFlow = new EventEmitter();
  text = 'Timeline';
  currentUser: any;
  @Input() parentRouteId: any;
  sub: any; 
  creating_contract = false;
  has_workflow = false;
  showPicture = false;
  constructor(private dataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public router: Router, ) {
    this.currentUser = this.storageService.read<any>('currentUser');
    this.dataService.approvalDone.subscribe(res => {
      this.getSummary();
    });
    this.dataService.sendBackLoanToCaller.subscribe(res => {
      this.loan = res.loan;
    })
  }
  ngOnInit() {
    this.getSummary();
  }
  getSummary() {
    console.log(this.loan)
   
  }
  getLoan() {
    this.dataService.getLoanFromTheDashboard.emit();
    if (this.loan) {
      this.has_workflow = this.loan.has_workflow;
    } else {
      this.loansService.getLoanForTimeline(this.currentUser.token, this.parentRouteId)
        .subscribe(data => {
          this.loan = data.loan;
          this.has_workflow = data.loan.has_workflow;
        });
    }
  }
  openModal(section) {
    this.dataService.onOpenApplicationsModal.emit({ section: section, request_id: this.parentRouteId });
  }
  doingDisbursement(event) {
    event.new_loan = this.loan;
    this.dataService.disbursingRequest.emit(event);
  }
  openOfferLetter(request_id) {
    this.router.navigate(['./applications/offerletter/' + request_id]);
  }
}
