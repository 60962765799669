import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Headers, Http, RequestOptions, Response } from '@angular/http';
import { validateConfig } from '@angular/router/src/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  constructor(public http: Http) {
  }
  updateOfferLetterLogo(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerLetterLogo', data)
    .map((response: Response) => response.json());
  }
  
  updateOfferLetterCustomer(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerLetterCustomer', data)
    .map((response: Response) => response.json());
  }
  saveRemark(token: any, request_id: any, remark:any, where_from:any, loan_product_id:any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/server/saveRemark', JSON.stringify({ where_from: where_from, loan_product_id: loan_product_id,remark:remark, token: token, request_id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  saveDebtRemark(token: any, request_id: any, remark:any, where_from:any, loan_product_id:any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/server/saveDebtRemark', JSON.stringify({ where_from: where_from, loan_product_id: loan_product_id,remark:remark, token: token, request_id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  updateSignature(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerLetterSignature', data)
    .map((response: Response) => response.json());
  }
  debtSignature(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/debtSignature', data)
    .map((response: Response) => response.json());
  }
  updateAddress(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerLetterAddress', data)
    .map((response: Response) => response.json());
  }
  updateDebtAddress(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/debtLetterAddress', data)
    .map((response: Response) => response.json());
  }
  salutation(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerLetterSalutation', data)
    .map((response: Response) => response.json());
  }
  debtsalutation(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/debtSalutation', data)
    .map((response: Response) => response.json());
  }
  offerLetterBody(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerLetterBody', data)
    .map((response: Response) => response.json());
  }
  debtBody(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/debtBody', data)
    .map((response: Response) => response.json());
  }
  saveChosenTags(token:any,chosenTags:any,where_from:any,request_id:any,product_id:any): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerletterTags', JSON.stringify({ loan_product_id:product_id,chosenTags: chosenTags, token: token, where_from:where_from,request_id:request_id }))
    .map((response: Response) => response.json());
  }

  offerLetterTerm(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerLetterTerm', data)
    .map((response: Response) => response.json());
  }


  offerLetterSubject(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerletterSubject', data)
    .map((response: Response) => response.json());
  }
  debtSubject(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/debtSubject', data)
    .map((response: Response) => response.json());
  }

  offerLetterTags(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerletterTags', data)
    .map((response: Response) => response.json());
  }

  getOfferLetterDetails(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getOfferLetterDetails', data)
    .map((response: Response) => response.json());
  }
  getIndebtednessLetterDetails(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getIndebtednessLetterDetails', data)
    .map((response: Response) => response.json());
  }

  getOfferLetterRepayment(data): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/offerLetterRepayment', data)
    .map((response: Response) => response.json());
  }
  getBanddetails(token: any): Observable<any>  {
    return this.http.
    post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getBanddetails', token)
    .map((response: Response) => response.json());
  }
  getCompulsoryData(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getCompulsoryData', token)
    .map((response: Response) => response.json());
  }
  saveCompulsoryData(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/saveCompulsoryData', token)
    .map((response: Response) => response.json());
  }
  saveoffer(offer:any, offer_type:any, ecosystem:any, band_type:any, band_details:any): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/saveEligibilityOffers',
      JSON.stringify({ offer: offer,offertype:offer_type,ecosystem:ecosystem,band_type:band_type, band_details:band_details  })
    );
  } 
  loadEligibilityOffers(token: any): Observable<any>  {
    return this.http.
    post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/loadEligibilityOffers', token)
    .map((response: Response) => response.json());
  }
  updatemerchantclan(token, data):
  Observable<any>  {
    return this.http.
    post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/savemasterlavessetting', {
      token: data.token,
      band_id: token.band_id,
      ecosystem: token.ecosystem,
      band_type: token.band_type,
      band_details: token.band_details,
      profile: data.profile,
      guarantor: data.guarantor,
      account: data.account,
      card: data.card,
      directdebit: data.directdebit,
      creditbureau: data.creditbureau,
      question: data.question,
      merchant: data.merchant,
      fcn: data.fcn,
      loanspider: data.loanspider,
      identity: data.identity,
      bankstatement: data.bankstatement,
      sms: data.sms,
      bvn: data.bvn,
      call: data.call,
      social: data.social,
      repayment: data.repayment,
      profile_s: data.profile_s,
      guarantor_s: data.guarantor_s,
      account_s: data.account_s,
      card_s: data.card_s,
      directdebit_s: data.directdebit_s,
      sms_s: data.sms_s,
      calllog_s: data.call_s,
      social_s: data.social_s,
      repayment_s: data.repayment_s,
      bvn_s: data.bvn_s,
      creditbureau_s: data.creditbureau_s,
      loanspider_s: data.loanspider_s,
      identity_s: data.identity_s,
      bankstatement_s: data.bankstatement_s,
      question_s: data.question_s,
      merchant_s: data.merchant_s,
      fcn_s: data.fcn_s,
    })
    .map((response: Response) => response.json());
  }
  updatemasteranalysis(token, data):
  Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/savemasterlavessetting', {
      token: data.token,
      band_id: token.band_id,
      ecosystem: token.ecosystem,
      band_type: token.band_type,
      profile: data.profile,
      guarantor: data.guarantor,
      account: data.account,
      card: data.card,
      directdebit: data.directdebit,
      creditbureau: data.creditbureau,
      question: data.question,
      merchant: data.merchant,
      fcn: data.fcn,
      loanspider: data.loanspider,
      identity: data.identity,
      bankstatement: data.bankstatement,
      sms: data.sms,
      bvn: data.bvn,
      call: data.call,
      social: data.social,
      repayment: data.repayment,
      profile_s: data.profile_s,
      guarantor_s: data.guarantor_s,
      account_s: data.account_s,
      card_s: data.card_s,
      directdebit_s: data.directdebit_s,
      sms_s: data.sms_s,
      calllog_s: data.call_s,
      social_s: data.social_s,
      repayment_s: data.repayment_s,
      bvn_s: data.bvn_s,
      creditbureau_s: data.creditbureau_s,
      loanspider_s: data.loanspider_s,
      identity_s: data.identity_s,
      bankstatement_s: data.bankstatement_s,
      question_s: data.question_s,
      merchant_s: data.merchant_s,
      fcn_s: data.fcn_s,
    })
    .map((response: Response) => response.json());
  }

  getprofileanalysis(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getprofileanalysisprofile', token)
    .map((response: Response) => response.json());
  }
  

  getprofileanalysiscall(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getprofileanalysiscalllog', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysisbvn(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getprofileanalysisbvn', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysisrepayment(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisRepayment', token)
    .map((response: Response) => response.json());
  }


  getprofileanalysissocial(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisSocial', token)
    .map((response: Response) => response.json());
  }


  getprofileanalysissms(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisSms', token)
    .map((response: Response) => response.json());
  }

  getprofileanalysisguarantor(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisGuarantor', token)
    .map((response: Response) => response.json());
  }

  getprofileanalysisdirectdebit(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisDirectdebit', token)
    .map((response: Response) => response.json());
  }

  getprofileanalysiscreditbueau(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisCreditbureau', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysisloanspider(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisLoanspider', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysisidentity(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisIdentity', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysisbankstatement(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisBankstatement', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysisfcn(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisFCN', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysismerchant(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisMerchant', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysisquestion(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisQuestion', token)
    .map((response: Response) => response.json());
  }
  getprofileanalysiscard(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisCard', token)
    .map((response: Response) => response.json());
  }

  getprofileanalysisaccount(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysisAccount', token)
    .map((response: Response) => response.json());
  }

  saveprofileanalysis(data, user_id, token) {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/profile_analysis', {
      data: data,
      user_id: user_id,
      token: token
    })
    .map((response: Response) => response.json());
  }

  updateprofileanalysis(data, token) {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/updateprofileanalysis', {
      setting: data,
      token: token
    })
    .map((response: Response) => response.json());
  }

  // getmasterprofileanalysis() {
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getmasterprofileanalysis', '')
  //   .map((response: Response) => response.json());
  // }


  saveprofileanalysissetting(data, token) {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/saveprofileanalysissetting', {
      setting: data,
      token: token
    })
    .map((response: Response) => response.json());
  }


  updateprofileanalysisStructuresetting(data, token) {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/updateprofileanalysisStructuresetting', {
      setting: data,
      token: token
    })
    .map((response: Response) => response.json());
  }

  updateprofileanalysissettingeligibity(data, token, request_id, band_details) {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/updateprofileanalysissettingeligibity', {
      setting: data,
      token: token,
      request_id:request_id,
      band_details:band_details
    })
    .map((response: Response) => response.json());
  }
  updateprofileanalysissetting(data, token) {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/updateprofileanalysissetting', {
      setting: data,
      token: token
    })
    .map((response: Response) => response.json());
  }

  updatecallogeanalysis(data, token) {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/updatecallogeanalysis', {
      setting: data,
      token: token
    })
    .map((response: Response) => response.json());
  }

  updatecallloganalysisseting(data, token) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/updatecallogeanalysisSetting', {
      setting: data,
      token: token
    }).map((response: Response) => response.json());
  }

  getlenderleavessetting(token: any): Observable<any>  {
    
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getlenderleavessetting', token)
    .map((response: Response) => response.json());
  }


  getlenderleavesvalue(token: any): Observable<any>  {
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getlenderleavesvalues', token)
    .map((response: Response) => response.json());
  }

  getState(): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getState', '')
    .map((response: Response) => response.json());
  }

  getCountry(): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getCountry', '')
    .map((response: Response) => response.json());
  }

  getOccupation(): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getOccupation', '')
    .map((response: Response) => response.json());
  }

  getBanks(): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getBank', '')
    .map((response: Response) => response.json());
  }

  getOccupationborrowed(): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getOccupationborrowed', '')
    .map((response: Response) => response.json());
  }

  getOccupationsector(): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getOccupationsector', '')
    .map((response: Response) => response.json());
  }

  getOccupationdesignation(): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getOccupationdesignation', '')
    .map((response: Response) => response.json());
  }

  getQualification(): Observable<any>  {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getQualification', '')
    .map((response: Response) => response.json());
  }

  
}
