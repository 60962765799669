import 'rxjs/add/operator/map';

import { Injectable } from '@angular/core';
import {
  Headers,
  Http,
  RequestOptions,
  Response
} from '@angular/http';
import { validateConfig } from '@angular/router/src/config';
import { Observable, BehaviorSubject } from 'rxjs';

import { LoggingService } from './logging.service';
import { RoleModel } from '../_models/role.model';

@Injectable()
export class OperationsService {

  baseUrl = 'https://creditclanapis.creditclan.com/api/v2/services/getemail';
  tokenUrl = '?token=';
  queryUrl = '&search=';
  rolerights: any = {};

  public $rolerights = new BehaviorSubject<any>(this.rolerights);

  constructor(public http: Http, private loggingService: LoggingService) { }

  search(terms: Observable<string>, token) {
    return terms.debounceTime(400)
      .distinctUntilChanged()
      .switchMap(term => this.searchEntries(term, token));
  }

  searchEntries(term, token) {
    return this.http
      .get(this.baseUrl + this.tokenUrl + token + this.queryUrl + term)
      .map(res => res.json());
  }
  removeRequestToMarket(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/removeRequestToMarket', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  queueLenderRequests(token: any, disburse:any, DISBURSEMENT_OTP:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/queueLenderRequests', JSON.stringify({ SECURITY_QUESTION_ANSWER:DISBURSEMENT_OTP,token: token,disburse:disburse }))
      .map((response: Response) => response.json());
  }
  queueLenderRequestss(token: any, disburse:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/queueLenderRequestss', JSON.stringify({ token: token,disburse:disburse }))
      .map((response: Response) => response.json());
  }
  loadCustomerEmail(token): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getemail', { token: token })
      .map((response: Response) => response.json());
  }
  getOfferLetterTemplate(token: string, parentRouteId: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getOfferTemplate', { token: token, parentRouteId: parentRouteId })
      .map((response: Response) => response.json());
  }
  getIndebtednessTemplate(token: string, parentRouteId: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getIndebtednessTemplate', { token: token, parentRouteId: parentRouteId })
      .map((response: Response) => response.json());
  }
  sendEmail(token, subject, message, send_type, message_type, ids, is_multiple = false): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/sendEmail', JSON.stringify(
      {
        token: token,
        subject: subject,
        massage: message,
        send_type: send_type,
        message_type: message_type,
        ids: ids,
        is_multiple: is_multiple
      }))
      .map((response: Response) => response.json());
  }

  sendSMS(token, message, send_type, message_type, ids): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/sendSMS', JSON.stringify(
      { token: token, massage: message, send_type: send_type, message_type: message_type, ids: ids }))
      .map((response: Response) => response.json());
  }

  loadSentEmail(token, start, limit): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getallsentemail',
      { token: token, start: start, limit: limit })
      .map((response: Response) => response.json());
  }


  loadNextSentEmail(token, start, limit): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getallsentemail',
      { token: token, start: start, limit: limit })
      .map((response: Response) => response.json());
  }

  loadPreviousSentEmail(token, start, limit): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getallsentemail',
      { token: token, start: start, limit: limit })
      .map((response: Response) => response.json());
  }

  loadNextSentSMS(token, start, limit): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getallsentsms',
      { token: token, start: start, limit: limit })
      .map((response: Response) => response.json());
  }

  loadPreviousSentSMS(token, start, limit): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getallsentsms',
      { token: token, start: start, limit: limit })
      .map((response: Response) => response.json());
  }

  loadSentSMS(token, start, limit): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getallsentsms',
      { token: token, start: start, limit: limit })
      .map((response: Response) => response.json());
  }

  countSMS(token): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/countsms',
      { token: token })
      .map((response: Response) => response.json());
  }

  countEmail(token): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/countEmail',
      { token: token })
      .map((response: Response) => response.json());
  }
  SaveFirstCentral(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/SaveFirstCentral', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  SaveCRC(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/SaveCRC', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  SaveCRServices(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/SaveCRServices', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  SavePaystack(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/SavePaystack', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  SaveRave(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/SaveRave', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  // getBureauSettings(token:any,): Observable<any> {
  getBureauSettings(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/getBureauSettings', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getGatewaySettings(token: any,): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/getGatewaySettings', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  // sendLoanInvite(token:any, request:any): Observable<any> {
  sendLoanInvite(token: any, request: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/sendLoanInvites', JSON.stringify({ token: token, request: request }))
      .map((response: Response) => response.json());
  }
  sendLoanGuarantorInvites(token: any, request: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/sendLoanGuarantorInvites', JSON.stringify({ token: token, request: request }))
      .map((response: Response) => response.json());
  }
  generateInviteLink(token: any, request: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/generateInviteLink', JSON.stringify({ token: token, request: request }))
      .map((response: Response) => response.json());
  }
  sendLoanInvites(token: any, request: any, whatsapp: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/sendMultipleLoanInvites', JSON.stringify({ token: token, request: request, whatsapp: whatsapp }))
        .toPromise()
        .then(
          res => {
            resolve();
          }
        );
    });
    return promise;
  }
  downLoadBulkDisbursements(token: any, disbursements: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/downLoadBulkDisbursements', JSON.stringify({ token: token, disbursements: disbursements }))
      .map((response: Response) => response.json());
  }
  loadCustomerLoans(token, customer): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/loadCustomerLoans', JSON.stringify({ token: token, customer: customer }))
      .map((response: Response) => response.json());
  }
  completeLenderSetup(token): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/onboard/completelendersetup', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getLedgerReports(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLedgerReports', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getAccruedInterests(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getAccruedInterest', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getCustomerReportedPayments(token: any, request_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getCustomerReportedPayments', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  uploadTheLoanPhoto(token: any, filesUploaded: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/uploadTheLoanPhoto', JSON.stringify({ token: token, filesUploaded: filesUploaded }))
      .map((response: Response) => response.json());
  }
  uploadPayments(token: any, data: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/uploadPayments', JSON.stringify({ token: token, result: data }))
      .map((response: Response) => response.json());
  }
  // uploadPayments(token: any, data: any) {
  //   const promise = new Promise((resolve, reject) => {
  //     this.http.post('https://creditclanapis.creditclan.com/api/v2/services/uploadPayments', JSON.stringify({ token: token, result: data }))
  //       .toPromise()
  //       .then(
  //         res => { // Success
  //           //console.log(res.json());
  //           resolve();
  //         }
  //       );
  //   });
  //   return promise;
  // }
  getBalanceSheetNew(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getBalanceSheetNew', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getLoanAndAdvances(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoansAndAdvances', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getSubLedgerReports(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getSubLedgerReports', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getCustomerLedgerReports(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getCustomerLedgerReports', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getFeesIncome(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getFeesIncome', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  initializeLedgerReports(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/initializeledgerreports', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getFinesIncome(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getFinesIncome', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getInterestIncome(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getInterestIncome', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  getCashAndBank(token: any, query: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getCashAndBank', JSON.stringify({ token: token, query: query }))
      .map((response: Response) => response.json());
  }
  saveGeneralAnalytics(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveGeneralAnalyticsSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveCardRecollectionTimes(token: any, card_recollection_times: any, parentRouteId: any, card: any): Observable<any> {
    // get users from api
    return this.http.post('https://loanstest.creditclan.com/loan/saveCardRecollectionTimes', JSON.stringify({ token: token, card_recollection_times: card_recollection_times, request_id: parentRouteId, card: card }))
      .map((response: Response) => response.json());
  }
  saveDirectDebitSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/save_direct_debit_settings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  doWalletTopUp(token: any, value: any, card: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/doWalletTopUp', JSON.stringify({ token: token, data: value, card: card }))
      .map((response: Response) => response.json());
  }
  finishWalletTopUp(token: any, value: any, card: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/finishWalletTopUp', JSON.stringify({ token: token, data: value, card: card }))
      .map((response: Response) => response.json());
  }
  tokenizeLenderCard(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/tokennizeLenderCard', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveGeneralNotificationSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/save_general_notifications', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveAnalytics(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/analytics/saveAnalyticsSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }

  autoPostServices(token: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/autoPostServices', JSON.stringify({ token: token }))
      .map(res => res.json())
      .catch(this.loggingService.handleError);
  }
  refreshData(token: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/refreshData', JSON.stringify({ token: token }))
      .map(res => res.json())
      .catch(this.loggingService.handleError);
  }
  getEducationDetails(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getEducationDetails', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }

  getBankDetails(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getBankDetails', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }

  getJobDetails(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getJobDetails', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }

  getJobSector(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getJobSector', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getOptionsForRepayments(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getPaymentOptionsOmni', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getBanks(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getBanks', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getLenderBanks(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getLenderBanks', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  bulkpaystatus(token: any, bulkpayrequests: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/bulkpaystatus', JSON.stringify({ bulkpayrequests: bulkpayrequests, token: token }))
      .map((response: Response) => response.json());
  }
  getAccounts(token: any, start: any, search: any, type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getAccounts', JSON.stringify({ search: search, type: type, start: start, token: token }))
      .map((response: Response) => response.json());
  }
  saveEmployee(token: any, data: any, people_people_id: any, isedit: any, roles: any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveEmployee', JSON.stringify({ roles: roles, data: data, isedit: isedit, people_people_id: people_people_id, token: token, branch_id: branch_id }))
      .map((response: Response) => response.json());
  }
  saveMBSSettings(token: any, data: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveMBSSettings', JSON.stringify({ data: data, token: token }))
      .map((response: Response) => response.json());
  }
  saveEmployeeOmni(token: any, data: any, people_people_id: any, isedit: any, roles: any, branch_id: any, employee_role:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveEmployeeOmni', JSON.stringify({ employee_role:employee_role,roles: roles, data: data, isedit: isedit, people_people_id: people_people_id, token: token, branch_id: branch_id }))
      .map((response: Response) => response.json());
  }
  createEmployeeAsAnAgent(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://repaidserver.creditclan.com/merchant/onboard-agent', JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  sendCollectionToRepid(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://repaidserver.creditclan.com/merchant/add-collection', JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  sendKYCToAgents(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    headers.append('x-api-key', '************88');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://avsapi.africacodes.net/index.php/avs/request',  data, options)
      .map((response: Response) => response.json());
  }
  createClusterOnRepaid(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://repaidserver.creditclan.com/merchant/add-cluster', JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  createClusterAgentOnRepaid(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://repaidserver.creditclan.com/merchant/onboard-agent', JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  attachClusterAgentOnRepaid(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    const url = "https://repaidserver.creditclan.com/merchant/cluster/" + data.cluster_id + "/attach-agents";
    return this.http.post(url, JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  authorizeAgentToCollect(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://repaidserver.creditclan.com/merchant/authorize-agent', JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  resetAgentToCollect(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://repaidserver.creditclan.com/merchant/reset-agent-limit', JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  approveAgentCollectedPayment(data: any, payment_id: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    const url = "https://repaidserver.creditclan.com/merchant/verify-payment/" + payment_id;
    return this.http.post(url, JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  rejectAgentCollectedPayment(data: any, payment_id: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    const url = "https://repaidserver.creditclan.com/merchant/verify-payment/" + payment_id;
    return this.http.post(url, JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  viewAgentDetails(data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://repaidserver.creditclan.com/merchant/get-agent', JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }
  viewAllMerchantsPayments(creditclan_id: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    //const url = "https://repaidserver.creditclan.com/merchant/"+creditclan_id+"/all-payments-unapproved/";
    const url = "http://sellbackend.creditclan.com/parent/index.php/globalrequest/request/802403";
    return this.http.get(url)
      .map((response: Response) => response.json());
  }
  viewAgentPayments(creditclan_id: any, agent_id: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    const url = "https://repaidserver.creditclan.com/merchant/" + creditclan_id + "/payments/" + agent_id;
    return this.http.get(url)
      .map((response: Response) => response.json());
  }
  getCreditReportFG(token: any, filter: any, start: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getCreditReportFG', JSON.stringify({ token: token, filter: filter, start: start }))
      .map((response: Response) => response.json());
  }
  getFGLoans(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getFGLoans', JSON.stringify({ token: token, filter: filter }))
      .map((response: Response) => response.json());
  }
  viewAgentReports(creditclan_id: any, agent_id: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    const url = "https://repaidserver.creditclan.com/merchant/" + creditclan_id + "/reports/" + agent_id;
    return this.http.get(url)
      .map((response: Response) => response.json());
  }
  viewAgentsCollections(creditclan_id: any, agent_id: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    const url = "https://repaidserver.creditclan.com/merchant/" + creditclan_id + "/collections/" + agent_id;
    return this.http.get(url)
      .map((response: Response) => response.json());
  }
  sendCollectionToRepaid(token: any, data: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://repaidserver.creditclan.com/merchant/add-collections', JSON.stringify({ data: data }), options)
      .map((response: Response) => response.json());
  }

  getCollectionAgents(token: any, cluster: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/getAgentsInACluster', JSON.stringify({ token: token, cluster_id: cluster }))
      .map((response: Response) => response.json());
  }
  getAllPlatformClusters(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/company/list', JSON.stringify({ token: token, start: '0' }))
      .map((response: Response) => response.json());
  }
  assignCollectionAgent(token: any, data: any, loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/assignCollectionAgent', JSON.stringify({ token: token, data: data, loan: loan }))
      .map((response: Response) => response.json());
  }
  saveEmployeeCompany(token: any, data: any, company_id: any, is_edit: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/company/saveEmployee', JSON.stringify({ token: token, data: data, company_id: company_id, isedit: is_edit }))
      .map((response: Response) => response.json());
  }
  saveRole(role: RoleModel, token: any, is_edit: any, role_id: any, chosenRights: any, landingPage: string, selectedModules: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/saveRole', JSON.stringify({ chosenRights, is_edit, role_id, role, landingPage, selectedModules, token }))
      .map((response: Response) => response.json());
  }
  deleteRole(role: RoleModel, token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/deleteRole', JSON.stringify({ role: role, token: token }))
      .map((response: Response) => response.json());
  }
  getCompanyEmployees(token: any, start: any, search: any, company_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/company/getEmployees', JSON.stringify({ search: search, start: start, token: token, company_id: company_id }))
      .map((response: Response) => response.json());
  }
  getEmployees_(token: any, start: any, search: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getEmployees', JSON.stringify({ search: search, start: start, token: token }))
      .map((response: Response) => response.json());
  }
  getAgents(token: any, start: any, search: any, branch_id: any, from_where: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/getAgents', JSON.stringify({ branch_id: branch_id, from_where: from_where, search: search, start: start, token: token }))
      .map((response: Response) => response.json());
  }
  getAgentsInACluster(token: any, cluster_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/getAgentsInACluster', JSON.stringify({ cluster_id: cluster_id, token: token }))
      .map((response: Response) => response.json());
  }
  getEmployees___Serviced(token: any, start: any, search: any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getEmployeesWithServicedCompany', JSON.stringify({ branch_id: branch_id, search: search, start: start, token: token }))
      .map((response: Response) => response.json());
  }
  getEmployees___(token: any, start: any, search: any, branch_id: any, from_where: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getEmployeesWithBranches', JSON.stringify({ branch_id: branch_id, from_where: from_where, search: search, start: start, token: token }))
      .map((response: Response) => response.json());
  }
  getEmployees__(token: any, start: any, search: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getEmployees', JSON.stringify({ search: search, start: start, token: token }))
      .map((response: Response) => response.json());
  }
  getRoles(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/getRoles', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }

  getRoleRights(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/getRoleRights', JSON.stringify({ token: token }))
      .map((response: Response) => {
        const data = response.json();
        this.$rolerights.next(data);
        return data;
      });
  }
  getBanks_(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getCompanyBanks', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getNigerianBanks(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getNigerianBanks', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  // saveJournal(token: any, data: any, edit: any) {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveJournal', JSON.stringify({ token: token, data: data, edit: edit }))
  //     .map((response: Response) => response.json());
  // }
  approveJournal(token: any, data: any, edit: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/approveJournal', JSON.stringify({ token: token, data: data, edit: edit }))
      .map((response: Response) => response.json());
  }
  // getBulkRepayments(token: any, param:any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/getDueLoans', JSON.stringify({ token: token, param:param }))
  //     .map((response: Response) => response.json());
  // }
  // postDuePayments(token:any,repayments:any,payment_date:any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/postDuePayments', JSON.stringify({ token: token, repayments:repayments, payment_date: payment_date }))
  //     .map((response: Response) => response.json());
  // }
  rejectJournal(token: any, data: any, edit: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/rejectJournal', JSON.stringify({ token: token, data: data, edit: edit }))
      .map((response: Response) => response.json());
  }
  confirmBankAccount(token: any, bank: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmBankAccount', JSON.stringify({ token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  resolve_bvn(token: any, bvn: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/resolve_bvn', JSON.stringify({ token: token, bvn: bvn }))
      .map((response: Response) => response.json());
  }
  confirmBankAccounts(token: any, bank: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmBankAccounts', JSON.stringify({ token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  doWalletTransfer(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doWalletTransfer', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  getRecords(token: any, account_id: any, record_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getRecords', JSON.stringify({ token: token, account_id: account_id, record_type: record_type }))
      .map((response: Response) => response.json());
  }
  getInvestmentPortfolio(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getInvestmentPortfolio', JSON.stringify({ token: token, filter: filter }))
      .map((response: Response) => response.json());
  }
  getInvestmentMaturity(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getInvestmentMaturity', this.serializeParams(filter), options)
      .map((response: Response) => response.json());
  }
  getClosedLoanPortfolio(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getClosedLoanPortfolio', JSON.stringify({ token: token, filter: filter }))
      .map((response: Response) => response.json());
  }
  getAutodebitReport(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getAutodebitReport', JSON.stringify({ token: token, filter: filter }))
      .map((response: Response) => response.json());
  }
  getAutodebitReportForLoan(token: any, filter: any, request_id: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getAutodebitReport', JSON.stringify({ token: token, filter: filter, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getLoanPortfolio(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanPortfolioPartner', JSON.stringify({ token: token, filter: filter }))
      .map((response: Response) => response.json());
  }
  getLoanPrepayment(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanPrepayment', JSON.stringify({ token: token, filter: filter }))
      .map((response: Response) => response.json());
  }
  getCreditReport(token: any, filter: any, start: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getCreditReport', JSON.stringify({ token: token, filter: filter, start: start }))
      .map((response: Response) => response.json());
  }
  getCreditReportXDS(token: any, filter: any, start: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getCreditReportXDS', JSON.stringify({ token: token, filter: filter, start: start }))
      .map((response: Response) => response.json());
  }
  getLoanPending(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanPending', JSON.stringify({ token: token, filter: filter }))
      .map((response: Response) => response.json());
  }
  getTrialBalance(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getTrialBalance', this.serializeParams(filter), options)
      .map((response: Response) => response.json());
  }
  getBalanceSheet(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getBalanceSheet', this.serializeParams(filter), options)
      .map((response: Response) => response.json());
  }
  getProfitLoss(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getProfitLoss', this.serializeParams(filter), options)
      .map((response: Response) => response.json());
  }
  getJournalReports(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getJournalReports', this.serializeParams(filter), options)
      .map((response: Response) => response.json());
  }
  getLoanMaturiy(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getLoanMaturiy', this.serializeParams(filter), options)
      .map((response: Response) => response.json());
  }
  getLoanAgeing(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getLoanAgeing', this.serializeParams(filter), options)
      .map((response: Response) => response.json());
  }
  getAppSettings(token: any, where_from: any = '0'): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getAppSettings', JSON.stringify({ token: token, where_from: where_from }))
      .map((response: Response) => response.json());
  }
  saveWidgetVisibility(token: any, visibility: any, product_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveWidgetVisibility', JSON.stringify({ token: token, visibility: visibility, product_id: product_id }))
      .map((response: Response) => response.json());
  }
  saveSimpleWidgetVisibility(token: any, visibility: any, product_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveSimpleWidgetVisibility', JSON.stringify({ token: token, visibility: visibility, product_id: product_id }))
      .map((response: Response) => response.json());
  }
  getInterestBands(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getInterestBands', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getDefaultCardCollection(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getDefaultCardCollection', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  saveDefaultCardRecollectionTimes(token: any, card_recollection_times: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveCardRecollection', JSON.stringify({ token: token, data: card_recollection_times }))
      .map((response: Response) => response.json());
  }
  getLenderSettings(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getLenderSettings', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  // exportReport(token: any, param: any) {
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/exportReport', JSON.stringify({ token: token, param: param }))
  //     .map((response: Response) => response.json());
  // }
  exportRecords(token: any, account_details: any, date_start: any, date_end: any, contra_charts: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/exportRecords', JSON.stringify({ token: token, account_details: account_details, date_start: date_start, date_end: date_end, contra_charts: contra_charts }))
      .map((response: Response) => response.json());
  }
  checkDirectDebitStatus(token: any, value: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/checkDirectDebitStatus', JSON.stringify({ token: token, value: value }))
      .map((response: Response) => response.json());
  }
  saveFloorRate(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveFloorRate', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  changeTheDefaultPayment(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/changeTheDefaultPayment', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  notifyRemitaOfLoan(token: any, value: any): Observable<any> {
    // get users from apiAnalyserNodeAnalyserNodeAnalyserNode
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/remita/notifyRemita', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  refresh_remita_details(token: any, value: any, remita_records: any, loan: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/remita/refresh', JSON.stringify({ loan: loan, token: token, data: value, remita_records: remita_records }))
      .map((response: Response) => response.json());
  }
  creditTheStatement(token: any, value: any, ttype: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/credit_statement', JSON.stringify({ token: token, data: value, ttype: ttype }))
      .map((response: Response) => response.json());
  }
  changeTheLoanOfficer(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/changeTheLoanOfficer', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveInterestForm(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveInterestForm', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }

  saveOLSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveOL', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveTCSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveTC', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveITCSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveITC', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveSLInterest(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveSLInterest', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveContract(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveContractSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveUssdSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveUssdSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveAutodisburse(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveAutodisburse', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveRollover(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveRollover', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveLoanProduct(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveLoanProduct', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveUssdSettings_(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveUssdSettingss', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveMobileApplication(token: any, value: any, required_documents: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveMobileApplication', JSON.stringify({ token: token, data: value, required_documents: required_documents }))
      .map((response: Response) => response.json());
  }
  saveMobileApplicationBusiness(token: any, value: any, required_documents: any, required_documents_business: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveMobileApplicationBusiness', JSON.stringify({ required_documents_business: required_documents_business, token: token, data: value, required_documents: required_documents }))
      .map((response: Response) => response.json());
  }
  saveLenderBranch(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/saveLenderBranch', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  completeClearBulkUpload(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/completeClearBulkUpload', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  cancelLenderQueue(token: any, value: any, otp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/cancelLenderQueue', JSON.stringify({ token: token, data: value, SECURITY_QUESTION_ANSWER: otp }))
      .map((response: Response) => response.json());
  }
  logoutTheUser(token: string) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/logoutTheUser', JSON.stringify({ token: token }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            //resolve();
          }
        );
    });
    return promise;

  }
  doneLenderQueue(token: any, value: any, otp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doneLenderQueue', JSON.stringify({ token: token, data: value, SECURITY_QUESTION_ANSWER: otp }))
      .map((response: Response) => response.json());
  }
  pushnow(token: any, value: any, partner:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/pushnow', JSON.stringify({ token: token, data: value, partner:partner }))
      .map((response: Response) => response.json());
  }
  getLenderPQueue(token: any, partner:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getDisbursementQueueL', JSON.stringify({ token: token, partner: partner }))
      .map((response: Response) => response.json());
  }
  downloadLenderQueue(token: any, value: any, otp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/downloadLenderQueue', JSON.stringify({ token: token, data: value, SECURITY_QUESTION_ANSWER: otp }))
      .map((response: Response) => response.json());
  }
  downloadLenderQueues(token: any, value: any, otp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/downloadLenderQueues', JSON.stringify({ token: token, data: value, SECURITY_QUESTION_ANSWER: otp }))
      .map((response: Response) => response.json());
  }
  processLenderQueue(token: any, value: any, otp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/processLenderQueue', JSON.stringify({ token: token, data: value, SECURITY_QUESTION_ANSWER: otp }))
      .map((response: Response) => response.json());
  }
  retryLenderQueue(token: any, value: any, otp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/retryLenderQueue', JSON.stringify({ token: token, data: value, SECURITY_QUESTION_ANSWER: otp }))
      .map((response: Response) => response.json());
  }
  post(request_id:any): Observable<any> {
    // get users from api
    return this.http.get('https://creditclanapis.creditclan.com/api/v2/services/passLoanToBank/'+request_id)
      .map((response: Response) => response.json());
  }
  getDisbursementQueue(token: any, value: any, nocheck:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getDisbursementQueue', JSON.stringify({ nocheck:nocheck, token: token, data: value }))
      .map((response: Response) => response.json());
  }
  getTransferStatus(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getTransferStatus', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveRecordsValidation(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveRecordsValidation', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveSecurity(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveSecurity', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveautorouting(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveautorouting', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveManagementFee(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveManagementFee', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveFines(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveFines', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveCollaterals(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveCollaterals', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveGeofencing(token: any, value: any, allowedlgas: any, addresstypes: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveGeofencing', JSON.stringify({ token: token, data: value, allowedlgas: allowedlgas, addresstypes: addresstypes }))
      .map((response: Response) => response.json());
  }
  saveBreakSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveBreakSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  savePayments(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/savePayment', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveFee(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveFee', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  deleteLevel(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/deleteLevel', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveLevel(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveLevel', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveApprovalLevel(token: any, value: any, products: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/product/saveApprovalLevel', JSON.stringify({ token: token, data: value, products: products }))
      .map((response: Response) => response.json());
  }
  updateApprovalLevel(token: any, value: any, employees: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/product/updateApprovalLevel', JSON.stringify({ token: token, data: value, employees: employees }))
      .map((response: Response) => response.json());
  }
  getApprovalLevel(token: any, level_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getApprovalLevel', JSON.stringify({ token: token, level_id: level_id }))
      .map((response: Response) => response.json());
  }
  saveInvApprovalLevel(token: any, value: any, products: any, product_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/product/saveInvApprovalLevel', JSON.stringify({ product_id: product_id, token: token, data: value, products: products }))
      .map((response: Response) => response.json());
  }
  saveQualifiedBorrowers(token: any, value: any, sectors: any, occupations: any, marital_status: any, states: any, guarantors: any, guarantor_requirements: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveQualifiedBorrowers', JSON.stringify({ guarantor_requirements: guarantor_requirements, guarantors: guarantors, marital_status: marital_status, states: states, sectors: sectors, occupations: occupations, token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveGuarantorRequirements(token: any, value: any, sectors: any, occupations: any, marital_status: any, states: any, guarantors: any, guarantor_requirements: any, default_docs_guarantors: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveGuarantorRequirements', JSON.stringify({ default_docs_guarantors: default_docs_guarantors, guarantor_requirements: guarantor_requirements, guarantors: guarantors, marital_status: marital_status, states: states, sectors: sectors, occupations: occupations, token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveAccounts(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveAccounts', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveAccPeer(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveAccPeer', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  buyBackLoan(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/buybackloan', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveSMSSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveSMSSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveMailSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveMailSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  savePaymentSettings(token: any, value: any, cards: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/savePaymentGatewaySettings', JSON.stringify({ token: token, data: value, cards: cards }))
      .map((response: Response) => response.json());
  }
  saveInvestor(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveInvestor', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveInvestorGeneralSettings(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveInvestorGeneralSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveInvestorRepayment(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveInvestorRepayment', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveReminders(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveReminders', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }

  saveReferral(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveReferralSettings', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }

  saveEligibility(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveEligibility', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  saveMobileRegisteration(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveMobileRegisteration', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  getInvestmentRateHistory(token: any, filter: any): Observable<any> {
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const options = new RequestOptions({ headers: headers });
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getInvestmentRateHistory', this.serializeParams(filter), options)
      .map((response: Response) => response.json());
  }
  doInitiateFuning(token: any, payment_queue: any, people_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doInitiateFuning', JSON.stringify({ people_id: people_id, token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doConfirmWalletFunding(token: any, payment_queue: any, people_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doConfirmWalletFunding', JSON.stringify({ people_id: people_id, token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  lookforemployees(token: string, lender_name: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/lookforemployees', JSON.stringify({ token: token, searchText: lender_name }))
      .map((response: Response) => response.json());
  }
  doQueueFunding(token: any, payment_queue: any, people_id: any, ttype: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doQueueFunding', JSON.stringify({ ttype: ttype, people_id: people_id, token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doQueueFundingComplete(token: any, payment_queue: any, people_id: any, ttype: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doQueueFundingComplete', JSON.stringify({ ttype: ttype, people_id: people_id, token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  saveSettings(token: any, repaymentbased): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveSettings', JSON.stringify({ token: token, repaymentbased: repaymentbased }))
      .map((response: Response) => response.json());
  }
  saveSettingsForProduct(token: any, repaymentbased: any, product_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/product/saveSettingsForProduct', JSON.stringify({ product_id: product_id, token: token, repaymentbased: repaymentbased }))
      .map((response: Response) => response.json());
  }
  confirmWalletWithdrawal(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmWalletWithdrawal', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  confirmWalletWithdrawalCancel(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmWalletWithdrawalCancel', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  walletFundingComplete(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/walletFundingComplete', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doWalletFundingConfirm(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doWalletFundingConfirm', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doInitiateWithdrawal(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doInitiateWithdrawal', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doConfirmWithdrawal(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/wallet/doConfirmWithdrawal', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doSplitPayment(token: any, payment_queue: any, split_payments: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doSplitPayment', JSON.stringify({ token: token, payment_queue: payment_queue, split_payments: split_payments }))
      .map((response: Response) => response.json());
  }
  confirmMoneyReceivedFromAgent(token: any, payment_queue: any, disburse: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmMoneyReceivedFromAgent', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  confirmBorrowerHasBeenPaid(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmBorrowerHasBeenPaid', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  confirmBorrowerHasBeenPaidForBulk(token: any, payment_queue: any, bulkpayrequests: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmBorrowerHasBeenPaidBlk', JSON.stringify({ token: token, payment_queue: payment_queue, bulkpayrequests: bulkpayrequests }))
      .map((response: Response) => response.json());
  }

  confirmOTPForTransferToBorrower(token: any, otp: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmOTPForTransferToBorrower', JSON.stringify({ token: token, otp: otp, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doCustomerPaymentConfirm(token: any, payment_queue: any, schedule_type: any, record_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doCustomerPaymentConfirm', JSON.stringify({ token: token, payment_queue: payment_queue, schedule_type: schedule_type, record_type: record_type }))
      .map((response: Response) => response.json());
  }
  checkIfEbillsBankAlreadyExists(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/checkIfEbillsBankAlreadyExists', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  doSendRepaymentLink(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/sendRepaymentLinkTo', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doPaymentConfirm(token: any, payment_queue: any, schedule_type: any, record_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/doPaymentConfirmpay', JSON.stringify({ token: token, payment_queue: payment_queue, schedule_type: schedule_type, record_type: record_type }))
      .map((response: Response) => response.json());
  }
  doInvestmentPaymentConfirm(token: any, payment_queue: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/savings/doPaymentConfirmpay', JSON.stringify({ token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doPaymentConfirmOld(token: any, payment_queue: any, schedule_type: any, record_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doPaymentConfirm', JSON.stringify({ token: token, payment_queue: payment_queue, schedule_type: schedule_type, record_type: record_type }))
      .map((response: Response) => response.json());
  }
  doPaymentCancel(token: any, payment_queue: any, schedule_type: any, record_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doPaymentCancel', JSON.stringify({ token: token, payment_queue: payment_queue, schedule_type: schedule_type, record_type: record_type }))
      .map((response: Response) => response.json());
  }
  doPaymentConfirmForBulk(token: any, payment_queue: any, bulkpayrequests: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doPaymentConfirmForBulk', JSON.stringify({ token: token, bulkpayrequests: bulkpayrequests, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doPaymentConfirmDebitAll(token: any, payment_queue: any, schedule_type: any, record_type: any, debit_all_card: any, disburse: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/doPaymentConfirmDebitAll', JSON.stringify({ disburse: disburse, debit_all_card: debit_all_card, token: token, payment_queue: payment_queue, schedule_type: schedule_type, record_type: record_type }))
      .map((response: Response) => response.json());
  }
  dopaymentConfirmRollbackForm(token: any, payment_queue: any, schedule_type: any, record_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/dopaymentConfirmRollback', JSON.stringify({ token: token, payment_queue: payment_queue, schedule_type: schedule_type, record_type: record_type }))
      .map((response: Response) => response.json());
  }
  doTransferToWallet(token: any, payment_queue: any, schedule_type: any, record_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doTransferToWallet', JSON.stringify({ token: token, payment_queue: payment_queue, schedule_type: schedule_type, record_type: record_type }))
      .map((response: Response) => response.json());
  }
  saveCustomerBank(token: any, bank: any, userId: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveCustomerBank', JSON.stringify({ userId: userId, token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  saveBorrowerAccount(token: any, bank: any, schedule_type: any, record_type: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveBorrowerAccount', JSON.stringify({ schedule_type: schedule_type, record_type: record_type, token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  saveBorrowerAccountOnly(token: any, bank: any, schedule_type: any, record_type: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveBorrowerAccountOnly', JSON.stringify({ schedule_type: schedule_type, record_type: record_type, token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  saveWalletWithdrawalAccount(token: any, bank: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveWalletWithdrawalAccount', JSON.stringify({ token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  initiateAutodebit(token: any, autodebit_form: any, request_id: any, connected_card_id: any, disburse: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/initiateAutodebut', JSON.stringify({ disburse: disburse, autodebit_form: autodebit_form, connected_card_id: connected_card_id, token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  chargeThisCard(token: any, autodebit_form: any, request_id: any, card: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/chargeThisCard', JSON.stringify({ card: card, autodebit_form: autodebit_form, token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  setAsConnectedAccountId(token: any, connected_account_id: any, request_id: any, payment_queue_id: any, record_type: any, schedule_type: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/setAsConnectedAccountId', JSON.stringify({ schedule_type: schedule_type, record_type: record_type, payment_queue_id: payment_queue_id, token: token, connected_account_id: connected_account_id, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  deleteConnectedCard(token: any, connected_account_id: any, request_id: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/deleteConnectedCard', JSON.stringify({ token: token, connected_account_id: connected_account_id, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  setAsConnectedCard(token: any, connected_account_id: any, request_id: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/setAsConnectedCard', JSON.stringify({ token: token, connected_account_id: connected_account_id, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  setAsConnectedAccount(token: any, connected_account_id: any, request_id: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/setAsConnectedAccount', JSON.stringify({ token: token, connected_account_id: connected_account_id, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  setAsWithdrawalAccount(token: any, connected_account_id: any, wallet_withdrawal_request_id: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/setAsWithdrawalAccount', JSON.stringify({ wallet_withdrawal_request_id: wallet_withdrawal_request_id, token: token, connected_account_id: connected_account_id }))
      .map((response: Response) => response.json());
  }
  accountToAccount(token: any, bank: any, credit: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/accountToAccount', JSON.stringify({ token: token, bank: bank, credit: credit }))
      .map((response: Response) => response.json());
  }
  payBorrowerWithBank(token: any, transaction: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/payBorrowerWithBank', JSON.stringify({ token: token, transaction: transaction }))
      .map((response: Response) => response.json());
  }
  payInvestorWithBank(token: any, transaction: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/payInvestorWithBank', JSON.stringify({ token: token, transaction: transaction }))
      .map((response: Response) => response.json());
  }
  confirmOTPForTransfer(token: any, otp: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/confirmOTPForTransfer', JSON.stringify({ token: token, otp: otp }))
      .map((response: Response) => response.json());
  }
  getWalletData_(token: any, data: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getWalletData', JSON.stringify({ token: token, data: data }))
      .map((response: Response) => response.json());
  }
  getLenderAccountStatement(token: any, data: any, start_date: any, end_date: any, ttype: any, people_customer: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getLenderAccountStatement', JSON.stringify({ token: token, data: data, start_date: start_date, end_date: end_date, ttype: ttype, people_customer: people_customer }))
      .map((response: Response) => response.json());
  }
  getCustomerWalletsGL(token: any, start_date: any, end_date: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/wallet/getCustomerWalletsGL', JSON.stringify({ token: token, start_date: start_date, end_date: end_date }))
      .map((response: Response) => response.json());
  }
  getAccountStatement(token: any, data: any, start_date: any, end_date: any, ttype: any, people_customer: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getAccountStatement', JSON.stringify({ token: token, data: data, start_date: start_date, end_date: end_date, ttype: ttype, people_customer: people_customer }))
      .map((response: Response) => response.json());
  }
  getLeafAccountBalance(token: any, data: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getLeafAccountBalance', JSON.stringify({ token: token, data: data }))
      .map((response: Response) => response.json());
  }
  getSecurityQuestion(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getSecurityQuestion', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getPortfolioTarget(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getPortfolioTarget', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  updatePortfolioTarget(token: any, target: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/updatePortfolioTarget', JSON.stringify({ token: token, target: target }))
      .map((response: Response) => response.json());
  }
  getWalletData(token: any, hw = 0, is_company = 1, customer_id = 0): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getWalletData', JSON.stringify({ token: token, hw: hw, is_company: is_company, customer_id: customer_id }))
      .map((response: Response) => response.json());
  }
  getWalletSummary(token: any, how_much_was_given: any = 0): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getWalletSummary', JSON.stringify({ token: token, HOW_MUCH_WAS_GIVEN: how_much_was_given }))
      .map((response: Response) => response.json());
  }
  getWalletSummarys(token: any, how_much_was_given: any = 0): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getWalletSummarys', JSON.stringify({ token: token, HOW_MUCH_WAS_GIVEN: how_much_was_given }))
      .map((response: Response) => response.json());
  }
  getCustomerWalletData(token: any, hw: any, is_company: any, customer_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getWalletData', JSON.stringify({ token: token, hw: hw, is_company: is_company, customer_id: customer_id }))
      .map((response: Response) => response.json());
  }
  saveWalletDisbursementSetting(token: any, wallet_settings: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveWalletDisbursementSetting', JSON.stringify({ token: token, wallet_settings: wallet_settings }))
      .map((response: Response) => response.json());
  }
  getOptions(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getOptions', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getOptionsForReport(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getOptionsForReport', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  saveLenderBank(token: any, bank: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveLenderBank', JSON.stringify({ token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  getWalletAccountStatus(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getWalletAccountStatus', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getCustomerWalletAccountStatus(token: any, customer_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getCustomerWalletAccountStatus', JSON.stringify({ token: token, customer_id: customer_id }))
      .map((response: Response) => response.json());
  }
  saveWalletBank(token: any, bank: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveWalletBank', JSON.stringify({ token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  saveSelectedLenderBank(token: any, bank: any, account_type: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveSelectedLenderBank', JSON.stringify({ token: token, bank: bank, account_type: account_type }))
      .map((response: Response) => response.json());
  }
  saveBankAccount(token: any, bank: any, account_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveBankAccount', JSON.stringify({ token: token, bank: bank, account_type: account_type }))
      .map((response: Response) => response.json());
  }
  deleteBank(token: any, bank: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/deleteBank', JSON.stringify({ token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  remitaBank(token: any, bank: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/remitaBank', JSON.stringify({ token: token, bank: bank }))
      .map((response: Response) => response.json());
  }
  saveLenderAccount(token: any, account: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/saveLenderAccount', JSON.stringify({ token: token, account: account }))
      .map((response: Response) => response.json());
  }
  getRepaymentSchedule(token: any, record_type: any, schedule_type: any, repayment_schedule_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getRepaymentSchedule', JSON.stringify({ token: token, record_type: record_type, schedule_type: schedule_type, repayment_schedule_id: repayment_schedule_id }))
      .map((response: Response) => response.json());
  }
  getDisbursements(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getDisbursements', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getPLGrouping(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/profit_loss_grouping', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getBSGrouping(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/balance_sheet_grouping', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  loadInvestmentDashboardData(token: any, dashboardFilter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/loadInvestmentDashboardData', JSON.stringify({ token: token, dashboardFilter: dashboardFilter }))
      .map((response: Response) => response.json());
  }
  loadDashboardData(token: any, dashboardFilter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/loadDashboardData', JSON.stringify({ token: token, dashboardFilter: dashboardFilter }))
      .map((response: Response) => response.json());
  }
  loadDashboard(token: any, dashboardFilter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/loadDashboard', JSON.stringify({ token: token, dashboardFilter: dashboardFilter }))
      .map((response: Response) => response.json());
  }
  getBulkRepayments(token: any, param: any, show_for_account_officer: any = false, page?: number): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/retrieveDueLoans', JSON.stringify({ token: token, param: param, show_for_account_officer: show_for_account_officer, page }))
      .map((response: Response) => response.json());
  }
  getDueRepaymentsByDate(token: any, due_date: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/retrieveDueLoansByDate', JSON.stringify({ token: token, param: due_date }))
      .map((response: Response) => response.json());
  }
  getLenderBranches(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getLenderBranches', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  setLenderBranch(token: any, status: any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/setLenderBranch', JSON.stringify({ token: token, status: status, branch_id: branch_id }))
      .map((response: Response) => response.json());
  }
  getThemeSettings(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/getThemeSettings', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  saveThemeColor(token: any, color: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/saveThemeColor', JSON.stringify({ token: token, color: color }))
      .map((response: Response) => response.json());
  }
  saveWidgetLogo(uploadBgImage: any): Observable<any> {
    // get users from api
    // return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/saveWidgetLogo', JSON.stringify({ token: token, logo:logo }))
    //   .map((response: Response) => response.json());
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/saveWidgetLogo',
      uploadBgImage).map((response) => response.json());
  }
  getPaymentsForTheScheduleDate(token: any, param: any, schedule_date: any, show_for_account_officer: any = false): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/getDueLoansCalendar', JSON.stringify({ token: token, param: param, schedule_date: schedule_date, show_for_account_officer: show_for_account_officer }))
      .map((response: Response) => response.json());
  }
  getPaymentsThatWhereMadeFromRequestDueOnDate(token: any, param: any, schedule_date: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/getDueLoansCalendarPaid', JSON.stringify({ token: token, param: param, schedule_date: schedule_date }))
      .map((response: Response) => response.json());
  }
  getCashFlowPerDate(token: any, param: any, schedule_date: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/getCalendarCashFlow', JSON.stringify({ token: token, param: param, schedule_date: schedule_date }))
      .map((response: Response) => response.json());
  }
  // postDuePayments(token:any,repayments:any,payment_date:any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/postDuePayments', JSON.stringify({ token: token, repayments:repayments, payment_date: payment_date }))
  //     .map((response: Response) => response.json());
  // }
  postDuePayments(token: any, repayments: any, payment_date: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v3/request/postDuePayments', JSON.stringify({ token: token, repayments: repayments, payment_date: payment_date }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  searchPortfolio(token: any, param: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/searchPortfolio', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  postBreakLoans(token: any, repayments: any, payment_date: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v3/request/postBreakLoans', JSON.stringify({ token: token, repayments: repayments, payment_date: payment_date }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  // getBulkRepayments(token: any, param:any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/getDueLoans', JSON.stringify({ token: token, param:param }))
  //     .map((response: Response) => response.json());
  // }
  // postDuePayments(token:any,repayments:any,payment_date:any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/postDuePayments', JSON.stringify({ token: token, repayments:repayments, payment_date: payment_date }))
  //     .map((response: Response) => response.json());
  // }
  getDueRepayments(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getDueRepayments', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getDueRepaymentss(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/getDueRepayments', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  currentStats(token: any, dashboardFilter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/currentStats', JSON.stringify({ token: token, dashboardFilter: dashboardFilter }))
      .map((response: Response) => response.json());
  }
  loanPlatforWallet(token: any, dashboardFilter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/loanPlatforWallet', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getJournals(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getJournals', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getSingleJournal(token: any, id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getSingleJournal', JSON.stringify({ token: token, id: id }))
      .map((response: Response) => response.json());
  }
  searchJournal(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/searchJournal', JSON.stringify({ token: token, search: value }))
      .map((response: Response) => response.json());
  }
  searchJournal_(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/searchJournal_', JSON.stringify({ token: token, search: value }))
      .map((response: Response) => response.json());
  }
  nextGLAccL(token: any, next: any, prev: any, index: any, searchForm: any, searchForm_: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/nextGLAccL', JSON.stringify({ token: token, next: next, prev: prev, index: index, searchForm: searchForm, searchForm_: searchForm_ }))
      .map((response: Response) => response.json());
  }
  prevGLAccL(token: any, next: any, prev: any, index: any, searchForm: any, searchForm_: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/prevGLAccL', JSON.stringify({ token: token, next: next, prev: prev, index: index, searchForm: searchForm, searchForm_: searchForm_ }))
      .map((response: Response) => response.json());
  }
  getCurrentRepaymentCalendar(token: any, repay_type: any, schedule_type: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getCurrentRepaymentCalendar', JSON.stringify({ token: token, repay_type: repay_type, schedule_type: schedule_type }))
      .map((response: Response) => response.json());
  }
  getCurrentRepaymentCalendarWithAccountOfficer(token: any, repay_type: any, schedule_type: any, show_for_account_officer: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getCurrentRepaymentCalendar', JSON.stringify({ show_for_account_officer: show_for_account_officer, token: token, repay_type: repay_type, schedule_type: schedule_type }))
      .map((response: Response) => response.json());
  }
  getCurrentRepaymentCalendarWithAccountOfficerWithDuration(token: any, repay_type: any, schedule_type: any, show_for_account_officer: any, start: any, end: any, filterType: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/request/getCurrentRepaymentCalendarPartner', JSON.stringify({ filterType: filterType, start: start, end: end, show_for_account_officer: show_for_account_officer, token: token, repay_type: repay_type, schedule_type: schedule_type }))
      .map((response: Response) => response.json());
  }
  getCurrentRequestsCalendar(token: any, start: any, end: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/getCurrentRequestsCalendar', JSON.stringify({ token: token, start: start, end: end }))
      .map((response: Response) => response.json());
  }
  getBranchCurrentRequestsCalendar(token: any, start: any, end: any, branch_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/getCurrentRequestsCalendar', JSON.stringify({ token: token, start: start, end: end, branch_id }))
      .map((response: Response) => response.json());
  }
  getServicedCompanyCurrentRequestsCalendar(token: any, start: any, end: any, company_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/dashboard/getCurrentRequestsCalendar', JSON.stringify({ token: token, start: start, end: end, company_id: company_id }))
      .map((response: Response) => response.json());
  }
  getCustomerCurrentRepaymentCalendar(token: any, repay_type: any, schedule_type: any, customer_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/getCurrentRepaymentCalendar', JSON.stringify({ token: token, repay_type: repay_type, schedule_type: schedule_type, customer_id: customer_id }))
      .map((response: Response) => response.json());
  }
  sendEmailReminder(token: string, request_id: any, loan_statement: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/sendEmailReminder', JSON.stringify({ loan_statement: loan_statement, token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendLoanStatement(token: string, request_id: any, loan_statement: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/sendLoanStatement', JSON.stringify({ loan_statement: loan_statement, token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  sendLoanStatements(token: string, loan: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/sendLoanStatement', JSON.stringify({ token: token, loan: loan }))
      .map((response: Response) => response.json());
  }
  sendSMSReminder(token: string, request_id: any, loan_statement: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/sendSMSReminder', JSON.stringify({ loan_statement: loan_statement, token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getRepaymentsForTheDay(token: string, record_type: any, schedule_type: any, datechosen: any, start: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getRepaymentsForTheDay', JSON.stringify({ start: start, token: token, repay_type: record_type, schedule_type: schedule_type, datechosen: datechosen }))
      .map((response: Response) => response.json());
  }
  getBranchRepaymentsForTheDay(token: string, record_type: any, schedule_type: any, datechosen: any, start: any, branch_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getRepaymentsForTheDay', JSON.stringify({ start: start, token: token, repay_type: record_type, schedule_type: schedule_type, datechosen: datechosen, branch_id }))
      .map((response: Response) => response.json());
  }
  makepayment(token: any, repayment_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/make_bulk_pay', JSON.stringify({ token: token, repayment_id: repayment_id }))
      .map((response: Response) => response.json());
  }
  makepqueueThePaymentayment(token: any, data: any, request_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v3/loan/make_bulk_pay', JSON.stringify({ token: token, data: data, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  makeinvpayment(token: any, repayment_id: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/make_inv_bulk_pay', JSON.stringify({ token: token, repayment_id: repayment_id }))
      .map((response: Response) => response.json());
  }
  getQueuedRecords(token: any, record_type: any, schedule_type: any, search: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getQueuedRecordsOmni', JSON.stringify({ token: token, record_type: record_type, schedule_type: schedule_type, search: search }))
      .map((response: Response) => response.json());
  }
  getLenderQueuedRecords(token: any, record_type: any, schedule_type: any, search: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getQueuedLenderRecordsOmni', JSON.stringify({ token: token, record_type: record_type, schedule_type: schedule_type, search: search }))
      .map((response: Response) => response.json());
  }
  getLenderQueuedRecordsApproved(token: any, record_type: any, schedule_type: any, search: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getApprovedQueuedLenderRecordsOmni', JSON.stringify({ token: token, record_type: record_type, schedule_type: schedule_type, search: search }))
      .map((response: Response) => response.json());
  }
  getBulkPaymentHistory(token: any, approved: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getBulkPaymentHistory', JSON.stringify({ token: token, approved: approved }))
      .map((response: Response) => response.json());
  }
  // finalizeBulkApproval(token: any, disburse: any) {
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/finalizigBulkApproval', JSON.stringify({ token: token, disburse: disburse }))
  //     .map((response: Response) => response.json());
  // }
  finalizeBulkApproval(token: any, disburse: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/completefinalizeBulkApproval', JSON.stringify({ token: token, disburse: disburse }))
      .map((response: Response) => response.json());
  }
  completefinalizeBulkApproval(token: any, disburse: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/completefinalizeBulkApproval', JSON.stringify({ token: token, disburse: disburse }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  getCustomerWallets(token: any, search: any, start: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getCustomerWallets', JSON.stringify({ token: token, search: search, start: start }))
      .map((response: Response) => response.json());
  }
  getSingleCustomerWallets(token: any, account_details: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getSingleCustomerWallets', JSON.stringify({ token: token, id: account_details }))
      .map((response: Response) => response.json());
  }
  getQueueSummary(token: any, record_type: any, schedule_type: any, disburse: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getQueueSummary', JSON.stringify({ token: token, record_type: record_type, schedule_type: schedule_type, disburse: disburse }))
      .map((response: Response) => response.json());
  }
  cancelQueuedOperation(token: any, formdata: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/cancelQueuedOperation', JSON.stringify({ token: token, formdata: formdata }))
      .map((response: Response) => response.json());
  }
  getHistoricalQueuedRecords(token: any, record_type: any, schedule_type: any, search: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getHistoricalQueuedRecords', JSON.stringify({ token: token, record_type: record_type, schedule_type: schedule_type, search: search }))
      .map((response: Response) => response.json());
  }
  serializeParams(obj) {
    let str = '';
    for (const key in obj) {
      if (str != '') {
        str += '&';
      }
      str += key + '=' + obj[key];
    }
    return str;
  }


  // Reports
  getDueLoans(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/getDueLoans', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getAllDueLoans(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/get_due_loans_partner', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getReportOptions(token: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/options', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getMissedRepayment(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/get_missed_repayments', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getNoRepayment(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/get_no_repayment_loans', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getPastMaturity(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/get_past_maturity_loans', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getGuarantorsList(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/get_list_of_gurantors', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getRepayments(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/get_repayments', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getBorrowersReport(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/get_borrowers_report', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getOfficerReport(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/get_loan_officer_report', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  getDisbursementReport(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/get_disbursement_report', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  exportReport(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/exportReport', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  exportLPReport(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/exportLPReport', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  exportRepayments(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/exportRepayments', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  exportDueLoans(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/exportDueLoans', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  exportPastMaturity(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/report/exportPastMaturity', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  exportCreditReport(token: any, param: any, report_type: any, filter: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/exportCreditReport', JSON.stringify({ token: token, param: param, report_type: report_type, filter: filter }))
      .map((response: Response) => response.json());
  }
  downloadRepayments(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/downloadRepayments', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  downloadCustomers(token: any, param: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/downloadCustomers', JSON.stringify({ token: token, param: param }))
      .map((response: Response) => response.json());
  }
  downLoadActiveLoans(token: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/downLoadActiveLoans', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  saveUssd(value: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getUssd', JSON.stringify({ data: value }))
      .map((response: Response) => response.json());
  }

  getSpecialCompanies(token: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getSpecialCompanies', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getLoanDirectDebitMandate(token: any, request_id: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/getDirectDebitMandates', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getMandateTransactionHistory(token: any, loan: any, account: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/mandatetranshistory', JSON.stringify({ token: token, loan: loan, account: account }))
      .map((response: Response) => response.json());
  }
  getMandateTransactionHistoryNibbs(token: any, loan: any, account: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/cyberpay/mandatetranshistory', JSON.stringify({ token: token, loan: loan, account: account }))
      .map((response: Response) => response.json());
  }
  getLoanCreditCheck(token: any, request_id: any, where: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/getLoanCreditCheck', JSON.stringify({ token: token, request_id: request_id, where: where }))
      .map((response: Response) => response.json());
  }
  getLoanKyc(token: any, request_id: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/getLoanKYCs', JSON.stringify({ token: token, request_id: request_id }))
      .map((response: Response) => response.json());
  }
  getKYCResult(request_id: any, merchant_id: any): Observable<any> {
    // get users from api
    return this.http.post('https://avsapi.africacodes.net/index.php/avs/request/result', JSON.stringify({ request_id: request_id, merchant_id: merchant_id}))
      .map((response: Response) => response.json());
  }
  getKYCResultDecoded(token: any, request_id:any, result:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/credit/getKYCResultDecoded', JSON.stringify({ request_id: request_id, token: token, result:result}))
      .map((response: Response) => response.json());
  }
  doSendDebitInstruction(token: any, payment_queue: any, repayment: any, directdebitrequest: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/sendDebitInstruction', JSON.stringify({ repayment: repayment, token: token, payment_queue: payment_queue, directdebitrequest: directdebitrequest }))
      .map((response: Response) => response.json());
  }
  sendDebitInstruction(token: any, payment_queue: any, account: any,): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/doSendDebitInstruction', JSON.stringify({ token: token, payment_queue: payment_queue, directdebitrequest: account }))
      .map((response: Response) => response.json());
  }
  sendDebitInstruction_(token: any, payment_queue: any, account: any, repayment: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/doSendDebitInstruction', JSON.stringify({ token: token, payment_queue: payment_queue, directdebitrequest: account, repayment: repayment }))
      .map((response: Response) => response.json());
  }
  doSendReverseTransaction(token: any, payment_queue: any, repayment: any, directdebitrequest: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/doSendReverseTransaction', JSON.stringify({ repayment: repayment, token: token, payment_queue: payment_queue, directdebitrequest: directdebitrequest }))
      .map((response: Response) => response.json());
  }
  doClearFines(token: any, payment_queue: any, repayment: any, directdebitrequest: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/clear_fines', JSON.stringify({ repayment: repayment, token: token, payment_queue: payment_queue, directdebitrequest: directdebitrequest }))
      .map((response: Response) => response.json());
  }
  doCheckDirectDebitStatus(token: any, repayment: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/doCheckDirectDebitStatus', JSON.stringify({ repayment: repayment, token: token }))
      .map((response: Response) => response.json());
  }
  doCancelRemitaInflight(token: any, payment_queue: any, repayment: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/remita/doCancelRemitaInflight', JSON.stringify({ repayment: repayment, token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doCancelDirectDebit(token: any, payment_queue: any, repayment: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/doCancelDirectDebit', JSON.stringify({ repayment: repayment, token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  initializeInvites(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/platform/initializeInvites', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  doStopDirectDebitMandate(token: any, payment_queue: any, repayment: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/mandate/doStopDirectDebitMandate', JSON.stringify({ repayment: repayment, token: token, payment_queue: payment_queue }))
      .map((response: Response) => response.json());
  }
  doConfirmRepayment(token: any, value: any, payment: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/queueThePayment', JSON.stringify({ payment: payment, token: token, value: value }))
      .map((response: Response) => response.json());
  }
  queueThePayment(token: any, repayment: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/queueThePayment', JSON.stringify({ repayment: repayment, token: token }))
      .map((response: Response) => response.json());
  }
  getListOfCustomers(token: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/customer/getListOfCustomers', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getListOfCustomersInLoans(token: any) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/customer/getListOfCustomers', JSON.stringify({ token: token, type: '1' }))
      .map((response: Response) => response.json());
  }

  runOPTests() {
    const promise = new Promise((resolve, reject) => {
      this.http.get('https://creditclanapis.creditclan.com/api/v2/operations/test')
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  updateTheAgentClusterIdFromRepaid(token: any, merchant_id: any, people_people_id: any, agent_repaid_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/company/updateTheAgentClusterIdFromRepaid', JSON.stringify({ token: token, merchant_id: merchant_id, people_people_id: people_people_id, agent_repaid_id: agent_repaid_id }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  updateTheClusterIdFromRepaid(token: any, creditclan_cluster_id: any, repaid_cluster_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/company/updateTheClusterIdFromRepaid', JSON.stringify({ token: token, creditclan_cluster_id: creditclan_cluster_id, repaid_cluster_id: repaid_cluster_id }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  sendBVNRequest(token: any, request_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendBVNRequest', JSON.stringify({ token: token, request_id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  completePaymentConfirm(token: any, payment_queue: any, schedule_type: any, record_type: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v3/request/completePaymentConfirm', JSON.stringify({ token: token, payment_queue: payment_queue, schedule_type: schedule_type, record_type: record_type }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  sendRequestForData(token: any, request_id: any, data: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendRequestForData', JSON.stringify({ token: token, request_id: request_id, data: data }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  sendRequestForCustomerData(token: any, request_id: any, data: any, ask_for_email:any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendRequestForCustomerDatas', JSON.stringify({ ask_for_email:ask_for_email,token: token, request_id: request_id, data: data }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  sendCardValuation(token: any, request_id: any, collateral_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/carvaluation/do_car_valuation', JSON.stringify({ token: token, request_id: request_id, collateral_id: collateral_id }))
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  sendContractDocumentRequest(token: string, request_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendContractDocumentRequest', JSON.stringify({ token: token, request_id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  startProcessLenderQueue(token: any, value: any, otp:any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/startProcessLenderQueue', JSON.stringify({ token: token, data: value, SECURITY_QUESTION_ANSWER: otp }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  sendKYCToAfri(data: any, apkey:any) {
    const headers = new Headers();
    headers.append('x-api-key', apkey);
    const options = new RequestOptions({ headers: headers });
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://avsapi.africacodes.net/index.php/avs/request', data, options)
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  sendAccountConfirmationRequest(token: string, request_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendAccountConfirmationRequest', JSON.stringify({ token: token, request_id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;

  }
  confirmAcceptLoanMarketOffer(token, REQUEST_LENDER_ID, REQUEST_ID) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/acceptMarketLoanOffer', JSON.stringify({ token: token, offer_id: REQUEST_LENDER_ID, request_id: REQUEST_ID }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;

  }
  confirmRejectLoanMarketOffer(token, REQUEST_LENDER_ID, REQUEST_ID) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/rejectMarketLoanOffer', JSON.stringify({ token: token, offer_id: REQUEST_LENDER_ID, request_id: REQUEST_ID }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;

  }
  sendCardRequest(token: any, request_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v3/request/sendCardRequest', JSON.stringify({ token: token, request_id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  inviteForAnalytics(token: any, request_id: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v3/request/inviteForAnalytics', JSON.stringify({ token: token, request_id: request_id }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  sendForGuarantors(token: string, request_id: any, financial_docs: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendForGuarantors', JSON.stringify({ token: token, request_id: request_id, financial_request: financial_docs }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  sendForOtherDocuments(token: string, request_id: any, financial_docs: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/loan/sendForOtherDocuments', JSON.stringify({ token: token, request_id: request_id, financial_request: financial_docs }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;
  }
  deleteEmployee(token: any, employee: any) {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/deleteEmployee', JSON.stringify({ token: token, employee: employee }))
      .map((response: Response) => response.json());
  }
  getSlimAppSettings(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/getSlimAppSettings', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getGuarantorContacts(request_id: any) {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/tope`, { loan_request_id: request_id })
      .map((response: Response) => response.json());
  }
  // accounts codes start here
  saveJournal(token: any, savedJournals: any, journal: any) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/savejournal`, {
        token: token,
        journal: journal,
        transactions: savedJournals
      })
      .map((res: Response) => res.json());
  }
  getAllJournals(token) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/listalljournals`, {
        token: token
      })
      .map(res => res.json());
  }
  journalApproval(token: any, id: any) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/approvejournal`, {
        token: token,
        journal_id: id
      })
      .map(res => res.json());
  }
  journalRejection(token: any, id: any, rejection_notes, rejection_id) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/rejectjournal`, {
        token: token,
        journal_id: id,
        REJECT_NOTES: rejection_notes,
        REJECT_REASON_ID: rejection_id
      })
      .map(res => res.json());
  }
  // move to next journal
  nextJournal(token: string, journal_id: any, next: boolean): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/nextorpreviousjournal`, {
        token: token,
        journal_id: journal_id,
        next: next
      })
      .map(res => res.json());
  }
  nextJournals(token: string, next: boolean, pagenumber: number): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/listalljournals`, {
        token: token,
        next: next,
        pagenumber: pagenumber
      })
      .map(res => res.json());
  }
  reOpenJournal(id: any, token: string): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/settopending`, {
        journal_id: id,
        token: token
      })
      .map(res => res.json());
  }
  deleteJournal(id: any, token: string): Observable<any> {
    return this.http
      .post(
        `
https://eligibilitytest.creditclan.com/public/deletejournal`,
        { journal_id: id, token: token }
      )
      .map(res => res.json());
  }
  bulkApprove(ids, token, check_all) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/bulkapprovejournals`, {
        journal_ids: ids,
        token: token,
        check_all: check_all
      })
      .map(res => res.json());
  }
  listOneJournal(token: any, id: any) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/listonejournal`, {
        token: token,
        journal_id: id
      })
      .map(res => res.json());
  }
  localAccounts(token: any, pagenumber: any, next: any, category: any, search: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/localaccounts`, {
        token: token,
        pagenumber: pagenumber,
        next: next,
        category: category,
        search: search
      })
      .map(res => res.json());
  }
  globalAccounts() {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/globalaccounts`, {})
      .map(res => res.json());
  }
  addNewAccount(token: any, account_id: any, name: any, GROUP_ID: any, group_type: any, GL_PARENT: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/addnewaccount`, {
        token,
        account_id,
        name,
        GROUP_ID,
        group_type,
        GL_PARENT
      })
      .map(res => res.json());
  }
  getAccountGroups(token: string, group_type: string): Observable<any> {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/accountgroups`, {
      token,
      group_type
    }).map(res => res.json());
  }
  editAccount(token: any, new_name: any, id: any, group: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/editaccount`, {
        token: token,
        NEW_NAME: new_name,
        account_id: id
      })
      .map(res => res.json());
  }
  deleteAccount(token: any, id: any): Observable<any> {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/deleteaccountcharts`, { token: token, chartaccount_id: id }).map(res => res.json());
  }
  saveEReminderSettings(token: any, data: any) {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/AddorUpdateReminderSetting`, { token: token, data: data }).map(res => res.json());
  }
  getEReminderSettings(token: any) {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/getAllReminderSettingsForLender`, { token: token }).map(res => res.json());
  }
  saveESMSReminderSettings(token: any, data: any) {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/AddorUpdateSMSReminderSetting`, { token: token, data: data }).map(res => res.json());
  }
  getGeolocationSettings(token: any, product_id: any) {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/getGeolocationSettingsForLender`, { token: token, product_id: product_id }).map(res => res.json());
  }
  saveGeolocationSettings(token: any, locations: any, product_id: any) {
    return this.http.post(`https://creditclanapis.creditclan.com/api/v2/platform/addGeolocationSettingsForLender`, JSON.stringify({ token: token, data: locations, product_id: product_id })).map(res => res.json());
  }
  editJournal(
    token: any,
    journal_id: any,
    journal: any,
    transactions: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/editjournal`, {
        token: token,
        journal_id: journal_id,
        journal: journal,
        transactions: transactions
      })
      .map(res => res.json());
  }
  // Journal reports
  loadJournalReports(
    token: any,
    start_date: any,
    end_date: any,
    loan_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/journalreport`, {
        token: token,
        start_date: start_date,
        end_date: end_date,
        loan_id: loan_id
      })
      .map(res => res.json());
  }
  downloadJournalReports(
    token: any,
    start_date: any,
    end_date: any,
    loan_id: any
  ): Observable<any> {
    return this.http
      .post(`https://creditclanapis.creditclan.com/api/v2/reports/downloadJournalReports`, {
        token: token,
        start_date: start_date,
        end_date: end_date,
        loan_id: loan_id
      })
      .map(res => res.json());
  }
  loadCustomerJournalReports(
    token: any,
    start_date: any,
    end_date: any,
    customer_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/customerjournalreport`, {
        token: token,
        start_date: start_date,
        end_date: end_date,
        customer_id: customer_id
      })
      .map(res => res.json());
  }
  loadGeneralLedgerReports(
    token: any,
    start_date: any,
    end_date: any,
    account_type: any
  ) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/generaledgereport`, {
        token: token,
        start_date: start_date,
        end_date: end_date,
        account_type: account_type
      })
      .map(res => res.json());
  }
  loadCustomerLedgerReports(
    token: any,
    start_date: any,
    end_date: any,
    account_type: any
  ) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/customerledgereport`, {
        token: token,
        start_date: start_date,
        end_date: end_date,
        account_type: account_type
      })
      .map(res => res.json());
  }
  loadTrialBalance(token: any, start_date: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/trialbalance`, {
        token: token,
        start_date: start_date
      })
      .map(res => res.json());
  }
  searchForJournal(
    token: any,
    start_date_value: any,
    end_date_value: any,
    status_value: any,
    journal_no_value,
    source_value: any,
    narration: any,
    loan_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/searchalljournals`, {
        token: token,
        start_date_value: start_date_value,
        end_date_value: end_date_value,
        status_value: status_value,
        journal_no_value: journal_no_value,
        source_value: source_value,
        narration: narration,
        loan_id: loan_id
      })
      .map(res => res.json());
  }
  displayAccounts(token: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/displayaccounts`, {
        token: token
      })
      .map(res => res.json());
  }
  addPlGroup(
    token: any,
    group_title: string,
    group_category_id: any,
    account_ids: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/addplgroup`, {
        token: token,
        group_title: group_title,
        group_category_id: group_category_id,
        account_ids: account_ids
      })
      .map(res => res.json());
  }
  listPlGroup(token: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/listplgroup`, {
        token: token
      })
      .map(res => res.json());
  }
  updatePlGroup(
    token: any,
    group_title: string,
    group_category_id: any,
    account_ids: any,
    group_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/updateplgroup`, {
        token: token,
        group_title: group_title,
        group_category_id: group_category_id,
        account_ids: account_ids,
        group_id: group_id
      })
      .map(res => res.json());
  }
  deletePlGroup(token: any, group_id: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/deleteplgroup`, {
        token: token,
        group_id: group_id
      })
      .map(res => res.json());
  }
  moveGroupOrder(
    token: any,
    group_id: any,
    up: boolean,
    down: boolean,
    group_category_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/movegrouporder`, {
        token: token,
        group_id: group_id,
        up: up,
        down: down,
        group_category_id: group_category_id
      })
      .map(res => res.json());
  }
  deleteAccountFromGroup(
    token: any,
    group_item_id: any,
    account_id: any
  ): Observable<any> {
    return this.http
      .post(
        `https://eligibilitytest.creditclan.com/public/deleteaccountfromplgroup`,
        { token: token, group_item_id: group_item_id, account_id: account_id }
      )
      .map(res => res.json());
  }
  moveAccountOrder(
    token: any,
    group_id: any,
    left: boolean,
    right: boolean,
    group_item_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/moveaccountorder`, {
        token: token,
        group_id: group_id,
        left: left,
        right: right,
        group_item_id: group_item_id
      })
      .map(res => res.json());
  }
  listBsGroup(token: any) {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/listbsgroup`, {
        token: token
      })
      .map(res => res.json());
  }
  getBSGroupsByCategory(
    token: any,
    category_id
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/getBSGroupsByCategory`, {
        token: token,
        category_id: category_id,
      })
      .map(res => res.json());
  }
  getPLGroupsByCategory(
    token: any,
    category_id
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/getPLGroupsByCategory`, {
        token: token,
        category_id: category_id,
      })
      .map(res => res.json());
  }
  addBsGroupByDefault(
    token: any,
    group_category_id,
    account_ids: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/addBsGroupByDefault`, {
        token: token,
        group_category_id: group_category_id,
        account_ids: account_ids
      })
      .map(res => res.json());
  }
  addPlGroupByDefault(
    token: any,
    group_category_id,
    account_ids: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/addPlGroupByDefault`, {
        token: token,
        group_category_id: group_category_id,
        account_ids: account_ids
      })
      .map(res => res.json());
  }
  addBsGroup(
    token: any,
    group_title: any,
    group_category_id,
    account_ids: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/addbsgroup`, {
        token: token,
        group_title: group_title,
        group_category_id: group_category_id,
        account_ids: account_ids
      })
      .map(res => res.json());
  }
  updateBsGroup(
    token: any,
    group_title: string,
    group_category_id: any,
    account_ids: any,
    group_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/updatebsgroup`, {
        token: token,
        group_title: group_title,
        group_category_id: group_category_id,
        account_ids: account_ids,
        group_id: group_id
      })
      .map(res => res.json());
  }
  deleteBsGroup(token: any, group_id: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/deletebsgroup`, {
        token: token,
        group_id: group_id
      })
      .map(res => res.json());
  }
  moveBsGroupOrder(
    token: any,
    group_id: any,
    up: boolean,
    down: boolean,
    group_category_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/movebsgrouporder`, {
        token: token,
        group_id: group_id,
        up: up,
        down: down,
        group_category_id: group_category_id
      })
      .map(res => res.json());
  }
  moveBsAccountOrder(
    token: any,
    group_id: any,
    left: boolean,
    right: boolean,
    group_item_id: any
  ): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/movebsaccountorder`, {
        token: token,
        group_id: group_id,
        left: left,
        right: right,
        group_item_id: group_item_id
      })
      .map(res => res.json());
  }
  deleteAccountFromBsGroup(
    token: any,
    group_item_id: any,
    account_id: any
  ): Observable<any> {
    return this.http
      .post(
        `https://eligibilitytest.creditclan.com/public/deleteaccountfrombsgroup`,
        { token: token, group_item_id: group_item_id, account_id: account_id }
      )
      .map(res => res.json());
  }
  getPlStatement(token: any, start_date: any, end_date: any, show_actual: any): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/plstatementnew`, {
        token: token,
        start_date: start_date,
        end_date: end_date,
        show_actual: show_actual
      })
      .map(res => res.json());
  }
  getBalanceSheetStatement(token: any, date: any): Observable<any> {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/bsstatement`, { token: token, date: date }).map(res => res.json());
  }
  searchForCustomer(token: any, search_name: string): Observable<any> {
    return this.http
      .post(
        `https://eligibilitytest.creditclan.com/public/customerswithbookedloans`,
        { token: token, search_name: search_name }
      )
      .map(res => res.json());
  }
  selectCustomer(token: any, customer_id: string): Observable<any> {
    return this.http
      .post(`https://eligibilitytest.creditclan.com/public/customerloans`, {
        token: token,
        customer_id: customer_id
      })
      .map(res => res.json());
  }
  postTransaction(transaction_details) {
    return this.http.post(`https://eligibilitytest.creditclan.com/public/savejournalform`, transaction_details).map(res => res.json());
  }
  // account codes end here
  /// Start sms api
  dueSms(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/smsDueReminder', { token: token, smsDue: value })
      .map((response: Response) => response.json());
  }

  getDueSms(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getDueReminder', { token: token })
      .map((response: Response) => response.json());
  }

  latenessSms(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/smsLatenessNotification', { token: token, smsLateness: value })
      .map((response: Response) => response.json());
  }

  getLatenessSms(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/server/getSmsLatenessNotification', { token: token })
      .map((response: Response) => response.json());
  }
  saveProductAccounts(token: any, value: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/operations/save_account', JSON.stringify({ token: token, data: value }))
      .map((response: Response) => response.json());
  }
  getRecommendations(data: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/recommendation/getLoanRecommendation', data)
      .map((r: Response) => r.json());
  }
  getPaymentsMadeByTheAgentByName(creditclan_id: any, agent_id:any, name:any): Observable<any> {
    // get users from api
    // const headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    // const options = new RequestOptions({ headers: headers });
    // const url = "https://repaidserver.creditclan.com/merchant/"+creditclan_id+"/payments/"+agent_id;
    // return this.http.get(url)
    //   .map((response: Response) => response.json());

      let data = {
        name: name,
        creditclan_id: creditclan_id,
        agent_id: agent_id
      }
      // get users from api
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const options = new RequestOptions({ headers: headers });
        return this.http.post('https://repaidserver.creditclan.com/merchant/get-payments-by-customer-name',  data , options)
        .map((response: Response) => response.json());
  }
  getPaymentsMadeByTheAgentByDate(creditclan_id: any, agent_id:any, start_date: any, end_date:any): Observable<any> {
    let data = {
      start_date: start_date,
      end_date: end_date,
      creditclan_id: creditclan_id,
      agent_id: agent_id
    }
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
      return this.http.post('https://repaidserver.creditclan.com/merchant/get-payments-by-date',  data , options)
      .map((response: Response) => response.json());
  }
  saveRecommendations(data: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/recommendation/saveRecommendation', data)
      .map((r: Response) => r.json());
  }
  deleteRecommendation(data: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/recommendation/deleteRecommendation', data)
      .map((r: Response) => r.json());
  }
  saveCollateral(token: any, request_id: any, data: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/collateral/saveCollateral', JSON.stringify({ token: token, data: data, request_id: request_id }))
      .map((r: Response) => r.json());
  }
  getCollaterals(token: any, request_id: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/collateral/getCollaterals', JSON.stringify({ token: token, request_id: request_id }))
      .map((r: Response) => r.json());
  }
  deleteCollateral(data: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/collateral/deleteCollateral', data)
      .map((r: Response) => r.json());
  }
  updateChartTrans(token: any, data: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/updateChartTrans', JSON.stringify({ token: token, data: data }))
      .map((r: Response) => r.json());
  }
  updateJournalEntry(token: any, data: any): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/updateJournalEntry', JSON.stringify({ token: token, data: data }))
      .map((r: Response) => r.json());
  }
  repaymentDistribution(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/repaymentDistribution', JSON.stringify({ filter: filter, token: token }))
      .map((response: Response) => response.json());
  }
  getLoanPortfolioByPar(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanPortfolioByPar', JSON.stringify({ filter: filter, token: token }))
      .map((response: Response) => response.json());
  }
  getPaymentRollback(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getPaymentRollback', JSON.stringify({ filter: filter, token: token }))
      .map((response: Response) => response.json());
  }
  getWalletReport(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getWalletReport', JSON.stringify({ filter: filter, token: token }))
      .map((response: Response) => response.json());
  }
  getEmployeesReport(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getEmployeesReport', JSON.stringify({ filter: filter, token: token }))
      .map((response: Response) => response.json());
  }
  getEmployeePerformanceReport(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getEmployeePerformanceReport', JSON.stringify({ filter: filter, token: token }))
      .map((response: Response) => response.json());
  }
  getEmployeePortfolioReport(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getEmployeePortfolioReport', JSON.stringify({ filter: filter, token: token }))
      .map((response: Response) => response.json());
  }
  getBranchReport(token: any, filter: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getBranchReport', JSON.stringify({ filter: filter, token: token }))
      .map((response: Response) => response.json());
  }
  searchCustomer(search: any, token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/custom/allCustomers', { token: token, searchTerm: search, start: '0' })
      .map((response: Response) => response.json());
  }
  sendBulkSMS(token: string, subject: any, message: any, users: any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/services/sendBulkSMS', JSON.stringify({ token: token, subject: subject, message: message, users: users }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;

  }
  sendEmailToEmployees(token, subject, message,is_for_all_employee:any, employee:any ): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/services/sendEmailToEmployee', JSON.stringify(
      {
        token: token,
        subject: subject,
        massage: message,
        is_for_all_employee: is_for_all_employee,
        employee: employee
      }))
      .map((response: Response) => response.json());
  }
  sendEmailToEmployee(token: string, subject: any, message: any, is_for_all_employee:any, employee:any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/services/sendEmailToEmployee', JSON.stringify({ is_for_all_employee:is_for_all_employee,employee:employee,token: token, subject: subject, message: message }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;

  }
  viewAgentsCollectionsByName(creditclan_id: any, agent_id:any, name:any): Observable<any> {
    let data = {
      name: name,
      creditclan_id: creditclan_id,
      agent_id: agent_id
    }
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
      return this.http.post('https://repaidserver.creditclan.com/merchant/get-collections-by-customer-name',  data , options)
      .map((response: Response) => response.json());
  }
  viewAgentsCollectionsByDate(creditclan_id: any, agent_id:any, start_date: any, end_date:any): Observable<any> {
    let data = {
      start_date: start_date,
      end_date: end_date,
      creditclan_id: creditclan_id,
      agent_id: agent_id
    }
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
      return this.http.post('https://repaidserver.creditclan.com/merchant/get-collections-by-date',  data , options)
      .map((response: Response) => response.json());
  }
  viewAllMerchantsPaymentsByName(creditclan_id: any, name: any): Observable<any> {
    let data = {
      name: name,
      creditclan_id: creditclan_id
    }
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
      return this.http.post('https://repaidserver.creditclan.com/merchant/all-payments-unapproved-by-name',  data , options)
      .map((response: Response) => response.json());
  }
  viewAllMerchantsPaymentsByDate(creditclan_id: any, start_date: any, end_date:any): Observable<any> {
    let data = {
      start_date: start_date,
      end_date: end_date,
      creditclan_id: creditclan_id
    }
    // get users from api
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const options = new RequestOptions({ headers: headers });
      return this.http.post('https://repaidserver.creditclan.com/merchant/all-payments-unapproved-by-date',  data , options)
      .map((response: Response) => response.json());
  }
  sendBulkEmail(token: string, subject: any, message:any, users:any) {
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://creditclanapis.creditclan.com/api/v2/services/sendBulkEmail', JSON.stringify({ token: token, subject: subject, message:message, users:users }))
        .toPromise()
        .then(
          res => { // Success
            //console.log(res.json());
            resolve();
          }
        );
    });
    return promise;

  }
  prepareDisbursementQueue(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/prepareDisbursementQueue', data)
    .map((response: Response) => response.json());
  }
  getSchoolRequests(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/schools', data)
    .map((response: Response) => response.json());
  }
  getRentRequests(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/rent', data)
    .map((response: Response) => response.json());
  }
  getTeachersRequests(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/teachers', data)
    .map((response: Response) => response.json());
  }
  getSchoolSMERequests(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/schoolssme', data)
    .map((response: Response) => response.json());
  }
  refreshTeachersRequests(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/teachers_refresh', data)
    .map((response: Response) => response.json());
  }
  refreshSchoolRequests(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/schools_refresh', data)
    .map((response: Response) => response.json());
  }
  SchoolSMERequests(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/parents_refresh', data)
    .map((response: Response) => response.json());
  }
  getPOSRequests(data: object): Observable<any> {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/market/pos', data)
    .map((response: Response) => response.json());
  }
  getEcosystemRequestAmountSettings(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getEcosystemRequestAmountSettings', data)
    .map((response: Response) => response.json());
  }
  saveEcosystemRequestAmountSettings(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/saveEcosystemRequestAmountSettings', data)
    .map((response: Response) => response.json());
  }
  deleteEcosystemRequestAmountSettings(band_id:any): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/deleteEcosystemRequestAmountSettings', JSON.stringify({band_id:band_id}))
    .map((response: Response) => response.json());
  }
  saveAllowedEcosysetms(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/saveAllowedEcosysetms', data)
    .map((response: Response) => response.json());
  }
  getAllowedEcosystems(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getAllowedEcosysetms', data)
    .map((response: Response) => response.json());
  }
  getTheMerchantRequest(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheMerchantRequest', data)
    .map((response: Response) => response.json());
  }
  getTheEatRequest(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheEatRequest', data)
    .map((response: Response) => response.json());
  }
  getTheRentRequest(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheRentRequest', data)
    .map((response: Response) => response.json());
  }
  getTheParentRequest(data: object): Observable<any> {
    return this.http.post('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getTheParentRequest', data)
    .map((response: Response) => response.json());
  }
  changePhone({ phone, token }) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/change_phone', { phone, token })
      .map(response => response.json());
  }
  confirmOtp({ otp, token }) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/confirm_otp', { otp, token })
      .map(response => response.json());
  }
  updatePartnerInfo(data) {
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/employee/update_partner_info', data)
      .map(response => response.json());
  }
  getPrincipalOutstanding(token: any, start: any, lp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getPrincipalOutstanding', JSON.stringify({ start: start, token: token, lp:lp }))
      .map((response: Response) => response.json());
  }
  getLoanReport(token: any, start: any, lp:any, status:any, loan_id:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanReport', JSON.stringify({ start: start, token: token, lp:lp, status:status, loan_id:loan_id }))
      .map((response: Response) => response.json());
  }
  getLoanRecords(token: any, lp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanRecords', JSON.stringify({ token: token, lp:lp }))
      .map((response: Response) => response.json());
  }
  getLoanReportProducts(token: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanReportProducts', JSON.stringify({ token: token }))
      .map((response: Response) => response.json());
  }
  getRecordsSchedules(token: any, loan_id:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getRecordsSchedules', JSON.stringify({ token: token, loan_id:loan_id }))
      .map((response: Response) => response.json());
  }
  getLoanArrearsAgeing(token: any, start: any, lp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanArrearsAgeing', JSON.stringify({ start: start, token: token, lp:lp }))
      .map((response: Response) => response.json());
  }
  getLoanArrearsAgeings(token: any, start: any, lp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanArrearsAgeings', JSON.stringify({ start: start, token: token, lp:lp }))
      .map((response: Response) => response.json());
  }
  getLoanArrearsAgeingss(token: any, start: any, lp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanArrearsAgeingss', JSON.stringify({ start: start, token: token, lp:lp }))
      .map((response: Response) => response.json());
  }
  getLoanArrearsAgeingsss(token: any, start: any, lp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanArrearsAgeingsss', JSON.stringify({ start: start, token: token, lp:lp }))
      .map((response: Response) => response.json());
  }
  getLoanArrearsAgeingssss(token: any, start: any, lp:any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getLoanArrearsAgeingssss', JSON.stringify({ start: start, token: token, lp:lp }))
      .map((response: Response) => response.json());
  }
  getCollectionReports(token: any, start: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getCollectionReports', JSON.stringify({ start: start, token: token }))
    .map((response: Response) => response.json());
  }
  getCollections(token: any, record: any): Observable<any> {
    // get users from api
    return this.http.post('https://creditclanapis.creditclan.com/api/v2/reports/getCollections', JSON.stringify({ record: record, token: token }))
    .map((response: Response) => response.json());
  }
  getInterestAccruedReport(data): Observable<any>  {
    //console.log(data);
    return this.http.
    post('https://creditclanapis.creditclan.com/api/v2/server/getInterestAccruedReport', {
      token: data.TOKEN,
      legal_name: data.NAME,
      state_id: data.STATE,
      occupation_id: data.OCCUPATION,
      gender: data.GENDER,
      home_state: data.HOME_STATE,
      work_sector: data.WORK_SECTOR,
      work_designation: data.WORK_DESIGNATION,
      marital_status: data.MARITAL_STATUS,
      min_salary_range: data.MIN_SALARY,
      max_salary_range: data.MAX_SALARY,
      education_qualification: data.QUALIFICATION,
      request_principal: data.REQUEST_PRINCIPAL,
      branch_id: data.branch_id,
      company_id: data.company_id,
      start:data.start,
      limit:data.limit,
      is_export:data.is_export,
      report_types:data.report_types,
      product_id:data.product_id,
      request_status:data.request_status,
      date_start:data.date_start,
      date_end:data.date_end
    })
    .map((response: Response) => response.json());
  }
}
