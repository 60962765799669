import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from 'src/app/_services/constants.service';
import { StorageService } from 'src/app/_services';

@Component({
  selector: 'app-bvndetails-card',
  templateUrl: './bvndetails-card.component.html',
  styleUrls: ['./bvndetails-card.component.css']
})
export class BvndetailsCardComponent implements OnInit {

  @Input('currentUser') currentUser: any;
  @Input() reqid: any;
  @Input() cid = '0';
  @Output() more = new EventEmitter();
  @Input() from = '0';

  bvn_details: any;
  loading = false;

  constructor(
    private http: HttpClient,
    private constant: ConstantsService,
    private storageService: StorageService,
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }

  ngOnInit() {
    this.getBVNDetails();
  }

  getBVNDetails() {
    this.loading = true;
      return this.http.post<any>(`${this.constant.read('api_loan')}loan/bvn`, {
        request_id: this.reqid,
        token: this.currentUser.token,
        customer_id: this.cid,
        from: this.from
      }).subscribe(response => {
        this.loading = false;
        if (response.status) {
          this.bvn_details = response.data;
        }
      }, error => {
        //console.log(error);
      });
   
  }
}
