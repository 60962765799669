import { StorageService } from './../../_services/storage.service';
import { CustomerService } from './../../_services/customer.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OptionsserviceService, LoansService, DataService } from 'src/app/_services';

@Component({
  selector: 'app-customerinfoonly',
  templateUrl: './customerinfoonly.component.html',
  styleUrls: ['./customerinfoonly.component.css']
})
export class CustomerinfoonlyComponent implements OnInit {

  @Input() customer: any;
  @Input() loan: any;
  @Input() reqid = '';
  @Input() cid = '';
  @Input() pid = '';
  @Input() from = '';
  @Output() refresh = new EventEmitter();
  @Output() requestingData = new EventEmitter();
  @Input('from_loan') from_loan = true;
  @Input('show_header') show_header = true;
  @Input('customer_type') customer_type: any;
  @Input() is_individual = true;
  @Input() embeded = false;
  @Input() checklist;
  @Output() setMore = new EventEmitter();
  @Output() openModalChild = new EventEmitter();
  currentUser: any;
  loading = false;
  loadingCards = false;
  editing = "";
  anio: number = 0;
  states: any;
  @Input('lgas') lgas: any;
  sectors: any;
  occupations: any;
  edu_sectors: any;
  designations: any;
  card: any = {};
  account: any = {};

  constructor(
    public loansService: LoansService,
    private customerService: CustomerService,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public dataService: DataService
  ) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  
  ngOnInit() {
    this.anio = new Date().getFullYear();
    this.optionsService.getSec().subscribe(sectors => (this.sectors = sectors));
    this.optionsService.getOcc().subscribe(sectors => (this.occupations = sectors));
    this.optionsService.getDes().subscribe(sectors => (this.designations = sectors));
    this.optionsService.getEdu().subscribe(sectors => (this.edu_sectors = sectors));
    this.optionsService.getStates().subscribe(sectors => (this.states = sectors));
  }
  
  loadLGAs(event) {
    this.optionsService.getLGAs(this.currentUser.token, event).subscribe(lgas => this.lgas = lgas.lgas);
  }

  saveBio() {
    this.loading = true;
    this.customerService.updateCustomer(this.currentUser.token, this.reqid, this.pid, { ...this.customer.profile, ...this.customer.home_address })
      .subscribe(data => {
        this.loading = false;
        this.refresh.emit();
      });
  }
  saveWork() {
    this.loading = true;
    this.customerService.updateWorkNew(this.currentUser.token, this.reqid, this.pid, {
      ...this.customer.work
    })
      .subscribe(data => {
        this.loading = false;
        this.refresh.emit();
      });
  }
  saveExpenses() {
    this.loading = true;
    this.customerService.updateCustomer(this.currentUser.token, this.reqid, this.pid, {
      ...this.customer.expenses
    })
      .subscribe(data => {
        this.loading = false;
        this.refresh.emit();
      });
  }
  
  saveNok() {
    this.loading = true;
    this.customerService.updateCustomer(this.currentUser.token, this.reqid, this.pid, {
      ...this.customer.next_of_kin,
    })
      .subscribe(data => {
        this.loading = false;
        this.refresh.emit();
      });
  }
  getAge(date) {
    return new Date().getFullYear() - new Date(date).getFullYear();
  }
  requestForData(section, info_type) {
    this.requestingData.emit({ priority:'customer',section: section, link_type: info_type, customer_details: {people_id: this.pid} });
  }
  sendRequestForWorkEmail(){
    this.requestingData.emit({ priority:'customer',section: 'Work Email', link_type: '100', customer_details: {people_id: this.pid} });
  }
}
