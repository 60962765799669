<div class="flex">
  <div class="col-md-12 main-center pl_0 sticky" style="padding-left : 0px !important"
    [ngClass]="{'pr_0 pl_0': view_from=='approvals', 'main-center pl_0':  view_from!='approvals', 'no-padding':  view_from == 'drawer'}">
    <app-contractheader *ngIf="loan?.loan_status < 2 && view_from !='approvals' && !requestId"
      [request_id]="loan?.request_id"></app-contractheader>
    <app-applicationportfolioheader *ngIf="loan?.loan_status >= 3 && view_from !='approvals' && !requestId"
      [loan]="loan" [request_id]="parentRouteId"></app-applicationportfolioheader>
    <app-currentapprovallevel (openWorkFlow)="openWorkFlow.emit($event)" [loan_]="loan" *ngIf="has_workflow && loan?.loan_status < 3 && view_from !='approvals'"
      [request_id]="loan?.request_id">
    </app-currentapprovallevel>
    <app-timeline [request_id]="loan?.request_id" [where]='requestId ? 1 : 2'></app-timeline>
  </div>
</div>