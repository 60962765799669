

<div *ngIf="!loadingData" class="overpanel-scroll">
  <div *ngIf="!mandate_history"> 

    <div
      *ngIf="!sending_account_request && !sending_direct_request && !checking_mandate_status && !cancelling_dd && !resending_link">
      <div class="flex flex-column pd_20" *ngIf="!loading">
        <div class="card mb-4" *ngFor="let account of accounts">
          <div class="flex justify-between align-center">
            <span class="bank">{{account?.bank_name}}</span>
            <div class="flex">
              <span class="tag blue mr-2" *ngIf="account?.direct_debit_exists === true">DD Attached</span>
              
            </div>
          </div>
          <div class="number">{{account?.account_number}}</div>
          <div class="name">{{account?.account_name}}</div>
          <div *ngIf="account.CARD_LAST_NAME"><a href="{{account.CARD_LAST_NAME}}" target="_blank">View Mandate Form</a></div>
          <div *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE =='1'" class="name">Staus : Active</div>
          <div *ngIf="account?.direct_debit_exists && account?.direct_debit_request?.IS_ACTIVE =='0'" class="name">Staus : Pending</div>
          <div *ngIf="!account?.direct_debit_exists" class="name">Staus : No Mandate</div>
          <div *ngIf="account?.direct_debit_exists" class="name">ID : {{account?.DIRECT_DEBIT_REQUEST?.DD_MANDATE_ID}}</div>
        </div>
      </div>
    </div>
 
  </div> 
</div> 