<table class="table table-stripped">
  <tbody>
    <tr *ngFor="let record of data">
      <td>
        {{record.AMOUNT_PAID|number:'.2-2'}}
      </td>
      <td>
        {{record.DATE_PAID}}
      </td>
      <td>
        {{record.PAID_THROUGH}}
      </td>
    </tr>

  </tbody>
</table>