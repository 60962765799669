import { Component, OnInit, OnDestroy, Output, Input, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService, OptionsserviceService, LoansService, StorageService, OperationsService } from '../../_services/index';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-uploadpayments',
  templateUrl: './uploadpayments.component.html',
  styleUrls: ['./uploadpayments.component.css']
})
export class UploadpaymentsComponent implements OnInit {
  send_payments_to_queue:any;
  result: any;
  attachments: any;
  currentUser: any;
  description = '';
  file_field: any;
  loading = false;
  file_type = '2';
  photoHasBeenUploaded = false;
  fileHasBeenUploaded = false;
  doc_types: any;
  DOCTYPE: any;
  @ViewChild('file') file;
  param = {
    'search_string':''
  }
  filesUploaded = [];
  public files: Set<File> = new Set();
  uploadingfile = false;
  filesSaved = false;
  due_loans:any;
  public banks:any;
  finalpay = false;

  constructor(private sanitizer: DomSanitizer,
    private DataService: DataService,
    public route: ActivatedRoute,
    public storageService: StorageService,
    public optionsService: OptionsserviceService,
    public loansService: LoansService,
    public operationsService: OperationsService,
    public toastr: ToastrService) {
    this.currentUser = this.storageService.read<any>('currentUser');
  }
  fileUploadeds(event) {
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  ngOnInit() {
    this.getBanks();
    this.getDueRepayments();
  }
  getDueRepayments(){
    this.loading = true;
    this.operationsService.getBulkRepayments(this.currentUser.token,this.param)
      .subscribe(result => {
        this.loading = false;
        // this.has_data = result.has_data;
        // this.repayments = result.message;
        this.send_payments_to_queue = result.send_payments_to_queue;
      });
  }
  uploadTheFile() {

    this.loading = true;
    this.operationsService.uploadTheLoanPhoto(this.currentUser.token, this.filesUploaded)
      .subscribe(result => {
        this.loading = false;
        this.result = result;
        this.filesSaved = true;
      });
  }
  getBanks() {
    this.operationsService.getBanks(this.currentUser.token)
      .subscribe(banks => {
        this.banks = banks.banks;
      });
  }

  onFilesAdded(event) {
    this.uploadingfile = true;
    let files = event.target.files;
    this.loansService.doUpload(files[0])
      .subscribe(result => {
        this.uploadingfile = false;
        if (result.status == true) {
          this.photoHasBeenUploaded = true;
          this.filesUploaded.push(result.result);
        }

      });
  }
  openUploadDialog() {
    this.file.nativeElement.click();
  }
  uploadFile(event) {
  }
  clearRecords(){
    this.result = [];
    this.filesUploaded = [];
    this.photoHasBeenUploaded=false;
  }
  // getDueLoans(){
  //   this.loading = true;
  //   this.operationsService.getDueLoanss(this.currentUser.token).subscribe(data => {
  //     this.loading = false;
  //     if (data.status) {
  //       this.due_loans = data.data;
  //     } else {
  //       alert('Data could not be exported.');
  //     }
  //   });
  // }
  downloadActiveLoans() {
    this.loading = true;
    this.operationsService.downLoadActiveLoans(this.currentUser.token).subscribe(data => {
      this.loading = false;
      if (data.status) {
        alert('Data Successfully exported. Download would start automatically.');
        window.open(data.message);
        return;
      } else {
        alert('Data could not be exported.');
      }
    });
  }
  uploadPayments(){
    this.loading = true;
    this.operationsService.uploadPayments(this.currentUser.token, this.result.result).subscribe(data => {
      this.loading = false;
      this.finalpay = true;
      this.showSuccess('Success');
    });
    // this.loading = true;
    // this.loading = false;  
    // this.finalpay = true;
    // this.showSuccess('Success');
    // this.operationsService.uploadPayments(
    //   this.currentUser.token,
    //   this.result.result
    // );
  }
  showSuccess(message) {
    this.toastr.success(message, 'Success!');
  }

  showError(message) {
    this.toastr.error(message, 'Error');
  }
  doRepayment(value,i){
console.log(value)
  }
}
